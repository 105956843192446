import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '@core/services/api.service';

const routes = {
  cadastrar: `/faq/salvar`,
  editar: (id: number) =>`/faq/editar/${id}`,
  inativar: (id: number) => `/faq/inativar/${id}`,
  ativar: (id: number) => `/faq/ativar/${id}`,
  listar: `/faq/listar`,
  listarAtivos: `/faq/listarAtivos`,
  listarAtivosPublico:`/publica/faq/listarAtivos`,
  faqPorId: (id: number) => `/faq/${id}`
}

@Injectable({
  providedIn: 'root'
})
export class FaqService {

  constructor(private apiService: ApiService) { }

  cadastrarFaq(obj: any) {
    return this.apiService.post<any>(routes.cadastrar, obj);
  }

  editarFaq(obj: any, id: number) {
    return this.apiService.put<any>(routes.editar(id), obj);
  }

  ativarFaq(id: number) {
    return this.apiService.put<any>(routes.ativar(id));
  }

  inativarFaq(id: number) {
    return this.apiService.put<any>(routes.inativar(id));
  }

  faqPorId(id: number) {
    return this.apiService.get<any>(routes.faqPorId(id));
  }

  listarFaq(obj: any, page:number,pageSize:number) {
    let params = new HttpParams();
    params = params.append('size',pageSize);
    params = params.append('page',page - 1);
    return this.apiService.post<any>(routes.listar, obj,undefined,undefined,params);
  }

  listarFaqAtivos(obj: any, page:number,pageSize:number) {
    let params = new HttpParams();
    params = params.append('size',pageSize);
    params = params.append('page',page - 1);
    return this.apiService.post<any>(routes.listarAtivos, obj,undefined,undefined,params);
  }

  listarFaqAtivosPublica(obj: any, page:number,pageSize:number) {
    let params = new HttpParams();
    params = params.append('size',pageSize);
    params = params.append('page',page - 1);
    return this.apiService.post<any>(routes.listarAtivosPublico, obj,undefined,undefined,params);
  }

}
