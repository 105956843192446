import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenticationService } from '@core/auth/authentication.service';
import { environment } from '@env/environment';
import { of } from 'rxjs';
import { ApiService } from './api.service';

const routes = {
  listarProjetos: `/relatorios/editais/listar-projetos`,
  consultarEditaisPorProjeto: (id: number) => `/relatorios/editais/projeto/${id}`
}

@Injectable({
  providedIn: 'root'
})
export class ListChamadasAtendemProjetoService {

  constructor(
    private apiService: ApiService,
    private authenticationService: AuthenticationService,
  ) { }

  listarEditais() {
    return this.apiService.getCustomApi<any>(`${environment.api}/${routes.listarProjetos}`);
  }
  
  buscarEditaisPorProjeto(id: number, page:number,pageSize:number) {
    let params = new HttpParams();
    params = params.append('size',pageSize);
    params = params.append('page',page - 1);
    return this.apiService.get<any>(`${routes.consultarEditaisPorProjeto(id)}`, undefined,params);
  }

}
