import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { ApiService } from './api.service';

const routes = {
  fotoPerfil: (id: number) => `/contas/foto-perfil/${id}`,
  fotoCapa: (id: number) => `/contas/foto-capa/${id}`,
}

@Injectable({
  providedIn: 'root'
})
export class FotosUsuarioService {

constructor(
  private apiService: ApiService
) { }

  buscarFotoPerfil(id: number) {
    return this.apiService.get<any>(routes.fotoPerfil(id));
    //return this.apiService.getCustomApi<any>(routes.fotoPerfil(id));
  }
  
  buscarFotoCapa(id: number) {
  return this.apiService.get<any>(routes.fotoCapa(id));
    //return this.apiService.getCustomApi<any>(routes.fotoPerfil(id));
  }

}
