<div class="modal-header">
  <h4 class="modal-title"></h4>
  <button type="button" class="close btn-close" aria-label="Close" (click)="fecharModal()" *ngIf="active == 1">
  </button>
</div>

<div class="modal-body mt-2 pt-sm-5 pt-3">
  <form [formGroup]="formAcessarConta" id="formAcessarConta" class="col-10 mx-auto">

    <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">

      <li [ngbNavItem]="1">
        <ng-template ngbNavContent>

          <h3 class="text-center mb-sm-5 mb-3">{{'Acessar_Conta' | translate}}</h3>

          <div class="form-floating border-bottom ">
            <input type="text" class="form-control border-0" name="username" formControlName="username" placeholder="Digite seu Login">
            <label for="username">{{'Digite_seu_Login' | translate}}</label>
          </div>

          <div class="form-floating border-bottom my-2">
            <input type="password" class="form-control border-0" name="password" formControlName="password" placeholder="Digite sua senha">
            <label for="password">{{'Digite_sua_Senha' | translate}}</label>
          </div>

          <div class="col-12 d-flex justify-content-center my-2 my-sm-4">
            <span class="text-muted h7">
              Esqueceu sua senha? <a class="text-decoration-none" (click)="recuperarSenha()">Clique aqui</a> para recuperar sua <b>senha!</b>
            </span>
          </div>

          <div class="col-12 d-flex justify-content-center my-2 my-sm-4">
            <span class="text-muted h7">
              Esqueceu seu login? <a class="text-decoration-none" (click)="recuperarUsername()">Clique aqui</a> para recuperar seu <b>login!</b>
            </span>
          </div>

          <div class="col-12 my-2">
            <button type="submit" class="btn btn-primary-color py-3 border-brada w-100 h6" (click)="submit()">
              {{'Entrar' | translate}}
            </button>
          </div>

          <div class="col-12 my-3" *ngIf="exibirErroVista">
            <div class="alert alert-danger alert-dismissible fade show" role="alert">
              {{this.message}}
              <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
            </div>
          </div>

          <section class="new-account mt-sm-5 mb-sm-3 my-3">
            <div class="col-12 text-center">
              <p class="text-muted h7 m-0 p-0">{{'Ainda_não_tem_conta' | translate}}</p>
              <button type="button" class="btn txt-secundary-color h7" (click)="openModalCadastrar()">{{'Quero_me_Cadastrar' | translate}}</button>
            </div>
          </section>

        </ng-template>

      </li>

      <li [ngbNavItem]="2">
        <ng-template ngbNavContent>

          <h3 class="text-center mb-5 txt-primary-color">{{'Escolha_o_Perfil' | translate}}</h3>

          <div class="col-12 my-4">
            <div class="row gx-4">

              <div class="col" *ngIf="formPerfilCidadao" (click)="selectPerfil(papelContaEnum.Cidadao)">
                <div class="user-perfil col mx-auto py-2 border-brada text-center shadow-sm">
                  <i class="bi bi-person fs-3 fw-400"></i>
                  <h6 class="h6 fw-400">{{'Colaborador' | translate}}</h6>
                </div>
              </div>

              <div class="col" *ngIf="ambosPapeis" (click)="selectPerfil(papelContaEnum.Empreendedor)">
                <div class="user-perfil col mx-auto py-2 border-brada text-center shadow-sm">
                  <i class="bi bi-person fs-3 fw-400"></i>
                  <h6 class="h6 fw-400">{{'Empreendedor' | translate}}</h6>
                </div>
              </div>

              <div class="col" *ngIf="ambosPapeis" (click)="selectPerfil(papelContaEnum.Patrocinador)">
                <div class="user-perfil col mx-auto py-2 border-brada text-center shadow-sm">
                  <i class="bi bi-person fs-3 fw-400"></i>
                  <h6 class="h6 fw-400">{{'Investidor' | translate}}</h6>
                </div>
              </div>
            </div>
          </div>

          <!-- <div class="form-floating border-bottom mt-3 mb-2">
            <select class="form-select border-0" aria-label="Perfil de Usuário" name="papel" formControlName="papel"  placeholder="Selecione o perfil">
              <option *ngFor="let papel of papeis" [value]="papel.nome">
                {{papel.nome}}
              </option>
            </select>
            <label for="perfil">Perfil de Usuário</label>
          </div> -->

        </ng-template>
      </li>
    </ul>
    <div [ngbNavOutlet]="nav" class="bg-white"></div>

  </form>

</div>

<div class="modal-footer"></div>
