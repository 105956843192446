import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AcaoFiltrosEnum } from '@core/models/enum/acao-filtro';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-modal-justificativa',
  templateUrl: './modal-justificativa.component.html',
  styleUrls: ['./modal-justificativa.component.scss']
})
export class ModalJustificativaComponent implements OnInit {

  exibirErroVista!: any;
  message!:any;
  tituloDescricao!: string;
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: false,
    height: '15rem',
    minHeight: '5rem',
    width: 'auto',
    minWidth: '0',
    translate: 'no',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Digite o seu comentário aqui...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' }
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: 'v1/image',
    uploadWithCredentials: false,
    sanitize: false,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      [
        //'fontSize',
        'textColor',
        'backgroundColor',
        'customClasses',
        //'link',
        //'unlink',
        'insertImage',
        'insertVideo',
        'insertHorizontalRule',
        'removeFormat',
        'toggleEditorMode'
      ]
    ]
  };

  form = this.formBuilder.group({
    justificativa:['', Validators.required]
  })

  constructor(
    public activeModal: NgbActiveModal,
    private translateService: TranslateService,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit() {
    this.definirTituloDescricao(this.tituloDescricao);
  }

  definirTituloDescricao(acao: string) {
    switch (acao) {
      case AcaoFiltrosEnum.Desbloquear:
        this.tituloDescricao = this.translateService.instant("Informe_um_motivo_para_Desbloquear")
        break;
      case AcaoFiltrosEnum.Bloquear:
        this.tituloDescricao = this.translateService.instant("Informe_um_motivo_para_Bloquear")
        break;
    }
  }

  salvarMotivo():void{
    if(this.form.invalid || this.form.controls.justificativa.value == 'null'){
      this.exibirErroVista = true;
      this.message = this.translateService.instant("Informe_um_motivo_para_finalizar");
      setTimeout(() => {
        this.exibirErroVista = false;
      }, 4000);
    }else if(this.form.valid) {
      this.activeModal.close(this.form.value);
    }
  }

  fechar():void{
    this.activeModal.close();
  }
}
