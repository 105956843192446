import { Component, HostListener, ElementRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
// import {faTrash} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: FileUploadComponent,
      multi: true
    }
  ]
})
export class FileUploadComponent implements ControlValueAccessor {

  onChange!: Function;
  file: File | null = null;
  @Input() extensoes!: string[];
  // faTrash = faTrash;
  @Input() buttonLabel!:string;
  @Input() boxLabel!:string;

  @HostListener('change', ['$event.target.files']) emitFiles(event: FileList) {
    const file = event && event.item(0);
    this.onChange(file);
    this.file = file;
  }

  corBtnPagina = "vermelho";

  constructor(private host: ElementRef<HTMLInputElement>) {
  }

  writeValue(value: null) {
    this.host.nativeElement.value = '';
    this.file = null;
  }

  registerOnChange(fn: Function) {
    this.onChange = fn;
  }

  registerOnTouched(fn: Function) {
  }

  clear(): void {
    this.writeValue(null);
  }

  getCorBtnPagina(corBtnPagina: string) {
    switch (corBtnPagina) {
      case 'vermelho':
        return 'bg-primary-color border-0 h7'
      break;
      case 'azul':
        return 'bg-secundary-color border-0 h7'
      break;
      default:
        return
        break;
    }
  }

  getCorIconPagina(corBtnPagina: string) {
    switch (corBtnPagina) {
      case 'vermelho':
        return 'txt-primary-color fs-4'
      break;
      case 'azul':
        return 'txt-secundary-color fs-4'
      break;
      default:
        return
        break;
    }
  }
}