import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '@core/auth/authentication.service';
import * as dayJS from 'dayjs';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  public anoDireitos: string = '';
  public isLoggedIn$ = new Observable<boolean>();
  landingPage: boolean = false;

  constructor(
    private authenticationService: AuthenticationService,
    private translateService: TranslateService,
    private activatedRoute: ActivatedRoute,
  ) {
    this.isLoggedIn$ = this.authenticationService.isLoggedIn;
  }

  ngOnInit() {
    this.anoDireitos = dayJS().year().toString();
    this.validarFooter();
  }

  validarFooter(): void {
    this.landingPage = (window.location.href).slice((window.location.origin).length).slice(3,14) == 'landingpage';
    const subdomain = window.location.hostname.split(".")[0];
    if (subdomain != 'somosbrada') {
      this.landingPage = true;
    }
  }

  scrollTo(className: string): void {
    const elementList = document.querySelectorAll(className);
    const element = elementList[0] as HTMLElement;
    element.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }

}
