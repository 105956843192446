import { LandingPageRoutingModule } from './landingpage-routing.module';
import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { NgxPermissionsModule } from 'ngx-permissions';

import { LandingPageChamadaComponent } from './pages/landingpage-chamada/landingpage-chamada.component';
import { LandingpageProjetosComponent } from './pages/landingpage-projetos/landingpage-projetos.component';
import { LandingpageVotacaoPopularComponent } from './pages/landingpage-votacao-popular/landingpage-votacao-popular.component';
import { LandingpageChamadaV2Component } from './pages/landingpage-chamada-v2/landingpage-chamada-v2.component';

@NgModule({
  declarations: [
    LandingPageChamadaComponent,
    LandingpageProjetosComponent,
    LandingpageVotacaoPopularComponent
  ],
  exports: [
    LandingPageChamadaComponent,
    LandingpageProjetosComponent,
    LandingpageVotacaoPopularComponent
  ],
  imports: [
    SharedModule,
    LandingPageRoutingModule
  ]
})
export class LandingPageSharedModule { }
