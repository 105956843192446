import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { FaqService } from '@core/services/faq.service';
import { environment } from '@env/environment';

@Component({
  selector: 'app-faq-publica',
  templateUrl: './faq-publica.component.html',
  styleUrls: ['./faq-publica.component.scss']
})
export class FaqPublicaComponent implements OnInit {
  namePage = "FAQ";
  urlContext = "/faq";
  urlHome = `http://${environment.urlSiteAtual}`;
  page = 1;
  pageSize = 5;
  lastPage = 1;
  collectionSize: number = 0;
  lista: any[] = [];
  form!: FormGroup;
  site: any;

  constructor(
    public route: ActivatedRoute,
    private router: Router,
    private service: FaqService,
    private formBuilder: FormBuilder,
    private sanitizer: DomSanitizer,
  ) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      textoPergunta: ['']
    })
    this.pesquisar();
  }

  voltarDashboard(){
    this.router.navigate([`/dashboard`]);
  }

  trocarSize(): void {
    this.page = 1;
    this.pesquisar(undefined);
  }

  pesquisar(lastPage?: number): void {
    if (lastPage && lastPage == this.page)
      return;

    this.lastPage = this.page;

    this.service.listarFaqAtivosPublica(this.form?.value, this.page, this.pageSize).subscribe((data: any) => {
      this.lista = data.content;
      let listaValidada = this.lista.filter((x:any) => x.urlMidiaPT != null)
      listaValidada.forEach((x: any) => x.urlMidiaPT = this.safeURL(x.urlMidiaPT));
      this.collectionSize = data.totalElements;
    });
  }

  safeURL(item: any){
    let urlCod = item.slice(32, 43);
    let url = 'https://www.youtube-nocookie.com/embed/' + urlCod + '/:SameSite=Strict';
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
