import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalEscolhaAdesaoComponent } from '@modules/adesao/components/modal-escolha-adesao/modal-escolha-adesao.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-banner-principal',
  templateUrl: './banner-principal.component.html',
  styleUrls: ['./banner-principal.component.scss']
})
export class BannerPrincipalComponent implements OnInit {

  constructor(
    private router: Router,
    private modalService: NgbModal,
  ) { }

  ngOnInit() {
  }

  cadastro(objBotao: string): void {
    switch (objBotao) {
      case 'cidadao':
        this.router.navigate(['/adesao/colaborador']);
      break;
      case 'empreendedor':
        this.router.navigate(['/adesao/empreendedor']);
      break;
      case 'investidor':
        this.router.navigate(['/adesao/investidor']);
      break;
    }
  }

  openModalCadastrar(): void {
    const modalColunas = this.modalService.open(ModalEscolhaAdesaoComponent, {
      centered: true,
      beforeDismiss: () => false,
      size: 'xl',
      modalDialogClass:'modal-grafico'
    })

    let instance = <ModalEscolhaAdesaoComponent>modalColunas.componentInstance;
  }

  scrollTo(className: string): void {
    const elementList = document.querySelectorAll(className);
    const element = elementList[0] as HTMLElement;
    element.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }

}
