import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CepService } from '@core/services/cep.service';
import { DominioService } from '@core/services/dominio.service';
import { NotificationService } from '@core/services/notification.service';
import { VendasComerciaisService } from '@core/services/vendas-comerciais.service';

@Component({
  selector: 'app-mensagem-pagamento',
  templateUrl: './mensagem-pagamento.component.html',
  styleUrls: ['./mensagem-pagamento.component.scss']
})
export class MensagemPagamentoComponent implements OnInit {

  model: any;


  constructor(
    private formBuilder: FormBuilder,
    private cepService: CepService,
    private dominioService: DominioService,
    private vendasComerciaisService: VendasComerciaisService,
    public route: ActivatedRoute,
    private router: Router,
    private notificationService: NotificationService
  ) {

    const nav = this.router.getCurrentNavigation()?.extras.state;
    if(nav){
      this.model = nav!['model'];
    }

  }

  ngOnInit(): void {
// console.log(this.model)
  }

}
