import { Directive, ViewContainerRef, ViewChild } from '@angular/core';
import { ControlStatusComponent } from '@shared/components/dynamic-form/components/control-status/control-status.component';

@Directive({
  selector: '[controlStatusContainer]'
})
export class ControlStatusContainerDirective {

  @ViewChild(ControlStatusComponent,{static:false}) child !: ControlStatusComponent;

  constructor(public viewContainerRef: ViewContainerRef) { }
}