import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { LocalStorageService } from '@core/local-storage/local-storage.service';
import { Observable } from 'rxjs';
import { UserAuth } from './user';
import { StatusProdutoContratadoEnum } from '../models/enum/produtos-contratados.enum';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalAcessoProdutoComponent } from '../../modules/public/components/modal-acesso-produto/modal-acesso-produto.component';
import { PerfilEnum } from '@core/models/enum/perfil';
import { PapelContaEnum } from '@core/models/enum/papel-conta';
import { ProdutosContratadosEnum } from '@core/models/enum/produtos-contratados.enum';

@Injectable({
  providedIn: 'root'
})
export class ProdutoGuardService implements CanActivate {

  usuarioLogado!: UserAuth;
  produtosContratados: any;
  permissaoProd: any;
  permissaoProdInv: any;

  constructor(
    private localStorageService: LocalStorageService,
    private modalService: NgbModal,
  ){}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    this.usuarioLogado = JSON.parse(this.localStorageService.getItem('currentUser'));
    this.produtosContratados = this.usuarioLogado.produtosContratados;
    this.permissaoProd = route.data.permissaoProd;
    this.permissaoProdInv = route.data.permissaoProdInv;

    if (this.usuarioLogado.perfil == PerfilEnum.Administrador) {
      return true;
    }

    console.log("PRODUTOS CONTRATADOS:", this.produtosContratados);

    const produtoContratado = this.produtosContratados?.find((item:any) => item.codigo == this.permissaoProd);
    const produtoContratadoInv = this.produtosContratados?.find((item:any) => item.codigo == this.permissaoProdInv);

    console.log(produtoContratado);
    console.log(produtoContratadoInv);


    if (produtoContratado?.status == StatusProdutoContratadoEnum.Ativo) {
      return true;
    }

    if (produtoContratadoInv?.status == StatusProdutoContratadoEnum.Ativo) {
      return true;
    }

    // Guard para produtos especificos

    // * Monitoramento para Empreendedor
    if (this.usuarioLogado.papelAtivo == PapelContaEnum.Empreendedor && this.permissaoProd == ProdutosContratadosEnum.FerramentaMonitoramento) {
      return true;
    }

    // * Liberação Parecerista
    if (this.usuarioLogado.perfil == PerfilEnum.Parecerista) {
      return true;
    }

    this.openModalAcessoProduto();
    return false;
  }

  openModalAcessoProduto(): void {
    const modal = this.modalService.open(ModalAcessoProdutoComponent, {
      centered: true,
      beforeDismiss: () => false,
      size: 'lg',
      modalDialogClass: 'modal-grafico'
    })
  }

}


