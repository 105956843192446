import { Directive, Self, Inject, Optional, Host, ComponentRef, ViewContainerRef, ComponentFactoryResolver, Input, Renderer2 } from '@angular/core';
import { NgControl } from '@angular/forms';
import { FORM_ERRORS } from '@core/config/form-errors.config';
import { FormSubmitDirective } from '@shared/components/dynamic-form/directives/form-submit/form-submit.directive';
import { Observable, EMPTY, merge, fromEvent, of } from 'rxjs';
import { ControlStatusComponent } from '@shared/components/dynamic-form/components/control-status/control-status.component';
import { ControlStatusContainerDirective } from './control-status-container.directive';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Directive({
  selector: '[formControl], [formControlName]'
})
export class ControlStatusDirective {
  submit$: Observable<Event>;
  ref: ComponentRef<ControlStatusComponent> | undefined;
  container: ViewContainerRef;
  touched$ = fromEvent(this.element, 'blur')
  teste$ = fromEvent(this.element, 'touchstart')
  @Input() noKeyPressError: boolean = false;
  @Input() showError: boolean = true;

  constructor(@Self() private control: NgControl,
    @Optional() @Host() private form: FormSubmitDirective,
    @Inject(FORM_ERRORS) private errors: any,
    private viewContainerRef: ViewContainerRef,
    private resolver: ComponentFactoryResolver,
    @Optional() private controlErrorContainer: ControlStatusContainerDirective, private renderer: Renderer2) {

    this.container = controlErrorContainer ? controlErrorContainer.viewContainerRef : viewContainerRef;
    this.submit$ = this.form ? this.form.submit$ : EMPTY;

  }

  ngOnInit() {

    let merged;

    if (!this.noKeyPressError) {
      merged = merge(this.submit$,
        <Observable<Input>>this.control.valueChanges,
        this.touched$,
        <Observable<Input>>this.control.statusChanges,
        this.teste$)
    }
    else
      merged = merge(
        this.submit$,
        this.touched$,
      );

    merged.pipe(
      untilDestroyed(this)
    ).subscribe(() => {
      const controlErrors = this.control.errors;
      if (controlErrors) {
        const firstKey = Object.keys(controlErrors)[0];

        const getError = this.errors[firstKey];
        if (getError) {
          const text = getError(controlErrors[firstKey]);
          this.setError(text);
        }
      } else if (this.ref) {
        this.setError(null);

        if (this.control.valid && this.control.value != undefined) {
          this.setSuccess();
        }
      }
    })
  }

  setError(text: string | any): void {


    if (!this.ref) {
      const factory = this.resolver.resolveComponentFactory(ControlStatusComponent);
      this.ref = this.container.createComponent(factory);
    }

    if (this.showError) {
      this.ref.instance.text = text;
    }

    this.element.classList.remove('is-valid');
    if (text)
      this.element.classList.add('is-invalid');
    else
      this.element.classList.remove('is-invalid');

  }

  setSuccess(): void {
    this.element.classList.add('is-valid');
  }

  ngOnDestroy() {

  }

  get element() {
    return this.viewContainerRef.element.nativeElement;
  }
}
