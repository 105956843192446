import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenticationService } from '@core/auth/authentication.service';
import { ApiService } from '@core/services/api.service';
import { environment } from '@env/environment';

const routes = {
  cadastrar: `/edital/novo`,
  rascunho: `/edital/rascunho`,
  editar: `/edital/novo`,
  consultar: (id: number) => `/edital/consultar/${id}`,
  listar: `/edital/listar`,
  inativar: `/edital/inativarEdital`,
  reativar: `/edital/reativarEdital`,
  gerarLandingPage: `/edital/landingpage`,
  convidarEmpreendedor: `/edital/convidarempreendendor`,
  buscarEmpreendedor: `/edital/consultarempreendendor`,
  associarPareceristaEdital: `/edital/associarparecerista`,
  buscarPareceristaPorPatrocinador: (id: number) => `/edital/consultarparecerista/${id}`,
  consultarPatrocinador: `/edital/consultarpatrocinador`,
  buscarDataLimiteInscricaoEdital: (id: number) => `/edital/consultardatalimiteinscricao/${id}`,
  conviteEditalPrivado: (id: number) => `/convite-edital-privado/listar/${id}`,
  removerConviteEditalPrivado: (id: number) => `/convite-edital-privado/remover/${id}`,
  listarBloqueados: `/edital/listarBloqueados`,
  bloquearChamadas: `/edital/bloquearEditalAdm`,
  reativarBloqueados: `/edital/reativarEdital`,
}

@Injectable({
  providedIn: 'root'
})
export class ChamadaService {

  constructor(
    private apiService: ApiService,
    private authenticationService: AuthenticationService,
    ) { }

  cadastrarChamada(obj: any) {
    return this.apiService.post<any>(routes.cadastrar, obj);
  }

  rascunhoChamada(obj: any) {
    return this.apiService.post<any>(routes.rascunho, obj);
  }

  editarChamada(obj: any) {
    return this.apiService.post<any>(routes.editar, obj);
  }

  buscarDadosChamada(id: number) {
    return this.apiService.get<any>(routes.consultar(id));
  }

  listarEditais(obj: any, page:number,pageSize:number) {
    let params = new HttpParams();
    params = params.append('size',pageSize);
    params = params.append('page',page - 1);
    return this.apiService.post<any>(routes.listar, obj,undefined,undefined,params);
  }

  inativarEdital(obj: any) {
    return this.apiService.post<any>(routes.inativar, obj);
  }
  
  reativarEdital(obj: any) {
    return this.apiService.post<any>(routes.reativar, obj);
  }
  
  gerarLandingPage(obj: any) {
    return this.apiService.post<any>(routes.gerarLandingPage, obj);
  }

  convidarEmpreededores(obj: any) {
    return this.apiService.post<any>(routes.convidarEmpreendedor, obj);
  }

  buscarEmpreendedor(obj: any) {
    return this.apiService.get<any>(routes.buscarEmpreendedor, obj);
  }
 
  associarPareceristaEdital(obj: any) {
    return this.apiService.post<any>(routes.associarPareceristaEdital, obj);
  }

  buscarPareceristaPorPatrocinador(id: number) {
    return this.apiService.get<any>(routes.buscarPareceristaPorPatrocinador(id));
  }

  consultarPatrocinador(obj: any) {
    return this.apiService.post<any>(routes.consultarPatrocinador, obj);
  }

  buscarDataLimiteInscricaoEdital(id: number) {
    return this.apiService.get<any>(routes.buscarDataLimiteInscricaoEdital(id));
  }

  buscarConviteEditalPrivado(id: number) {
    return this.apiService.get<any>(routes.conviteEditalPrivado(id));
  }

  removerConviteEditalPrivado(id: number) {
    return this.apiService.delete<any>(routes.removerConviteEditalPrivado(id));
  }

  listarBloqueados(obj: any, page:number,pageSize:number){
    let params = new HttpParams();
    params = params.append('size',pageSize);
    params = params.append('page',page - 1);
    return this.apiService.post<any>(routes.listarBloqueados, obj,undefined,undefined,params);
  }

  reativarBloqueados(obj: any){
    return this.apiService.post<any>(routes.reativarBloqueados, obj);
  }

  bloquearChamadas(obj: any){
    return this.apiService.post<any>(routes.bloquearChamadas, obj);
  }

}
