import { HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { AuthenticationService } from '@core/auth/authentication.service';
import { ApiService } from '@core/services/api.service';
import { environment } from '@env/environment';

const routes = {
  cadastrar: `/projetos/salvar`,
  rascunhoProjeto: `/projetos/rascunho`,
  buscarDadosProjeto: (id: number) => `/projetos/${id}`,
  inativarProjeto: `/projetos/inativar`,
  irParaLandingPage: (idProjeto: number) => `/projetos/landingPage/${idProjeto}`,
  listarProjetos: `/projetos/listar`,
  gerarLandingPage: `/projetos/gerar-landingpage`,
  listarProjetosPorEmpreendedor: `/projetos/listar/por-empreendedor`,
  listarProjetosPorEmpreendedorIds: `/projetos/listar/por-empreendedor/`,
  reativarProjeto: `/projetos/reativar`,
  cadastrarProjetoSeguir: (idProjeto: number) => `/projetos/seguir/${idProjeto}`,
  listarBloqueados: `/projetos/listarBloqueados`,
  bloquearProjetos: `/projetos/bloquearProjetoAdm`,
  reativarBloqueados: `/projetos/reativar`,
}

@Injectable({
  providedIn: 'root'
})
export class ProjetoService {

  public isForaBrada = new EventEmitter<Boolean>();

  constructor(
    private apiService: ApiService,
    private authenticationService: AuthenticationService,
  ) { }

  cadastrarProjeto(obj: any) {
    return this.apiService.post<any>(routes.cadastrar, obj);
  }

  rascunhoProjeto(obj: any) {
    return this.apiService.post<any>(routes.rascunhoProjeto, obj);
  }

  buscarDadosProjeto(id: number) {
    return this.apiService.get<any>(routes.buscarDadosProjeto(id));
  }

  inativarProjeto(obj: any) {
    return this.apiService.post<any>(routes.inativarProjeto, obj);
  }

  reativarProjeto(obj: any) {
    return this.apiService.post<any>(routes.reativarProjeto, obj);
  }

  cadastrarLandingPage(id: number) {
    return this.apiService.get<any>(routes.irParaLandingPage(id));
  }

  cadastrarProjetoSeguir(id: number) {
    return this.apiService.put<any>(routes.cadastrarProjetoSeguir(id));
  }

  listarProjetos(obj: any, page: number, pageSize: number) {
    let params = new HttpParams();
    params = params.append('size', pageSize);
    params = params.append('page', page - 1);
    return this.apiService.post<any>(routes.listarProjetos, obj, undefined, undefined, params);
  }

  listarProjetosPorEmpreendedor() {
    return this.apiService.get<any>(routes.listarProjetosPorEmpreendedor);
  }

  listarProjetosPorEmpreendedorIds(obj: number[]) {
    return this.apiService.post<any>(routes.listarProjetosPorEmpreendedorIds, obj);
  }

  gerarLandingPage(obj: any) {
    return this.apiService.post<any>(routes.gerarLandingPage, obj);
  }

  listarBloqueados(obj: any, page:number,pageSize:number){
    let params = new HttpParams();
    params = params.append('size',pageSize);
    params = params.append('page',page - 1);
    return this.apiService.post<any>(routes.listarBloqueados, obj,undefined,undefined,params);
  }

  reativarBloqueados(obj: any){
    return this.apiService.post<any>(routes.reativarBloqueados, obj);
  }

  bloquearProjetos(obj: any){
    return this.apiService.post<any>(routes.bloquearProjetos, obj);
  }
}
