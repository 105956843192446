import { Injectable } from '@angular/core';

const imagesInteresse = [
  {
    id: 1,
    imagem: '/assets/image/areas-interesse/area.png'
  },
  {
    id: 2,
    imagem: '/assets/image/areas-interesse/area.png'
  },
  {
    id: 3,
    imagem: '/assets/image/areas-interesse/area.png'
  },
  {
    id: 4,
    imagem: '/assets/image/areas-interesse/area.png'
  },
  {
    id: 5,
    imagem: '/assets/image/areas-interesse/area.png'
  },
  {
    id: 6,
    imagem: '/assets/image/areas-interesse/area.png'
  },
  {
    id: 7,
    imagem: '/assets/image/areas-interesse/area.png'
  },
  {
    id: 8,
    imagem: '/assets/image/areas-interesse/area.png'
  },
  {
    id: 9,
    imagem: '/assets/image/areas-interesse/area.png'
  },
  {
    id: 10,
    imagem: '/assets/image/areas-interesse/area.png'
  },
  {
    id: 11,
    imagem: '/assets/image/areas-interesse/area.png'
  },
  {
    id: 12,
    imagem: '/assets/image/areas-interesse/area.png'
  },
  {
    id: 13,
    imagem: '/assets/image/areas-interesse/area.png'
  },
  {
    id: 14,
    imagem: '/assets/image/areas-interesse/area.png'
  },
  {
    id: 15,
    imagem: '/assets/image/areas-interesse/area.png'
  },
]
@Injectable({
  providedIn: 'root'
})
export class AreaInteresseService {

  constructor() { }

  popularImagens(areasInteresse: any[]): void {
    areasInteresse.forEach(area => {
      area.imagem = imagesInteresse.find(x => x.id == area.id)?.imagem;
    })
  }
}
