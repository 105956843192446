<div class="bg-body-color">

    <div class="container">
        <app-header-dashboard *ngIf="(isLoggedIn$ | async) && exibirHeaderDashboard"></app-header-dashboard>
    </div>

    <div class="conteudo">
        <router-outlet></router-outlet>
    </div>

    <div class="footer-pages bg-white-color">
        <app-footer *ngIf="(isLoggedIn$ | async)"></app-footer>
    </div>
</div>
<app-loader></app-loader>