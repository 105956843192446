import { PatrocinadorPublicoService } from './../../core/services/patrocinador-publico';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RouteAction } from '@core/action/action';
import { AuthenticationService } from '@core/auth/authentication.service';
import { AreaInteresse } from '@core/models/chamada';
import { TipoPessoa } from '@core/models/dominio';
import { AcaoFiltrosEnum } from '@core/models/enum/acao-filtro';
import { ChamadaService } from '@core/services/chamada.service';
import { DominioService } from '@core/services/dominio.service';
import { NotificationService } from '@core/services/notification.service';
import { NgbModal, NgbPaginationConfig } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';
import { RelacaoDeSeguidosService } from '@core/services/relacao-de-seguidos.service';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-list-patrocinadores',
  templateUrl: './list-patrocinadores.component.html',
  styleUrls: ['./list-patrocinadores.component.scss']
})
export class ListPatrocinadoresComponent implements OnInit {

  namePage = "Lista de Investidores";

  id!: number;
  acao!: RouteAction;
  criar!: boolean;
  editar!: boolean;
  visualizar!: boolean;

  page = 1;
  pageSize = 5;
  listaPatrocinadores: any[] = []; // criarModel
  tabelaListaPatrocinadores: any[] = [];
  collectionSize: any;

  usuarioLogado: any;
  papelAtivo: any;
  idContaAtiva: any;
  lastPage: number = 0;
  lista: any[] = [];
  usuarioLogadoContas: any[] = [];
  idUsuarioLogado: any;
  message!: string;
  isLoading: boolean = false;
  success: boolean = false;
  exibirErroVista: boolean = false;
  objEnvio: any;
  objEnvioFiltroChamadas: any;
  fill: boolean = false;


  statusEdital: any[] = [];
  fonteFinanciamento: any[] = [];
  tipoEdital: any[] = [];
  tipoPessoa: any[] = [];
  tipoFoto: any[] = [];
  fotos: any[] = [];
  tipoPessoas: Array<TipoPessoa> = [];
  publicos: any[] = [];
  ods: any[] = [];
  areasDeInteresse: Array<AreaInteresse> = [];
  estados: any[] = [];
  ufs: any;
  municipios: any[] = [];
  tipoDocumento: any[] = [];
  midias: any[] = [];
  areaDeInteresseEdital: any[] = [];
  acaoFiltroChamdaEnum = AcaoFiltrosEnum;
  filtroPaginas: any[]=[];

  teste: any;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private notificationService: NotificationService,
    private chamadaService: ChamadaService,
    private dominioService: DominioService,
    private authenticationService: AuthenticationService,
    private modalService: NgbModal,
    private activatedRoute: ActivatedRoute,
    private patrocinadorPublicoService: PatrocinadorPublicoService,
    private relacaoDeSeguidosService: RelacaoDeSeguidosService,
    private sanitizer: DomSanitizer,
    private config: NgbPaginationConfig,
    private translateService: TranslateService
  ) {
    config.size = 'sm';
    config.boundaryLinks = true;
  }

  formFiltroPatrocinadores = this.formBuilder.group({
    areaInteresse: [],
    descricao: [],
    municipio: [],
    ods: [],
    publico: [],
    tipoPessoa: [],
    estado: [],
  });

  ngOnInit() {
    this.userlogged();
    this.buscarDadosEtapas();
    this.pesquisar();
  }

  submit():void {
    let objEnvio = { ...this.formFiltroPatrocinadores.value };
    objEnvio.idPatrocinador = this.idContaAtiva;

    this.chamadaService.listarEditais(objEnvio,this.page,this.pageSize).subscribe((data: any) => {
// console.log(data);
      this.listaPatrocinadores = data.content;
      this.collectionSize = data.size;
      this.refreshCountries();
    });
  }

  voltarDashboard(): void {
    this.router.navigate(['/dashboard']);
  }

  refreshCountries() {
    this.tabelaListaPatrocinadores = this.listaPatrocinadores
      .map((x, i) => ({id: i + 1, ...x}))
      .slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
  }

  reset(){
    this.formFiltroPatrocinadores.reset();

    this.fill == false
  }

  buscarDadosEtapas(): void {
    this.buscarTipoPessoa();
    this.buscarfiltroPaginas();
  }

  buscarTipoPessoa(): void {
    this.dominioService.buscarTipoPessoa().subscribe((data: any) => {
      this.tipoPessoa = data;
      this.tipoPessoa = this.tipoPessoa.filter(x => x.ativo == true);
    });
  }

  buscarfiltroPaginas(): void {
    this.dominioService.filtroPaginas().subscribe((data: any) => {
      this.filtroPaginas = data;
    });
  }

  showError(mensagem: any) {
    this.notificationService.showError(mensagem);
  }

  showSuccess(mensagem: any) {
    this.notificationService.showSuccess(mensagem);
  }

  listar(): void {
    if (this.formFiltroPatrocinadores.valid) {
      this.page = 1;
      this.pesquisar();
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    this.fill == false
  }

  Filtro() {
    if (this.fill == false) {
      this.fill = true
// console.log(this.fill)
    } else if (this.fill == true) {
      this.fill = false
// console.log(this.fill)
    }
    return console.log(this.fill)
  }

  trocarSize(): void {
    this.page = 1;
    this.pesquisar(undefined);
  }

  pesquisar(lastPage?: number): void {
// console.log(lastPage)
    if (lastPage && lastPage == this.page)
      return;

    this.lastPage = this.page;
    let objEnvio = { ...this.formFiltroPatrocinadores.value };
    this.patrocinadorPublicoService.listar(objEnvio, this.page, this.pageSize).subscribe((data: any) => {
// console.log(data)
      this.lista = data.content;
// console.log(this.lista)
      this.collectionSize = data.totalElements;
    });
  }

  subimit(){
    this.objEnvioFiltroChamadas = {};
    let objForm = { ...this.formFiltroPatrocinadores.getRawValue() };
  }

  userlogged(): void {
    if(this.authenticationService.currentUserValue){
      this.usuarioLogado = this.authenticationService.currentUserValue;
      this.papelAtivo = this.usuarioLogado.papelAtivo;
      this.idContaAtiva = this.usuarioLogado.idContaAtiva;
    }
  }

  visualizarLandingPage(idPatrocinador: any){
    window.open(`${window.location.origin}/#/landingpage/patrocinador/visualizar/${idPatrocinador}`, '_blank')
  }

  fotosChamada(item: any): any {
    if(item){
      if(item.base64Content && item.base64info){
        let img = item.base64info + item.base64Content;
        if(img){
          return this.sanitizer.bypassSecurityTrustUrl(img);
        }
      }
    }
    return 'https://images.unsplash.com/photo-1485217988980-11786ced9454?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80';
  }

  btSeguirInvestidor(item: any): void {
    this.relacaoDeSeguidosService.seguirPatrocinador(item.id).subscribe((data: any) => {
      this.ocultarSeguir(item);

      let mensagem = this.translateService.instant("Seguindo_o_Investidor")
      this.notificationService.showSuccess(mensagem);
    });
  }

  btDeixarDeSeguirInvestidor(item: any): void {
    this.relacaoDeSeguidosService.deixarDeSeguirPatrocinador(item.id).subscribe((data: any) => {
      this.ocultarDeixarDeSeguir(item);

      let mensagem = this.translateService.instant("Deixou_de_seguir_o_Investidor")
      this.notificationService.showSuccess(mensagem);
    });
    location.reload();
  }

  ocultarSeguir(item: any): void {
    item.selectSeguir = !item.selectSeguir;
    item.selectDeixar = item.selectDeixar;
  }

  ocultarDeixarDeSeguir(item: any): void {
    item.selectDeixar = !item.selectDeixar;
    item.selectSeguir = item.selectSeguir;
  }

}
