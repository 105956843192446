import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

const routes = {
  validarDominioUrl: (nomeDominio: string) => `/publica/landpage/validar/${nomeDominio}`,
  salvarDominioUrlEdital: (idEdital: string, nomeDominio: string) => `/landpage/edital/salvar/${idEdital}/${nomeDominio}`,
  editarDominioUrlEdital: (idEdital: string, nomeDominio: string) => `/landpage/edital/editar/${idEdital}/${nomeDominio}`,
  buscarDadosLandingPageEdital: (nomeDominio: string) => `/publica/landpage/edital/get/${nomeDominio}`,
  salvarDominioUrlProjeto: (idProjeto: string, nomeDominio: string) => `/landpage/projeto/salvar/${idProjeto}/${nomeDominio}`,
  editarDominioUrlProjeto: (idProjeto: string, nomeDominio: string) => `/landpage/projeto/editar/${idProjeto}/${nomeDominio}`,
  buscarDadosLandingPageProjeto: (nomeDominio: string) => `/publica/landpage/projeto/get/${nomeDominio}`,
  salvarDominioUrlVotacao: (idVot: number, nomeDominio: string) => `/landpage/etapa-votacao/salvar/${idVot}/${nomeDominio}`,
  buscarDadosLandingPageVotacao: (nomeDominio: string) => `/publica/landpage/etapa-votacao/get/${nomeDominio}`,
  buscarDadosLandingPagePessoa: (nomeDominio: string) => `/publica/landpage/pessoa/get/${nomeDominio}`
}

@Injectable({
  providedIn: 'root'
})
export class DominioLandingPageService {

  constructor(private apiService: ApiService) { }

  validarDominioUrl(nomeDominio: any) {
    return this.apiService.get(`${routes.validarDominioUrl(nomeDominio)}`);
  }

  salvarDominioUrlEdital(idEdital: string, nomeDominio: string) {
    return this.apiService.put(`${routes.salvarDominioUrlEdital(idEdital, nomeDominio)}`);
  }

  editarDominioUrlEdital(idEdital: string, nomeDominio: string) {
    return this.apiService.put(`${routes.editarDominioUrlEdital(idEdital, nomeDominio)}`);
  }

  buscarDadosLandingPageEdital(nomeDominio: any) {
    return this.apiService.get(`${routes.buscarDadosLandingPageEdital(nomeDominio)}`);
  }

  salvarDominioUrlProjeto(idProjeto: string, nomeDominio: string) {
    return this.apiService.put(`${routes.salvarDominioUrlProjeto(idProjeto, nomeDominio)}`);
  }

  editarDominioUrlProjeto(idProjeto: string, nomeDominio: string) {
    return this.apiService.put(`${routes.editarDominioUrlProjeto(idProjeto, nomeDominio)}`);
  }

  buscarDadosLandingPageProjeto(nomeDominio: any) {
    return this.apiService.get(`${routes.buscarDadosLandingPageProjeto(nomeDominio)}`);
  }

  salvarDominioUrlVotacao(idProjeto: any, nomeDominio: string) {
    return this.apiService.put(`${routes.salvarDominioUrlVotacao(idProjeto, nomeDominio)}`);
  }

  buscarDadosLandingPageVotacao(nomeDominio: any) {
    return this.apiService.get(`${routes.buscarDadosLandingPageVotacao(nomeDominio)}`);
  }

  buscarDadosLandingPagePessoa(nomeDominio: any) {
    return this.apiService.get(`${routes.buscarDadosLandingPagePessoa(nomeDominio)}`);
  }

}
