<div class="container">
  <div class="modal-header">
    <h4 class="modal-title"></h4>
    <button type="button" class="close btn-close" aria-label="Close" (click)="fechar()">

    </button>
  </div>
  <div class="modal-body">
    <div class="col-10 mx-3 m-sm-auto mb-5">
      <div class="row">
        <div class="col-12 m-auto">
          <div class="row">
            <div class="col-12 d-flex justify-content-center">
              <i class="bi bi-exclamation-circle iconeModal txt-primary-color"></i>
            </div>
          </div>

          <h4 class="text-center mb-3 txt-support-color ">
            {{tituloDescricao}}
          </h4>

        </div>
      </div>

      <div class="row">
        <div class="col">
          <div class="row my-sm-4" *ngIf="exibirErroVista">
            <div class="col-12">
              <div class="alert alert-danger alert-dismissible fade show" role="alert">
                {{this.message}}
                <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
              </div>
            </div>
          </div>


          <ng-container [ngSwitch]="acao">

            <div *ngSwitchDefault>
              <form [formGroup]="form">

                <div class="col-12 mt-3">
                  <input type="text" formControlName="motivoInativacao" class="form-control p-3" placeholder="Digite aqui..." [showError]="false">
                </div>

                <div class="row mt-3">
                  <div class="col-12 mt-4 d-flex align-items-center justify-content-center">
                    <button type="button" class="btn btn-primary-color py-2 px-5" (click)="salvarMotivo()">
                      {{'Finalizar' | translate}}
                    </button>
                  </div>
                </div>

              </form>
            </div>

            <div *ngSwitchCase="acaoFiltroChamadaEnum.LandingPage">
              <form [formGroup]="form">

                <div class="col-12 mb-2">
                  <div class="text-center h7 fw-500 text-muted mt-2 mb-3">
                    <span>https://</span>

                    <span class="border-0 fs-600" [ngClass]="{'valid': !validarUrl, 'invalid': validarUrl, 'text-muted' : validarUrl == null}">{{formValue}}</span>

                    <span *ngIf="!formValue">seu-domínio</span>

                    <span>.somosbrada.com.br</span>
                  </div>

                  <input type="text" formControlName="motivoInativacao" class="form-control p-3" [ngClass]="{'valid': !validarUrl, 'invalid': validarUrl, 'text-muted sem-borda' : validarUrl == null}" placeholder="Digite aqui..." [showError]="false" (change)="verificarDominio()">

                  <div class="text-center mt-4 border-0 sem-borda" *ngIf="validarUrl || !validarUrl && validarUrl != null" [ngClass]="{'valid': !validarUrl, 'invalid': validarUrl, 'text-muted sem-borda' : validarUrl == null}">
                    {{ !validarUrl ? 'Disponível para registro!' : 'Indisponível para registro!'}}
                  </div>
                </div>

                <div class="col-12 mt-4 d-flex align-items-center justify-content-center" *ngIf="validarUrl || !validarUrl">
                  <button type="button" class="btn btn-primary-color py-2 px-5" *ngIf="acao == acaoFiltroChamadaEnum.LandingPage && !editar" (click)="salvarLandingPage()" [disabled]="validarUrl || validarUrl === undefined" [ngClass]="{'mt-2': !validarUrl}">
                    {{ validarUrl != null && !validarUrl ? 'Registrar Domínio' : 'Validar Domínio' | translate }}
                  </button>

                  <button type="button" class="btn btn-primary-color py-2 px-5" *ngIf="acao == acaoFiltroChamadaEnum.LandingPage && editar" (click)="editarLandingPage()" [disabled]="validarUrl || validarUrl === undefined" [ngClass]="{'mt-2': !validarUrl}">
                    {{ validarUrl != null && !validarUrl ? 'Editar Domínio' : 'Validar Domínio' | translate }}
                  </button>
                </div>

              </form>
            </div>

            <div *ngSwitchCase="acaoFiltroChamadaEnum.Parecer">
              <form [formGroup]="form">

                <div class="col-12 mt-3">
                  <div class="editor border">
                    <angular-editor id="motivoInativacao" [placeholder]="'Digite a mensagem aqui...'" formControlName="motivoInativacao" [config]="editorConfig"></angular-editor>
                  </div>
                </div>

                <div class="row mt-3">
                  <div class="col-12 mt-4 d-flex align-items-center justify-content-center">
                    <button type="button" class="btn btn-primary-color py-2 px-5" (click)="salvarMotivo()">
                      {{'Finalizar' | translate}}
                    </button>
                  </div>
                </div>

              </form>
            </div>

            <div *ngSwitchCase="acaoFiltroChamadaEnum.Pendencia">
              <form [formGroup]="formPendencia">

                <div class="col-12 mt-3" >
                  <label class="mb-1">{{'Título:' | translate}}</label>
                  <input type="text" formControlName="titulo" class="form-control p-3" placeholder="Digite aqui..." [showError]="false" >
                </div>

                <div class="col-12 mt-3">
                  <label>{{'Descrição' | translate}}</label>
                  <div class="editor border">
                    <angular-editor id="pendencia" [placeholder]="'Digite a mensagem aqui...'" formControlName="pendencia" [config]="editorConfig" ></angular-editor>
                  </div>
                </div>

                <div class="row mt-3">
                  <div class="col-12 mt-4 d-flex align-items-center justify-content-center">
                    <button type="button" class="btn btn-primary-color py-2 px-5" (click)="salvarPendencia()">
                      {{'Finalizar' | translate}}
                    </button>
                  </div>
                </div>

              </form>
            </div>
          </ng-container>

        </div>
      </div>

    </div>
  </div>
</div>
