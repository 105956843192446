export enum ProdutosContratadosEnum {
    GestaoChamadas = 'GESTAO_DE_CHAMADAS',
    FerramentaVotoPopular = 'FERRAMENTA_DE_VOTO_POPULAR',
    BaseRelacionamento = 'BASE_DE_RELACIONAMENTO',
    FerramentaMonitoramento = 'FERRAMENTA_DE_MONITORAMENTO',
    AdicionalSituacaoCadastral = 'ADICIONAL_SITUACAO_CADASTRAL',
    GestaoProjetos = 'GESTAO_DE_PROJETOS',
    BaixarPdfLandingPage = 'BAIXAR_PDF_DA_LANDPAGE',
    Divulgacao = 'DIVULGACAO',
    Planner = 'PLANNER'
}

export enum StatusProdutoContratadoEnum {
  Ativo = 'ATIVO',
  Inativo = 'INATIVO'
}
