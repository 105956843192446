<div class="bg-overlay bg-body-color h-100 landingPage" id="topo">
  <div class="position-fixed w-100">

    <header id="topo" class="container-flex shadow bg-white-color">
      <nav class="navbar navbar-expand-lg navbar-light mt-0 pt-0">
        <div class="container py-3 align-middle d-flex">

          <a class="col col-sm-2 d-flex justify-content-center">
            <img class="ms-3 mx-sm-0" [src]="imagemPerfilConta?imagemPerfilConta:'/assets/image/marca-brada.png'" height="40" alt="Logo do site Brada" loading="lazy" />
          </a>

          <button class="col-3 navbar-toggler border-0" type="button" (click)="isMenuCollapsed = !isMenuCollapsed"
            [ngClass]="{'ms-auto': isMenuCollapsed}">
            <span class="navbar-toggler-icon"></span>
          </button>

          <div [ngbCollapse]="isMenuCollapsed" class="col collapse navbar-collapse">
            <div class="navbar-nav col mt-4 mt-sm-0 ms-auto menu-principal">
              <div class="navbar-nav mt-2 col d-flex justify-content-start">
                <ul class="navbar-nav col-12 col-sm-9 d-flex justify-content-start me-auto mb-2 mb-lg-0 align-items-center">
                  <li class="nav-item ps-sm-3 fw-500" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                    <a class="nav-link" pageScroll href="/#topo">{{'Home' | translate}}</a>
                  </li>

                  <li class="nav-item ps-sm-3 fw-500" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" *ngIf="patrocinador?.sobreOrganizacao">
                    <a class="nav-link" pageScroll href="/#sobre">{{'Sobre' | translate}}</a>
                  </li>

                  <li class="nav-item ps-sm-3 fw-500" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" *ngIf="midia?.length">
                    <a class="nav-link" pageScroll href="/#midias">{{'Mídias' | translate}}</a>
                  </li>

                  <li class="nav-item ps-sm-3 fw-500" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" *ngIf="patrocinador?.estouDeAcordoDadosPublicos">
                    <a class="nav-link" pageScroll href="/#contato">{{'Contato' | translate}}</a>
                  </li>

                </ul>
              </div>
              <ng-select [notFoundText]="'Nenhum_item_encontrado' | translate" (change)="selectLanguage($event)" class="custom col-5 col-sm-2 mx-auto mx-sm-0" [searchable]="false" [clearable]="false"
                [items]="cities" [(ngModel)]="selectedCity">
                <ng-template ng-label-tmp let-item="item">
                  <div class="d-flex align-items-center justify-content-start">
                    <img class="mx-2" height="18" width="29" [src]="item.avatar" />
                    <h5 class="Txt mt-2">{{item.lingua}}</h5>
                  </div>
                </ng-template>
                <ng-template [items]="selectLanguage" ng-option-tmp let-item="item">
                  <div style="margin: 4px 0;" *ngIf="item.name !== 'Kaunas'">
                    <div class="d-flex align-items-center justify-content-start">
                      <img class="mx-2" height="15" width="24" [src]="item.avatar" />
                      <h5 class="Txt mt-2">{{item.lingua}}</h5>
                    </div>
                  </div>
                </ng-template>
              </ng-select>
            </div>
          </div>
        </div>
      </nav>
    </header>

    <a type="button" class="btn arraste-cima btn-primary-color border-brada position-fixed bottom-0 end-0 me-3 mb-5"
      href="/#topo" pageScroll *ngIf="windowScrolled">
      <i class="bi bi-arrow-up-square fs-4"></i>
    </a>
  </div>

  <div class="container-flex pt-5 pt-sm-0">
    <section id="banner">
      <div class="banner-principal bg-image d-flex align-items-center transbox justify-content-center ss">
        <img [src]="imagemCapaBanner" alt="..." *ngIf="imagemCapaBanner">
        <div class="container position-absolute mt-5 pt-5">
          <div class="col-10 col-sm-6 mx-auto bg-white-color p-5 rounded-3 shadow">
            <span class="h7 text-muted m-0">{{'Investidor' | translate}}</span>
            <h1>{{patrocinador?.nomePessoa}}</h1>
            <h6 class="fw-400">{{patrocinador?.tipoPessoa?.nome}}</h6>
          </div>
        </div>
      </div>
    </section>

    <section id="sobre" class="col-12 bg-white py-5">
      <div class="container my-5">
        <div class="col-12">

          <div class="col-12 mb-4">
            <div class="col-10 col-sm-7 mx-auto border-bottom text-capitalize text-start"
              *ngIf="patrocinador?.dataNascimentoFundacao">
              <h6 class="mt-4 mb-2 fw-400 h7 text-muted ">{{'Data_Nascimento_Fundacão' | translate}}</h6>
              <h6>{{patrocinador?.dataNascimentoFundacao}}</h6>
            </div>
          </div>

          <div class="col-10 col-sm-7 mx-auto text-muted scroll">
            <div [innerHtml]="patrocinador?.sobreOrganizacao"></div>
          </div>

        </div>
      </div>
    </section>

    <section id="midias" class="col-12" *ngIf="midia?.length">
      <div class="col-12 transbox px-5 px-sm-0 pt-4 pb-5">
        <div class="container pb-5">

          <section id="title" class="mt-5 mb-3 d-flex justify-content-start">
            <h5 class="title-line">{{'Mídias' | translate}}</h5>
          </section>

          <div class="row g-4 row-cols-1 row-cols-sm-5 d-flex justify-content-start">
            <div class="col" *ngFor="let item of midia">
              <div
                class="card border-0 shadow-sm d-flex align-items-center justify-content-center text-center py-4 h-100"
                (click)="goToUrl(item.enderecoMidia)">

                <i class="bi bi-play-btn fs-2 txt-primary-color m-0 p-0"
                  *ngIf="item?.tipoMidia?.nomeTipoMidia == 'Vídeos'"></i>

                <i class="bi bi-image fs-2 txt-primary-color m-0 p-0"
                  *ngIf="item?.tipoMidia?.nomeTipoMidia == 'Fotos'"></i>

                <i class="bi bi-link-45deg fs-2 txt-primary-color m-0 p-0"
                  *ngIf="item?.tipoMidia?.nomeTipoMidia == 'Sites'"></i>

                <div class="card-body m-0 p-0">
                  <a class="card-text h7">{{item.enderecoMidia | slice:0:20}}...</a>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>

    <section id="contato" class="col-12 py-sm-5 pb-5"
      [ngClass]="{'bg-body-color': midia.length}"
      *ngIf="patrocinador?.estouDeAcordoDadosPublicos">
      <div class="container my-sm-5">
        <div class="d-sm-flex g-3 py-3">

          <div class="col col-sm-4">
            <div id="title" class="my-5 text-start mx-4">
              <h2 class="border-bottom txt-primary-color pb-2 mb-4">{{'Contato' | translate}}</h2>
              <p>{{'Informações_de_contato_do_patrocinador' | translate}}</p>
            </div>
          </div>

          <div class="col-10 col-sm mx-auto d-flex justify-content-center align-items-center">
            <div>
              <h6 class="text-muted">{{'Endereço:' | translate}}</h6>
              <h6>
                {{'Endereço:' | translate}}
                {{patrocinador?.logradouro}}, {{patrocinador?.numero}}
                <span *ngIf="patrocinador?.complemento">, {{patrocinador?.complemento}}</span>
                - {{patrocinador?.bairro}}
                <br />
                {{patrocinador?.cidade?.nome}} - {{patrocinador?.estado?.codigo}} / {{patrocinador?.pais?.nome}}
              </h6>

              <h6 class="text-muted mt-4">{{'Telefone_de_Contato:' | translate}}</h6>
              <h6>{{patrocinador?.telefone}}</h6>
            </div>
          </div>

        </div>
      </div>
    </section>
  </div>
</div>
