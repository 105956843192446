import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FichaInscricaoEditalService } from '@core/services/ficha-inscricao-edital.service';
import { InscreverSeService } from '@core/services/inscrever-se.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-escolher-ficha-inscricao',
  templateUrl: './modal-escolher-ficha-inscricao.component.html',
  styleUrls: ['./modal-escolher-ficha-inscricao.component.scss']
})
export class ModalEscolherFichaInscricaoComponent implements OnInit {

  idChamada!: number;
  papelAtivo!: string;
  namePage = "Selecione uma Ficha Cadastrada"
  listaFichasInscricao: any[]=[];

  constructor(
    private router: Router,
    public activeModal: NgbActiveModal,
    private inscreverSeService: InscreverSeService
  ) { }

  ngOnInit() {    
  }  

  editarFicha(idFicha: number){
    this.fechar();
    this.router.navigate([`/chamadas/inscrever-se/editar/${idFicha}`]);
  }  

  adicionarFicha(){
    this.fechar();
    this.router.navigate([`/chamadas/inscrever-se/inserir/${this.idChamada}`]);
  }  

  fechar():void{
    this.activeModal.close();
  }
}
