import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RouteAction } from '@core/action/action';
import { ModalEscolhaAdesaoComponent } from '@modules/adesao/components/modal-escolha-adesao/modal-escolha-adesao.component';
import { FichaInscricaoChamadaComponent } from '@modules/chamadas/pages/ficha-inscricao-chamada/ficha-inscricao-chamada.component';
import { InscreverSeComponent } from '@modules/chamadas/pages/inscrever-se/inscrever-se.component';
import { ListFichaInscricaoChamadaComponent } from '@modules/chamadas/pages/list-ficha-inscricao-chamada/list-ficha-inscricao-chamada.component';
import { FiltroDeChamadasPublicasComponent } from '@modules/chamadas/pages/public/pages/filtro-de-chamadas-publicas/filtro-de-chamadas-publicas.component';
import { ListChamadasAtendemProjetoComponent } from '@modules/list-chamadas-atendem-projeto/list-chamadas-atendem-projeto.component';
import { ListPatrocinadoresComponent } from '@modules/list-patrocinadores/list-patrocinadores.component';
import { ListProjetosAtendemChamadaComponent } from '@modules/list-projetos-atendem-chamada/list-projetos-atendem-chamada.component';
import { AlterarSenhaLoginComponent } from '@modules/login/pages/alterar-senha/alterar-senha-login/alterar-senha-login.component';
import { DocumentComponent } from '@shared/document/document.component';
import { ListEmpreendedoresComponent   } from '@modules/list-empreendedores/list-empreendedores/list-empreendedores.component'
import { ListProjetosComponent } from '@modules/list-projetos/list-projetos.component'
import { TrocarSenhaComponent } from '@modules/usuario/pages/trocar-senha/trocar-senha.component';
import { KanbanComponent } from '@shared/components/kanban/kanban.component';
import { MensagemPagamentoComponent } from '@modules/checkout-pagamento/pages/mensagem-pagamento/mensagem-pagamento.component';
import { PessoaListComponent } from '@modules/usuario/pages/pessoa-list/pessoa-list.component';
import { AuthGuardService } from '@core/auth/auth-guard.service';
import { ProdutoGuardService } from '@core/auth/produto-guard.service';
import { ProdutosContratadosEnum } from '@core/models/enum/produtos-contratados.enum';

const routes: Routes = [
  { path: '', loadChildren: () => import('@modules/public/public.module').then(mod => mod.PublicModule) },
  { path: 'acessoNaoPermitido', loadChildren: () => import('./modules/acesso-nao-permitido/acesso-nao-permitido.module').then(mod => mod.AcessoNaoPermitidoModule) },

  { path: 'login', loadChildren: () => import('@modules/login/login.module').then(mod => mod.LoginModule) },
  { path: 'usuario', loadChildren: () => import('@modules/usuario/usuario.module').then(mod => mod.UsuarioModule) },
  { path: 'dashboard', loadChildren: () => import('@modules/dashboard/dashboard.module').then(mod => mod.DashboardModule) },
  { path: 'executor', loadChildren: () => import('@modules/usuario/usuario.module').then(mod => mod.UsuarioModule) },
  { path: 'adesao', loadChildren: () => import('@modules/adesao/adesao.module').then(mod => mod.AdesaoModule) },
  { path: 'chamadas', loadChildren: () => import('@modules/chamadas/chamadas.module').then(mod => mod.ChamadasModule) },
  { path: 'projetos', loadChildren: () => import('@modules/projetos/projetos.module').then(mod => mod.ProjetosModule) },

  { path: 'filtro-projetos', component: ListProjetosComponent },
  { path: 'filtro-patrocinadores', component: ListPatrocinadoresComponent },
  { path: 'filtro-empreendedores', component: ListEmpreendedoresComponent },
  { path: 'filtro-projetos-atendem-chamada', component: ListProjetosAtendemChamadaComponent, canActivate: [ProdutoGuardService], data: {permissaoProd: ProdutosContratadosEnum.GestaoChamadas} },
  { path: 'filtro-chamadas-atendem-projeto', component: ListChamadasAtendemProjetoComponent, canActivate: [ProdutoGuardService], data: {permissaoProd: ProdutosContratadosEnum.GestaoProjetos} },

  { path: 'propostas', loadChildren: () => import('@modules/proposta/proposta.module').then(mod => mod.PropostaModule), canActivate: [ProdutoGuardService], data: {permissaoProd: ProdutosContratadosEnum.GestaoProjetos, permissaoProdInv: ProdutosContratadosEnum.GestaoChamadas} },
  { path: 'landingpage', loadChildren: () => import('@modules/landingpages/landingpage.module').then(mod => mod.LandingPageModule) },
  { path: 'workflow', loadChildren: () => import('@modules/workflow/workflow.module').then(mod => mod.WorkflowModule), canActivate: [ProdutoGuardService], data: {permissaoProd: ProdutosContratadosEnum.GestaoChamadas}  },
  { path: 'planner', loadChildren: () => import('@modules/planner/planner.module').then(mod => mod.PlannerModule), canActivate: [ProdutoGuardService], data: {permissaoProd: ProdutosContratadosEnum.Planner } },
  { path: 'seguidos', loadChildren: () => import('@modules/seguidos/seguidos.module').then(mod => mod.SeguidosModule)},
  { path: 'seguidores', loadChildren: () => import('@modules/seguidores/seguidores.module').then(mod => mod.SeguidoresModule), canActivate: [ProdutoGuardService], data: {permissaoProd: ProdutosContratadosEnum.GestaoProjetos, permissaoProdInv: ProdutosContratadosEnum.GestaoChamadas} },
  { path: 'banco-perguntas', loadChildren: () => import('@modules/pergunta/pergunta.module').then(mod => mod.PerguntaModule), canActivate: [ProdutoGuardService], data: {permissaoProd: ProdutosContratadosEnum.GestaoChamadas} },
  { path: 'convites', loadChildren: () => import('@modules/convites/convites.module').then(mod => mod.ConvitesModule), canActivate: [ProdutoGuardService], data: {permissaoProd: ProdutosContratadosEnum.GestaoProjetos, permissaoProdInv: ProdutosContratadosEnum.GestaoChamadas} },
  { path: 'tabela-precos', loadChildren: () => import('@modules/tabela-precos/tabela-precos.module').then(mod => mod.TabelaPrecosModule) },
  { path: 'todas-chamadas', component: FiltroDeChamadasPublicasComponent },
  { path: 'dominios', loadChildren: () => import('@modules/dominios/dominios.module').then(mod => mod.DominiosModule) },
  { path: 'base-relacionamento', loadChildren: () => import('@modules/base-relacionamento/base-relacionamento.module').then(mod => mod.BaseRelacionamentoModule), canActivate: [ProdutoGuardService], data: {permissaoProd: ProdutosContratadosEnum.BaseRelacionamento} },
  { path: 'produtos', loadChildren: () => import('@modules/produtos/produtos.module').then(mod => mod.ProdutosModule) },
  { path: 'autorizacao', loadChildren: () => import('@modules/autorizacao-Recebida/autorizacao.module').then(mod => mod.AutorizacaoModule) },
  { path: 'faturas-pendentes', loadChildren: () => import('@modules/faturas-pendentes/faturas-pendentes.module').then(mod => mod.FaturasPendentesModule) },
  { path: 'faturas-baixadas', loadChildren: () => import('@modules/faturas-baixadas/faturas-baixadas.module').then(mod => mod.FaturasBaixadasModule) },
  { path: 'plano-vendas', loadChildren: () => import('@modules/plano-vendas/plano-vendas.module').then(mod => mod.PlanoVendasModule) },
  { path: 'contatos-pendentes', loadChildren: () => import('@modules/contatos-pendentes-consultores/contatos-pendentes-consultores.module').then(mod => mod.ContatosPendentesConsultoresModule) },
  { path: 'venda-comercial', loadChildren: () => import('@modules/cadastro-venda-comercial/cadastro-venda.module').then(mod => mod.CadastroVendaModule) },
  { path: 'planos-produtos-adquiridos', loadChildren: () => import('@modules/planos-produtos-adquiridos/planos-produtos-adquiridos.module').then(mod => mod.PlanosProdutosAdquiridosModule) },
  { path: 'payment', loadChildren: () => import('@modules/checkout-pagamento/checkout-pagamento.module').then(mod => mod.CheckoutPagamentoModule) },
  { path: 'monitoramento', loadChildren: () => import('@modules/monitoramento/monitoramento.module').then(mod => mod.MonitoramentoModule), canActivate: [ProdutoGuardService], data: {permissaoProd: ProdutosContratadosEnum.FerramentaMonitoramento} },
  { path: 'payment-mensagem', component: MensagemPagamentoComponent },
  { path: 'pessoas', component: PessoaListComponent},
  { path: 'faq', loadChildren: () => import('@modules/faq/faq.module').then(mod => mod.FaqModule) },
  { path: 'chamadas-bloqueadas', loadChildren: () => import('@modules/chamadas-bloqueadas/chamadas-bloqueadas.module').then(mod => mod.ChamadasBloqueadasModule) },
  { path: 'projetos-bloqueados', loadChildren: () => import('@modules/projetos-bloqueados/projetos-bloqueados.module').then(mod => mod.ProjetosBloqueadosModule) },
  //{ path: 'kanban', component: KanbanComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
