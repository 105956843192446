<div class="bg-overlay pb-3">
  <app-header-site></app-header-site>

  <div class="container mt-5" *ngIf="model?.formaPagamento.descricao == 'Cartão Crédito' && model?.primeiraAquisicao">

    <section class="title pt-3 pb-5">
      <div class="col-4 px-5 mx-auto">
        <h1 class="h3 txt-support-color text-start title-line">
          Pagamento Concluído!
        </h1>
      </div>
    </section>

    <section>
      <h1>Parabéns!<br>Seja bem-vindo ao Brada!</h1>
      <p class="my-3">
        Seu pagamento foi concluído com sucesso!<br>
        Em breve, você reberá um e-mail com um link de ativação do seu login de acesso à nossa plataforma!
      </p>
      <a href="/">www.somosbrada.com.br</a>
    </section>

  </div>

  <div class="container mt-5" *ngIf="model?.formaPagamento.descricao == 'Cartão Crédito' && !model?.primeiraAquisicao">

    <section class="title pt-3 pb-5">
      <div class="col-4 px-5 mx-auto">
        <h1 class="h3 txt-support-color text-start title-line">
          Pagamento Concluído!
        </h1>
      </div>
    </section>

    <section>
      <h1>Parabéns!</h1>
      <p class="my-3">
        Seu pagamento foi concluído com sucesso!<br>
        Acesse sua conta na plataforma para verificar seu(s) produto(s) adquirido(s).
      </p>
      <a href="/">www.somosbrada.com.br</a>
    </section>

  </div>



  <div class="container mt-5" *ngIf="model?.formaPagamento.descricao != 'Cartão Crédito'">

    <section class="title pt-3 pb-5">
      <div class="col-4 px-5 mx-auto">
        <h1 class="h3 txt-support-color text-start title-line">
          Aguardando Pagamento!
        </h1>
      </div>
    </section>

    <section>
      <h1>Olá, {{ model?.conta.pessoa.nomePessoa }}!</h1>
      <p class="my-3">
        Foi gerada uma cobrança no valor de
        <span *ngIf="model?.valorLiquido">{{model?.valorLiquido | currency:'BRL':'symbol' }}.</span>
        <span *ngIf="model?.valorContratado">{{model?.valorContratado | currency:'BRL':'symbol' }}.</span>
        <br>
        Em breve, você receberá um e-mail com o link para a sua fatura!
      </p>
      <a href="/">www.somosbrada.com.br</a>
    </section>

  </div>

</div>
