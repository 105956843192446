import { ModalLoginComponent } from '@modules/login/components/modal-login/modal-login.component';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AcaoFiltrosEnum } from '@core/models/enum/acao-filtro';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from '@core/auth/authentication.service';
import { ModalEscolhaAdesaoComponent } from '@modules/adesao/components/modal-escolha-adesao/modal-escolha-adesao.component';

@Component({
  selector: 'app-modal-acesso-restrito',
  templateUrl: './modal-acesso-restrito.component.html',
  styleUrls: ['./modal-acesso-restrito.component.scss']
})
export class ModalAcessoRestritoComponent implements OnInit {

  constructor(
    public activeModal: NgbActiveModal,
    private router: Router,
    private modalService: NgbModal,
    private authenticationService: AuthenticationService,
  ) { }

  ngOnInit(): void {
  }


  acessar(acao: any ){
    switch (acao) {
      case 'adesao':
        this.fechar();
        this.openModalCadastrar();
        break;
      case 'login':
        this.fechar();
        this.modalLogin();
        break;
    }
  }

  fechar():void{
    this.activeModal.close();
  }

  modalLogin() {

    let modalLogin = this.modalService.open(ModalLoginComponent, {
      centered: true,
      beforeDismiss: () => false,
      size: 'md',
      modalDialogClass: 'modal-grafico'
    });
  }

  openModalCadastrar(): void {
    const modalColunas = this.modalService.open(ModalEscolhaAdesaoComponent, {
      centered: true,
      beforeDismiss: () => false,
      size: 'xl',
      modalDialogClass: 'modal-grafico'
    })

    let instance = <ModalEscolhaAdesaoComponent>modalColunas.componentInstance;
  }
}
