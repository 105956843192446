import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenticationService } from '@core/auth/authentication.service';
import { UserAuth } from '@core/auth/user';
import { PapelContaEnum } from '@core/models/enum/papel-conta';
import { Paginator } from '@core/models/paginator';
import { ApiService } from '@core/services/api.service';
import { environment } from '@env/environment';
import { of } from 'rxjs';

const routes = {
  tokens: `/tokens`,

  //contas
  get: (id: number) => `/contas/${id}`,
  alterar: (id: number) => `contas/${id}`,
  exportar: (id: number) => `/contas/xlsx/${id}`,
  listarContasInvestidores: `/contas/listar-conta-patrocinador`,

  // usuarios
  consultarUsuarios: `usuarios/consultar`,
  filtrarUsuarios: `/usuarios/consultar`,
  filtrar: (query: string) => `/usuarios/consultar${query}`,
  filtrarPorIdPessoa: (idPessoa:number, query: string) => `/usuarios/consultarPorIdPessoa/${idPessoa}${query}`,
  salvarNovoUsuario: `usuarios`,
  buscarUsuario: (id: number) => `usuarios/${id}`,
  editarUsuario: (id: number) => `usuarios/${id}`,
  bloquearUsuario: `usuarios/bloquear`,
  inativarUsuario: `usuarios/inativar`,
  desbloquearUsuario: (id: number) => `usuarios/desbloquear/${id}`,
  alterarSenha: `usuarios/password`,
  reenviarLinkAtivacao: (id: number) => `usuarios/reenviar-link/${id}`,
  trocarSenha: `usuario/password`,
  listarEditaisParecerista: (idParecerista: number) => `/edital/listar/por-parecerista/${idParecerista}`
}
@Injectable({
  providedIn: 'root'
})
export class UsuarioService {

  idContaUsuarioLogado: any[] = [];
  idUsuarioLogado: any;

  constructor(
    private apiService: ApiService,
    private authenticationService: AuthenticationService,
    ) { }

  getToken(){
    return this.apiService.get<UserAuth>(routes.tokens);
  }

  // conta
  buscarDadosUsuario(id: number){
    return this.apiService.get<any>(routes.get(id));
  }

  editarDadosUsuario(obj: any, id: number){
    return this.apiService.putCustomApi<any>(`${environment.api}/${routes.alterar(id)}`, obj);
  }

  exportarDadosUsuario(id: number){
    return this.apiService.downloadGet(routes.exportar(id));
  }

  // usuarios
  salvarUsuario(obj: any){
    return this.apiService.postCustomApi<any>(`${environment.api}/${routes.salvarNovoUsuario}`, obj);
  }

  consultarUsuario(id: number){
    return this.apiService.getCustomApi<any>(`${environment.api}/${routes.buscarUsuario(id)}`);
  }

  filtrar(obj: any, paginacao: Paginator){
    return this.apiService.post<any>(routes.filtrar(paginacao.query), obj);
  }

  filtrarPorIdPessoa(idPessoa:number, obj: any, paginacao: Paginator){
    return this.apiService.post<any>(routes.filtrarPorIdPessoa(idPessoa, paginacao.query), obj);
  }

  editarUsuario(obj: any, id: number){
    return this.apiService.putCustomApi<any>(`${environment.api}/${routes.editarUsuario(id)}`, obj);
  }

  bloquearUsuario(obj: any){
    return this.apiService.putCustomApi<any>(`${environment.api}/${routes.bloquearUsuario}`, obj);
  }

  inativarUsuario(obj: any){
    return this.apiService.putCustomApi<any>(`${environment.api}/${routes.inativarUsuario}`, obj);
  }

  desbloquearUsuario(id: number){
    return this.apiService.putCustomApi<any>(`${environment.api}/${routes.desbloquearUsuario(id)}`);
  }

  alterarSenhaUsuario(obj: any){
    return this.apiService.putCustomApi<any>(`${environment.api}/${routes.alterarSenha}`, obj);
  }

  trocarSenhaUsuario(obj: any){
    return this.apiService.putCustomApi<any>(`${environment.auth}/${routes.trocarSenha}`, obj);
  }

  reenviarLinkAtivacao(id: number){
    return this.apiService.getCustomApi<any>(`${environment.api}/${routes.reenviarLinkAtivacao(id)}`);
  }

  listarContasInvestidores(){
    return this.apiService.get<any>(routes.listarContasInvestidores);
  }

  listarEditaisParecerista(idParecerista: number){
    return this.apiService.get<any>(routes.listarEditaisParecerista(idParecerista));

  }

  filtrarUsuarios(obj: any, page:number,pageSize:number) {
    let params = new HttpParams();
    params = params.append('size',pageSize);
    params = params.append('page',page - 1);
    return this.apiService.post<any>(`${routes.filtrarUsuarios}`, obj,undefined,undefined,params);
  }

  // filtrarPorIdPessoa(idPessoa:number, obj: any, paginacao: Paginator){
  //   return this.apiService.post<any>(routes.filtrarPorIdPessoa(idPessoa, paginacao.query), obj);
  // }

  filtrarIdUsuarios(idPessoa: any, obj: any, page:number,pageSize:number) {
    let params = new HttpParams();
    params = params.append('idPessoa',idPessoa);
    params = params.append('size',pageSize);
    params = params.append('page',page - 1);
    return this.apiService.post<any>(`${routes.filtrarPorIdPessoa}`, obj,undefined,undefined,params);
  }

  // filtrarPorIdPessoa(idPessoa:number, obj: any, paginacao: Paginator){
  //   return this.apiService.post<any>(routes.filtrarPorIdPessoa(idPessoa, paginacao.query), obj);
  // }

  consultarStatus() {
    return of([
      { id: 1, nome: 'Ativo' },
      { id: 2, nome: 'Bloqueado' },
      { id: 3, nome: 'Inativo' },
      // { id: 4, nome: 'Removido' }
    ]);
  }

}
