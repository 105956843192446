import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Injectable } from '@angular/core';

@Injectable()
export class CustomNgbDateParserFormatter {
    parse(value: string): any {
        return value;
    }
    format(date: NgbDateStruct): string {
        if (date)
            return `${this.padNumber(date.day)}/${this.padNumber(date.month)}/${date.year}`;


        return '';
    }
    padNumber(num: number) {
        if (num < 10)
            return `0${num}`;

        return num;
    }
}