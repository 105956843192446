<div class="bg-body-color pb-5">
  <div class="container">


    <section class="titleEbreadcrumb mt-5">
      <div class="col-12 d-sm-flex align-items-center">
        <button class="btn btn-secundary-color d-flex align-items-center me-4 px-4 py-1 rounded-pill h7"
          (click)="voltar()">
          <i class="bi bi-arrow-left-short fs-6 me-1"></i>
          {{'Voltar' | translate}}
        </button>
        <nav class="col pt-3" aria-label="breadcrumb">
          <ol class="breadcrumb h7 fw-500">
            <li class="breadcrumb-item txt-primary-color text-decoration-underline">
              <a class="nav-link m-0 p-0 txt-primary-color" (click)="voltarDashboard()">{{'Dashboard' | translate}}</a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              {{ 'Lista_de_projetos_que_uma_chamada_atende' | translate }}
            </li>
          </ol>
        </nav>
      </div>
    </section>

    <section class="title py-3 mt-5">
      <div class="col col-sm-7 px-5 mx-auto">
        <h1 class="h3 txt-support-color text-center title-line">
          {{ 'Lista_de_projetos_que_uma_chamada_atende' | translate }}
        </h1>
      </div>
    </section>
  </div>

  <div class="container">
    <div class="row gx-5">

      <div class="form-group my-4 col-10 col-sm-5 mx-auto">
        <label class="mb-1">{{'Selecione_uma_Chamada' | translate}}</label>
        <ng-select [notFoundText]="'Nenhum_item_encontrado' | translate"[multiple]="false" name="listaProjetos" placeholder="Selecione" (change)="pesquisarProjetos($event)">
          <ng-option *ngFor="let item of listaEditais" [value]="item">{{ item.tituloEdital }}</ng-option>
        </ng-select>
      </div>

      <section class="col-sm-8 col-11 mx-auto projetos-filtrados mt-5 mb-5 pb-5">

        <div class="col-10 mx-auto text-center px-5 py-3" *ngIf="listaProjetos.length == 0">
          {{'Nenhum_projeto_encontrado' | translate}}
        </div>

        <div *ngIf="listaProjetos.length > 0">
          <div class="row gx-5 gy-5">

            <div class="col-sm-8 col-11 mx-auto col-sm-4 s" *ngFor="let item of listaProjetos" >

              <div class="card-brada card-height col-12 pt-2 pt-sm-3 px-4 bg-white-color shadow border-5 h-100">
                <div class="card bg-body-color text-white border-brada-4 mt-2">
                  <img class="card-img border-brada-4 w-100" [src]="fotoCard(item.fotoPerfil)" alt="imagem chamada" loading="lazy" />

                  <div class="card-img-overlay border-brada-4">
                    <div
                      class="card-header col-12 position-absolute top-0 start-0 d-flex align-items-center border-0 border-brada-4">
                      <div class="col mt-2">
                        <span class="h7 bg-white-color-t2 py-2 px-3 rounded-3 fw-300">
                          {{'Id_Projeto' | translate}} {{item.idProjeto}}
                        </span>
                      </div>
                    </div>

                  </div>
                </div>

                <div class="card-body px-2 px-sm position-relative">
                  <div class="col-12">
                    <section class="title mb-3 h-50">
                      <span class="h7 txt-muted">{{'Título_Projeto' | translate}}</span>
                      <h6 class="card-title">{{item.nomeProjeto}}</h6>
                    </section>
                  </div>

                  <div class="col-12 border-top border-bottom py-3">
                    <section class="patrocinador">
                      <p class="h7 card-subtitle text-muted fw-400">
                        {{'Empreendedor:' | translate}}<span class="ms-2 fw-500">{{item.empreendedor}}</span>
                      </p>
                    </section>

                    <section class="url" style="overflow: auto" >
                      <span class="col-12 h7 text-muted fw-400">{{'LandingPage:' | translate}}</span>
                      <a class="ms-2 text-muted h7" (click)="visualizarLandingPage(item.idProjeto)">{{item.urlLandingPage}}</a>
                    </section>
                  </div>

                  <div class="col-12 mt-3 pt-2">
                    <div class="row gx-2">
                      <div class="col-6 mx-auto d-flex align-items-start justify-content-end">
                        <a class="btn btn-secundary-color py-2 h7" (click)="visualizarLandingPage(item.idProjeto)">
                          {{'Acessar_Agora' | translate}}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>

            <section class="col-10 mx-auto" *ngIf="collectionSize > 5">
              <div class="d-flex justify-content-between p-2">
                <select class="custom-select px-2 h7 border-0 text-decoration-underline bg-body-color" style="width: auto" [(ngModel)]="pageSize" (ngModelChange)="trocarSize()">
                  <option *ngFor="let item of filtroPaginas" [value]="item.valor">{{ item.nome }}</option>
                </select>

                <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize" (pageChange)="pesquisar(lastPage)" [maxSize]="6" [rotate]="true" [ellipses]="false" [boundaryLinks]="true" class="mt-2">
                </ngb-pagination>
              </div>
            </section>
          </div>
        </div>

      </section>
    </div>
  </div>
</div>
