<div class="bg-body-color pb-5">
  <div class="container">
    <section class="titleEbreadcrumb mt-5">
      <div class="col-12 d-flex align-items-center">
        <button class="btn btn-secundary-color d-flex align-items-center me-4 px-4 py-1 rounded-pill h7"
          (click)="voltarDashboard()">
          <i class="bi bi-arrow-left-short fs-6 me-1"></i>
          {{'Voltar' | translate}}
        </button>
        <nav class="col pt-3" aria-label="breadcrumb">
          <ol class="breadcrumb h7 fw-500">
            <li class="breadcrumb-item txt-primary-color text-decoration-underline">
              <a class="nav-link m-0 p-0 txt-primary-color" (click)="voltarDashboard()">{{'Dashboard' | translate}}</a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              {{ "Lista_de_Projetos" | translate }}
            </li>
          </ol>
        </nav>
      </div>
    </section>

    <section class="title py-3">
      <div class="col-10 col-sm-4 px-5 mx-auto">
        <h1 class="h3 txt-support-color text-center title-line">
          {{ "Lista_de_Projetos" | translate }}
        </h1>
      </div>
    </section>
  </div>

  <div class="col-12 d-flex justify-content-center">
    <div class="col-9">
      <button type="button" class="btn btnl-primary-color rounded-pill d-flex align-items-center px-5 py-2 h7 fw-500 d-sm-none" (click)="Filtro()">
        Filtro
      </button>
    </div>
  </div>

  <div class="container">
    <div class="row gx-5">
      <aside class="col-12 col-sm-3 bg-white-color shadow-sm border-brada mt-4" [ngClass]="{'d-none d-sm-block': fill == false}">
        <div class="p-3">
          <h5 class="py-4 text-center txt-primary-color">{{'Filtros' | translate}}</h5>

          <form [formGroup]="formFiltroProjetos" id="formFiltroProjetos" (ngSubmit)="listar()">
            <div class="form-group my-3">
              <label class="mb-1">{{'Nome_ou_Descrição' | translate}}</label>
              <input type="text" class="form-control tm-input" formControlName="nomeOuDescricao" placeholder="Buscar nome Projeto"
                autocomplete="off" />
            </div>

            <div class="form-group my-4">
              <label class="mb-1">{{'Patrocinadores' | translate}}</label>
              <ng-select [notFoundText]="'Nenhum_item_encontrado' | translate"[multiple]="false" name="patrocinador" placeholder="Selecione" formControlName="patrocinador">
                <ng-option *ngFor="let item of listaPatrocinador" [value]="item"><span [title]="item.nomePessoa">{{item.nomePessoa}}</span></ng-option>
              </ng-select>
            </div>

            <div class="form-group my-4">
              <label class="mb-1">{{'Empreendedores' | translate}}</label>
              <ng-select [notFoundText]="'Nenhum_item_encontrado' | translate"[multiple]="false" name="empreendedor" placeholder="Selecione" formControlName="empreendedor">
                <ng-option *ngFor="let item of listaEmpreendedor" [value]="item"><span [title]="item.nomePessoa">{{item.nomePessoa}}</span></ng-option>
              </ng-select>
            </div>
            <div class="form-group my-4">
              <label class="mb-1">{{'Local_Atuação_UF' | translate}}</label>
              <ng-select [notFoundText]="'Nenhum_item_encontrado' | translate"[multiple]="false" name="estado" placeholder="Selecione" formControlName="estado" (change)="buscarMunicipio($event)">
                <ng-option *ngFor="let item of estados" [value]="item"><span [title]="item.nome">{{item.nome}}</span></ng-option>
              </ng-select>
            </div>
            <div class="form-group my-4">
              <label class="mb-1">{{'Local_Atuação_Município' | translate}}</label>
              <ng-select [notFoundText]="'Nenhum_item_encontrado' | translate"[multiple]="false" name="municipios" placeholder="Selecione" formControlName="municipio">
                <ng-option *ngFor="let item of municipios" [value]="item"><span [title]="item.nome">{{item.nome}}</span></ng-option>
              </ng-select>
            </div>
            <div class="form-group my-4">
              <label class="mb-1">{{'Área_de_Interesse' | translate}}</label>
              <ng-select [notFoundText]="'Nenhum_item_encontrado' | translate"[multiple]="false" name="areasDeInteresse" placeholder="Selecione" formControlName="areaInteresse">
                <ng-option *ngFor="let item of areasDeInteresse" [value]="item"><span [title]="item.nomeAreaInteresse">{{item.nomeAreaInteresse}}</span></ng-option>
              </ng-select>
            </div>
            <div class="form-group my-4">
              <label class="mb-1">{{'Fonte_de_Financiamento' | translate}}</label>
              <ng-select [notFoundText]="'Nenhum_item_encontrado' | translate"[multiple]="false" name="fonteFinanciamento" placeholder="Selecione" formControlName="fonteFinanciamento">
                <ng-option *ngFor="let item of fonteFinanciamento" [value]="item"><span [title]="item.nomeFonteFinanciamento">{{item.nomeFonteFinanciamento}}</span></ng-option>
              </ng-select>
            </div>
            <div class="form-group my-4">
              <label class="mb-1">{{'ODS' | translate}}</label>
              <ng-select [notFoundText]="'Nenhum_item_encontrado' | translate"[multiple]="false" name="ods" placeholder="Selecione" formControlName="ods">
                <ng-option *ngFor="let item of ods" [value]="item"><span [title]="item.nome">{{item.nome}}</span></ng-option>
              </ng-select>
            </div>
            <div class="form-group my-4">
              <label class="mb-1">{{'Status' | translate}}</label>
              <ng-select [notFoundText]="'Nenhum_item_encontrado' | translate"[multiple]="true" name="statusProjeto" placeholder="Selecione" formControlName="statusProjeto">
                <ng-option *ngFor="let item of statusProjeto" [value]="item"><span [title]="item.nome">{{item.nome}}</span></ng-option>
              </ng-select>
            </div>

            <div class="row gx-3 my-4">
              <div class="col-6">
                <button type="button" class="btn btnl-primary-color w-100" (click)="reset()">{{'Limpar' | translate}}</button>
              </div>
              <div class="col-6">
                <button type="submit" (click)="Filtro()" class="btn btn-primary-color w-100">{{'Filtrar' | translate}}</button>
              </div>
            </div>

          </form>
        </div>

      </aside>
      <section class="col-12 col-sm-9 chamadas-filtradas mt-4">

        <div class="col-10 mx-auto bg-white-color shadow border-5" *ngIf="lista.length == 0">

          <div class="col-12 text-center px-5 py-5">
            {{'Nenhum_resultado_encontrado' | translate}}
          </div>

        </div>
        <div *ngIf="lista.length > 0" [ngClass]="{'d-none d-sm-block': fill == true}">
          <div class="row g-3" *ngFor="let item of lista">
            <div class="col-11 col-sm-10 mx-auto mb-3 ">
              <div class="card-brada bg-white-color shadow border-5 ss">
                <div class="col-12 p-4 d-sm-flex align-items-center">
                  <div class="col-12 col-sm-3 px-3 py-3 d-flex align-items-center justify-content-center border-brada">
                    <img class="mx-5" [src]="fotosChamada(item.fotoPerfil)" alt="imagem chamada" loading="lazy" class="card-img" class="w-100" />
                  </div>
                  <div class="col-12 col-sm px-5">
                    <section class="titulo">
                      <span class="h7 text-muted fw-500">{{'Título_Projeto' | translate}}</span>
                      <h6>{{item.nomeProjeto}}</h6>
                    </section>
                    <section class="h7 mt-3">
                      <span class="h7 text-muted fw-500">{{'Empreendedor:' | translate}} </span>
                      {{item.nomeEmpreendedor}}
                    </section>
                    <div class="border-top mt-2 py-2 d-flex text-muted flex-wrap">
                      <h6 class="fw-500 h7 flex-fill py-1 pe-1 my-1 me-1">{{'Areas_de_Interesse' | translate}} </h6>
                      <div *ngFor="let area of item.areaInteresse">
                        <h6 class="h7 bg-claro-color p-1 my-1 me-1 flex-fill">{{area}}</h6>
                      </div>
                    </div>
                  </div>

                  <div class="col-12 col-sm-3 pt-4">

                    <button type="button" class="btn btn-acoes txt-secundary-color h7 text-center w-100" *ngIf="item.seguir && !item.selectSeguir || item.selectDeixar && !item.selectSeguir" (click)="btSeguirProjeto(item)">
                      {{'Seguir_Projeto' | translate}}
                    </button>
                    <button type="button" class="btn btn-acoes txt-secundary-color h7 text-center w-100 mb-3" *ngIf="usuarioLogado.perfil === 'ADMINISTRADOR' && item.statusProjeto.id != statusProjetoEnum.BloqueadoPeloAdm" (click)="openModalBloquear(item.id)">
                      <i class="bi bi-eye-slash bloqueio"> Bloquear Projeto</i>

                    </button>
                    <div class="col-12 d-flex align-items-center">
                      <div class="col-12 rounded-2 bg-primary-color mx-auto text-center">
                        <h6 class="pt-2 txt-white fw-400 h7">{{item.statusProjeto.nome}}</h6>
                      </div>
                    </div>

                    <section class="col-12 acoes mx-auto d-flex align-items-center">
                      <div class="col-12 mx-auto">
                        <div class="d-flex justify-content-center mx-auto" *ngIf="item.statusProjeto.nome != statusProjetoEnum.AguardandoGeracaoLandingPage">
                          <button type="button" class="btn btn-secundary-color py-2 w-100 my-3 h7" (click)="visualizarLandingPage(item.id)">
                            {{'Visualizar' | translate}}
                          </button>
                        </div>
                      </div>
                    </section>

                    <button type="button" class="btn btn-acoes h7 text-center border w-100" *ngIf="!item.seguir && !item.selectDeixar || item.selectSeguir && !item.selectDeixar" (click)="btDeixarDeSeguirProjeto(item)">
                      {{'Deixa_de_Seguir' | translate}}
                    </button>

                  </div>
                </div>
              </div>
            </div>
          </div>

          <section class="col-sm-10 col-12 mx-auto" *ngIf="collectionSize > 5">
            <div class="d-sm-flex justify-content-between p-2">
              <select class="custom-select px-2 h7 border-0 text-decoration-underline bg-body-color" style="width: auto" [(ngModel)]="pageSize" (ngModelChange)="trocarSize()">
                 <option *ngFor="let item of filtroPaginas" [value]="item.valor">{{ item.nome }}</option>
              </select>

              <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize" (pageChange)="pesquisar(lastPage)" [maxSize]="6" [rotate]="true" [ellipses]="false" [boundaryLinks]="true" class="mt-2">
              </ngb-pagination>
            </div>
          </section>
        </div>

      </section>

    </div>
  </div>
</div>
