import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService } from '@core/auth/authentication.service';
import { Masks } from '@core/custom-validators/masks';
import { TipoPerfil } from '@core/models/dominio';
import { AcaoFiltrosEnum } from '@core/models/enum/acao-filtro';
import { Paginator } from '@core/models/paginator';
import { DominioService } from '@core/services/dominio.service';
import { NotificationService } from '@core/services/notification.service';
import { PapelPessoaService } from '@core/services/papel-pessoa.service';
import { PessoaService } from '@core/services/pessoa.service';
import { UsuarioService } from '@core/services/usuario.service';
import { ModalMotivoComponent } from '@modules/chamadas/components/modalMotivo/modalMotivo.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-pessoa-list',
  templateUrl: './pessoa-list.component.html',
  styleUrls: ['./pessoa-list.component.scss']
})
export class PessoaListComponent implements OnInit {

  paginator: Paginator = new Paginator;
  acaoFiltroEnum = AcaoFiltrosEnum;
  namePage = "Lista de Usuários";
  pessoas: any[] = [];
  message!: string;

  formFiltroUsuarios!: FormGroup;
  tiposPessoa: Array<TipoPerfil> = [];
  tipoStatus: any[]=[];
  papeisPessoa: any[]=[];
  filtroPaginas: any[]=[];
  page = 1;
  pageSize = 5;
  lastPage = 1;
  collectionSize: any;
  fill: boolean = false;

  maskCPFCNPJ = {
    mask: Masks.cpfCnpj,
    guide: false
  }

  constructor(
    private pessoaService: PessoaService,
    private router: Router,
    private modalService: NgbModal,
    private translateService: TranslateService,
    private notificationService: NotificationService,
    private dominioService: DominioService,
    private usuarioService: UsuarioService,
    private formBuilder: FormBuilder,
    private papelPessoaService: PapelPessoaService
  ) { }

  ngOnInit(): void {
    this.dominioService.filtroPaginas().subscribe((data: any) => this.filtroPaginas = data);
    this.usuarioService.consultarStatus().subscribe((data: any) => this.tipoStatus = data );
    this.dominioService.buscarTipoPessoa().subscribe((data: any) => this.tiposPessoa = data);

    this.papelPessoaService.buscarPapelPessoa().pipe(
      map((data: any[]) => data.filter(item => item.nome !== "Ambos"))
    ).subscribe((data: any[]) => {
      this.papeisPessoa = data;
    });

    this.formFiltroUsuarios = this.formBuilder.group({
      nome: [],
      cpfCnpj: [],
      tipoPessoa: [],
      statusPessoa: [],
      papelPessoa: [],
    });

    this.filtrar();
  }

  filtrar(lastPage?: number) {
    if (lastPage && lastPage == this.page)
    return;

    this.lastPage = this.page;
    let objEnvio = { ...this.formFiltroUsuarios.value };

    if(objEnvio.nome == "")
      objEnvio.nome = null

    if(objEnvio.cpfCnpj == "")
      objEnvio.cpfCnpj = null

    if(objEnvio.statusPessoa != null)
      objEnvio.statusPessoa = [objEnvio.statusPessoa]


    this.pessoaService.filtrarPessoas(objEnvio, this.page, this.pageSize).subscribe((data: any) => {
      this.pessoas = data.content;
      this.collectionSize = data.totalElements;
    });
  }

  openModal(idPessoa: any, acao: AcaoFiltrosEnum) {
    switch (acao) {
      case AcaoFiltrosEnum.Inativar:
        let modalBloquear = this.modalService.open(ModalMotivoComponent, {
          centered: true,
          beforeDismiss: () => false,
          size: 'md'
        });
        modalBloquear.componentInstance.tituloDescricao = AcaoFiltrosEnum.Inativar;

        modalBloquear.result.then((data) => {
          let objData = data;
          objData = objData.motivoInativacao;
          if (data) {
            let objEnvio = {
              id: idPessoa,
              justificativa: objData
            }
            this.inativarPessoa(idPessoa, objEnvio);
          }
        })
        break;
    }
  }

  inativarPessoa(idPessoa: any, dataForm: any) {
    if (dataForm) {
      this.pessoaService.inativarPessoa(dataForm).toPromise();
      this.message = this.translateService.instant("Pessoa_inativado_com_Sucesso");
      this.pessoas.find(pessoa => {
        if (pessoa.id == dataForm.id) {
          pessoa.statusPessoa.nome = 'Inativo';
        }
      });
      this.notificationService.showSuccess(this.message);
    }
  }

  desbloquearPessoa(idPessoa: number) {
    if (idPessoa) {
      this.pessoaService.desbloquearPessoa(idPessoa).subscribe((data: any) => {
        this.message = this.translateService.instant("Pessoa_desbloqueado_com_Sucesso");
        this.pessoas.find(pessoa => {
          if (pessoa.id == idPessoa) {
            pessoa.statusPessoa.id = 1;
            pessoa.statusPessoa.nome = 'Ativo';
          }
        })
        this.notificationService.showSuccess(this.message);
      });
    }
  }

  bloquearPessoa(idPessoa: number) {
    if (idPessoa) {
      this.pessoaService.bloquearPessoa(idPessoa).subscribe((data: any) => {
        this.message = this.translateService.instant("Pessoa_bloqueado_com_Sucesso");
        this.pessoas.find(pessoa => {
          if (pessoa.id == idPessoa) {
            pessoa.statusPessoa.id = 2;
            pessoa.statusPessoa.nome = 'Bloqueado';
          }
        })
        this.notificationService.showSuccess(this.message);
      })
    }
  }

  voltarDashboard(): void {
    this.router.navigate(['/dashboard']);
  }

  showError(mensagem: any) {
    this.notificationService.showError(mensagem);
  }

  showSuccess(mensagem: any) {
    this.notificationService.showSuccess(mensagem);
  }

  trocarSize(): void {
    this.paginator.page = 1;
    this.paginator.lastPage = 0;
    this.filtrar();
  }

  reset() {
    this.formFiltroUsuarios.reset();
    this.fill == false
  }

  compareFn(a: any, b: any) {
    return a.id == b.id;
  }
}
