export class Paginator {
    constructor(
        ) {}
    public pageSize: number = 5;
    public page: number = 1;
    public collectionSize: number = 0;
    public lastPage: number = 0;
    public filtroPaginas: any[] = [
        { id: 1, valor: '5', nome: '05 itens por páginas' },
        { id: 2, valor: '10', nome: '10 itens por páginas' },
        { id: 3, valor: '25', nome: '25 itens por páginas' },
        { id: 4, valor: '50', nome: '50 itens por páginas' }
    ]

    public get query() {
        let pageQuery = this.page -1;
        let pageSizeQuery = this.pageSize;
        return `?page=${pageQuery}&size=${pageSizeQuery}`;
    }
}