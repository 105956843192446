import { DOCUMENT } from '@angular/common';
import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TipoFotoEnum } from '@core/models/enum/tipo-foto';
import { ArquivoService } from '@core/services/arquivo.service';
import { PageScrollService } from 'ngx-page-scroll-core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '@env/environment';
@Component({
  selector: 'app-header-landingpage',
  templateUrl: './header-landingpage.component.html',
  styleUrls: ['./header-landingpage.component.scss']
})
export class HeaderLandingpageComponent implements OnInit {
  cities = [
    {
      id: 0,
      value: "pt-BR",
      avatar: 'https://static.todamateria.com.br/upload/ba/nd/bandeira-do-brasil-og.jpg',
      lingua: 'PT'
    },
    {
      id: 1,
      value: "en-US",
      avatar: 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/a4/Flag_of_the_United_States.svg/2560px-Flag_of_the_United_States.svg.png',
      lingua: 'EN'
    },
    {
      id: 2,
      value: "es-ES",
      avatar: 'https://upload.wikimedia.org/wikipedia/commons/thumb/9/9a/Flag_of_Spain.svg/2560px-Flag_of_Spain.svg.png',
      lingua: 'ES'
    }
  ];

  windowScrolled!: boolean;
  activeSection = 1;
  selectedCity = this.cities[0];
  imagemPerfilMenu: any;
  imagemCapaBanner: any;
  edital: any;
  linguas = 0

  constructor(
    @Inject(DOCUMENT) private document: any,
    private arquivoService: ArquivoService,
    private translateService: TranslateService,
    private sanitizer: DomSanitizer,
  ) {}

  ngOnInit() {
    this.fotoMenu();
  }

  fotoMenu(){
    let fotoPerfil = this.edital.fotos.map((x: { tpFoto: { nomeTipoFoto: TipoFotoEnum; }; }) => x.tpFoto.nomeTipoFoto = TipoFotoEnum.Perfil);

    if(fotoPerfil.base64Content != null){
      this.imagemPerfilMenu = fotoPerfil;

      if (this.imagemPerfilMenu?.base64Content && this.imagemPerfilMenu?.extencao) {
        this.imagemPerfilMenu = this.sanitizer.bypassSecurityTrustUrl(`data:image/${this.imagemPerfilMenu?.extencao};base64,${this.imagemPerfilMenu?.base64Content }`);
      }
    }
  }

  public selectLanguage(event: any) {
    const linguagemSelecionada = event.value; // Captura a linguagem selecionada
    console.log('Linguagem selecionada:', linguagemSelecionada);
    this.translateService.use(event.value)
    this.linguas = (event.id)
// console.log(this.linguas)
  }

  @HostListener("window:scroll", [])
  onWindowScroll() {
    if (window.pageYOffset > 300) {
      this.windowScrolled = true;
    } else {
      this.windowScrolled = false;
    }
  }

  clickActiveLink(i: number) {
    this.activeSection = i;
    if(i === i) {
      return true;
    }else {
      return false;
    }
  }

  goToUrl(): void {
    window.open(`http://${environment.urlSiteAtual}`, '_self')
  }
}
