<div class="col-12">
  <footer class="w-100 text-center text-lg-start text-muted">

    <section class="footer-site" *ngIf="!(isLoggedIn$ | async)" [ngClass]="{'py-md-5': !landingPage}">
      <div class="container text-center text-md-start mt-5" *ngIf="!landingPage">
        
        <div class="row mt-3">
          
          <div class="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4 p-0">
            <img src="/assets/image/marca-brada.png" class="mb-4">
            
            <h6 class="pt-1 pb-3 fw-400">
              Plataforma digital que incentiva ações e projetos de impacto social.
            </h6>
            
            <div class="row">
              <div class="col-12 col-sm-6 d-sm-flex justify-content-sm-start my-sm-3">
                <i class="bi bi-facebook fs-4"></i>
                <i class="bi bi-twitter fs-4 mx-3"></i>
                <i class="bi bi-instagram fs-4"></i>
              </div>
            </div>
          </div>

          <!-- <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4 mt-2">
            <h6 class="text-uppercase mb-4 text-white-color">
              {{'Links_Úteis' | translate}}
            </h6>
            <p>
              <a href="#" class="text-muted">{{'Home' | translate}}</a>
            </p>
            <p>
              <a (click)="scrollTo('#oportunidades')" class="text-muted">{{'Oportunidades' | translate}}</a>
            </p>
            <p>
              <a href="#!" class="text-muted">{{'Planos_e_Preços' | translate}}</a>
            </p>
            <p>
              <a href="#!" class="text-muted">{{'Contato' | translate}}</a>
            </p>
          </div> -->

          <!-- <div class="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4 mt-2">
            <h6 class="text-uppercase mb-4 text-white-color">
              {{'Suporte' | translate}}
            </h6>
            <p>
              <a href="#!" class="text-muted">{{'Central_de_Ajuda' | translate}}</a>
            </p>
            <p>
              <a href="#!" class="text-muted">{{'Política_de_Privacidade' | translate}}</a>
            </p>
            <p>
              <a href="#!" class="text-muted">{{'Termos_de_Uso' | translate}}</a>
            </p>
            <p>
              <a href="#!" class="text-muted">{{'Suporte' | translate}}</a>
            </p>
          </div> -->

          <div class="d-none-sm col-3"></div>
          <div class="d-none-sm col-2"></div>

          <div class="col-10 col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4 mt-2">
            <h6 class="text-uppercase mb-2 mb-md-4 text-white-color">
              {{'Fale_Conosco' | translate}}
            </h6>
            <div class="col-12 d-flex flex-column">
              <div class="d-flex mx-auto ms-md-0 align-items-center">
                <i class="bi bi-telephone col-1 mt-2"></i>
                <a class="ms-2 text-muted mt-2" href="tel:+5521989348808">
                  +55 (21) 98934-8808
                </a>
              </div>
              <div class="d-flex mx-auto ms-md-0">
                <i class="bi bi-telephone col-1 align-items-center mt-2"></i>
                <a class="ms-2 text-muted mt-2" href="tel:+552124371641">
                  +55 (21) 2437-1641
                </a>
              </div>
            </div>
            <div class="d-flex mx-auto ms-md-0">
              <i class="bi bi-envelope col-1 align-items-center mt-2"></i>
              <a class="ms-1 text-muted mt-2" href="mailto:atendimento@somosbrada.com.br">
                atendimento@somosbrada.com.br
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="container">
      <div class="border-top">
        <p class="h7 text-center text-muted py-4 m-0 fw-500">
          <a href="/" class="link-danger text-decoration-none">Brada</a> © {{anoDireitos}}. {{'Todos_os_direitos_reservado_.' | translate}}
        </p>
      </div>
    </div>
  </footer>

</div>