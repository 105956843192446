import { Injectable } from '@angular/core';

const imagesInteresse = [
  {
    id: 1,
    imagem: '/assets/image/publico-alvo/pb-alvo.png'
  },
  {
    id: 2,
    imagem: '/assets/image/publico-alvo/pb-alvo.png'
  },
  {
    id: 3,
    imagem: '/assets/image/publico-alvo/pb-alvo.png'
  },
  {
    id: 4,
    imagem: '/assets/image/publico-alvo/pb-alvo.png'
  },
  {
    id: 5,
    imagem: '/assets/image/publico-alvo/pb-alvo.png'
  },
  {
    id: 6,
    imagem: '/assets/image/publico-alvo/pb-alvo.png'
  },
  {
    id: 7,
    imagem: '/assets/image/publico-alvo/pb-alvo.png'
  },
  {
    id: 8,
    imagem: '/assets/image/publico-alvo/pb-alvo.png'
  },
  {
    id: 9,
    imagem: '/assets/image/publico-alvo/pb-alvo.png'
  },
]
@Injectable({
  providedIn: 'root'
})
export class PublicoAlvoService {

  constructor() { }

  popularImagens(areasInteresse: any[]): void {
    areasInteresse.forEach(area => {
      area.imagem = imagesInteresse.find(x => x.id == area.id)?.imagem;
    })
  }
}
