<div class="container">
  <div class="modal-header">
    <h4 class="modal-title"></h4>
    <button type="button" class="close btn-close" aria-label="Close" (click)="fechar()">
      
    </button>
  </div>
  <div class="modal-body">
    <div class="col-10 mx-auto">
      <div class="row">
        <div class="col-12 m-auto">
          <h4 class="text-center my-3 txt-support-color ">
            {{'Selecione_uma_Ficha_Cadastrada' | translate}}
          </h4>
  
          <div class="mt-5 tabela-filtros table-responsive" *ngIf="listaFichasInscricao.length">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th scope="col" class="text-right">{{'Nº' | translate}}</th>
                  <th scope="col">{{'Título' | translate}}</th>
                  <th scope="col">{{'Projeto' | translate}}</th>
                  <th scope="col" class="text-center">{{'Ações' | translate}}</th>
                </tr>
              </thead>
              <tbody>
              <tr *ngFor="let item of listaFichasInscricao" class="h6 fw-400">
                <td class="text-right ps-2">{{item?.edital?.id}}</td>
                <td>{{item?.edital?.titulo}}</td>
                <td>{{item?.projeto?.nomeProjeto}}</td>              
                <td class="col">
                  <section class="col-11 acoes mx-auto d-flex align-items-center justify-content-center">
                    <button class="btn btn-primary-color d-flex align-items-center h7" placement="bottom" (click)="editarFicha(item.id)">
                      <i class="bi bi-pencil-square pe-2"></i>
                      {{'Editar' | translate}}
                    </button>
                  </section>
                </td>
              </tr>
              </tbody>
            </table>
            
            <!-- <section class="col-12 mx-auto" *ngIf="collectionSize > 5">
              <div class="d-flex justify-content-between p-2">        
                <select class="custom-select px-2 h7 border-0 text-decoration-underline" style="width: auto" [(ngModel)]="pageSize" (ngModelChange)="trocarSize()">
                  <option [ngValue]="5" selected>5 itens por página</option>
                  <option [ngValue]="10">10 itens por página</option>
                </select>
      
                <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize" (pageChange)="pesquisar(lastPage)">
                </ngb-pagination>
              </div>
            </section> -->
          </div>
          
          <div class="row my-3">
            <div class="col-12 my-4 d-flex align-items-center justify-content-center">
              <button type="button" class="btn btn-primary-color py-2 px-5" (click)="adicionarFicha()"> 
                {{'Cadastrar_Novo_Projeto' | translate}}
              </button>
            </div>
          </div>
  
        </div>
      </div>
  
    </div>
  </div>
</div>