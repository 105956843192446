import { Injectable } from '@angular/core';
import { AuthenticationService } from '@core/auth/authentication.service';
import { ApiService } from './api.service';

const routes = {
  buscarDadosEtapaAvaliacao: (idEtapaAvaliacaoProjeto: number) => `/etapa-avaliacao-projeto-votacao/${idEtapaAvaliacaoProjeto}`,
  buscarDadosConvidado: (protocolo: number) => `/publica/etapa-avaliacao-projeto-votacao/convidado/${protocolo}`,
  atualizarEtapaVotacao: (idEtapaAvaliacaoProjeto: number) => `/etapa-avaliacao-projeto-votacao/${idEtapaAvaliacaoProjeto}/atualizar-votacao-popular`,
  enviarEmailConvidado: (idChamada: number, idEtapaAvaliacaoProjeto: number) => `/etapa-avaliacao-projeto-votacao/${idChamada}/${idEtapaAvaliacaoProjeto}/enviar-email-convidado`,
  landingPageVotacao: (idEtapaAvaliacaoProjeto: number) => `/publica/etapa-avaliacao-projeto-votacao/${idEtapaAvaliacaoProjeto}`,
  votarNalandingPage: `/publica/etapa-avaliacao-projeto-votacao/votar`
}
@Injectable({
  providedIn: 'root'
})
export class VotacaoPopularService {

constructor(
  private apiService: ApiService,
  private authenticationService: AuthenticationService,
) { }

  buscarDadosEtapaAvaliacao(idEtapaAvaliacaoProjeto: any) {
    return this.apiService.get<any>(routes.buscarDadosEtapaAvaliacao(idEtapaAvaliacaoProjeto));
  }

  atualizarEtapaVotacao(idEtapaAvaliacaoProjeto: any, obj: any) {
    return this.apiService.post<any>(routes.atualizarEtapaVotacao(idEtapaAvaliacaoProjeto), obj);
  }

  enviarEmailConvidado(idChamada: any, idEtapaAvaliacaoProjeto: any, obj: any) {
    return this.apiService.post<any>(routes.enviarEmailConvidado(idChamada, idEtapaAvaliacaoProjeto), obj);
  }

  landingPageVotacao(idEtapaAvaliacaoProjeto: any) {
    return this.apiService.get<any>(routes.landingPageVotacao(idEtapaAvaliacaoProjeto));
  }

  votarNalandingPage(obj: any) {
    return this.apiService.post<any>(routes.votarNalandingPage, obj);
  }

  buscarDadosConvidado(protocolo: number){
    return this.apiService.get<any>(routes.buscarDadosConvidado(protocolo));
  }
}
