import { Injectable } from '@angular/core';
import { Notificacoes } from '@core/models/notificacao';
import { environment } from '@env/environment';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from './api.service';

const routes = {
  buscarNotificacoes: (id: number) => `/notificacao/notificacoes/${id}`,
  buscarQuantidade: (id: number) => `/notificacao/${id}`,
  buscarNotificacoesQuantidade: (id: number) => `/notificacao/qtdNotificacoes/${id}`,
  excluir: (id: number) => `/notificacao/excluir/${id}`,
  excluirTodos: (id: number) => `/notificacao/excluirtodas/${id}`,
  marcarComoLida: (id: number) => `/notificacao/lida/${id}`,
  marcarTodasComoLida: (id: number) => `/notificacao/todaslida/${id}`
}
@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(
    private apiService: ApiService,
    private toastr: ToastrService
  ) { }

  // id usuario
  buscarNotificacoes(id: number) {
    return this.apiService.get<Notificacoes>(routes.buscarNotificacoes(id));
  }

  // id usuario
  buscarQuantidade(id: number) {
    return this.apiService.get<any>(routes.buscarQuantidade(id),true);
  }

  // id da mensagem
  excluir(id: number) {
    return this.apiService.delete(routes.excluir(id));
  }

  // id usuario
  excluirTodos(id: number) {
    return this.apiService.delete(routes.excluirTodos(id));
  }

  // id da mensagem
  marcarComoLida(id: number) {
    return this.apiService.put(routes.marcarComoLida(id));
  }

  // id usuario
  marcarTodasComoLida(id: number) {
    return this.apiService.put(routes.marcarTodasComoLida(id));
  }


  // Toast
  showSuccess(message: any){
    this.toastr.success(message)
  }

  showError(message: any){
      this.toastr.error(message,undefined,{enableHtml:true})
  }

  showInfo(message: any){
      this.toastr.info(message)
  }

  showWarning(message: any){
      this.toastr.warning(message)
  }

  default = (title: string, message: any) => this.toastr.error(message, title, {
    enableHtml: true, timeOut: 3000
  });
}

enum TypeNotification {
  Success = 'success',
  Info = 'info',
  Warning = 'warning',
  Error = 'error'
}
