<div class="bg-missao-overlay">
  <div id="vantagens" class="container py-3 py-md-5 my-md-5">
  
    <div class="col-10 col-sm-12 mx-auto d-flex flex-column mt-5">
      <h3 class="fw-400 mt-3 fs">{{'Nossa' | translate}} <span class="fw-600">{{'missão' | translate}}</span> {{'é quebrar barreiras de' | translate}} <span class="d-none d-md-block"><br></span> {{'acesso__ampliando_e_democratizando_o_alcance_de' | translate}} <br> <span class="fw-600">{{'Recursos_em_investimento_social' | translate}}</span></h3>
      <h5 class="fw-400 mt-3 fs1"> {{'Conectamos' | translate}} <span class="fw-600">{{'investidores__executores' | translate}} </span>{{'e' | translate}}  <span
          class="fw-600">{{'colaboradores' | translate}}</span> {{'de projetos' | translate}} <br> {{'e negócios de impacto social de forma' | translate}} <span
          class="fw-600">{{'simples' | translate}}</span> {{'e' | translate}} <span class="fw-600">{{'acessível.' | translate}}</span></h5>
    </div>
    
    <div class="col-10 col-sm-12 mx-auto py-5 my-md-5">
      <div class="row g-5">
        <div class="col-6 col-md-3">
          <div class="col-12 d-flex justify-content-center align-items-center flex-column">
            <img class="icones-img" src="/assets/image/site-principal/investidor-brada.png" alt="">
          </div>
        </div>
  
        <div class="col-6 col-md-3">
          <div class="col-12 d-flex justify-content-center align-items-center flex-column">
            <img class="icones-img" src="/assets/image/site-principal/empreendedor-brada.png" alt="">
          </div>
        </div>      
    
        <div class="col-6 col-md-3">
          <div class="col-12 d-flex justify-content-center align-items-center flex-column">
            <img class="icones-img" src="/assets/image/site-principal/startup-brada.png" alt="">
          </div>
        </div>
        
        <div class="col-6 col-md-3">
          <div class="col-12 d-flex justify-content-center align-items-center flex-column">
            <img class="icones-img" src="/assets/image/site-principal/inovacao-brada.png" alt="">
          </div>
        </div>
      </div>
    </div>
  
  </div>
</div>