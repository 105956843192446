import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { BannerPrincipalComponent } from './components/banner-principal/banner-principal.component';
import { FacaParteSiteComponent } from './components/faca-parte-site/faca-parte-site.component';
import { OportunidadesSiteComponent } from './components/oportunidades-site/oportunidades-site.component';
import { MissaoSiteComponent } from './components/missao-site/missao-site.component';
import { QuemSomosSiteComponent } from './components/quem-somos-site/quem-somos-site.component';
import { SolucoesPersonalizadasComponent } from './components/solucoes-personalizadas/solucoes-personalizadas.component';
import { SiteComponent } from './pages/site/site.component';
import { PublicRoutingModule } from './public-routing.module';
import { LandingPageSharedModule } from '@modules/landingpages/landingpage-shared.module';
import { CadastroComponent } from './pages/cadastro/cadastro.component';
import { LoginComponent } from './pages/login/login.component';
import { ModalAcessoRestritoComponent } from './components/modal-acesso-restrito/modal-acesso-restrito.component';
import { FaqPublicaComponent } from './pages/faq-publica/faq-publica.component';
import { ModalAcessoProdutoComponent } from './components/modal-acesso-produto/modal-acesso-produto.component';
@NgModule({
  declarations: [
    SiteComponent,
    CadastroComponent,
    BannerPrincipalComponent,
    QuemSomosSiteComponent,
    MissaoSiteComponent,
    OportunidadesSiteComponent,
    FacaParteSiteComponent,
    SolucoesPersonalizadasComponent,
    LoginComponent,
    ModalAcessoRestritoComponent,
    FaqPublicaComponent,
    ModalAcessoProdutoComponent
  ],
  imports: [
    PublicRoutingModule,
    SharedModule,
    LandingPageSharedModule
  ],
  exports: [
    SiteComponent
  ]
})
export class PublicModule { }
