import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenticationService } from '@core/auth/authentication.service';
import { ApiService } from './api.service';

const routes = {
  seguidosEdital: `/edital/seguidos`,
  seguirEdital: (idEdital: number) => `/edital/seguir/${idEdital}`,
  deixarDeSeguirEdital: (idEdital: number) => `/edital/deixar-de-seguir/${idEdital}`,

  seguidosProjeto: `/projetos/seguidos`,
  seguirProjeto: (idProjeto: number) => `/projetos/seguir/${idProjeto}`,
  deixarDeSeguirProjeto: (idProjeto: number) => `/projetos/deixar-de-seguir/${idProjeto}`,

  seguidosEmpreendedor: `/empreendedor/seguidos`,
  seguirEmpreendedor: (idEmpreendedor: number) => `/empreendedor/seguir/${idEmpreendedor}`,
  deixarDeSeguirEmpreendedor: (idEmpreendedor: number) => `/empreendedor/deixar-de-seguir/${idEmpreendedor}`,
  
  seguidosPatrocinador: `/patrocinador/seguidos`,
  seguirPatrocinador: (idPatrocinador: number) => `/patrocinador/seguir/${idPatrocinador}`,
  deixarDeSeguirPatrocinador: (idPatrocinador: number) => `/patrocinador/deixar-de-seguir/${idPatrocinador}`
}

@Injectable({
  providedIn: 'root'
})
export class RelacaoDeSeguidosService {
  seguirInvestidor(id: any) {
    throw new Error('Method not implemented.');
  }
  deixarDeSeguirInvestidor(id: any) {
    throw new Error('Method not implemented.');
  }

constructor(
  private apiService: ApiService,
  private authenticationService: AuthenticationService,
) { }

  seguidosEdital(page:number,pageSize:number) {
    let params = new HttpParams();
    params = params.append('size',pageSize);
    params = params.append('page',page - 1);
    return this.apiService.get<any>(routes.seguidosEdital, undefined, params);
  }

  seguirEdital(idEdital: number) {
    return this.apiService.put<any>(routes.seguirEdital(idEdital));
  }

  deixarDeSeguirEdital(idEdital: number) {
    return this.apiService.delete<any>(routes.deixarDeSeguirEdital(idEdital));
  }

  //
  seguidosProjeto(page:number,pageSize:number) {
    let params = new HttpParams();
    params = params.append('size',pageSize);
    params = params.append('page',page - 1);
    return this.apiService.get<any>(routes.seguidosProjeto, undefined, params);
  }

  seguirProjeto(idProjeto: number) {
    return this.apiService.put<any>(routes.seguirProjeto(idProjeto));
  }

  deixarDeSeguirProjeto(idProjeto: number) {
    return this.apiService.delete<any>(routes.deixarDeSeguirProjeto(idProjeto));
  }

  //
  seguidosEmpreendedor(page:number,pageSize:number) {
    let params = new HttpParams();
    params = params.append('size',pageSize);
    params = params.append('page',page - 1);
    return this.apiService.get<any>(routes.seguidosEmpreendedor, undefined, params);
  }

  seguirEmpreendedor(idEmpreendedor: number) {
    return this.apiService.put<any>(routes.seguirEmpreendedor(idEmpreendedor));
  }

  deixarDeSeguirEmpreendedor(idEmpreendedor: number) {
    return this.apiService.delete<any>(routes.deixarDeSeguirEmpreendedor(idEmpreendedor));
  }

  //
  seguidosPatrocinador(page:number,pageSize:number) {
    let params = new HttpParams();
    params = params.append('size',pageSize);
    params = params.append('page',page - 1);
    return this.apiService.get<any>(routes.seguidosPatrocinador, undefined, params);
  }

  seguirPatrocinador(idPatrocinador: number) {
    return this.apiService.put<any>(routes.seguirPatrocinador(idPatrocinador));
  }

  deixarDeSeguirPatrocinador(idPatrocinador: number) {
    return this.apiService.delete<any>(routes.deixarDeSeguirPatrocinador(idPatrocinador));
  }

}
