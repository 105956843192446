
export enum PapelContaEnum {
  Cidadao = 'CIDADAO',
  Empreendedor = 'EMPREENDEDOR',
  Patrocinador = 'PATROCINADOR',
  Ambos = 'AMBOS',
  Default = 'DEAFULT'
}

export enum PapelContaIdEnum {
  Patrocinador = 1,
  Empreendedor = 2,
  Cidadao = 3,
  Administrador = 4,
  Default = 'DEAFULT'
}
