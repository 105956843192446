<div class="bg-overlay bg-body-color pb-5">
  <div class="container">


    <section class="titleEbreadcrumb mt-5">
      <div class="col-12  d-flex align-items-center">
        <button class="btn btn-secundary-color d-flex align-items-center me-4 px-4 py-1 rounded-pill h7"
          (click)="voltarDashboard()">
          <i class="bi bi-arrow-left-short fs-6 me-1"></i>
          {{'Voltar' | translate}}
        </button>
        <nav class="col pt-3" aria-label="breadcrumb">
          <ol class="breadcrumb h7 fw-500">
            <li class="breadcrumb-item txt-primary-color text-decoration-underline">
              <a class="nav-link m-0 p-0 txt-primary-color" (click)="voltarDashboard()">{{'Dashboard' | translate}}</a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              {{ 'Cadastro_de_Empresa' | translate }}
            </li>
          </ol>
        </nav>
      </div>
    </section>

    <section class="title py-3">
      <div class="col-sm-5 col-10 col-sm-5 px-5 mx-auto">
        <h1 class="h3 txt-support-color text-center title-line">
          {{ 'Cadastro_de_Empresa' | translate }}
        </h1>
      </div>
    </section>
  </div>

  <div class="container mt-3">
    <div class="row gx-5">

      <aside class="col-9 col-sm-3 ms-5 ms-sm-0 bg-white-color shadow-sm border-brada mt-4" [ngClass]="{'d-none d-sm-block': fill == false}">
        <div class="p-3">
          <h5 class="py-4 text-center txt-primary-color">{{'Filtros' | translate}}</h5>

          <form [formGroup]="formFiltroUsuarios" id="formFiltroChamadas">
            <div class="form-group my-3">
              <label class="mb-1">{{'Nome_da_Empresa' | translate}}</label>
              <input type="text" class="form-control tm-input" formControlName="nome" placeholder="Buscar Nome da Empresa"
                autocomplete="off" />
            </div>

            <div class="form-group my-4">
              <label class="mb-1">{{'CPF/CNPJ' | translate}}</label>
              <input type="text" class="form-control tm-input" formControlName="cpfCnpj" [textMask]="maskCPFCNPJ" placeholder="Buscar CPF ou CNPJ "
                autocomplete="off" />
            </div>

            <div class="form-group my-4">
              <label class="mb-1">{{'Tipo_Pessoa' | translate}}</label>
              <ng-select [notFoundText]="'Nenhum_item_encontrado' | translate"[multiple]="false" name="tipoPessoa" formControlName="tipoPessoa" placeholder="Selecione"
              [compareWith]="compareFn">
              <ng-option *ngFor="let item of tiposPessoa" [value]="item">{{ item.nome }}
              </ng-option>
            </ng-select>
            </div>

            <div class="form-group my-4">
              <label class="mb-1">{{'Papel_Pessoa' | translate}}</label>
              <ng-select [notFoundText]="'Nenhum_item_encontrado' | translate"[multiple]="false" name="papelPessoa" formControlName="papelPessoa" placeholder="Selecione"
              [compareWith]="compareFn">
              <ng-option *ngFor="let item of papeisPessoa" [value]="item">{{ item.nome }}
              </ng-option>
            </ng-select>
            </div>
            <div class="form-group my-4">
              <label class="mb-1">{{'Status' | translate}}</label>
              <ng-select [notFoundText]="'Nenhum_item_encontrado' | translate"[multiple]="false" formControlName="statusPessoa" placeholder="Selecione">
                <ng-option *ngFor="let item of tipoStatus" [value]="item">{{ item.nome }}
                </ng-option>
              </ng-select>
            </div>
            <div class="row gx-3 my-4">
              <div class="col-6">
                <button type="button" class="btn btnl-primary-color w-100" (click)="reset()">{{'Limpar' | translate}}</button>
              </div>
              <div class="col-6">
                <button type="button" (click)="filtrar()"  class="btn btn-primary-color w-100">{{'Filtrar' | translate}}</button>
              </div>
            </div>

          </form>
        </div>

      </aside>

      <section class="col col-sm-9 chamadas-filtradas mt-4">
        <div class="col-12 text-center px-5 pt-5" *ngIf="pessoas.length == 0">
          {{'Nenhuma_pessoa_encontrado' | translate}}
        </div>

        <div class="col-12 px-3 px-sm-5 pb-2 py-sm-4 tabela-filtros card-brada bg-white-color shadow border-5 ss" *ngIf="pessoas.length > 0">
          <div class="tabela-filtros table-responsive">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th scope="col">{{'Nome' | translate}}</th>
                  <th scope="col">{{'CNPJ/CPF' | translate}}</th>
                  <th scope="col">{{'Tipo_Pessoa' | translate}}</th>
                  <th scope="col">{{'Papel_Pessoa' | translate}}</th>
                  <th scope="col">{{'Status' | translate}}</th>
                  <th scope="col" class="text-center">{{'Ações' | translate}}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of pessoas" class="h6">
                  <td>{{ item?.nomePessoa }}</td>
                  <td>{{ item?.cpfCnpj}}</td>
                  <td>{{ item?.tipoPessoa?.nome }}</td>
                  <td>{{ item?.papelPessoa }}</td>
                  <td>{{ item?.statusPessoa?.nome }}</td>
                  <td class="col ">
                    <div class="col d-flex align-items-start bt-card" ngbDropdown container="body" display="dynamic"
                      placement="bottom-right">
                      <button type="button" class="btn btnl-primary px-2 h7 btn-card" ngbDropdownToggle>
                        <i class="bi bi-three-dots"></i>
                      </button>
                      <div class="dropdown-menu mt-1 shadow border-1" ngbDropdownMenu>
                        <div *ngIf="item.statusPessoa.nome == 'Ativo'">
                          <button ngbDropdownItem (click)="bloquearPessoa(item.id)">{{'Bloquear' | translate}}</button>
                        </div>
                        <div *ngIf="item.statusPessoa.nome == 'Bloqueado'">
                          <button ngbDropdownItem (click)="desbloquearPessoa(item.id)">{{'Desbloquear' | translate}}</button>
                        </div>
                        <div *ngIf="item.statusPessoa.nome != 'Inativo'">
                          <button ngbDropdownItem (click)="openModal(item.id, acaoFiltroEnum.Inativar)">{{'Inativar' | translate}}</button>
                        </div>
                        <div>
                          <button ngbDropdownItem [routerLink]="['/usuario']"
                            [queryParams]="{idPessoa: item.id}">{{'Visualizar_usuarios' | translate}}</button>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="d-sm-flex justify-content-sm-between p-2">
            <select class="custom-select px-2 h7 border-0 text-decoration-underline" style="width: auto"
              [(ngModel)]="pageSize" (ngModelChange)="trocarSize()">
              <option *ngFor="let item of filtroPaginas" [value]="item.valor">{{ item.nome }}</option>
            </select>

            <ngb-pagination [collectionSize]="collectionSize" [(page)]="page"
              [pageSize]="pageSize" (pageChange)="filtrar()" [maxSize]="4" [rotate]="true" [ellipses]="false"
              [boundaryLinks]="true" class="mt-2">
            </ngb-pagination>
          </div>
        </div>
      </section>

    </div>
  </div>
</div>
