<header class="container mt-1" id="topo">

  <nav class="navbar navbar-expand-lg navbar-light bg-body-color">
    <div class="container-fluid">
        <a class="navbar-brand" (click)="goToUrl()">
          <img class="hj" src="/assets/image/mask/marca-brada.png" alt="Logo do site Brada">
        </a>

        <button class="navbar-toggler " type="button" (click)="isMenuCollapsed = !isMenuCollapsed" [ngClass]="{'ms-auto': isMenuCollapsed}">
          <span class="navbar-toggler-icon"></span>
        </button>

        <div [ngbCollapse]="isMenuCollapsed" class="collapse navbar-collapse">
          <div class="navbar-nav mt-4 mt-sm-0 ms-sm-auto">
            <a class="nav-item nav-link pt-2 pt-sm-0 fw-500" (click)="scrollTo('#quemsomos')">{{'QUEM_SOMOS' | translate}}</a>
            <a class="nav-item nav-link pt-2 pt-sm-0 fw-500" (click)="scrollTo('#vantagens')">{{'VANTAGENS' | translate}}</a>
            <a class="nav-item nav-link pt-2 pt-sm-0 fw-500" (click)="scrollTo('#solucoes')">{{'SOLUÇÕES' | translate}}</a>
            <a class="nav-item nav-link pt-2 pt-sm-0 fw-500" (click)="scrollTo('#oportunidades')">{{'OPORTUNIDADES' | translate}}</a>
          </div>
          <div class="navbar-nav mt-2 mt-sm-0 me-sm-0">
            <ng-select [notFoundText]="'Nenhum_item_encontrado' | translate"(change)="selectLanguage($event)" class="custom" [searchable]="false" [clearable]="false" [items]="cities" [(ngModel)]="selectedCity">
              <ng-template ng-label-tmp let-item="item">
                <div class="d-flex align-items-center justify-content-start">
                  <img class="mx-2" height="18" width="29" [src]="item.avatar" />
                  <h5 class="Txt mt-2">{{item.lingua}}</h5>
                </div>
              </ng-template>
              <ng-template ng-option-tmp let-item="item">
                <div style="margin: 4px 0;" *ngIf="item.name !== 'Kaunas'">
                  <div class="d-flex align-items-center justify-content-start">
                    <img class="mx-2" height="15" width="24" [src]="item.avatar" />
                    <h5 class="Txt mt-2">{{item.lingua}}</h5>
                  </div>
                </div>
              </ng-template>
            </ng-select>
          </div>
          <button class="btn d-flex align-items-center justify-content-center" (click)="acessarFaq()">
            <i class="bi bi-chat-quote fs-5" placement="bottom" ngbTooltip="FAQ"></i>
          </button>
          <div class="navbar-nav my-3 mt-sm-2 ms-sm-auto">
            <button class="btn btn-primary-color px-5 py-3 border-brada" (click)="openModalLogin()">{{"Acessar_conta" | translate}}</button>
          </div>
        </div>
    </div>
  </nav>
</header>

