<div class="container">
  <div class="modal-header">
    <h4 class="modal-title"></h4>
    <button type="button" class="close btn-close" aria-label="Close" (click)="fechar()"></button>
  </div>
  <div class="modal-body">
    <h4 class="text-center mb-3 txt-support-color ">
      Produto Inativo
    </h4>

    <h6 class="col-10 fw-400 text-center text-muted h6 mx-auto pt-1 mb-5">
      Parece que você está tentando acessar uma página pertencente a um produto que está inativo!<br>
      Provavelmente o seu plano contratado está com a vigência expirada.<br>
      Para acessar novamente, é necessário a renovação de sua assinatura.<br>
    </h6>

    <div class="col-8 mx-auto mb-4 pb-2">
      <div class="col-12 mt-4 d-flex align-items-center justify-content-center">
        <button type="button" class="btn btn-primary-color py-3 px-5 border-brada" (click)="fechar()">
          OK
        </button>
      </div>
    </div>

    <!--
    <div class="col-8 mx-auto mb-4 pb-2">
      <div class="col-12 mt-4 d-flex align-items-center justify-content-center">
        <button type="button" class="btn btn-primary-color py-3 px-5 border-brada w-100" (click)="acessar('login')">
          {{'Login' | translate}}
        </button>
      </div>

      <div class="col-12 mt-4 d-flex align-items-center justify-content-center">
        <button type="button" class="btn btn-secundary-color py-3 px-5 border-brada w-100" (click)="acessar('adesao')">
          Criar uma conta
        </button>
      </div>
    </div>

    <h6 class="col-10 fw-400 text-center text-muted h6 mx-auto pt-1 mb-5 h7">
      Ao final do processo, você será redirecionado para a página solicitada, de acordo com o seu perfil de acesso
    </h6>
    -->

  </div>
</div>
