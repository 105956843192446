import { ModalLoginComponent } from '@modules/login/components/modal-login/modal-login.component';
import { Component, HostListener, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '@core/auth/authentication.service';
import { RouteAction } from '@core/action/action';
import { ODSService } from '@core/services/ods.service';
import { Subscription, interval } from 'rxjs';
import * as dayjs from 'dayjs';
import { DOCUMENT } from '@angular/common';
import { TipoFotoEnum } from '@core/models/enum/tipo-foto';
import { DomSanitizer } from '@angular/platform-browser';
import { ArquivoService } from '@core/services/arquivo.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PapelContaEnum } from '@core/models/enum/papel-conta';
import { ModalInscreverComponent } from '@modules/chamadas/pages/public/components/modal-inscrever/modal-inscrever.component';
import { ProjetosPublicoService } from '@core/services/projetos-publico';
import * as FileSaver from 'file-saver';
import * as customParseFormat from 'dayjs/plugin/customParseFormat';
import { NomeStatusChamadaEnum } from '@core/models/enum/status-chamadas.enum';
import { TranslateService } from '@ngx-translate/core';
import { DominioService } from '@core/services/dominio.service';
import { FotosUsuarioService } from '@core/services/fotos-usuario.service';

@Component({
  selector: 'app-landingpage-projetos',
  templateUrl: './landingpage-projetos.component.html',
  styleUrls: ['./landingpage-projetos.component.scss']
})
export class LandingpageProjetosComponent implements OnInit, OnDestroy {


  cities = [
    {
      id: 0,
      value: "pt-BR",
      avatar: 'https://static.todamateria.com.br/upload/ba/nd/bandeira-do-brasil-og.jpg',
      lingua: 'PT'
    },
    {
      id: 1,
      value: "en-US",
      avatar: 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/a4/Flag_of_the_United_States.svg/2560px-Flag_of_the_United_States.svg.png',
      lingua: 'EN'
    },
    {
      id: 2,
      value: "es-ES",
      avatar: 'https://upload.wikimedia.org/wikipedia/commons/thumb/9/9a/Flag_of_Spain.svg/2560px-Flag_of_Spain.svg.png',
      lingua: 'ES'
    }
  ];
  selectedCity = this.cities[0];
  linguas = 0
  namePage = "Landing Page Projetos";

  @Input() dadosLandingPage: any;

  subscription!: Subscription;
  dateNow = new Date();
  milliSecondsInASecond = 1000;
  hoursInADay = 24;
  minutesInAnHour = 60;
  SecondsInAMinute = 60;
  timeDifference!: number;
  secondsToDday!: number;
  minutesToDday!: number;
  hoursToDday!: number;
  daysToDday!: number;

  idAcao!: any;
  acao!: any;
  routeAcao!: RouteAction;
  papelAtivo: any;
  papelContaEnum = PapelContaEnum;
  public isMenuCollapsed = true;

  dataLandingPage: any;
  imagemPerfil: string | any;
  imagemPerfilConta!: string | any;
  imagemCapaBanner: string | any;
  dataLimiteInscricao: any;
  dataCorreta: any;
  exibirContador: boolean = false;

  areaInteresse: any;
  publico: any;
  ods: any;
  midia: any;
  fonteFinanciamento: any;
  fotos: any[] = [];

  windowScrolled!: boolean;
  activeSection = 1;

  imagemPerfilMenu: any;
  nomeStatusProjeto = NomeStatusChamadaEnum;

  constructor(
    private projetosPublicoService: ProjetosPublicoService,
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateService,
    @Inject(DOCUMENT) private document: any,
    private arquivoService: ArquivoService,
    private sanitizer: DomSanitizer,
    private router: Router,
    private modalService: NgbModal,
    private authenticationService: AuthenticationService,
    private odsService: ODSService,
    private dominioService: DominioService,
    private fotosUsuarioService: FotosUsuarioService,
  ) { }

  ngOnInit() {
    this.acao = this.activatedRoute.snapshot.params["acao"];
    this.idAcao = this.activatedRoute.snapshot.params["id"];

    this.userlogged();
    this.buscarDadosLandingPage();
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  goToUrl(url: any): void {
    window.open(`http://${url}`, '_blank');
  }

  buscarDadosLandingPage():void {
    let idEnvio = null;

    if(this.acao && this.idAcao){
      idEnvio = this.idAcao;
    }

    if(this.dadosLandingPage){
      this.dataLandingPage = [];
      idEnvio = this.dadosLandingPage;
    }

    if(idEnvio){
      this.dataLandingPage = [];
      this.projetosPublicoService.buscarPorId(idEnvio).subscribe(data => {

        if(data?.conta?.id){
          this.fotosUsuarioService.buscarFotoPerfil(data?.conta?.id).subscribe({
            next: (data) => {
              if (data.base64Content != null) {
                this.imagemPerfilConta = data;
                if (this.imagemPerfilConta?.base64Content && this.imagemPerfilConta?.extencao) {
                  this.imagemPerfilConta = this.sanitizer.bypassSecurityTrustUrl(`data:image/${this.imagemPerfilConta?.extencao};base64,${this.imagemPerfilConta?.base64Content}`);
                }
              }
            },
            error: (erro) => console.log(erro)
          })
        }

        this.ajustarDadosLandingPage(data);
      });
    }

    if(!idEnvio && !this.dadosLandingPage){
      window.open('/', '_blank');
    }
  }

  ajustarDadosLandingPage(dadosLandingPage: any): void {
// console.log('Dados LandingPage', dadosLandingPage)

    this.dataLandingPage = dadosLandingPage;
    this.dataLimiteInscricao = dadosLandingPage?.dataLimiteInscricao;

    if (this.dataLimiteInscricao)
      this.subscription = interval(1000).subscribe(x => {

        dayjs.extend(customParseFormat)
        this.dataCorreta = dayjs(this.dataLimiteInscricao, 'DD/MM/YYYY', "pt-br", true).format('MMM D, YYYY 23:59:59');

        if (this.dataCorreta == 'Invalid Date')
          this.dataCorreta = dayjs(this.dataLimiteInscricao, 'DD/MM/YYYY', "pt-br", false,).format('MMM D, YYYY 23:59:59');

        //this.dataCorreta = dayjs(this.dataCorreta, "pt-br")
// console.log(this.dataCorreta)

        this.contador(this.dataCorreta);
      });

    this.areaInteresse = this.dataLandingPage?.areaInteresse.filter((x: { select: boolean; }) => x.select == true);
    this.dominioService.buscarAreaInteresse().subscribe((data: any) => {
      this.verificarArquivosItem(this.dataLandingPage.areaInteresse, this.areaInteresse, data.filter((x: any) => x.ativo == true));
    });

    this.publico = this.dataLandingPage?.publico.filter((x: { select: boolean; }) => x.select == true);
    this.dominioService.buscarPublicoAlvo().subscribe((data: any) => {
      this.verificarArquivosItem(this.dataLandingPage.publico, this.publico, data.filter((x: any) => x.ativo == true));
    });

    this.ods = this.dataLandingPage?.ods.filter((x: { select: boolean; }) => x.select == true);
    this.dominioService.buscarOds().subscribe((data: any) => {
      this.verificarArquivosItem(this.dataLandingPage.ods, this.ods, data);
    });

    this.midia = this.dataLandingPage?.midia;
    this.fonteFinanciamento = this.dataLandingPage?.fonteFinanciamento;
    this.fotos = this.dataLandingPage?.fotos;
    this.odsService.popularImagens(this.ods);

    this.ordenarFn(this.ods);
    this.ordenarFn(this.publico);
    this.ordenarFn(this.areaInteresse);
    this.sortOutrosFn(this.areaInteresse);
    this.sortOutrosFn(this.publico);
    this.fotosChamada();
  }

  verificarArquivosItem(data: any, dataAtual: any, item: any) {
    if (!data.base64Content)
      dataAtual.forEach((x: any) => {
        let itemAtual = <any>item.find((y: any) => y.id == x.id);
        x.arquivo = itemAtual.arquivo;
        x.base64Content = itemAtual.base64Content;
        x.base64info = itemAtual.base64info;
        x.extencao = itemAtual.extencao;
      });

    if (data.areaInteresse)
      return data.areaInteresse = dataAtual;
    if (data.ods)
      return data.ods = dataAtual;
    if (data.publico)
      return data.publico = dataAtual;
  }

  contador(data: any) {
    let dtHoje = dayjs().format('MMM D, YYYY HH:mm:ss');

    let dataFinal = new Date(data);
    let dataHoje = new Date(dtHoje);
    let distancia = dataFinal.getTime() - dataHoje.getTime();

    if (distancia > 0)
      this.contadorCalculadora(distancia);
  }

  contadorCalculadora(distancia: number) {
    this.exibirContador = true;
    this.secondsToDday = Math.floor((distancia) / (this.milliSecondsInASecond) % this.SecondsInAMinute);
    this.minutesToDday = Math.floor((distancia) / (this.milliSecondsInASecond * this.minutesInAnHour) % this.SecondsInAMinute);
    this.hoursToDday = Math.floor((distancia) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute) % this.hoursInADay);
    this.daysToDday = Math.floor((distancia) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute * this.hoursInADay));
  }

  fotosChamada() {
    let fotoBanner = this.fotos.find(x => x.tpFoto?.nomeTipoFoto == TipoFotoEnum.Capa && x.base64Content);
    if (fotoBanner) {
      this.imagemCapaBanner = fotoBanner;

      if (this.imagemCapaBanner?.base64Content && this.imagemCapaBanner?.extencao) {
        this.imagemCapaBanner = this.sanitizer.bypassSecurityTrustUrl(`data:image/${this.imagemCapaBanner?.extencao};base64,${this.imagemCapaBanner?.base64Content}`);
      }
    }

    let fotoPerfil = this.fotos.find(x => x.tpFoto?.nomeTipoFoto == TipoFotoEnum.Perfil && x.base64Content);
    if (fotoPerfil) {
      this.imagemPerfilMenu = fotoPerfil;

      if (this.imagemPerfilMenu?.base64Content && this.imagemPerfilMenu?.extencao) {
        this.imagemPerfilMenu = this.sanitizer.bypassSecurityTrustUrl(`data:image/${this.imagemPerfilMenu?.extencao};base64,${this.imagemPerfilMenu?.base64Content}`);
      }
    }
  }

  imagensDominios(item: any) {
    if (item?.base64Content && item?.extencao)
      return this.sanitizer.bypassSecurityTrustUrl(`data:image/${item?.extencao};base64,${item?.base64Content}`);
    else
      return undefined;
  }

  downloadEnviado(idProjeto: number, documento: any) {
    let extensao = documento.extencao;
    this.arquivoService.downloadProjetoPublica(idProjeto, documento?.id).subscribe((data) => {
      FileSaver.saveAs(<any>data.body, `arquivo_${documento?.id}.${extensao}`);
    })
  }

  inscrever() {
    if (this.papelAtivo != this.papelContaEnum.Empreendedor) {
      this.openModal();
    } else if (this.papelAtivo == this.papelContaEnum.Empreendedor) {
      this.router.navigate([`/chamadas/inscrever-se/editar/${this.idAcao}`]);
    }
  }

  openModal() {
    let modalInscricao = this.modalService.open(ModalInscreverComponent, {
      centered: true,
      beforeDismiss: () => false,
      size: 'md'
    });
    modalInscricao.componentInstance.idChamada = this.idAcao;
    modalInscricao.componentInstance.papelAtivo = this.papelAtivo;
  }

  modalLogin() {
    let modalLogin = this.modalService.open(ModalLoginComponent, {
      centered: true,
      beforeDismiss: () => false,
      size: 'md'
    });
  }

  @HostListener("window:scroll", [])
  onWindowScroll() {
    if (window.pageYOffset > 300) {
      this.windowScrolled = true;
    } else {
      this.windowScrolled = false;
    }
  }

  ordenarFn(item: any) {
    let semOrdenacao = item[0].ordenacao;
    if (semOrdenacao == null) {
      if (item == this.ods) {
        this.odsService.popularOrdenacao(item);
        this.sortOrdenacaoFn(item)
      }
      else if (item == this.areaInteresse) {
        this.sortNameFn(item)
      }
      else if (item == this.publico) {
        this.sortNameFn(item)
      }
    }
    else if (item == this.ods)
      this.sortOrdenacaoFn(item)
    else
      this.sortNameFn(item)
  }

  sortOrdenacaoFn(item: any) {
    item.sort((x: any, y: any) => {
      let a = x.ordenacao,
        b = y.ordenacao;
      return a == b ? 0 : a > b ? 1 : -1;
    });
  }

  sortNameFn(item: any) {
    if (item == this.areaInteresse) {
      item.sort((x: any, y: any) => {
        let a = x.nomeAreaInteresse.toUpperCase(),
          b = y.nomeAreaInteresse.toUpperCase();
        return a == b ? 0 : a > b ? 1 : -1;
      });
    }
    else {
      if(item.find((x:any) => x.nome)){
        item.sort((x: any, y: any) => {
          let a = x.nome.toUpperCase(),
            b = y.nome.toUpperCase();
          return a == b ? 0 : a > b ? 1 : -1;
        });
      }
    }
  }

  sortOutrosFn(item: any) {
    if (item == this.areaInteresse) {
      let nomeOutras = item.map((x: any) => x.nomeAreaInteresse.toUpperCase() == 'OUTRAS');
      if (nomeOutras.length) {
        const itemFiltrado = item.filter((x: any) => x.nomeAreaInteresse.toUpperCase() == 'OUTRAS');
        this.areaInteresse = item.filter((x: any) => x.nomeAreaInteresse.toUpperCase() != "OUTRAS");

        if (itemFiltrado[0])
          this.areaInteresse.push(itemFiltrado[0]);
      }
    }
    else if (item == this.publico) {
      let nomeOutros = item.map((x: any) => x.nome.toUpperCase() == 'OUTROS');
      if (nomeOutros.length) {
        const itemFiltrado = item.filter((x: any) => x.nome.toUpperCase() == 'OUTROS');
        this.publico = item.filter((x: any) => x.nome.toUpperCase() != "OUTROS");

        if (itemFiltrado[0])
          this.publico.push(itemFiltrado[0]);
      }
    }
  }

  validarDetalhes(){
    return 'dataLandingPage?.areaInteresse?.length' || 'dataLandingPage?.publico?.length' || 'dataLandingPage?.ods?.length' || 'dataLandingPage?.tipoPessoas?.length' || 'dataLandingPage?.fonteFinanciamento?.length' || 'dataLandingPage?.localAtuacao?.estado?.length' || 'dataLandingPage?.localAtuacao?.municipio?.length';
  }

  public selectLanguage(event: any) {
    this.translateService.use(event.value)
    this.linguas = (event.id)
// console.log(this.linguas)
  }

  userlogged(): void {
    this.papelAtivo = this.authenticationService.currentUserValue?.papelAtivo;
  }
}
