import { MessageDialogComponent } from './components/message-dialog/message-dialog.component';
import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import localePt from '@angular/common/locales/pt';
import { CoreModule } from '@core/core.module';
import { TextMaskModule } from 'angular2-text-mask';
import { NgbBoostrapModule } from '@shared/ngb-boostrap.module';
import { LoaderComponent } from '@shared/components/loader/loader.component';
import { LoaderInterceptorService } from '@shared/components/loader/loader-interceptor.service';
import { NgbAccordionModule, NgbCollapseModule, NgbDateAdapter, NgbDateNativeAdapter, NgbDateParserFormatter, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxPermissionsModule } from 'ngx-permissions';
import { HeaderModule } from './components/header/header.module';
import { NgApexchartsModule } from 'ng-apexcharts';
import { NgxCaptchaModule } from 'ngx-captcha';
import { currencyMaskProviders } from '@core/config';
import { FooterComponent } from './components/footer/footer.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { registerLocaleData } from '@angular/common';
import { DatepickerComponent } from './components/datepicker/datepicker.component';
import { CustomNgbDateParserFormatter } from '@core/providers/custom-ngb-date-parser-formater';
import { CustomNgbDateAdapter } from '@core/providers/custom-ngb-date-adapter';
import { DynamicFormModule } from './components/dynamic-form/dynamic-form.module';
import { PoliticaPrivacidadeComponent } from './components/politica-privacidade/politica-privacidade.component';
import { KanbanComponent } from './components/kanban/kanban.component';
registerLocaleData(localePt, 'pt-BR');
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { NgBrazil } from 'ng-brazil';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { TraducaoStatusProjetoPipe } from './pipes/traducao.pipe';

@NgModule({
  declarations: [
    LoaderComponent,
    FileUploadComponent,
    DatepickerComponent,
    PoliticaPrivacidadeComponent,
    KanbanComponent,
    MessageDialogComponent,
    TraducaoStatusProjetoPipe
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CoreModule,
    DynamicFormModule,
    TextMaskModule,
    NgBrazil,
    CurrencyMaskModule,
    NgbBoostrapModule,
    NgxPermissionsModule.forChild(),
    HeaderModule,
    NgApexchartsModule,
    NgxCaptchaModule,
    NgbNavModule,
    NgbAccordionModule,
    NgbCollapseModule,
    NgSelectModule,
    DragDropModule,
    AngularEditorModule
  ],
  exports: [
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    CoreModule,
    DynamicFormModule,
    TextMaskModule,
    NgBrazil,
    CurrencyMaskModule,
    NgbBoostrapModule,
    LoaderComponent,
    HeaderModule,
    NgApexchartsModule,
    NgxCaptchaModule,
    NgbNavModule,
    FooterComponent,
    FileUploadComponent,
    NgbAccordionModule,
    NgbCollapseModule,
    NgSelectModule,
    DatepickerComponent,
    MessageDialogComponent,
    DragDropModule,
    AngularEditorModule,
    TraducaoStatusProjetoPipe
  ],
  providers: [
    currencyMaskProviders,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptorService, multi: true },
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    { provide: NgbDateParserFormatter, useClass: CustomNgbDateParserFormatter },
    { provide: NgbDateAdapter, useClass: CustomNgbDateAdapter }
  ]
})
export class SharedModule { }
