<div class="d-block">
  <div class="input-group">
    <input type="text" [formControl]="data" class="form-control tm-select" [attr.name]="name?name:null" [textMask]="maskData" [(ngModel)]="value" (ngModelChange)="onValueChange($event)" #input placeholder="DD/MM/YYYY">
    <div class="input-group-append">
      <button class="btn btn-outline-secondary calendar tm-select" (click)="datepicker.toggle()" type="button">
        <i class="bi bi-calendar-date"></i>
      </button>
    </div>
    <input class="form-control" ngbDatepicker #datepicker="ngbDatepicker" [displayMonths]="1" [dayTemplate]="t" outsideDays="hidden" hidden [(ngModel)]="value"
      [ngModelOptions]="{standalone: true}" (dateSelect)="onDateSelection($event)" [positionTarget]="input">
    <ng-template #t let-date let-focused="focused">
      <span class="custom-day">
        {{ date.day }}
      </span>
    </ng-template>
  </div>
</div>

