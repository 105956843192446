import { Injectable } from '@angular/core';
import { LocalStorageService } from '@core/local-storage/local-storage.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HeaderSharedDataService {

  private resetTimer = new Subject<boolean>();
  resetTimer$ = this.resetTimer.asObservable();

  // Perfil Usuario Login
  private papelSelecionado = new Subject<boolean>();
  papelSelecionado$ = this.papelSelecionado.asObservable();

  // Dados Usuario Logado
  private dadosUsuarioLogado = new Subject<boolean>();
  dadosUsuarioLogado$ = this.dadosUsuarioLogado.asObservable();

  // Notificacoes
  private notificacoes = new Subject<boolean>();
  notificacoes$ = this.notificacoes.asObservable();

    // Traduções
    private traducao: BehaviorSubject<any> = new BehaviorSubject<any>(null);


  constructor(
    private localStorageService: LocalStorageService
  ) { }

  public setResetTimer(data: boolean): void {
    this.resetTimer.next(data);
  }

  // Perfil Usuario Login
  public setPapelSelecionado(data: any) {
    this.salvarPapelSelecionado(data);
    this.papelSelecionado.next(data);
  }

  public salvarPapelSelecionado(data: any) {
    this.localStorageService.setItem('papelSelecionado', data);
  }

  public buscarPapelSelecionado(): void {
    return this.localStorageService.getItem('papelSelecionado');
  }

  // Dados Usuario Logado
  public setDadosUsuarioLogado(data: any) {
    this.salvarDadosUsuarioLogado(data);
    this.dadosUsuarioLogado.next(data);
  }

  public salvarDadosUsuarioLogado(data: any) {
    this.localStorageService.setItem('dadosUsuarioLogado', data);
  }

  public buscarDadosUsuarioLogado(): void {
    return this.localStorageService.getItem('dadosUsuarioLogado');
  }

  // Dados Usuario Logado
  public setNotificacoes(data: any) {
    this.salvarNotificacoes(data);
    this.notificacoes.next(data);
  }

  public salvarNotificacoes(data: any) {
    this.localStorageService.setItem('notificacoes', data);
  }

  public buscarNotificacoes(): void {
    return this.localStorageService.getItem('notificacoes');
  }


  setTraducao(data: any): void {
    this.traducao.next(data);
  }

  getTraducao(): Observable<any> {
    return this.traducao.asObservable();
  }


}
