import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ControlStatusDirective } from './directives/control-status/control-status.directive';
import { ControlStatusComponent } from './components/control-status/control-status.component';
import { ControlStatusContainerDirective } from './directives/control-status/control-status-container.directive';
import { FormSubmitDirective } from './directives/form-submit/form-submit.directive';


@NgModule({
  declarations: [
    FormSubmitDirective,
    ControlStatusDirective,
    ControlStatusComponent,
    ControlStatusContainerDirective,
    ControlStatusComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule 
  ],
  exports: [
    FormSubmitDirective,
    ControlStatusDirective,
    ControlStatusComponent,
    ControlStatusContainerDirective,
    ControlStatusComponent,
  ],
  entryComponents: [
    ControlStatusComponent,
  ]
})
export class DynamicFormModule { }
