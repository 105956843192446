import { Injectable } from '@angular/core';

const imagesInteresse = [
  {
    id: 1,
    imagem: '/assets/image/ods/1.png',
    ordenacao: 1
  },
  {
    id: 2,
    imagem: '/assets/image/ods/3.png',
    ordenacao: 3
  },
  {
    id: 3,
    imagem: '/assets/image/ods/5.png',
    ordenacao: 5
  },
  {
    id: 4,
    imagem: '/assets/image/ods/7.png',
    ordenacao: 7
  },
  {
    id: 5,
    imagem: '/assets/image/ods/9.png',
    ordenacao: 9
  },
  {
    id: 6,
    imagem: '/assets/image/ods/11.png',
    ordenacao: 11
  },
  {
    id: 7,
    imagem: '/assets/image/ods/13.png',
    ordenacao: 13
  },
  {
    id: 8,
    imagem: '/assets/image/ods/15.png',
    ordenacao: 15
  },
  {
    id: 9,
    imagem: '/assets/image/ods/17.png',
    ordenacao: 17
  },
  {
    id: 10,
    imagem: '/assets/image/ods/2.png',
    ordenacao: 2
  },
  {
    id: 11,
    imagem: '/assets/image/ods/4.png',
    ordenacao: 4
  },
  {
    id: 12,
    imagem: '/assets/image/ods/6.png',
    ordenacao: 6
  },
  {
    id: 13,
    imagem: '/assets/image/ods/8.png',
    ordenacao: 8
  },
  {
    id: 14,
    imagem: '/assets/image/ods/10.png',
    ordenacao: 10
  },
  {
    id: 15,
    imagem: '/assets/image/ods/12.png',
    ordenacao: 12
  },
  {
    id: 16,
    imagem: '/assets/image/ods/14.png',
    ordenacao: 14
  },
  {
    id: 17,
    imagem: '/assets/image/ods/16.png',
    ordenacao: 16
  }
]
@Injectable({
  providedIn: 'root'
})
export class ODSService {

  constructor() { }

  popularImagens(areasInteresse: any[]): void {
    areasInteresse.forEach(area => {
      area.imagem = imagesInteresse.find(x => x.id == area.id)?.imagem;
    })
  }

  popularOrdenacao(x: any[]): void {
    x.forEach(y => {
      y.ordenacao = imagesInteresse.find(z => z.id == y.id)?.ordenacao
    })
  }
}
