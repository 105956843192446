import { Injectable } from '@angular/core';
import { AuthenticationService } from '@core/auth/authentication.service';
import { ApiService } from '@core/services/api.service';

const routes = {
  buscarPapelPessoa: `/papel-pessoa`,
  buscarPapelPessoaPublica: `/publica/papel-pessoa` 
}

@Injectable({
  providedIn: 'root'
})
export class PapelPessoaService {

  constructor(
    private apiService: ApiService,
  ) { }

  buscarPapelPessoa() {
    return this.apiService.get<any>(routes.buscarPapelPessoa);
  }

  buscarPapelPessoaPublica() {
    return this.apiService.get<any>(routes.buscarPapelPessoaPublica);
  }
  
}
