<div class="bg-overlay bg-body-color h-100 landingPage" id="topo">
  <div class="position-fixed w-100">

    <nav class="navbar navbar-expand-lg navbar-light pt-0" id="topo">
      <div class="container-fluid py-3 shadow bg-white-color align-middle ">

        <div class="container">
          <div class="collapse navbar-collapse" id="navbarSupportedContent">

            <ul class="navbar-nav ms-3 me-auto mb-2 mb-lg-0">
              <li class="nav-item ps-3 fw-500" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                <a class="nav-link" pageScroll href="/#votar">{{'Votar_Agora' | translate}}</a>
              </li>
            </ul>

            <div class="d-flex align-items-end">
              <section id="data-e-status" class="col-12">
                <div class="col-12 transbox">
                  <div class="container d-flex align-items-center justify-content-end">
                    <div class="col text-center">

                      <div *ngIf="!exibirContador && contadorEncerrado">
                        <p class="h4 txt-primary-color">{{'Votação_Encerrada' | translate}}</p>
                      </div>

                      <div *ngIf="exibirContador" class="d-flex">
                        <div class="col-3 d-flex align-items-center">
                          <p class="h6 mt-1 txt-primary-color">{{'Contagem_Regressiva' | translate}}</p>
                        </div>

                        <div class="col">
                          <div class="col-11">
                            <div class="row gx-2">
                              <div class="col">
                                <div class="border bg-light border-2 rounded-3 py-2 px-3">
                                  <span class="h6">{{daysToDday}}</span>
                                  <p class="h7 m-0">{{'Dias' | translate}}</p>
                                </div>
                              </div>

                              <div class="col">
                                <div class="border bg-light border-2 rounded-3 py-2 px-3">
                                  <span class="h6">{{hoursToDday}} </span>
                                  <p class="h7 m-0">{{'Horas' | translate}}</p>
                                </div>
                              </div>

                              <div class="col">
                                <div class="border bg-light border-2 rounded-3 py-2 px-3">
                                  <span class="h6">{{minutesToDday}}</span>
                                  <p class="h7 m-0">{{'Minutos' | translate}}</p>
                                </div>
                              </div>

                              <div class="col">
                                <div class="border bg-light border-2 rounded-3 py-2 px-3">
                                  <span class="h6">{{secondsToDday}}</span>
                                  <p class="h7 m-0">{{'Segundos' | translate}}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </section>
            </div>

            <!-- <div class="d-flex align-items-end me-5" *ngIf="!papelAtivo">
              <button type="button" class="btn btnl-primary-color rounded-pill px-5" (click)="modalLogin()">Login</button>
            </div> -->
          </div>
        </div>
      </div>
    </nav>

    <a type="button" class="btn arraste-cima btn-primary-color border-brada position-fixed bottom-0 end-0 me-5 mb-5"
      href="/#topo" pageScroll *ngIf="windowScrolled">
      <i class="bi bi-arrow-up-square fs-4"></i>
    </a>
  </div>

  <div class="container-fluid">
    <div class="banner-principal bg-image d-flex align-items-center justify-content-center" *ngIf="!imagemCapaBanner"
      style="background-image: url('https://images.unsplash.com/photo-1511649475669-e288648b2339?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1632&q=80%27')">
      <div class="col-12 text-banner">
        <div class="col-sm-7 mx-auto">
          <div class="m-0">
            <span class="fw-300 text-muted">{{'Título_da_Chamada' | translate}}</span>
            <h1>{{edital?.titulo}}</h1>
          </div>
          <div class="mt-2">
            <h6 class="text-capitalize txt-secundary-color">
              <span class="fw-300 text-muted">{{'Patrocinador:' | translate}} </span>
              {{edital?.contaPatrocinador?.pessoa?.nomePessoa}}
            </h6>
          </div>
        </div>
      </div>
    </div>

    <div class="banner-principal bg-image d-flex align-items-center justify-content-center" *ngIf="imagemCapaBanner">
      <img [src]="imagemCapaBanner" alt="...">
      <div class="col-12 text-banner">
        <div class="col-sm-7 mx-auto">
          <div class="m-0">
            <span class="fw-300 text-muted">{{'Título_da_Chamada' | translate}}</span>
            <h1>{{edital?.titulo}}</h1>
          </div>
          <div class="mt-2">
            <h6 class="text-capitalize txt-secundary-color">
              <span class="fw-300 text-muted">{{'Patrocinador:' | translate}} </span>
              {{edital?.contaPatrocinador?.pessoa?.nomePessoa}}
            </h6>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid bg-white-color py-5">
    <div class="container py-5">

      <p class="h2 txt-primary-color text-center">{{'Votação_Popular' | translate}}</p>

      <div class="col-12">
        <div *ngIf="!exibirContador && contadorEncerrado">
          <p class="h2 txt-primary-color my-2">{{'Votação_Encerrada' | translate}}</p>
        </div>

        <div *ngIf="exibirContador">
          <div class="d-flex align-items-center justify-content-center my-2">
            <i class="bi bi-calendar3 fs-6 text-muted"></i>
            <p class="h5 mt-1 text-muted ms-3">{{dataFimVotacao | slice:0:10}} {{'-Término' | translate}}</p>
          </div>
        </div>
      </div>
    </div>
    <div id="votar"></div>
  </div>

  <div class="col-12 my-5 pb-5">
    <section class="container px-5 py-5">

      <div class="col-mx-auto pt-3 mt-5 mb-5" *ngIf="votoAceito">
        <h2 class="txt-secundary-color text-center">
          {{'Parabéns' | translate}} <br>
          <span class="h4 text-muted">{{'Voce_fez_sua_parte' | translate}}</span>
        </h2>
      </div>

      <div class="col-12" *ngIf="votoAceito">
        <div class="row g-5 row-cols-3">
          <div *ngFor="let item of listaFichas" class="col mb-4 mx-auto">
            <div class="card-brada col-11 mx-auto pt-2 pt-sm-3 px-3 px-sm-4 bg-white-color shadow border-5 h-100"
              *ngIf="item.emailsVotantes.indexOf(convidado.enderecoEmailConvidado) != -1">

              <div class="card position-relative bg-body-color text-white border-brada-4 mt-2 click"
                (click)="visualizarLandingPage(item.id)">
                <img class="card-img border-brada-4 w-100" [src]="fotoFicha(item.fotoPerfil)" alt="imagem chamada"
                  loading="lazy" />

                <div class="card-img-overlay border-brada-4">
                  <div
                    class="card-header col-12 position-absolute top-0 start-0 d-flex align-items-center border-0 border-brada-4">
                    <div class="col mt-2">
                      <span class="h7 bg-white-color-t2 py-2 px-3 rounded-3 fw-300">{{'Nº_Ficha' | translate}}
                        {{item?.idFicha}}</span>
                    </div>

                    <div class="col-2 mt-2">
                      <div class="p-1 bg-white-color-t2 rounded-3 d-flex align-items-center justify-content-center">
                        <i class="bi bi-bookmark-fill txt-yellow-color fs-6"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card-body px-2 px-sm">
                <div class="col-12 click" (click)="visualizarLandingPage(item.idProjeto)">
                  <h6 class="card-title mt-3 mb-1">{{item?.nomeProjeto}}</h6>
                  <div [innerHtml]="item.descricaoProjeto | slice:0:200" class="text-muted pb-2"></div>
                  <p class="h7 card-subtitle mb-2 text-muted d-flex fw-500">
                    {{'Empreendedor:' | translate}}
                    <span class="ms-1 fw-600 txt-secundary-color">
                      {{item?.nomePessoaEmpreendedor}}
                    </span>
                  </p>
                </div>

                <div class="col-10 mx-auto my-4 pt-2 border-top pt-3">
                  <div class="d-flex align-items-center justify-content-center">
                    <button type="button" class="btn w-100 py-3 h7"
                      [ngClass]="{'btn-secundary-color': votoAceito, 'btn-terciary-color': !votoAceito }"
                      (click)="votar(item)">
                      {{votoAceito ? 'Obrigado pelo Voto' : 'Votar neste Projeto' }}
                    </button>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

      <div class="col-12" *ngIf="exibirContador && !votoAceito">
        <div class="row g-5 row-cols-3">
          <div *ngFor="let item of listaFichas" class="col mb-4 mx-auto">
            <div class="card-brada col-11 mx-auto pt-2 pt-sm-3 px-3 px-sm-4 bg-white-color shadow border-5 h-100">

              <div class="card position-relative bg-body-color text-white border-brada-4 mt-2 click"
                (click)="visualizarLandingPage(item.id)">
                <img class="card-img border-brada-4 w-100" [src]="fotoFicha(item.fotoPerfil)" alt="imagem chamada"
                  loading="lazy" />

                <div class="card-img-overlay border-brada-4">
                  <div
                    class="card-header col-12 position-absolute top-0 start-0 d-flex align-items-center border-0 border-brada-4">
                    <div class="col mt-2">
                      <span class="h7 bg-white-color-t2 py-2 px-3 rounded-3 fw-300">{{'Nº_Ficha' | translate}}
                        {{item?.idFicha}}</span>
                    </div>

                    <div class="col-2 mt-2">
                      <div class="p-1 bg-white-color-t2 rounded-3 d-flex align-items-center justify-content-center">
                        <i class="bi bi-bookmark-fill txt-yellow-color fs-6"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card-body px-2 px-sm">
                <div class="col-12 click" (click)="visualizarLandingPage(item.idProjeto)">
                  <h6 class="card-title mt-3 mb-1">{{item?.nomeProjeto}}</h6>
                  <div [innerHtml]="item.descricaoProjeto | slice:0:200" class="text-muted pb-2"></div>
                  <p class="h7 card-subtitle mb-2 text-muted d-flex fw-500">
                    {{'Empreendedor:' | translate}}
                    <span class="ms-1 fw-600 txt-secundary-color">
                      {{item?.nomePessoaEmpreendedor}}
                    </span>
                  </p>
                </div>

                <div class="col-10 mx-auto my-4 pt-2 border-top pt-3">
                  <div class="d-flex align-items-center justify-content-center">
                    <button type="button" class="btn w-100 py-3 h7"
                      [ngClass]="{'btn-secundary-color': votoAceito, 'btn-terciary-color': !votoAceito }"
                      (click)="votar(item)">
                      {{votoAceito ? 'Obrigado pelo Voto' : 'Votar neste Projeto' }}
                    </button>
                  </div>
                </div>
              </div>

            </div>
          </div>

        </div>
      </div>

    </section>
  </div>

</div>
