import { Inject, Injectable } from '@angular/core';
import { HttpParams, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, delay, finalize } from 'rxjs/operators';
import { UserAuth, UserTokenData } from './user';
import { environment } from '@env/environment';
import { LocalStorageService } from '@core/local-storage/local-storage.service';
import { Router } from '@angular/router';
import { ApiService } from '@core/services/api.service';
import { LoaderService } from '@shared/components/loader/loader.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthRequest, PasswordRequest } from '@core/models/auth-request';
//import { UsuarioCadastro, UsuarioTrocaSenha } from '@core/models/usuario';
import { concatMap, tap } from 'rxjs/operators';
import { UsuarioMeusDados } from '@core/models/usuario';
import * as dayjs from 'dayjs';
import { DOCUMENT } from '@angular/common';
//import { AES } from 'crypto-js';

const routes = {
    login: `tokens`,
    getUserData: `prefs`,
    revokeToken: `revoke`,
    //recuperarSenha: (usuario: string) => `${usuario}/recuperarSenha`,
    recuperarSenha: (username: string) => `${username}/recuperarSenha`,
    recuperarUsername: (email: string) => `${email}/recuperarUsername`,
    changePassword: `usuario/password`
}

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<any>;
    public currentUser: Observable<UserAuth>;
    private currentDataExpiracaoSubject: BehaviorSubject<Date>;
    public currentDataExpiracao: Observable<Date>;
    private currentTempoSessaoSubject: BehaviorSubject<number>;
    public currentTempoSessao: Observable<number>;
    private loggedIn = new BehaviorSubject<boolean>(false);

    constructor(private apiService: ApiService,
        private localStorageService: LocalStorageService,
        private router: Router,
        private loaderService: LoaderService,
        @Inject(DOCUMENT) private document: any) {
        this.currentUserSubject = new BehaviorSubject<UserTokenData>(JSON.parse(localStorageService.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
        this.currentDataExpiracaoSubject = new BehaviorSubject<Date>(JSON.parse(localStorageService.getItem('expiration_token')));
        this.currentDataExpiracao = this.currentDataExpiracaoSubject.asObservable();
        this.currentTempoSessaoSubject = new BehaviorSubject<number>(localStorageService.getItem('session_time'));
        this.currentTempoSessao = this.currentTempoSessaoSubject.asObservable();
        this.loggedIn = new BehaviorSubject<boolean>(localStorageService.getItem('token') != undefined);
    }

    public get isLoggedIn() {
        return this.loggedIn.asObservable().pipe(delay(0));
    }

    public get currentUserValue(): UserAuth {
        return <UserAuth>this.currentUserSubject.value;
    }

    public get dataExpiracaoAcesso(): Date {
        return this.currentDataExpiracaoSubject.value;
    }

    public get tempoSessao(): number {
        return this.currentTempoSessaoSubject.value;
    }

    login(user: AuthRequest) {
        return this.apiService.postCustomApi<any>(`${environment.auth}/${routes.login}`, user)

            .pipe(tap(res => {
                return res;
            }),
                concatMap((res) => {
                    if (res.token)
                        return this.getUserData(res.token).pipe(map(data => {
                            this.salvarToken(res);
                            return { ...data, ...res };
                        }));

                    return of(res);
                }),
                map((data: UserAuth) => {
                    return data;
                }));
    }

    salvarToken(res: any) {
        this.localStorageService.setItem('token', JSON.stringify(res.token));
        let expire = dayjs().add(res.tempoDeExpiracao, 'minute').toDate();
        this.setExpirationDate(expire);
        this.setTempoSessao(res.tempoDeExpiracao);
        this.currentDataExpiracaoSubject.next(expire);
    }

    recuperarSenha(username: string) {
        return this.apiService.getCustomApi<any>(`${environment.auth}/${routes.recuperarSenha(username)}`);
    }

    recuperarUsername(email: string) {
        return this.apiService.getCustomApi<any>(`${environment.auth}/${routes.recuperarUsername(email)}`);
    }

    logout(setReturnUrl?: boolean, message?: string, acaoUsuario?: boolean) {
        if (!this.router.url.startsWith('/login')) {
            {
                this.localStorageService.removeItem('currentUser');
                this.localStorageService.removeItem('token');
                this.localStorageService.removeItem('expiration_token');
                this.localStorageService.removeItem('session_time');
                this.localStorageService.removeItem('papelSelecionado');
                this.localStorageService.removeItem('dadosUsuarioLogado');
                this.localStorageService.removeItem('notificacoes');
                this.localStorageService.removeItem('projetoSelecionado');
                this.localStorageService.removeItem('primeiroAcessoRealizado');
                this.localStorageService.removeItem('idChamadaCadastrarPerguntas');
                this.localStorageService.removeItem('jaFoiEmBasePerguntas');
                this.currentUserSubject.next(null);
                this.currentDataExpiracaoSubject.next(null!);

                // this.loggedIn.next(false);
                if (!environment.production) this.loggedIn.next(false);

                this.loaderService.hide();
            }
        }

        window.location.href = `http://${environment.urlSiteAtual}`;

        // if (setReturnUrl) {
        //     window.location.href = `https://${environment.urlSiteAtual}`
        //     //this.router.navigate(['/'], { queryParams: { returnUrl: this.router.url, msg: message } });
        // }
        // else {
        //     window.location.href = `https://${environment.urlSiteAtual}`
        //     //this.router.navigate(['/'], { queryParams: { msg: message } });
        // }

    }

    // refreshToken() {
    //     let params = new HttpParams();
    //     params = params.append('ignoreLoading', 'true');
    //     params = params.append('ignoreError', 'true');
    //     return this.apiService.getCustomApi<any>(`${environment.refreshToken}`, params);
    // }

    getUserData(token?: string) {
        if (token) {
            let header = new HttpHeaders().append('Authorization', `${token}`);
            return this.apiService.getCustomApi<any>(`${environment.auth}/${routes.getUserData}`, undefined, header);
        }

        return this.apiService.getCustomApi<any>(`${environment.auth}/${routes.getUserData}`);
    }

    revokeToken() {
        let params = new HttpParams().append('ignoreError', 'true');
        return this.apiService.getCustomApi<any>(`${environment.auth}/${routes.revokeToken}`, params);
    }

    alterarSenha(obj: PasswordRequest, token: string) {
        let header = new HttpHeaders();
        header = header.append('Authorization', token);
        return this.apiService.putCustomApi<any>(`${environment.auth}/${routes.changePassword}`, obj, header);
    }

    setCurrentUser(dadosUsuario: any) {
        this.localStorageService.setItem('currentUser', JSON.stringify(dadosUsuario));
        console.log('setCurrentUser', dadosUsuario);
        this.currentUserSubject.next(dadosUsuario);
        this.loggedIn.next(true);
    }

    loginWithToken(token: string) {
        this.localStorageService.setItem('token', JSON.stringify(token));
        this.loggedIn.next(true);
    }

    setExpirationDate(expire: Date) {
        this.localStorageService.setItem('expiration_token', JSON.stringify(expire));
        this.currentDataExpiracaoSubject.next(expire);
    }

    setTempoSessao(tempo: number) {
        this.localStorageService.setItem('session_time', tempo);
        this.currentTempoSessaoSubject.next(tempo);
    }

}
