import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AcaoFiltrosEnum } from '@core/models/enum/acao-filtro';
import { ModalAtualEnum } from '@core/models/enum/tipo-chamadas.enum';
import { ChamadaService } from '@core/services/chamada.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { data } from 'jquery';
import { ModalMotivoComponent } from '../modalMotivo/modalMotivo.component';
import { ModalSelectComponent } from '../modalSelect/modalSelect';

@Component({
  selector: 'app-modal-ajuda-navegacao',
  templateUrl: './modal-ajuda-navegacao.component.html',
  styleUrls: ['./modal-ajuda-navegacao.component.scss']
})
export class ModalAjudaNavegacaoComponent implements OnInit {

  tituloPage = "Parabéns!";
  textoDescricao!: string;
  btnSim!: string;
  btnNao!: string;
  idContaAtiva: any;
  idChamada: any;
  tituloChamada: any;
  modalAtual!: ModalAtualEnum;
  modalAtualEnum = ModalAtualEnum;
  listaChamadas: any[]=[];
  tipoChamada: any;
  dadosChamadaSalva: any;

  constructor(
    private router: Router,
    public activeModal: NgbActiveModal,
    private chamadaService: ChamadaService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private activatedRoute: ActivatedRoute,
  ) { }

  formFiltroChamadas = this.formBuilder.group({})

  ngOnInit() {
    if(this.modalAtual == ModalAtualEnum.AguardarFaseCaptacao)
      localStorage.removeItem('tipoChamadaAtual');

      if(this.idChamada != undefined)
        this.chamadaService.buscarDadosChamada(this.idChamada).subscribe(data =>
          this.dadosChamadaSalva = data
        );
  }

  clickBtnSimFichaInscricao():void{
    if(this.dadosChamadaSalva.id){
      this.activeModal.close();
      this.router.navigate([`/chamadas/ficha-inscricao/inserir/edital/${this.dadosChamadaSalva.id}`]);
    }
  }

  clickBtnSimLandingPage():void{
    this.activeModal.close();

    let modalUrl = this.modalService.open(ModalMotivoComponent, {
      centered: true,
      beforeDismiss: () => false,
      size: 'md'
    });
    modalUrl.componentInstance.acao = AcaoFiltrosEnum.LandingPage;
    modalUrl.componentInstance.tituloDescricao = AcaoFiltrosEnum.LandingPage;
    modalUrl.componentInstance.modalAjuda = 'true';
    modalUrl.componentInstance.idChamada = this.dadosChamadaSalva.id;
  }

  clickBtnSimEnvioConvites():void{
    this.activeModal.close();

    let modalUrl = this.modalService.open(ModalSelectComponent, {
      centered: true,
      beforeDismiss: () => false,
      size: 'md'
    });
    modalUrl.componentInstance.acao = AcaoFiltrosEnum.ConvidarEmpreededor;
    modalUrl.componentInstance.tituloDescricao = AcaoFiltrosEnum.ConvidarEmpreededor;
    modalUrl.componentInstance.modalAjuda = 'true';
    modalUrl.componentInstance.idEdital = this.dadosChamadaSalva.id;
  }

  clickBtnSimCadastrarPerguntas():void{
    this.activeModal.close();
    this.router.navigate([`/banco-perguntas/inserir`]);
  }

  fecharModal(){
    this.activeModal.close();
  }

  fechar():void{
    this.router.navigate([`/dashboard`]);
    this.activeModal.close();
  }

}
