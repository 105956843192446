<div class="file-drop-area">
  <div class="col-12 text-center">
    <div class="col-12 m-0">
      <i class="bi bi-cloud-upload" [ngClass]="getCorIconPagina(corBtnPagina)"></i>
    </div>    
    <span class="file-msg">{{file ? file.name : boxLabel? boxLabel: 'arraste e cole o arquivo aqui, ou' }}</span>
      <!-- <button class="btn btn-icon" *ngIf="file && file.name" (click)="clear()" type="button">
        <fa-icon [icon]="faTrash"></fa-icon>
      </button> -->
    <input class="file-input" type="file" [accept]="extensoes">
  </div>

  <div class="col-12 text-center">
    <button class="btn btn-secondary" [ngClass]="getCorBtnPagina(corBtnPagina)" type="button">{{!buttonLabel?'Escolha o arquivo': buttonLabel}}</button>
  </div>
</div>