import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-solucoes-personalizadas',
  templateUrl: './solucoes-personalizadas.component.html',
  styleUrls: ['./solucoes-personalizadas.component.scss']
})
export class SolucoesPersonalizadasComponent implements OnInit {

  classe1 = true
  classe2 = false
  classe3 = false

  constructor(){ }

  ngOnInit() {
  }

  BTN1(){
    if(this.classe2 == true || this.classe3 == true){
      this.classe2 = false
      this.classe3 = false

    }
    if(this.classe2 == false || this.classe3 == false){
      this.classe1 = true
    }

  }

  BTN2(){
    if(this.classe1 == true || this.classe3 == true){
      this.classe1 = false
      this.classe3 = false

    }
    if(this.classe1 == false){
      this.classe2 = true
    }
// console.log(this.classe2)
  }

  BTN3(){
    if(this.classe1 == true || this.classe2 == true){
      this.classe1 = false
      this.classe2 = false

    }
    if(this.classe1 == false){
      this.classe3 = true
    }
// console.log(this.classe3)
  }

}
