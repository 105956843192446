import { DOCUMENT } from '@angular/common';
import { Component, HostListener, Inject, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '@core/auth/authentication.service';
import { NomeStatusChamadaEnum } from '@core/models/enum/status-chamadas.enum';
import { TipoFotoEnum } from '@core/models/enum/tipo-foto';
import { NotificationService } from '@core/services/notification.service';
import { VotacaoPopularService } from '@core/services/votacao-popular.service';
import { ModalLoginComponent } from '@modules/login/components/modal-login/modal-login.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as customParseFormat from 'dayjs/plugin/customParseFormat';
import { interval, Subscription } from 'rxjs';
import * as dayjs from 'dayjs';
import { ChamadaPublicoService } from '@core/services/chamada-publico.service';

@Component({
  selector: 'app-landingpage-votacao-popular',
  templateUrl: './landingpage-votacao-popular.component.html',
  styleUrls: ['./landingpage-votacao-popular.component.scss']
})
export class LandingpageVotacaoPopularComponent implements OnInit {

  idAcao!: any;
  protocolo!: any;
  idChamada!: any;
  papelAtivo: any;
  message!: string;
  windowScrolled!: boolean;
  activeSection = 1;
  convidado: any;
  votoAceito: boolean = false;
  edital: any;

  projeto: any;
  imagemPerfil: string | any;
  imagemCapaBanner: string | any;
  fotos: any[] = [];
  listaFichas: any[] = [];

  dataFimVotacao: any;
  dataCorreta: any;
  subscription!: Subscription;
  dateNow = new Date();
  milliSecondsInASecond = 1000;
  hoursInADay = 24;
  minutesInAnHour = 60;
  SecondsInAMinute = 60;

  timeDifference!: number;
  secondsToDday!: number;
  minutesToDday!: number;
  hoursToDday!: number;
  daysToDday!: number;

  exibirContador: boolean = false;
  contadorEncerrado: boolean = false;

  imagemPerfilMenu: any;
  nomeStatusProjeto = NomeStatusChamadaEnum;

  @Input() idVotacao!: number;
  @Input() dadosLandingPage: any;

  constructor(
    private authenticationService: AuthenticationService,
    @Inject(DOCUMENT) private document: any,
    private sanitizer: DomSanitizer,
    private modalService: NgbModal,
    private activatedRoute: ActivatedRoute,
    private votacaoPopularService: VotacaoPopularService,
    private notificationService: NotificationService,
    private chamadaPublicoService: ChamadaPublicoService
  ) { }

  ngOnInit() {
    this.userlogged();
    window.scrollTo({ top: 0, behavior: 'smooth' });

    this.idAcao = this.activatedRoute.snapshot.paramMap.get("idEtapaAvaliacao");
    this.protocolo = this.activatedRoute.snapshot.paramMap.get("idProtocolo");
    this.idChamada = this.activatedRoute.snapshot.paramMap.get("idChamada");
// console.log(this.idAcao, this.idChamada, this.protocolo);
    if (this.idAcao)
      this.buscarDadosLandingPageVotacao(this.idAcao);

    if (this.idChamada)
      this.buscarDadosChamada(this.idChamada)

    if (this.protocolo)
      this.buscarDadosConvidado(this.protocolo)

    if (this.dadosLandingPage)
      this.buscarDadosChamadaSubdomain()
    if (this.idVotacao)
      this.buscarDadosChamada(this.idVotacao)

  }

  buscarDadosConvidado(protocolo: number) {
    this.votacaoPopularService.buscarDadosConvidado(protocolo).subscribe((data: any) => {
// console.log(data);
      this.convidado = data;
      if (data?.votoComputado) {
        this.votoAceito = true;
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  buscarDadosChamada(idChamada: number) {
    this.chamadaPublicoService.buscarDadosChamada(idChamada).subscribe((data: any) => {
// console.log('edital: ', data);
      this.edital = data;
      this.fotosChamada();
    })
  }

  buscarDadosChamadaSubdomain() {
    this.listaFichas = this.dadosLandingPage.fichas;

    this.dataFimVotacao = this.dadosLandingPage.dataFimVotacao;

    if (this.dataFimVotacao)
      this.subscription = interval(1000).subscribe(x => {

        dayjs.extend(customParseFormat)
        this.dataCorreta = dayjs(this.dataFimVotacao, 'DD/MM/YYYY', "pt-br", true).format('MMM D, YYYY 23:59:59');

        if (this.dataCorreta == 'Invalid Date')
          this.dataCorreta = dayjs(this.dataFimVotacao, 'DD/MM/YYYY', "pt-br", false,).format('MMM D, YYYY 23:59:59');

        //console.log(this.dataCorreta)
        this.contador(this.dataCorreta);
      });
  }

  buscarDadosLandingPageVotacao(idAcao: number) {
    this.votacaoPopularService.landingPageVotacao(idAcao).subscribe((data: any) => {
// console.log('votação: ', data);
      this.listaFichas = data.fichas;

      this.dataFimVotacao = data.dataFimVotacao;

      if (this.dataFimVotacao)
        this.subscription = interval(1000).subscribe(x => {

          dayjs.extend(customParseFormat)
          this.dataCorreta = dayjs(this.dataFimVotacao, 'DD/MM/YYYY', "pt-br", true).format('MMM D, YYYY 23:59:59');

          if (this.dataCorreta == 'Invalid Date')
            this.dataCorreta = dayjs(this.dataFimVotacao, 'DD/MM/YYYY', "pt-br", false,).format('MMM D, YYYY 23:59:59');

          //console.log(this.dataCorreta)
          this.contador(this.dataCorreta);
        });
    });
  }

  contador(data: any) {
    //console.log(data)
    let dtHoje = dayjs().format('MMM D, YYYY HH:mm:ss');

    let dataFinal = new Date(data);
    let dataHoje = new Date(dtHoje);
    let distancia = dataFinal.getTime() - dataHoje.getTime();

    if (distancia > 0) {
      this.contadorCalculadora(distancia);
    } else {
      this.exibirContador = false;
      this.contadorEncerrado = true;
    }
  }



  contadorCalculadora(distancia: number) {
    this.exibirContador = true;
    this.secondsToDday = Math.floor((distancia) / (this.milliSecondsInASecond) % this.SecondsInAMinute);
    this.minutesToDday = Math.floor((distancia) / (this.milliSecondsInASecond * this.minutesInAnHour) % this.SecondsInAMinute);
    this.hoursToDday = Math.floor((distancia) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute) % this.hoursInADay);
    this.daysToDday = Math.floor((distancia) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute * this.hoursInADay));
  }

  votar(item: any) {
    let objEnvio = {
      "idEtapaAvaliacaoProjetoVotacao": this.idAcao,
      "idFichaInscricaoProjeto": item.idFicha,
      "numeroProtocoloConvidado": this.protocolo
    }

    if (!this.votoAceito) {
      this.votacaoPopularService.votarNalandingPage(objEnvio).subscribe((data: any) => {
        this.listaFichas = [item];
        this.votoAceito = true;
        this.message = "Voto realizado com Sucesso!";
        this.notificationService.showSuccess(this.message);
      });
    }
  }

  fotosChamada() {
    let fotoBanner = this.fotos.find(x => x.tpFoto?.nomeTipoFoto == TipoFotoEnum.Capa && x.base64Content);
    if (fotoBanner) {
      this.imagemCapaBanner = fotoBanner;

      if (this.imagemCapaBanner?.base64Content && this.imagemCapaBanner?.extencao) {
        this.imagemCapaBanner = this.sanitizer.bypassSecurityTrustUrl(`data:image/${this.imagemCapaBanner?.extencao};base64,${this.imagemCapaBanner?.base64Content}`);
      }
    }

    let fotoPerfil = this.fotos.find(x => x.tpFoto?.nomeTipoFoto == TipoFotoEnum.Perfil && x.base64Content);
    if (fotoPerfil) {
      this.imagemPerfilMenu = fotoPerfil;

      if (this.imagemPerfilMenu?.base64Content && this.imagemPerfilMenu?.extencao) {
        this.imagemPerfilMenu = this.sanitizer.bypassSecurityTrustUrl(`data:image/${this.imagemPerfilMenu?.extencao};base64,${this.imagemPerfilMenu?.base64Content}`);
      }
    }
    //this.imagemCapaBanner = this.imagemCapaBanner?.changingThisBreaksApplicationSecurity.find((x: { unsafe: any; }) => x.unsafe);
  }

  fotoFicha(item: any): any {
    if (item) {
      if (item.base64Content && item.base64info) {
        let img = item.base64info + item.base64Content;
        if (img) {
          return this.sanitizer.bypassSecurityTrustUrl(img);
        }
      }
    }
    return 'https://images.unsplash.com/photo-1485217988980-11786ced9454?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80';
  }

  modalLogin() {
    let modalLogin = this.modalService.open(ModalLoginComponent, {
      centered: true,
      beforeDismiss: () => false,
      size: 'md'
    });
  }

  visualizarLandingPage(idProjeto: any) {
    window.open(`${window.location.origin}/#/landingpage/projetos/visualizar/${idProjeto}`, '_blank')
  }

  @HostListener("window:scroll", [])
  onWindowScroll() {
    if (window.pageYOffset > 300) {
      this.windowScrolled = true;
    } else {
      this.windowScrolled = false;
    }
  }

  userlogged(): void {
    this.papelAtivo = this.authenticationService.currentUserValue?.papelAtivo;
  }

}
