import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenticationService } from '@core/auth/authentication.service';
import { environment } from '@env/environment';
import { of } from 'rxjs';
import { ApiService } from './api.service';

const routes = {
  listarProjetosPorEmpreendedor: `/projetos/listar/por-empreendedor`,
  buscarProjeto: (id: number) => `/projetos/${id}`,
  buscarFichaIncricaoProjeto: (idFichaProjeto: number) => `/fichainscricaoprojeto/${idFichaProjeto}`,
  listarFichaIncricaoProjeto: `/fichainscricaoprojeto/listar`,
  rascunhoFichaIncricaoProjeto: `/fichainscricaoprojeto/rascunho`,
  salvarFichaIncricaoProjeto: `/fichainscricaoprojeto/salvar`,  
  buscarPerguntas: (id: number) => `/fichainscricaoedital/consultar/${id}`,
  buscarPerguntasPorEdital: (idEdital: number) => `/fichainscricaoedital/consultar/por-edital/${idEdital}`,
  buscarProjetoMerge: (idProjeto: number, idEdital: number)  => `/projetos/${idProjeto}/comMergeDominioEdital/${idEdital}`,
  buscarFichaInscricaoPorEdital: (idEdital: number)  => `/fichainscricaoprojeto/por-edital/${idEdital}`,
}

@Injectable({
  providedIn: 'root'
})
export class InscreverSeService {

  constructor(
    private apiService: ApiService,
    private authenticationService: AuthenticationService,
  ) { }

  listarProjetosPorEmpreendedor() {
    return this.apiService.get<any>(routes.listarProjetosPorEmpreendedor);
  }

  buscarProjeto(id: number) {
    return this.apiService.get<any>(routes.buscarProjeto(id));
  }

  buscarProjetoMerge(idProjeto: number, idEdital: number) {
    return this.apiService.get<any>(routes.buscarProjetoMerge(idProjeto, idEdital));
  }

  listarFichaIncricaoProjeto(obj: any) {
    return this.apiService.post<any>(routes.listarFichaIncricaoProjeto, obj)
  }
  
  rascunhoFichaIncricaoProjeto(obj: any) {
    return this.apiService.post<any>(routes.rascunhoFichaIncricaoProjeto, obj);
  }

  salvarFichaIncricaoProjeto(obj: any) {
    return this.apiService.post<any>(routes.salvarFichaIncricaoProjeto, obj);
  }
  
  buscarPerguntas(id: number) {
    return this.apiService.get<any>(routes.buscarPerguntas(id));
  }

  buscarPerguntasPorEdital(idEdital: number) {
    return this.apiService.get<any>(routes.buscarPerguntasPorEdital(idEdital));
  }

  buscarFichaInscricaoPorEdital(idEdital: number) {
    return this.apiService.get<any>(routes.buscarFichaInscricaoPorEdital(idEdital));
  }

  buscarFichaIncricaoProjeto(idFichaProjeto: number) {
    return this.apiService.get<any>(routes.buscarFichaIncricaoProjeto(idFichaProjeto));
  }
  

}
