import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgbBoostrapModule } from '@shared/ngb-boostrap.module';
import { HeaderDashboardComponent } from './components/header-dashboard/header-dashboard.component';
import { HeaderLandingpageComponent } from './components/header-landingpage/header-landingpage.component';
import { HeaderSiteComponent } from './components/header-site/header-site.component';
import { NgxPermissionsModule } from 'ngx-permissions';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import {HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FooterComponent } from '../footer/footer.component';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';


export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '../assets/jsonTranslate/', '.json');
}

@NgModule({
  imports: [
    TranslateModule.forChild({
      defaultLanguage: 'pt-BR',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgbBoostrapModule,
    NgxPermissionsModule.forChild(),
    NgSelectModule,
    NgbCollapseModule,
  ],
  declarations: [	
    FooterComponent,
    HeaderSiteComponent,
    HeaderDashboardComponent,
    HeaderLandingpageComponent
   ],
  exports: [
    FooterComponent,
    HeaderSiteComponent,
    HeaderDashboardComponent,
    HeaderLandingpageComponent
  ]
})
export class HeaderModule { }
