import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

const baseRef = 'cep';
const routes = {
  buscarEndereco: (cep: string) => `/${baseRef}/${cep}`,
  buscarEnderecoPublico: (cep: string) => `/publica/${baseRef}/${cep}`
}

@Injectable({
  providedIn: 'root'
})
export class CepService {

  constructor(
    private apiService: ApiService,
  ) { }

  buscarEndereco(cep: string) {
    let params = new HttpParams();
    return this.apiService.get<any>(routes.buscarEndereco(cep), undefined, params);
  }

  buscarEnderecoPublico(cep: string) {
    let params = new HttpParams();
    return this.apiService.get<any>(routes.buscarEnderecoPublico(cep), undefined, params);
  }
  
}