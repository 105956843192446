import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AuthenticationService } from '@core/auth/authentication.service';
import { PapelContaEnum } from '@core/models/enum/papel-conta';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Brada';

  form: any;
  public isLoggedIn$ = new Observable<boolean>();
  exibirHeaderDashboard: boolean = false;
  exibirHeaderSite: boolean = false;

  imgs = new Array();
  imgBackgroundCidadao = "assets/image/adesao/bg-adesao-cidadao.webp";
  imgBackgroundEmpreendedor = "assets/image/adesao/bg-adesao-empreendedor.webp";
  imgBackgroundInvestidor = "assets/image/adesao/bg-adesao-investidor.webp";

  papelContaEnum = PapelContaEnum;

  constructor(
    private activedRouted: ActivatedRoute,
    private authenticationService: AuthenticationService,
    public router: Router) {
    this.isLoggedIn$ = this.authenticationService.isLoggedIn;
  }

  ngOnInit(): void {
    this.pload(
      this.imgBackgroundCidadao,
      this.imgBackgroundEmpreendedor,
      this.imgBackgroundInvestidor,
    );

    this.router.events.pipe(
      filter((event: any) => event instanceof NavigationEnd),
      map((event: NavigationEnd) => {
        return { event, route: this.rootRoute(this.activedRouted) };
      }),
      filter((data: { event: NavigationEnd, route: ActivatedRoute }) => data.route.outlet === 'primary'),
      map((data: { event: NavigationEnd, route: ActivatedRoute }) => {
        return data;
      })
    ).subscribe((data: { event: NavigationEnd, route: ActivatedRoute }) => {
      if (data.route.snapshot.data?.header?.dashboard == undefined)
        this.exibirHeaderDashboard = true
      else
        this.exibirHeaderDashboard = data.route.snapshot.data?.header?.dashboard;
    });
  }


  private rootRoute(route: ActivatedRoute): ActivatedRoute {
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route;
  }

  pload(...args: any[]): void {
    for (var i = 0; i < args.length; i++) {
      this.imgs[i] = new Image();
      this.imgs[i].src = args[i];
      //console.log('loaded: ' + args[i]);
    }
  }
}
