<div class="container">
  <div class="modal-header">
    <h4 class="modal-title"></h4>
    <button type="button" class="close btn-close" aria-label="Close" (click)="fechar()">

    </button>
  </div>
  <div class="modal-body">
    <div class="col-10 mx-auto">
      <ng-container [ngSwitch]="modalAtual">
        <section *ngSwitchCase="modalAtualEnum.CadastrarPerguntas">
          <div class="titulo">
            <div class="col-12 d-flex justify-content-center">
              <i class="bi bi-question-circle iconeModal txt-primary-color"></i>
            </div>
            <h3 class="text-center mb-4 txt-primary-color">
              {{'Base_de_Perguntas' | translate}}
            </h3>
          </div>
        </section>

        <section *ngSwitchDefault>
          <div class="titulo">
            <div class="col-12 d-flex justify-content-center">
              <i class="bi bi-check-circle iconeModal txt-secundary-color"></i>
            </div>
            <h3 class="text-center mb-4 txt-secundary-color">
              {{ 'Parabéns!' | translate }}
            </h3>
          </div>
        </section>
      </ng-container>

      <div class="col-10 mx-auto">
        <ng-container [ngSwitch]="modalAtual">

          <section *ngSwitchCase="modalAtualEnum.FichaDeInscricao">
            <div class="col-12 text-center">
              Parabéns, sua chamada foi cadastrada com sucesso, agora o próximo passo é realizar o "Cadastro de ficha de inscrição", gostaria de realizar o cadastro agora?
            </div>
            <div class="row gx-4 d-flex align-items-center justify-content-between my-5">
              <div class="col">
                <button type="button" class="btn btnl-secundary-color py-2 px-5 h7" (click)="fechar()">
                  Não, gostaria de realizar o cadastro depois
                </button>
              </div>
              <div class="col">
                <button type="button" class="btn btn-secundary-color py-2 px-5 h7" (click)="clickBtnSimFichaInscricao()">
                  Sim, realizar cadastro de ficha de inscrição
                </button>
              </div>
            </div>
          </section>

          <section *ngSwitchCase="modalAtualEnum.LandingPage">
            <div class="col-12 text-center">
              O próximo passo agora é realizar o Cadastro da URL da Lading Page:
            </div>
            <div class="row gx-4 d-flex align-items-center justify-content-between my-5">
              <div class="col">
                <button type="button" class="btn btnl-secundary-color py-2 px-5 h7 mb-3" (click)="fechar()">
                  Não, gostaria de realizar o cadastro depois
                </button>
              </div>
              <div class="col">
                <button type="button" class="btn btn-secundary-color py-2 px-5 h7" (click)="clickBtnSimLandingPage()">
                  Sim, realizar cadastro de landing page
                </button>
              </div>
            </div>
          </section>

          <section *ngSwitchCase="modalAtualEnum.EnvioConvites">
            <div class="col-12 text-center">
              A URL da landing Page foi cadastrada com sucesso, agora o próximo passo é realizar o Envio dos convites para os empreendedores:
            </div>
            <div class="row gx-4 d-flex align-items-center justify-content-between my-5">
              <div class="col">
                <button type="button" class="btn btnl-secundary-color py-2 px-5 h7 mb-3" (click)="fechar()">
                  Não, gostaria de realizar o cadastro depois
                </button>
              </div>
              <div class="col">
                <button type="button" class="btn btn-secundary-color py-2 px-5 h7" (click)="clickBtnSimEnvioConvites()">
                  Sim, realizar o envio dos convites
                </button>
              </div>
            </div>
          </section>

          <section *ngSwitchCase="modalAtualEnum.CadastrarPerguntas">
            <div class="col-12 text-center">
              Você gostaria de cadastrar novas perguntas no nosso banco, para utilizar na sua ficha de inscrição?
            </div>
            <div class="row gx-4 d-flex align-items-center justify-content-between my-5">
              <div class="col">
                <button type="button" class="btn btnl-primary-color py-2 px-5 h7 mb-3" (click)="fecharModal()">
                  Não, utilizar perguntas que já estão cadastradas
                </button>
              </div>
              <div class="col">
                <button type="button" class="btn btn-primary-color py-2 px-5 h7" (click)="clickBtnSimCadastrarPerguntas()">
                  Sim, cadastrar perguntas agora
                </button>
              </div>
            </div>
          </section>

          <section *ngSwitchCase="modalAtualEnum.AguardarFaseCaptacao">
            <div class="col-12 text-center">
              Agora é só aguardar a fase de captação da sua chamada para realizar a avaliação das propostas
            </div>
            <div class="col d-flex justify-content-center mx-auto my-5">
              <button type="button" class="btn btn-secundary-color py-3 px-5 h7" (click)="fechar()">
                Fechar Agora
              </button>
            </div>
          </section>

        </ng-container>
      </div>
    </div>
  </div>
</div>
