<div class="modal-header">
  <h4 class="modal-title"></h4>
  <button type="button" class="close btn-close" aria-label="Close" (click)="fechar()"></button>
</div>
<div class="modal-body">
  <div class="w-100 p-sm-5 p-0">
    <div class="loader d-flex align-items-center justify-content-center" *ngIf="showLoading">
      <div class="loader-container d-flex align-items-center">
        <div class="loader-content">

          <div class="row">
            <div class="col-12">
              <div class="lds-ripple">
                <div></div>
                <div></div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <span class="mx-3">
                Carregando...
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <iframe [src]="src" title="Termos de uso" scrolling="auto" (load)="onLoad()"></iframe>
  </div>
  <div class="col-sm d-sm-flex justify-content-between px-sm-5 pt-2 pb-4 ms-sm-0 ms-4" >
    <button type="button" class="btn btnl-primary-color rounded-pill d-flex align-items-center px-5 py-2 mb-sm-0 mb-2 ms-sm-0 ms-4 "
      (click)="fechar()">
      Cancelar
    </button>
    <button type="button" class="btn btn-primary-color rounded-pill d-flex align-items-center px-5 py-2"
      (click)="aceitoTermos()">
      Aceito os Termos
    </button>
  </div>
</div>
