import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LocalStorageService } from '@core/local-storage/local-storage.service';
import { AuthGuardService } from '@core/auth/auth-guard.service';
import { HttpErrorInterceptor } from '@core/http-interceptors/http-error.interceptor';
import { HttpRequestInterceptor } from '@core/http-interceptors/http-request.interceptor';
import { HttpResponseInterceptor } from '@core/http-interceptors/http-response.interceptor';
import { LoginGuardService } from '@core/auth/login-guard.service';
import { ProdutoGuardService } from '@core/auth/produto-guard.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule,
  ],
  exports:[
    CommonModule,
    HttpClientModule,
  ],
  providers:[
    LocalStorageService,
    AuthGuardService,
    LoginGuardService,
    ProdutoGuardService,
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpResponseInterceptor, multi: true }
  ]
})
export class CoreModule { }
