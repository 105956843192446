<div id="oportunidades" class="container-fluid mx-0 px-0 bg-oportunidades-overlay">

  <div class="container my-5 py-5">

    <section class="title-primary col-12 mx-auto text-center">
      <div class="title-line col-10 col-sm-5 px-sm-5 mx-auto">
        <h2 class="h3 col-12 mt-3 ps-1 text-center fw-400">
          {{'Veja_nossas' | translate}} <strong>{{'Oportunidades' | translate}}</strong>
        </h2>
      </div>
    </section>

    <section class="cards mt-md-5">
      <div class="row gy-5 g-sm-3 scrolling-wrapper-flexbox">

        <div *ngFor="let item of lista" class="col-11 col-md-6 col-lg-4 mx-auto mx-sm-0">
          <div class="box card-brada col-12 col-md-10 mx-auto pt-2 pt-sm-3 px-3 px-sm-4 bg-white-color shadow border-5 h-100">

            <div class="card position-relative bg-body-color text-white border-brada-4 mt-2">
              <img class="card-img border-brada-4 w-100" [src]="fotosChamada(item.fotoPerfil)" alt="imagem chamada" loading="lazy" />

              <div class="card-img-overlay border-brada-4">
                <div class="card-header col-12 position-absolute top-0 start-0 d-flex align-items-center border-0 border-brada-4">
                  <div class="col mt-2">
                    <span class="h7 bg-white-color-t2 py-2 px-3 rounded-3 fw-300">{{item?.tipoEdital?.nome}}</span>
                  </div>

                  <div class="col-2 mt-2">
                    <div class="p-1 bg-white-color-t2 rounded-3 d-flex align-items-center justify-content-center">
                      <i class="bi bi-bookmark-fill txt-yellow-color fs-6"></i>
                    </div>
                  </div>
                </div>

                <div class="card-footer col-12 position-absolute bottom-0 start-0 border-0 d-flex align-items-center border-brada-4">
                  <div class="col-5 mb-2">
                    <span class="h7 bg-primary-color-t5 py-2 px-3 rounded-3 fw-300">{{'__Seguir' | translate}}</span>
                  </div>

                  <div class="col-7 d-sm-none mt-2 mb-2 me-2 d-flex align-items-center justify-content-end" *ngIf="item?.status?.id == 4">
                    <span class="h7 fw-400 txt-white-color d-flex align-items-center">
                      <i class="bi bi-circle-fill txt-green-color me-2"></i>
                      {{'Inscrições_Abertas' | translate}}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div class="card-body px-2 px-sm">
              <div class="col-12 border-bottom pb-3">
                <h5 class="card-title my-3">{{item?.titulo}}</h5>
                <p class="h7 card-subtitle mb-2 text-muted d-flex fw-500">
                  {{'Patrocinador:' | translate}}
                  <span class="ms-1 fw-600 txt-secundary-color">
                    {{item?.nomePatrocinador}}
                  </span>
                </p>
              </div>

              <div class="col-12 mt-3 pt-2">
                <div class="row gx-2">

                  <div class="col-6 card-data d-flex datas">
                    <div class="col-sm-12 d-inline-flex text-sm-center">
                      <div class="col-3 pt-1">
                        <i class="bi bi-calendar-week text-muted fs-5"></i>
                      </div>
                      <div class="col text-left">
                        <p class="h7 text-muted fw-500 m-0">
                          {{'Inscrições_até' | translate}}
                        </p>
                        <p class="fs-6 fw-600">
                          {{item?.dataLimiteInscricao | slice:0:10}}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="col-6 d-flex align-items-start justify-content-end bt-saiba" (click)="visualizarLandingPage(item.id)">
                    <button type="button" class="btn btn-secundary-color py-2 h7">
                      <i class="bi bi-pencil-square me-1"></i>
                      {{'Saiba Mais' | translate}}
                    </button>
                  </div>

                </div>
              </div>
            </div>

          </div>
        </div>

      </div>
    </section>

    <section class="button col-12 text-center mt-5">
      <a (click)="maisEditais()">
        <button type="button" class="btn btn-primary-color px-5 py-2 border-brada">{{'+ Mais Chamadas' | translate}}</button>
      </a>
    </section>
</div>
