import { DOCUMENT } from '@angular/common';
import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { DominioLandingPageService } from '@core/services/dominio-landing-page.service';
import { environment } from '@env/environment';

@Component({
  selector: 'app-site',
  templateUrl: './site.component.html',
  styleUrls: ['./site.component.scss']
})
export class SiteComponent implements OnInit {

  windowScrolled!: boolean;
  activeSection = 1;
  siteOuLandingPage: SiteOuLandingPageEnum = SiteOuLandingPageEnum.Site;
  siteOuLandingPageEnum = SiteOuLandingPageEnum;
  idChamada!: number;
  idProjeto!: number;
  idVotacao!: number;
  dadosLandingPage: any;

  constructor(
    @Inject(DOCUMENT) private document: any,
    private dominioLandingPageService: DominioLandingPageService
  ) { }

  ngOnInit() {
    this.verificarUrl();
  }

  @HostListener("window:scroll", [])
  onWindowScroll() {
    if (window.pageYOffset > 200) {
      this.windowScrolled = true;
    } else {
      this.windowScrolled = false;
    }
  }

  verificarUrl() {
    //if (!environment.production) {
      this.siteOuLandingPage = SiteOuLandingPageEnum.Site;
   // } else if (environment.production) {
      this.montarLandingPage();
   // } else {
    //  this.goToUrl();
   // }
  }

  montarLandingPage(): void {
    const subdomain = window.location.hostname.split(".")[0];
    console.log('montarLandingPageL:', subdomain);


    if (subdomain != 'somosbrada' && subdomain != 'www' && subdomain != 'homolog') {
      //if (environment.production) {
        console.log('Validating domain URL:', subdomain);
        this.dominioLandingPageService.validarDominioUrl(subdomain).subscribe((data: any) => {
          console.log('Domain validation result:', data);

          if (data.existeLandingPage == true) {
            console.log('Landing page exists. Type:', data.type);

            switch (data.type) {
              case 'Edital':
                console.log('Fetching data for Edital landing page');
                this.dominioLandingPageService.buscarDadosLandingPageEdital(subdomain).subscribe((data) => {
                  this.dadosLandingPage = data;
                  this.siteOuLandingPage = SiteOuLandingPageEnum.LandingPageChamada;
                  console.log('Edital landing page data:', this.dadosLandingPage);
                });
                break;

              case 'Projeto':
                console.log('Fetching data for Projeto landing page');
                this.dominioLandingPageService.buscarDadosLandingPageProjeto(subdomain).subscribe((data) => {
                  this.dadosLandingPage = data;
                  this.siteOuLandingPage = SiteOuLandingPageEnum.LandingPageProjeto;
                  console.log('Projeto landing page data:', this.dadosLandingPage);
                });
                break;

              case 'AvaliacaoProjetoVotacao':
                console.log('Fetching data for AvaliacaoProjetoVotacao landing page');
                this.dominioLandingPageService.buscarDadosLandingPageVotacao(subdomain).subscribe((data) => {
                  this.dadosLandingPage = data;
                  this.idVotacao = this.dadosLandingPage.id;
                  this.siteOuLandingPage = SiteOuLandingPageEnum.LandingPageVotacao;
                  console.log('AvaliacaoProjetoVotacao landing page data:', this.dadosLandingPage);
                });
                break;
                default:
                  this.goToUrl();
                  break;
            }
          }
        });
      }


    // } else {
    //   this.goToUrl();
    // }
  }

  scrollTo(className: string): void {
    const elementList = document.querySelectorAll(className);
    const element = elementList[0] as HTMLElement;
    element.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }

  goToUrl(): void {
    window.open(`http://${environment.urlSiteAtual}`, '_self')
  }
}

export enum SiteOuLandingPageEnum {
  Site = 1,
  LandingPageChamada = 2,
  LandingPageProjeto = 3,
  LandingPageVotacao = 4,
  Hmg = 9
}
