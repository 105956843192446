export enum StatusChamadasEnum {
    Ativo = "ativo",
    Inativo = "Inativo",
    AguardandoGeracaoLandingPage = "Aguardando Geração da Landing Page",
    Privado = "Edital privado",
    Concluido = "Concluído",
    SimplesDivulgacao = "Concluído – Simples Divulgação",
    StatusEditalSimplesDivulgacao = "Edital para simples divulgação"
}

export enum NomeStatusChamadaEnum {
    EmRegistro = 1,
    AguardandoDefinicaoFichaDeInscricao = 2,
    AguardandoGeracaoLandingPage = 3,
    EmCaptacao = 4,
    CaptacaoEncerrada = 5,
    AvaliacaoDePropostas = 6,
    ConcluidoProjetosEscolhidos = 7,
    ConcluidoSimplesDivulgacao = 8,
    AguardandoInicioInscricao = 9,
    Inativo = 99
}
