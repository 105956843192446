import { Injectable } from '@angular/core';
import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from '@env/environment';
import { HeaderSharedDataService } from '@shared/components/header/header-shared-data.service';

@Injectable()
export class HttpResponseInterceptor implements HttpInterceptor {
    constructor(private headerSharedDataService: HeaderSharedDataService) { }

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        if (request.url.indexOf(environment.api) != -1 || request.url.indexOf(environment.auth) != -1)
            this.countRequest++;
        return next.handle(request).pipe(
            tap((response: any) => {

                if ((request.url.indexOf(environment.api) != -1 || request.url.indexOf(environment.auth) != -1) && response.ok) {
                    this.countRequest--;
                    if (this.countRequest == 0) {
                        this.headerSharedDataService.setResetTimer(true);
                    }
                }
            }, (error) => {
                this.countRequest--;
            })
        )
    }

    private countRequest = 0;
}