<div class="container">
  <div class="modal-header">
    <h4 class="modal-title"></h4>
    <button type="button" class="close btn-close" aria-label="Close" (click)="close(false)"></button>
  </div>
  <div class="modal-body">
    <h4 class="text-center mb-3 txt-support-color ">
      {{title}}
    </h4>

    <h6 class="col-10 fw-400 text-center text-muted h6 mx-auto pt-1 mb-5">{{message}}</h6>

    <div class="col-8 mx-auto mb-4 pb-2">
      <div class="col-12 mx-auto d-flex justify-content-between mt-5 border-top pt-5 px-4">

        <button class="btn btnl-secundary-color rounded-pill px-5 py-2 h6" (click)="close(false)">
          Cancelar
        </button>

        <button type="button" class="btn btn-secundary-color rounded-pill px-5 py-2 h6" (click)="close(true)">
          OK
        </button>
      </div>
    </div>

  </div>
</div>
