export enum TipoChamadasEnum {
    SimplesDivulgacao = 1,
    Publico = 2,
    Privado = 3
}

export enum ModalAtualEnum {
    FichaDeInscricao = 1,
    LandingPage = 2,
    AguardarFaseCaptacao = 3,
    EnvioConvites = 4,
    CadastrarPerguntas = 5
}
