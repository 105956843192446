import { ChangeDetectorRef, Optional, Self, ViewEncapsulation } from '@angular/core';
import { Component, forwardRef, HostBinding, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Masks } from '@core/custom-validators/masks';
import { NgbCalendar, NgbDate, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import * as dayjs from 'dayjs';
import * as dayJS from 'dayjs';
import *as customParseFormat from 'dayjs/plugin/customParseFormat';
dayJS.extend(customParseFormat)
@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => DatepickerComponent),
    }
  ]
})
export class DatepickerComponent implements ControlValueAccessor, OnInit {

  date: NgbDate | null = null;
  data = new FormControl('');
  private defaultMask: string = 'DD/MM/YYYY';
  @Input() formatValue: string = this.defaultMask;
  public value!: Date;

  private onTouchedCallback!: () => {};
  private onChangeCallback!: (_: any) => {};
  maskData = { mask: Masks.data, guide: false }

  @Input()
  set name(value: string) { this._name = value; }
  get name() { return this._name; }
  private _name = '';

  constructor(public formatter: NgbDateParserFormatter, private changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit() {}

  ngAfterContentChecked() {
    this.changeDetectorRef.detectChanges();
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
  }

  public writeValue(obj: any): void {
    this.value = obj;
  }

  public onValueChange(value: any) {
    this.onChangeCallback(value);
  }

  onDateSelection(date: NgbDate) {
    let data = this.formatValue ? dayjs(this.formatter.format(date), this.defaultMask).format(this.formatValue) : dayjs(this.formatter.format(date), this.formatValue).toDate()
    this.value = <any>data;
  }

  get hasValue(): boolean {
    return this.date != undefined || (this.data.value != "" && this.data.value != undefined);
  }
}
