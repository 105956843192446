import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AcaoFiltrosEnum } from '@core/models/enum/acao-filtro';
import { StatusChamadasEnum } from '@core/models/enum/status-chamadas.enum';
import { ModalAtualEnum, TipoChamadasEnum } from '@core/models/enum/tipo-chamadas.enum';
import { ChamadaService } from '@core/services/chamada.service';
import { DominioLandingPageService } from '@core/services/dominio-landing-page.service';
import { NotificationService } from '@core/services/notification.service';
import { NgbActiveModal, NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalAjudaNavegacaoComponent } from '../modal-ajuda-navegacao/modal-ajuda-navegacao.component';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { ProjetoService } from '@core/services/projeto.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
  selector: 'app-modalMotivo',
  templateUrl: './modalMotivo.component.html',
  styleUrls: ['./modalMotivo.component.scss']
})
export class ModalMotivoComponent implements OnInit {

  exibirErroVista!: any;
  message!: any;
  motivoEscolhido: any;
  idChamada: any;
  idProjeto: any;
  chamada: any;
  projeto: any;
  editar: boolean = false;
  tituloDescricao!: string;
  acao: any;

  validarUrl!: any;

  acaoFiltroChamadaEnum = AcaoFiltrosEnum;
  statusChamadas = StatusChamadasEnum;
  modalAjuda: any;
  tipoChamada: any;

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: false,
    height: '15rem',
    minHeight: '5rem',
    width: 'auto',
    minWidth: '0',
    translate: 'no',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Digite o seu comentário aqui...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' }
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: 'v1/image',
    uploadWithCredentials: false,
    sanitize: false,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      [
        //'fontSize',
        'textColor',
        'backgroundColor',
        'customClasses',
        //'link',
        //'unlink',
        'insertImage',
        'insertVideo',
        'insertHorizontalRule',
        'removeFormat',
        'toggleEditorMode'
      ]
    ]
  };

  model: NgbDateStruct | undefined;

  form = this.formBuilder.group({
    motivoInativacao: ['', Validators.required]
  })

  formPendencia = this.formBuilder.group({
    titulo: ['', Validators.required],
    pendencia: ['', Validators.required]
  })

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private router: Router,
    private chamadaService: ChamadaService,
    private notificationService: NotificationService,
    private modalService: NgbModal,
    private projetoService: ProjetoService,
    private dominioLandingPageService: DominioLandingPageService,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    this.definirTituloDescricao(this.tituloDescricao);
    if (this.idProjeto != undefined){
      this.projetoService.buscarDadosProjeto(this.idProjeto).subscribe((data: any) => {
        this.projeto = data;
        if(data.urlLandingPage != undefined){
          this.editar = true;
          this.form.setValue({
            motivoInativacao: data.urlLandingPage.replace('.somosbrada.com.br', '')
          });
        }
      });
    }


    if (this.idChamada != undefined){
      this.chamadaService.buscarDadosChamada(this.idChamada).subscribe((data: any) => {
// console.log(data);
        this.chamada = data;
        if(this.chamada.urlLandingPage != undefined){
          this.editar = true;
          this.form.setValue({
            motivoInativacao: data.urlLandingPage.replace('.somosbrada.com.br', '')
          });
        }
      });
    }

// console.log(this.acao);
  }

  definirTituloDescricao(acao: any) {
    switch (acao) {
      case AcaoFiltrosEnum.Desbloquear:
        this.tituloDescricao = this.translateService.instant("Informe_um_motivo_para_Desbloquear")
        break;
      case AcaoFiltrosEnum.Inativar:
        this.tituloDescricao = this.translateService.instant("Informe_um_motivo_para_Inativar")
        break;
      case AcaoFiltrosEnum.Bloquear:
        this.tituloDescricao = this.translateService.instant("Informe_um_motivo_para_Bloquear")
        break;
      case AcaoFiltrosEnum.LandingPage:
        this.tituloDescricao = this.translateService.instant("Informe_a_URL_da_Landing_Page")
        break;
      case AcaoFiltrosEnum.Pendencia:
        this.tituloDescricao = this.translateService.instant("Informe_a_Pendência")
        this.acao = AcaoFiltrosEnum.Pendencia;
        break;
      case AcaoFiltrosEnum.Parecer:
        this.tituloDescricao = this.translateService.instant("Escreva_abaixo_o_seu_Parecer")
        this.acao = AcaoFiltrosEnum.Parecer;
        break;
      case AcaoFiltrosEnum.Desqualificar:
        this.tituloDescricao = this.translateService.instant("Informe_o_motivo_para_Desqualificar")
        break;
    }
  }

  fechar(): void {
    this.activeModal.close();
  }

  salvarMotivo(): void {
    if (this.form.invalid || this.form.controls.motivoInativacao.value == 'null') {
      this.exibirErroVista = true;
      this.message = this.translateService.instant("Informe_um_motivo_para_finalizar");
      setTimeout(() => {
        this.exibirErroVista = false;
      }, 4000);
    }
    else if (this.form.valid)
      this.activeModal.close(this.form.value);
  }

  salvarLandingPage() {

    let urlLandingPage = this.formValue.toString();
    if (!this.validarUrl.existeLandingPage) {
      if (this.idChamada) {
        this.dominioLandingPageService.salvarDominioUrlEdital(this.idChamada, urlLandingPage).subscribe((data: any) => {
// console.log(data)
          this.message = this.translateService.instant("Url_criada_com_Sucesso");
          this.notificationService.showSuccess(this.message);
          this.activeModal.close();
          this.verificarProximoPasso();
        });
      }
      if (this.idProjeto) {
        this.dominioLandingPageService.salvarDominioUrlProjeto(this.idProjeto, urlLandingPage).subscribe((data: any) => {
// console.log(data)
          this.message = this.translateService.instant("Url_criada_com_Sucesso");
          this.notificationService.showSuccess(this.message);
          this.activeModal.close();
        });
      }
    }

    else {
      this.message = this.translateService.instant("Domínio_Indisponível");
      this.validarUrl = <any>false;
      this.notificationService.showError(this.message);
    }
  }

  editarLandingPage() {
    let urlLandingPage = this.formValue.toString();
    if (!this.validarUrl.existeLandingPage) {
      if (this.idChamada) {
        this.dominioLandingPageService.editarDominioUrlEdital(this.idChamada, urlLandingPage).subscribe((data: any) => {
// console.log(data)
          this.message = this.translateService.instant("Url_editada_com_Sucesso");
          this.notificationService.showSuccess(this.message);
          this.activeModal.close();
        });
      }
      if (this.idProjeto) {
        this.dominioLandingPageService.editarDominioUrlProjeto(this.idProjeto, urlLandingPage).subscribe((data: any) => {
// console.log(data)
          this.message = this.translateService.instant("Url_editada_com_Sucesso");
          this.notificationService.showSuccess(this.message);
          this.activeModal.close();
        });
      }
    }

    else {
      this.message = this.translateService.instant("Domínio_Indisponível");
      this.validarUrl = <any>false;
      this.notificationService.showError(this.message);
    }
  }

  verificarProximoPasso() {
    this.tipoChamada = localStorage.getItem('tipoChamadaAtual');
// console.log(this.tipoChamada);

    if (this.tipoChamada == TipoChamadasEnum.Privado) {
      if (this.modalAjuda) {
        const timeElapsed = Date.now();
        const today = this.formatDate(new Date(timeElapsed));

        if (this.chamada?.dataInicioInscricao <= today) {
          let modalFaseCaptacao = this.modalService.open(ModalAjudaNavegacaoComponent, {
            centered: true,
            beforeDismiss: () => false,
            size: 'lg'
          });
          modalFaseCaptacao.componentInstance.modalAtual = ModalAtualEnum.EnvioConvites;
          modalFaseCaptacao.componentInstance.idChamada = this.idChamada;
        }

        else{
          this.message = this.translateService.instant("Aguarde_a_data_de_captação");
          this.notificationService.showSuccess(this.message);
          this.dashboard();
        }

      }
    } else if (this.modalAjuda) {
      let modalFaseCaptacao = this.modalService.open(ModalAjudaNavegacaoComponent, {
        centered: true,
        beforeDismiss: () => false,
        size: 'md'
      });
      modalFaseCaptacao.componentInstance.modalAtual = ModalAtualEnum.AguardarFaseCaptacao;
      modalFaseCaptacao.componentInstance.idChamada = this.idChamada;
    }
  }

  salvarPendencia(): void {
    const titulo = this.formPendencia.controls.titulo.value;
    const pendencia = this.formPendencia.controls.pendencia.value;

    if (this.formPendencia.invalid || !titulo || !pendencia) {
      this.showMessage("Informe um título e uma descrição para finalizar");
    } else if (pendencia.length > 2500) {
      this.showMessage("A pendência não pode ter mais que 2000 caracteres");
    } else if (titulo.length > 100) {
      this.showMessage("O título não pode ter mais que 100 caracteres");
    } else {
      this.activeModal.close(this.formPendencia.value);
    }
  }

  showMessage(messageKey: string): void {
    this.exibirErroVista = true;
    this.message = this.translateService.instant(messageKey);
    setTimeout(() => {
      this.exibirErroVista = false;
    }, 4000);
  }

  verificarDominio(): void {
    if (this.formValue)
      this.dominioLandingPageService.validarDominioUrl(this.formValue).subscribe((data: any) => {
        this.validarUrl = data.existeLandingPage;
      })
    else
      this.validarUrl = null;
  }

  get formValue(): FormGroup {
    return <FormGroup>this.form.controls.motivoInativacao.value;
  }

  dashboard(): void {
    this.router.navigate(['/dashboard']);
  }

  padTo2Digits(num: number) {
    return num.toString().padStart(2, '0');
  }

  formatDate(date: Date) {
    return [
      this.padTo2Digits(date.getDate()),
      this.padTo2Digits(date.getMonth() + 1),
      date.getFullYear(),
    ].join('/');
  }
}
