import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AuthenticationService } from '@core/auth/authentication.service';
import { AreaInteresse } from '@core/models/chamada';
import { AcaoFiltrosEnum } from '@core/models/enum/acao-filtro';
import { PapelContaEnum } from '@core/models/enum/papel-conta';
import { DominioService } from '@core/services/dominio.service';
import { ListChamadasAtendemProjetoService } from '@core/services/list-chamadas-atendem-projeto.service';
import { NotificationService } from '@core/services/notification.service';
import { NgbPaginationConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-list-chamadas-atendem-projeto',
  templateUrl: './list-chamadas-atendem-projeto.component.html',
  styleUrls: ['./list-chamadas-atendem-projeto.component.scss']
})
export class ListChamadasAtendemProjetoComponent implements OnInit {

  namePage = "Lista de chamadas que um projeto atende";

  page = 1;
  pageSize = 5;
  lastPage = 1;
  listaProjetos: any[] = []; // criarModel
  tabelaListaProjetos: any[] = [];
  collectionSize: any;
  acaoFiltrosEnum = AcaoFiltrosEnum;
  exibirList: boolean = false;

  listaEditais: any; // criarModel

  usuarioLogado: any;
  papelAtivo: any;
  idContaAtiva: any;

  message!: string;
  isLoading: boolean = false;
  success: boolean = false;
  exibirErroVista: boolean = false;
  objEnvio: any;
  idEventoAtual!: number;

  fonteFinanciamento: any[] = [];
  publicos: any[] = [];
  ods: any[] = [];
  statusEdital: any[] = [];
  areasDeInteresse: Array<AreaInteresse> = [];
  estados: any[] = [];
  ufs: any;
  municipios: any[] = [];
  filtroPaginas: any[]=[];

  teste: any;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private notificationService: NotificationService,
    private dominioService: DominioService,
    private authenticationService: AuthenticationService,
    private listChamadasAtendemProjetoService: ListChamadasAtendemProjetoService,
    private config: NgbPaginationConfig,
    private sanitizer: DomSanitizer,
  ) {
    config.size = 'sm';
    config.boundaryLinks = true;
  }

  ngOnInit() {
    this.userlogged();
    this.listarChamadas();
    this.buscarfiltroPaginas();
  }

  voltarDashboard(): void {
    this.router.navigate(['/dashboard']);
  }

  listarChamadas(): void {
    this.listChamadasAtendemProjetoService.listarEditais().subscribe((data: any) => {
      this.listaProjetos = data;
    });
  }

  pesquisarChamadas($event: any, lastPage?: number){
    this.idEventoAtual = $event.id;
    if($event == undefined){
      this.exibirList = false;
    } else {
// console.log(lastPage)
      if (lastPage && lastPage == this.page)
      return;

      this.lastPage = this.page;

// console.log('aqui')
      this.listChamadasAtendemProjetoService.buscarEditaisPorProjeto(this.idEventoAtual, this.page, this.pageSize).subscribe((data: any) => {
// console.log(data.content)
        this.listaEditais = data.content;
        this.collectionSize = data.totalElements;
        this.exibirList = true;
      });
    }
  }

  pesquisar($event: any, lastPage?: number){
    if($event == undefined){
      this.exibirList = false;
    } else {
// console.log(lastPage)
      if (lastPage && lastPage == this.page)
      return;

      this.lastPage = this.page;

// console.log('aqui')
      this.listChamadasAtendemProjetoService.buscarEditaisPorProjeto(this.idEventoAtual, this.page, this.pageSize).subscribe((data: any) => {
// console.log(data.content)
        this.listaEditais = data.content;
        this.collectionSize = data.totalElements;
        this.exibirList = true;
      });
    }
  }

  trocarSize(): void {
    this.page = 1;
    this.pesquisar(undefined);
  }

  buscarfiltroPaginas(): void {
    this.dominioService.filtroPaginas().subscribe((data: any) => {
      this.filtroPaginas = data;
    });
  }

  visualizarLandingPage(idChamada: any){
    this.router.navigate([`/landingpage/chamada/visualizar/${idChamada}`]);
  }

  fotoCard(item: any): any {
    if(item){
      if(item.base64Content && item.base64info){
        let img = item.base64info + item.base64Content;
        if(img){
          return this.sanitizer.bypassSecurityTrustUrl(img);
        }
      }
    }
    return 'https://images.unsplash.com/photo-1485217988980-11786ced9454?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80';
  }

  showError(mensagem: any) {
    this.notificationService.showError(mensagem);
  }

  showSuccess(mensagem: any) {
    this.notificationService.showSuccess(mensagem);
  }

  userlogged(): void{
    this.usuarioLogado = this.authenticationService.currentUserValue;
    this.papelAtivo = this.usuarioLogado.papelAtivo;
    this.idContaAtiva = this.usuarioLogado.idContaAtiva;
  }

}
