<div class="bg-overlay bg-body-color h-100 landingPage" id="topo">
  <div class="position-fixed w-100">
    <header id="topo" class="container-flex shadow bg-white-color">
      <nav class="navbar navbar-expand-lg navbar-light mt-0 pt-0">
        <div class="container py-3 align-middle d-flex">

          <a class="col col-sm-2 d-flex justify-content-start justify-content-md-center">
            <img class="ms-3 mx-sm-0" [src]="imagemPerfilConta?imagemPerfilConta:'/assets/image/marca-brada.png'" height="40" alt="Logo do site Brada" loading="lazy" />
          </a>

          <button class="col-3 navbar-toggler border-0" type="button" (click)="isMenuCollapsed = !isMenuCollapsed"
            [ngClass]="{'ms-auto': isMenuCollapsed}">
            <span class="navbar-toggler-icon"></span>
          </button>

          <div [ngbCollapse]="isMenuCollapsed" class="col collapse navbar-collapse">
            <div class="navbar-nav col mt-4 mt-sm-0 ms-auto menu-principal">
              <div class="navbar-nav mt-2 col d-flex justify-content-start">
                <ul class="navbar-nav col-12 col-sm-9 d-flex justify-content-start me-auto mb-2 mb-lg-0 align-items-center">
                  <li class="nav-item fw-500" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                    <a class="nav-link" pageScroll href="/#topo">{{'Home' | translate}}</a>
                  </li>

                  <li class="nav-item ps-sm-4 fw-500" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" *ngIf="dataLandingPage?.sobreProjeto">
                    <a class="nav-link" pageScroll href="/#sobre">{{'Sobre' | translate}}</a>
                  </li>

                  <li class="nav-item ps-sm-4 fw-500" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" *ngIf="validarDetalhes()">
                    <a class="nav-link" pageScroll href="/#detalhes">{{'Detalhes' | translate}}</a>
                  </li>

                  <li class="nav-item ps-sm-4 fw-500" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" *ngIf="dataLandingPage?.midia?.length">
                    <a class="nav-link" pageScroll href="/#midias">{{'Mídias' | translate}}</a>
                  </li>

                  <li class="nav-item ps-sm-4 fw-500" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" *ngIf="dataLandingPage?.documentos?.length">
                    <a class="nav-link" pageScroll href="/#documentos">{{'Documentos' | translate}}</a>
                  </li>
                </ul>
              </div>
              <ng-select [notFoundText]="'Nenhum_item_encontrado' | translate" (change)="selectLanguage($event)" class="custom col-5 col-sm-2 mx-auto mx-sm-0" [searchable]="false" [clearable]="false"
                [items]="cities" [(ngModel)]="selectedCity">
                <ng-template ng-label-tmp let-item="item">
                  <div class="d-flex align-items-center justify-content-start">
                    <img class="mx-2" height="18" width="29" [src]="item.avatar" />
                    <h5 class="Txt mt-2">{{item.lingua}}</h5>
                  </div>
                </ng-template>
                <ng-template [items]="selectLanguage" ng-option-tmp let-item="item">
                  <div style="margin: 4px 0;" *ngIf="item.name !== 'Kaunas'">
                    <div class="d-flex align-items-center justify-content-start">
                      <img class="mx-2" height="15" width="24" [src]="item.avatar" />
                      <h5 class="Txt mt-2">{{item.lingua}}</h5>
                    </div>
                  </div>
                </ng-template>
              </ng-select>
            </div>

          </div>
        </div>
      </nav>
    </header>

    <a type="button" class="btn arraste-cima btn-primary-color border-brada position-fixed bottom-0 end-0 me-3 mb-4"
      href="/#topo" pageScroll *ngIf="windowScrolled">
      <i class="bi bi-arrow-up-square fs-4"></i>
    </a>
  </div>

  <div class="container-flex pt-5 pt-sm-0">
    <div class="banner-principal bg-image d-flex align-items-end justify-content-center" [ngClass]="{'transbox': !imagemCapaBanner}">
      <img [src]="imagemCapaBanner" alt="..." *ngIf="imagemCapaBanner">
      <div class="col-12 text-banner text-center">

        <div class="container pt-5 pt-sm-0">
          <div class="row d-sm-flex align-items-sm-end">
            <div class="col-12 col-sm-9 order-1 order-sm-0">
              <div class="col-12 mx-auto mb-4 text-start py-4 px-5 bg-white border-brada title">
                <h1 class="h3">{{dataLandingPage?.nomeProjeto}}</h1>
                <h6 class="text-capitalize mt-3">{{dataLandingPage?.statusProjeto?.nome}}
                </h6>
              </div>
            </div>
            <div class="col-12 col-sm-3 order-0 order-sm-1 my-3 mb-sm-0">
              <div class="col-12 d-flex align-items-center justify-content-center">
                <div class="navbar-brand shadow d-flex align-items-center justify-content-center bg-white-color p-2"
                  href="#">
                  <div class="col-12 d-flex align-items-center justify-content-center bg-white-color shadow h-100">
                    <img class="mx-4 logo" [src]="imagemPerfilMenu?imagemPerfilMenu:'/assets/image/marca-brada.png'"
                      height="40" alt="Logo do site Brada" loading="lazy" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

    <section id="sobre" class="col-12 bg-white py-5">
      <div class="container my-5">
        <div class="col-12 d-flex">

          <div class="col-10 col-sm-7 mx-auto text-muted scroll">
            <div [innerHtml]="dataLandingPage?.sobreProjeto"></div>
          </div>

        </div>
      </div>
    </section>

    <section id="detalhes" class="container py-3 py-sm-5 px-5 px-sm-0">
      <section id="title" class="mt-2 mb-4 d-flex justify-content-start pt-3">
        <h5 class="title-line">Detalhes</h5>
      </section>

      <div class="row gx-4 gy-3 row-cols-sm-2 pb-5">

        <div class="col pt-3" *ngIf="areaInteresse?.length">
          <div class="col-12 h-100 border border-brada p-5">
            <h6>{{'Areas_de_Interesse' | translate}}</h6>
            <div class="mt-3" *ngFor="let item of areaInteresse">
              <div class="card-body d-flex align-items-center m-0 p-0">
                <img [src]="imagensDominios(item)" width="30" alt="..." *ngIf="imagensDominios(item)" />
                <p class="card-text h6 ms-3 text-muted fw-400">{{item.nomeAreaInteresse}}</p>
              </div>
            </div>
          </div>
        </div>

        <div class="col pt-3" *ngIf="publico?.length">
          <div class="col-12 h-100 border border-brada p-5">
            <h6>{{'Público_Alvo' | translate}}:</h6>
            <div *ngFor="let item of publico">
              <div class="card-body d-flex align-items-center m-0 p-0">
                <img [src]="imagensDominios(item)" width="30" alt="..." *ngIf="imagensDominios(item)" />
                <p class="card-text h6 text-muted fw-400 ms-3">{{item.nome}}</p>
              </div>
            </div>
          </div>
        </div>

        <div class="col pt-3" *ngIf="ods?.length">
          <div class="col-12 h-100 border border-brada p-5">
            <h6>{{'ODS' | translate}}:</h6>
            <div *ngFor="let item of ods">
              <div class="card-body d-flex align-items-center mt-3 m-0 p-0">
                <img [src]="imagensDominios(item)" class="border-brada" width="30" alt="..."
                  *ngIf="imagensDominios(item)" />
                <p class="card-text h6 text-muted fw-400 ms-3">{{item.nome}}</p>
              </div>
            </div>
          </div>
        </div>

        <div class="col pt-3" *ngIf="dataLandingPage?.tipoPessoas?.length">
          <div class="col-12 h-100 border border-brada p-5">
            <h6>{{'Tipo_de_Empreendedor' | translate}}:</h6>
            <div *ngFor="let item of dataLandingPage?.tipoPessoas">
              <div class="card-body d-flex align-items-center mt-3 m-0 p-0">
                <p class="card-text h6 text-muted fw-400">{{item.nome}}</p>
              </div>
            </div>
          </div>
        </div>

        <div class="col pt-3" *ngIf="dataLandingPage?.fonteFinanciamento?.length">
          <div class="col-12 h-100 border border-brada p-5">
            <h6>{{'Fonte_de_Financiamento' | translate}}:</h6>
            <div *ngFor="let item of dataLandingPage?.fonteFinanciamento">
              <div class="card-body d-flex align-items-center mt-3 m-0 p-0">
                <p class="card-text h6 text-muted fw-400">{{item.nomeFonteFinanciamento}}</p>
              </div>
            </div>
          </div>
        </div>

        <div class="col pt-3" *ngIf="dataLandingPage?.localAtuacao?.estado?.length || dataLandingPage?.localAtuacao?.municipio?.length">
          <div class="col-12 h-100 border border-brada p-5">
            <h6>{{'Estados_de_Atuação' | translate}}:</h6>
            <div class="col-12">
              <div class="col d-flex mt-3">
                <!-- <div class="col-2 pe-3">
                  <p class="h6 fw-400">{{'Estados' | translate}}:</p>
                </div> -->

                <div class="col scroll">
                  <div *ngFor="let estado of dataLandingPage?.localAtuacao?.estado">
                    <p class="h6 text-muted fw-400 mb-3">{{estado?.nome}}</p>
                  </div>
                </div>
              </div>

              <!-- <div class="col d-flex">
                <div class="col-2">
                  <p class="h6 fw-400">{{'Municípios' | translate}}:</p>
                </div>

                <div class="col ps-3 scroll">
                  <div *ngFor="let municipio of dataLandingPage?.localAtuacao?.municipio">
                    <p class="h6 text-muted fw-400 mb-3">{{municipio?.nome}} - {{municipio?.estado?.codigo}}</p>
                  </div>
                </div>
              </div> -->
            </div>
          </div>
        </div>

        <div class="col pt-3" *ngIf="dataLandingPage?.localAtuacao?.municipio?.length || dataLandingPage?.localAtuacao?.municipio?.length">
          <div class="col-12 h-100 border border-brada p-5">
            <h6>{{'Municípios_de_Atuação' | translate}}:</h6>
            <div class="col-12">
              <div class="col d-flex mt-3">
                <div class="col scroll">
                  <div *ngFor="let municipio of dataLandingPage?.localAtuacao?.municipio">
                    <p class="h6 text-muted fw-400 mb-3">{{municipio?.nome}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </section>

    <section id="midias" class="col-12 bg-white " *ngIf="dataLandingPage?.midia?.length">
      <div class="col-12 transbox px-5 px-sm-0 pt-4 pb-5">
        <div class="container pb-5">

          <section id="title" class="mt-5 mb-3 d-flex justify-content-start">
            <h5 class="title-line">{{'Mídias' | translate}}</h5>
          </section>

          <div class="row g-4 row-cols-1 row-cols-sm-5 d-flex justify-content-start">
            <div class="col" *ngFor="let item of midia">
              <div
                class="card border-0 shadow-sm d-flex align-items-center justify-content-center text-center py-4 h-100"
                (click)="goToUrl(item.enderecoMidia)">

                <i class="bi bi-play-btn fs-2 txt-primary-color m-0 p-0"
                  *ngIf="item?.tipoMidia?.nomeTipoMidia == 'Vídeos'"></i>

                <i class="bi bi-image fs-2 txt-primary-color m-0 p-0"
                  *ngIf="item?.tipoMidia?.nomeTipoMidia == 'Fotos'"></i>

                <i class="bi bi-link-45deg fs-2 txt-primary-color m-0 p-0"
                  *ngIf="item?.tipoMidia?.nomeTipoMidia == 'Sites'"></i>

                <div class="card-body m-0 p-0">
                  <a class="card-text h7">{{item.enderecoMidia | slice:0:20}}...</a>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>

    <section id="documentos" class="col-12" *ngIf="dataLandingPage?.documentos?.length">
      <div class="col-12 transbox pt-4 pb-5">
        <div class="container pb-5 px-5 px-sm-0">

          <section id="title" class="mt-5 mb-3 d-flex justify-content-start">
            <h5 class="title-line">{{'Documentos' | translate}}</h5>
          </section>

          <div class="row g-3 gy-2 row-cols-1 row-cols-sm-3">
            <div class="col" *ngFor="let item of dataLandingPage?.documentos">
              <div class="doc border border-1 p-4 d-flex align-items-center justify-content-between" (click)="downloadEnviado(dataLandingPage?.id, item)">
                <p class="h6 fw-400 pe-3">{{item?.tipoDocumento?.nomeTipoDocumento}}</p>
                <i class="bi bi-cloud-download fs-4 m-0 p-0 txt-secundary-color"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>
</div>


