import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'traducaoPipe' })
export class TraducaoStatusProjetoPipe implements PipeTransform {
  transform(value: string, traducao: string): any {
    let replaceMap;

    if (traducao === 'en-US') {
      replaceMap = {
        'Em Captação': 'In Capture',
        'Em Registro': 'In Registration',
        'Aguardando Geração da Landing Page': 'Awaiting Landing Page Generation',
        'Projeto Aprovado  (vinculado a um edital)': 'Project Approved (linked to a notice)',
        'Em Execução': 'In Execution',
        'Concluído': 'Completed',
        'Aguardando Início Captação': 'Awaiting Capture Start',
        'Aprovado fora do BRADA': 'Approved outside of BRADA',
        'Inativo': 'Inactive',
        'Bloqueado pelo adm': 'Blocked by Admin'
      };

      if (value in replaceMap) {
        return replaceMap[value];
      }
    } else if (traducao === 'es-ES') {
      replaceMap = {
        'Em Captação': 'En Captura',
        'Em Registro': 'En Registro',
        'Aguardando Geração da Landing Page': 'Esperando Generación de Página de Aterrizaje',
        'Projeto Aprovado  (vinculado a um edital)': 'Proyecto Aprobado (vinculado a un aviso)',
        'Em Execução': 'En Ejecución',
        'Concluído': 'Completado',
        'Aguardando Início Captação': 'Esperando Inicio de Captura',
        'Aprovado fora do BRADA': 'Aprobado fuera de BRADA',
        'Inativo': 'Inactivo',
        'Bloqueado pelo adm': 'Bloqueado por el admin'
      };
      if (value in replaceMap) {
        return replaceMap[value];
      }
    }

    return value;
  }
}
