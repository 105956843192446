<div class="modal-header">
  <h4 class="modal-title"></h4>
  <button type="button" class="close btn-close" aria-label="Close" (click)="fechar()">

  </button>
</div>

<div class="modal-body">
  <div class="col-10 m-auto mb-5">
    <div class="row">
      <div class="col-12 m-auto">
        <div class="row">
          <div class="col-12 d-flex justify-content-center">
            <i class="bi bi-exclamation-circle iconeModal txt-primary-color"></i>
          </div>
        </div>

        <h4 class="text-center mb-3 txt-support-color ">
          {{tituloDescricao}}
        </h4>

      </div>
    </div>

    <div class="row">
      <div class="col">
        <div class="row my-sm-4" *ngIf="exibirErroVista">
          <div class="col-12">
            <div class="alert alert-danger alert-dismissible fade show" role="alert">
              {{this.message}}
              <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
            </div>
          </div>
        </div>

        <form [formGroup]="form" (ngSubmit)="salvarMotivo()">
          <!-- <div class="col-12 mt-2">
            <textarea type="text" formControlName="justificativa" class="form-control" placeholder="Digite aqui..." [showError]="false"></textarea>
          </div> -->
          <div class="form-group my-4">
            <div class="editor border">
              <angular-editor id="justificativa" [placeholder]="'Digite aqui...'" formControlName="justificativa" [config]="editorConfig"></angular-editor>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-12 mt-4 d-flex align-items-center justify-content-center">
              <button type="button" class="btn btn-primary-color py-2 px-5" (click)="salvarMotivo()">
                {{'Finalizar' | translate}}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
