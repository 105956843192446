import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

const routes = {
  listarTodasVendasComerciais: `/venda-comercial`,
  salvarVendaComercial: `/venda-comercial`,
  editarVendaComercial: `/venda-comercial`,
  buscarVendaComercialPorId: (id: number) => `/venda-comercial/${id}`,
  buscarVendaComercialPorConta: `/venda-comercial/vendas-adquiridas-conta`,
  consultarVendasComerciais: `/venda-comercial/consultar`,
  efetivarVenda:  `/adesao/patrocinador/pagamento/efetivar-venda`,
  ativarVenda: `/venda-comercial/ativar-venda`,
  inativarVenda: `/venda-comercial/inativar-venda`,
  efetivarAssinatura: `/adesao/empreendedor/pagamento/efetivar-assinatura`,
  efetivarNovaAssinatura: `/plano-venda-contratado/efetivar-assinatura`,
  excluirAssinatura: `/plano-venda-contratado/excluir-assinatura`,
  cancelarAssinatura: `/cancelamento-assinatura/cancelar`,
  cancelarVenda: `/venda-comercial/cancelar`,
  cancelarCheckoutEmpreendedor: (idCompra: number) => `/adesao/empreendedor/cancelar-checkout/${idCompra}`,
  exportarExcel:`/venda-comercial/exportar-xls`,
  buscarVendaComercialPorIdPublica: (codigo: string) => `/adesao/patrocinador/buscar-venda/${codigo}`,
  // buscarVendaComercialPorIdPublica: (id: number) => `/publica/pagamento/${id}`,
}

@Injectable({
  providedIn: 'root'
})
export class VendasComerciaisService {

constructor(
  private apiService: ApiService
) { }

  listarTodasVendasComerciais() {
    return this.apiService.get<any>(routes.listarTodasVendasComerciais);
  }

  salvarVendaComercial(obj: any) {
    return this.apiService.post<any>(routes.salvarVendaComercial, obj);
  }

  editarVendaComercial(obj: any) {
    return this.apiService.put<any>(routes.editarVendaComercial, obj);
  }

  buscarVendaComercialPorId(id: number) {
    return this.apiService.get<any>(routes.buscarVendaComercialPorId(id));
  }

  buscarVendaComercialPorConta(conta: any) {
    return this.apiService.post<any[]>(routes.buscarVendaComercialPorConta, conta);
  }

  consultarVendasComerciais(obj: any, page:number,pageSize:number) {
    let params = new HttpParams();
    params = params.append('size',pageSize);
    params = params.append('page',page - 1);
    return this.apiService.post<any>(`${routes.consultarVendasComerciais}`, obj,undefined,undefined,params);
  }

  efetivarVenda(obj: any) {
    return this.apiService.post<any>(routes.efetivarVenda, obj);
  }

  efetivarAssinatura(obj: any) {
    return this.apiService.post<any>(routes.efetivarAssinatura, obj);
  }

  efetivarNovaAssinatura(obj: any) {
    return this.apiService.post<any>(routes.efetivarNovaAssinatura, obj);
  }

  cancelarAssinatura(obj: any) {
    return this.apiService.delete<any>(routes.cancelarAssinatura, obj);
  }

  cancelarCheckoutEmpreendedor(idCompra: any) {
    return this.apiService.delete<any>(routes.cancelarCheckoutEmpreendedor(idCompra));
  }


  excluirAssinatura(obj: any) {
    return this.apiService.delete<any>(routes.excluirAssinatura, obj);
  }

  buscarVendaComercialPorIdPublica(codigo: string) {
    return this.apiService.get<any>(routes.buscarVendaComercialPorIdPublica(codigo));
  }

  exportarDadosExcel(obj:any){
    return this.apiService.downloadPost(routes.exportarExcel, obj);
  }

  ativarVenda(obj: any) {
    return this.apiService.put<any>(routes.ativarVenda, obj);
  }

  inativarVenda(obj: any) {
    return this.apiService.put<any>(routes.inativarVenda, obj);
  }

  cancelarVenda(obj: any) {
    return this.apiService.put<any>(routes.cancelarVenda, obj);
  }
}
