import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { ModalLoginComponent } from '@modules/login/components/modal-login/modal-login.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-header-site',
  templateUrl: './header-site.component.html',
  styleUrls: ['./header-site.component.scss']
})
export class HeaderSiteComponent implements OnInit {
  cities = [
    {
      id: 0,
      value: "pt-BR",
      avatar: 'https://static.todamateria.com.br/upload/ba/nd/bandeira-do-brasil-og.jpg',
      lingua: 'PT'
    },
    {
      id: 1,
      value: "en-US",
      avatar: 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/a4/Flag_of_the_United_States.svg/2560px-Flag_of_the_United_States.svg.png',
      lingua: 'EN'
    },
    {
      id: 2,
      value: "es-ES",
      avatar: 'https://upload.wikimedia.org/wikipedia/commons/thumb/9/9a/Flag_of_Spain.svg/2560px-Flag_of_Spain.svg.png',
      lingua: 'ES'
    }
  ];
  selectedCity = this.cities[0];
  linguas = 0
  classe1 = true
  classe2 = false
  classe3 = false
  classe4 = false

  public isMenuCollapsed = true;

  constructor(
    private formBuilder: FormBuilder,
    private translateService: TranslateService,
    private modalService: NgbModal,
    public router: Router
  ) { }

  ngOnInit() {
  }

  BTN1() {
    if (this.classe2 == true || this.classe3 == true || this.classe4 == true) {
      this.classe2 = false
      this.classe3 = false
      this.classe4 = false
    }
    if (this.classe2 == false || this.classe3 == false || this.classe4 == false) {
      this.classe1 = true
    }
// console.log(this.classe1)
  }

  BTN2() {
    if (this.classe1 == true || this.classe3 == true || this.classe4 == true) {
      this.classe1 = false
      this.classe3 = false
      this.classe4 = false
    }
    if (this.classe1 == false) {
      this.classe2 = true
    }
// console.log(this.classe2)
  }

  BTN3() {
    if (this.classe1 == true || this.classe2 == true || this.classe4 == true) {
      this.classe1 = false
      this.classe2 = false
      this.classe4 = false
    }
    if (this.classe1 == false) {
      this.classe3 = true
    }
// console.log(this.classe3)
  }

  BTN4() {
    if (this.classe1 == true || this.classe3 == true || this.classe2 == true) {
      this.classe1 = false
      this.classe3 = false
      this.classe2 = false
    } if (this.classe1 == false) {
      this.classe4 = true
    }
// console.log(this.classe4)
  }

  public selectLanguage(event: any) {
    this.translateService.use(event.value)
    this.linguas = (event.id)
// console.log(this.linguas)
  }

  home() {
    this.router.navigate(['/']);
  }

  acessarFaq() {
    window.open(`http://${environment.urlSiteAtual}/#/faq-publica`, '_self')
  }

  openModalLogin(): void {
    const modalColunas = this.modalService.open(ModalLoginComponent, {
      centered: true,
      beforeDismiss: () => false,
      size: 'md',
      modalDialogClass: 'modal-grafico'
    })

    let instance = <ModalLoginComponent>modalColunas.componentInstance;
  }

  scrollTo(className: string): void {
    const elementList = document.querySelectorAll(className);
    const element = elementList[0] as HTMLElement;
    element.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }

  goToUrl(): void {
    window.open(`http://${environment.urlSiteAtual}`, '_self')
  }
}
