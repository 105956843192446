import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

const routes = {
  upload: `/arquivos/upload`,
  getBase64:(id:number)=>`/arquivos/base64/${id}`,
  download: (id:number)=>`/arquivos/download/${id}`,
  downloadEditalPublica: (idEdital: number, idDocumento: number)=> `/edital-publico/${idEdital}/documento/${idDocumento}/download`,
  downloadProjetoPublica: (idProjeto: number, idDocumento: number)=> `/publica/projeto/${idProjeto}/documento/${idDocumento}/download`,
}
@Injectable({
  providedIn: 'root'
})
export class ArquivoService {

  constructor(private apiService:ApiService) { }

  upload(file:any){
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.apiService.post<any>(routes.upload, formData);
  }

  getBase64(id:number){
    return this.apiService.get<any>(routes.getBase64(id));
  }

  download(id: number) {
    return this.apiService.downloadGet(routes.download(id));
  }

  downloadEditalPublica(idEdital: number, idDocumento: number) {
    return this.apiService.downloadGet(routes.downloadEditalPublica(idEdital, idDocumento));
  }

  downloadProjetoPublica(idProjeto: number, idDocumento: number) {
    return this.apiService.downloadGet(routes.downloadProjetoPublica(idProjeto, idDocumento));
  }

}
