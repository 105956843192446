import { Injectable } from '@angular/core';
import { AuthenticationService } from '@core/auth/authentication.service';
import { Papel, TipoPessoa } from '@core/models/dominio';
import { ApiService } from '@core/services/api.service';
import { environment } from '@env/environment';
import { of } from 'rxjs';

const routes = {
  patrocinador: `/publica/patrocinador/listar`,
  empreendedor: `/publica/empreendedor/listar`,
}
@Injectable({
  providedIn: 'root'
})
export class DominioServiceProjeto {

  constructor(
    private apiService: ApiService,
    private authenticationService: AuthenticationService,
  ) { }


  listaPatrocinador() {
    return this.apiService.get<any>(routes.patrocinador);
  }

  listaEmpreendedor() {
    return this.apiService.get<any>(routes.empreendedor);
  }
}
