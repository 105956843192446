import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginGuardService } from '@core/auth/login-guard.service';
import { CadastroComponent } from './pages/cadastro/cadastro.component';
import { FaqPublicaComponent } from './pages/faq-publica/faq-publica.component';
import { LoginComponent } from './pages/login/login.component';

import { SiteComponent } from './pages/site/site.component';

const routes: Routes = [
  {
    path: '',
    component: SiteComponent,
    canActivate: [LoginGuardService]
  },
  {
    path: 'cadastro',
    component: CadastroComponent,
    canActivate: [LoginGuardService]
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [LoginGuardService]
  },

  {
    path: 'faq-publica',
    component: FaqPublicaComponent,
    canActivate: [LoginGuardService]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PublicRoutingModule { }
