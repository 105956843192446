import { DOCUMENT } from '@angular/common';
import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-escolha-adesao',
  templateUrl: './modal-escolha-adesao.component.html',
  styleUrls: ['./modal-escolha-adesao.component.scss']
})
export class ModalEscolhaAdesaoComponent implements OnInit {

  constructor(
    public activeModal: NgbActiveModal,
    private router: Router,
    @Inject(DOCUMENT) private document: any
  ) {
  }

  ngOnInit() {
  }

  fecharModal(): void {
    this.goToUrl();
    this.fechar();
  }

  fechar(): void {
    this.activeModal.close();
  }

  goToUrl(): void {
    window.open(`http://${environment.urlSiteAtual}`, '_self')
  }

  cadastro(objBotao: string): void {
    switch (objBotao) {
      case 'cidadao':
        this.router.navigate(['/adesao/colaborador']);
        this.fechar();
        break;
      case 'empreendedor':
        this.router.navigate(['/adesao/planos']);
        this.fechar();
        break;
      case 'investidor':
        this.router.navigate(['/adesao/investidor']);
        this.fechar();
        break;
    }
  }

  validarDot(event: any) {
// console.log('Bateu aqui!')
    const doc = document.querySelector<any>('#profiles');
    let tOLeft = doc?.offsetLeft;

    // if (tOLeft > 5) {

    // }
  }
}
