import { ModalLoginComponent } from '@modules/login/components/modal-login/modal-login.component';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AcaoFiltrosEnum } from '@core/models/enum/acao-filtro';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from '@core/auth/authentication.service';

@Component({
  selector: 'app-modal-seguir',
  templateUrl: './modal-seguir.component.html',
  styleUrls: ['./modal-seguir.component.scss']
})
export class ModalSeguirComponent implements OnInit {

  acaoFiltroChamadaEnum = AcaoFiltrosEnum;
  idChamada: any;
  papelAtivo: any;
  titulo!: string;
  descricao!: string;

  constructor(
    public activeModal: NgbActiveModal,
    private router: Router,
    private modalService: NgbModal,
    private authenticationService: AuthenticationService,
  ) { }

  ngOnInit() {
  }

  acessar(acao: any ){
    switch (acao) {
      case 'inscrever':
        this.activeModal.close();
        this.authenticationService.logout();
        this.router.navigate([`/`]);
        break;
      case 'login':
        this.fechar();
        this.authenticationService.logout();
        this.modalLogin();
        break;   
    }
  }

  fechar():void{
    this.activeModal.close();
  }

  modalLogin() {
    let modalLogin = this.modalService.open(ModalLoginComponent, {
      centered: true,
      beforeDismiss: () => false,
      size: 'md'
    });
  }
}
