import { query } from '@angular/animations';
import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenticationService } from '@core/auth/authentication.service';
import { UserAuth } from '@core/auth/user';
import { PapelContaEnum } from '@core/models/enum/papel-conta';
import { Paginator } from '@core/models/paginator';
import { ApiService } from '@core/services/api.service';
import { environment } from '@env/environment';

const routes = {
  buscarTodos: `/pessoas/listar`,
  buscarTodosPaginado: (query: string) => `/pessoas/listar${query}`,
  filtrar: (query: string) => `/pessoas/filtrar${query}`,
  buscarPorId: (id: number) => `/pessoas/${id}`,
  bloquearPessoa: (idPessoa: number) => `/pessoas/bloquear/${idPessoa}`,
  inativarPessoa: `/pessoas/inativar`,
  desbloquearPessoa: (idPessoa: number) => `/pessoas/desbloquear/${idPessoa}`,
  buscarTodosList: `/pessoas/buscar`,
}

@Injectable({
  providedIn: 'root'
})
export class PessoaService {

  constructor(
    private apiService: ApiService,
    private authenticationService: AuthenticationService,
    ) { }

  buscarTodos() {
    return this.apiService.get<any>(routes.buscarTodos);
  }

  buscarTodosPaginado(paginator: Paginator) {
    return this.apiService.post<any>(routes.buscarTodosPaginado(paginator.query), null);
  }

  filtrar(filtro: any, paginator: Paginator) {
    return this.apiService.post<any>(routes.filtrar(paginator.query), filtro);
  }

  filtrarPessoa(obj: any) {
    return this.apiService.post<any>(`${routes.buscarTodosList}`, obj);
  }

  buscarPorId(id: number) {
    return this.apiService.get<any>(routes.buscarPorId(id));
  }

  bloquearPessoa(idPessoa: number){
    return this.apiService.put<any>(routes.bloquearPessoa(idPessoa));
  }

  inativarPessoa(obj: any){
    return this.apiService.put<any>(routes.inativarPessoa, obj);
  }

  desbloquearPessoa(idPessoa: number){
    return this.apiService.put<any>(routes.desbloquearPessoa(idPessoa));
  }

  filtrarPessoas(obj: any, page:number,pageSize:number) {
    let params = new HttpParams();
    params = params.append('size',pageSize);
    params = params.append('page',page - 1);
    return this.apiService.post<any>(`${routes.buscarTodos}`, obj,undefined,undefined,params);
  }
}
