<div class="container">
  <div class="modal-header">
    <h4 class="modal-title"></h4>
    <button type="button" class="close btn-close" aria-label="Close" (click)="fechar()"></button>
  </div>
  <div class="modal-body">
    <h4 class="text-center mb-3 txt-support-color ">
      {{titulo}}
    </h4>
  
    <h6 class="col-10 fw-400 text-center text-muted h6 mx-auto pt-1 mb-5">
      {{'Para_seguir' | translate}} {{descricao}}{{'__você_precisa_primeiro_se_cadastrar__Clique_no_botão_de_login_se_você_já_possui_o_cadastro_ou_crie_sua_conta_clicando_abaixo' | translate}}
    </h6>
  
    <div class="col-8 mx-auto mb-4 pb-2">
      <div class="col-12 mt-4 d-flex align-items-center justify-content-center">
        <button type="button" class="btn btn-primary-color py-3 px-5 border-brada w-100" (click)="acessar('login')"> 
          {{'Login' | translate}}
        </button>
      </div>
    
      <div class="col-12 mt-4 d-flex align-items-center justify-content-center">
        <button type="button" class="btn btn-secundary-color py-3 px-5 border-brada w-100" (click)="acessar('inscrever')"> 
          {{'Criar_uma_Conta' | translate}}
        </button>
      </div>
    </div>
    
  </div>
</div>