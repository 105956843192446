<div class="bg-solucoes-overlay">
  <div id="solucoes" class="col-12 py-5">
    <div class="container-fluid">
  
      <div class="container mt-5 pt-md-5 mx-auto">
        <h3 class="h1 fw-400">
          {{'Soluções' | translate}}
          <span class="fw-600">{{'Personalizadas' | translate}}</span>
          {{'para' | translate}} <br>
          <span class="fw-600">{{'investidores__empreendedores' | translate}}</span>
          {{'e' | translate}} <br>
          <span class="fw-600">{{'colaboradores.' | translate}}</span>
        </h3>
      </div>
  
      <div class="col-12">
        <div class="row gx-5 d-flex justify-content-md-end align-items-md-start">
          
          <div class="menu-clique col-12 col-md-5 pt-5">
            <div class="col-12 d-flex justify-content-end">
              <div class="col-7 me-4">
                <div class="col-12 my-3 py-2 ps-3">
                  <div class="col-6 col-sm-4">
                    <a [ngClass]="{'title-line': classe1 == true}" [class.active]="classe1" (click)="BTN1()" class="h3 fw-400 text-decoration-none b1">
                      <span class="h4">{{'Para' | translate}}</span> <br />
                      <span class="fw-600">{{'Investidor' | translate}}</span>
                    </a>
                  </div>
                </div>
                
                <div class="col-12 my-3 py-2 ps-3">
                  <div class="col-8 col-sm-6">
                    <a [ngClass]="{'title-line': classe2 == true}" [class.active]="classe2" (click)="BTN2()" class="h3 fw-400 text-decoration-none b2">
                      <span class="h4">{{'Para' | translate}}</span> <br />
                      <span class="fw-600">{{'Empreendedor' | translate}}</span>
                    </a>
                  </div>
                </div>
        
                <div class="col-12 my-3 py-2 ps-3">
                  <div class="col-8 col-sm-6">
                    <a [ngClass]="{'title-line': classe3 == true}" [class.active]="classe3" (click)="BTN3()" class="h3 fw-400 text-decoration-none b3">
                      <span class="h4">{{'Para' | translate}}</span> <br />
                      <span class="fw-600">{{'Colaborador' | translate}}</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
    
          <div class="col-12 col-md-7 d-flex justify-content-center justify-content-md-end align-items-md-start mt-4 mb-3 my-md-0">
            
            <div class="row g-5 scrolling-wrapper-flexbox d-md-none">
              <div class="box col-11 d-flex justify-content-center justify-content-md-end align-items-md-start ms-2" id="pc1">
                <div [ngClass]="{'d-none': classe1 == false}" class="col-12 col-md-11">
                  
                  <div class="col-6 col-md-8 titulo h3 fw-400 d-md-none mb-4 ps-3 pt-3" [ngClass]="{'title-line': classe1 == true}">
                    <span class="h4">{{'Para' | translate}}</span> <br />
                    <span class="fw-600">{{'Investidor' | translate}}</span>
                  </div>
    
                  <div class="position-relative">                  
                    <img class="card-img" src="/assets/image/site-principal/logo-investidor-brada.png" alt="logo investidor brada" loading="lazy" /> 
                    
                    <div class="card-img pc position-absolute top-50 start-50 translate-middle">
                      <img class="card-img computador position-absolute top-50 start-50 translate-middle" src="/assets/image/site-principal/computador-brada.png" alt="logo investidor brada" loading="lazy" />
                      
                      <div class="card-img position-absolute top-50 start-50 translate-middle">
                        <div class="col-8 col-md-7 mx-auto img-dash-mobile">
                          <div class="col-12 d-flex justify-content-center justify-content-md-end">
                            <img class="conteudo border-brada" width="90%" height="260" src="/assets/image/site-principal/dash-investidor.webp" />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-10 my-2 mx-auto d-flex justify-content-center justify-content-md-end text-img-dash">
                      <p class="fw-400 text-sm-end mt-3 h6 col-12 col-md-11">
                        {{'texto_solucoes_investidor_site' | translate}}
                      </p>   
                    </div>
    
                  </div>              
                </div>
              </div>
              
              <div class="box col-11 d-flex justify-content-center justify-content-md-end align-items-md-start mx-3" id="pc2">
                <div [ngClass]="{'d-none': classe1 == false}" class="col-12 col-md-11">
                  
                  <div class="col-6 col-md-8 titulo h3 fw-400 d-md-none mb-4 ps-3 pt-3" [ngClass]="{'title-line': classe1 == true}">
                    <span class="h4">{{'Para' | translate}}</span> <br />
                    <span class="fw-600">{{'Empreendedor' | translate}}</span>
                  </div>
    
                  <div class="position-relative">                  
                    <img class="card-img" src="/assets/image/site-principal/logo-empreendedor-brada.png" alt="logo empreendedor brada" loading="lazy" /> 
                    
                    <div class="card-img pc2 position-absolute top-50 start-50 translate-middle">
                      <img class="card-img computador position-absolute top-50 start-50 translate-middle" src="/assets/image/site-principal/computador-brada.png" alt="logo empreendedor brada" loading="lazy" />
                      
                      <div class="card-img position-absolute top-50 start-50 translate-middle">
                        <div class="col-8 col-md-7 mx-auto img-dash-mobile">
                          <div class="col-12 d-flex justify-content-center justify-content-md-end">
                            <img class="conteudo border-brada" width="90%" height="260" src="/assets/image/site-principal/dash-empreendedor.webp" />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-10 my-2 mx-auto d-flex justify-content-center justify-content-md-end text-img-dash">
                      <p class="fw-400 text-sm-end mt-3 h6 col-12 col-md-11">
                        {{'texto_solucoes_empreendedor_site' | translate}}
                      </p>   
                    </div>
    
                  </div>              
                </div>
              </div>
              
              <div class="box col-11 d-flex justify-content-center justify-content-md-end align-items-md-start me-2" id="pc3">
                <div [ngClass]="{'d-none': classe1 == false}" class="col-12 col-md-11">
                  
                  <div class="col-6 col-md-8 titulo h3 fw-400 d-md-none mb-4 ps-3 pt-3" [ngClass]="{'title-line': classe1 == true}">
                    <span class="h4">{{'Para' | translate}}</span> <br />
                    <span class="fw-600">{{'Colaborador' | translate}}</span>
                  </div>
    
                  <div class="position-relative">                  
                    <img class="card-img" src="/assets/image/site-principal/logo-colaborador-brada.png" alt="logo colaborador brada" loading="lazy" /> 
                    
                    <div class="card-img pc3 position-absolute top-50 start-50 translate-middle">
                      <img class="card-img computador position-absolute top-50 start-50 translate-middle" src="/assets/image/site-principal/computador-brada.png" alt="logo colaborador brada" loading="lazy" />
                      
                      <div class="card-img position-absolute top-50 start-50 translate-middle">
                        <div class="col-8 col-md-7 mx-auto img-dash-mobile">
                          <div class="col-12 d-flex justify-content-center justify-content-md-end">
                            <img class="conteudo border-brada" width="90%" height="260" src="/assets/image/site-principal/dash-colaborador.webp" />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-10 my-2 mx-auto d-flex justify-content-center justify-content-md-end text-img-dash">
                      <p class="fw-400 text-sm-end mt-3 h6 col-12 col-md-11">
                        {{'texto_solucoes_colaborador_site' | translate}}
                      </p>   
                    </div>
    
                  </div>              
                </div>
              </div>
            </div>

            <div class="col-10 d-none d-md-block d-flex justify-content-end align-items-start">
              <div [ngClass]="{'d-none': classe1 == false}" class="col-12">  
                <div class="position-relative">                  
                  <img class="card-img" src="/assets/image/site-principal/logo-investidor-brada.png" alt="logo investidor brada" loading="lazy" /> 
                  
                  <img class="card-img computador position-absolute top-50 start-50 translate-middle" src="/assets/image/site-principal/computador-brada.png" alt="logo investidor brada" loading="lazy" />
                  
                  <div class="card-img position-absolute top-50 start-50 translate-middle">
                    <div class="col-8 col-md-7 mx-auto img-dash-mobile">
                      <div class="col-12 d-flex justify-content-center justify-content-md-end">
                        <!-- <iframe width="90%" height="260" class="conteudo" src="https://www.youtube.com/embed/-F9HYWIkUSY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->
                        <img class="conteudo border-brada" width="90%" height="230" src="/assets/image/site-principal/dash-investidor.webp" />
                      </div>
                      <div class="col-12 d-flex justify-content-center justify-content-md-end text-img-dash positon-relative">
                        <p class="fw-500 text-sm-end mt-3 h6 col-12 col-md-11">
                          {{'texto_solucoes_investidor_site' | translate}}
                        </p>   
                      </div>
                    </div>
                  </div>
  
                </div>              
              </div>
              
              <div [ngClass]="{'d-none': classe2 == false}" class="col-12">
                <div class="position-relative">
                  <img class="card-img" src="/assets/image/site-principal/logo-empreendedor-brada.png" alt="logo investidor brada" loading="lazy" /> 
                  <img class="card-img computador position-absolute top-50 start-50 translate-middle" src="/assets/image/site-principal/computador-brada.png" alt="logo empreendedor brada" loading="lazy" />
                  <div class="card-img position-absolute top-50 start-50 translate-middle">
                    <div class="col-7 mx-auto">
                      <div class="col-12 d-flex justify-content-end">
                        <!-- <iframe width="90%" height="260" class="conteudo" src="https://www.youtube.com/embed/Qvy4CxImM-Q" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; 
                          encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->
                          <img class="conteudo border-brada" width="90%" height="230" src="/assets/image/site-principal/dash-empreendedor.webp" />
                      </div>
                      <div class="col-12 d-flex justify-content-end">
                        <p class="fw-500 text-sm-end mt-3 h6">
                          {{'texto_solucoes_empreendedor_site' | translate}}
                        </p>  
                      </div>
                    </div>
                  </div>
                </div>              
              </div>
              
              <div [ngClass]="{'d-none': classe3 == false}" class="col-12">
                <div class="position-relative">
                  <img class="card-img" src="/assets/image/site-principal/logo-colaborador-brada.png" alt="logo investidor brada" loading="lazy" /> 
                  <img class="card-img computador position-absolute top-50 start-50 translate-middle" src="/assets/image/site-principal/computador-brada.png" alt="logo colaborador brada" loading="lazy" />
                  <div class="card-img position-absolute top-50 start-50 translate-middle">
                    <div class="col-7 mx-auto">
                      <div class="col-12 d-flex justify-content-end">
                        <!-- <iframe width="90%" height="260" class="conteudo" src="https://www.youtube.com/embed/-F9HYWIkUSY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->
                        <img class="conteudo border-brada" width="90%" height="230" src="/assets/image/site-principal/dash-colaborador.webp" />
                      </div>
                      <div class="col-12 d-flex justify-content-end">
                        <p class="fw-500 text-sm-end mt-3 h6"> 
                          {{'texto_solucoes_colaborador_site' | translate}}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>              
              </div>
            </div>

          </div>
        </div>  
      </div>
    </div>
  
  </div>
</div>
