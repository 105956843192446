<div class="container-fluid bg-body-color m-0 p-0" id="topo">

  <ng-cotainer [ngSwitch]="siteOuLandingPage">

    <div *ngSwitchCase="siteOuLandingPageEnum.Hmg">
      <div class="bg-site"></div>
    </div>

    <div *ngSwitchCase="siteOuLandingPageEnum.Site">
      <header class="position-fixed col-12 mx-auto" [ngClass]="{'menu-scroll': windowScrolled}">
        <app-header-site></app-header-site>
      </header>

      <article>
        <app-banner-principal></app-banner-principal>
        <app-quem-somos-site></app-quem-somos-site>
        <app-missao-site></app-missao-site>

        <div class="bg-efeitos-padrao">
          <app-solucoes-personalizadas></app-solucoes-personalizadas>
        </div>

        <div class="bg-efeitos-padrao">
          <app-oportunidades-site></app-oportunidades-site>
        </div>

        <app-faca-parte-site></app-faca-parte-site>
      </article>

      <a type="button" class="btn arraste-cima btn-primary-color border-brada position-fixed bottom-0 end-0 me-md-5 mb-md-5 me-3 mb-3" href="#" pageScroll *ngIf="windowScrolled">
        <i class="bi bi-arrow-up-square fs-4"></i>
      </a>
    </div>

    <div *ngSwitchCase="siteOuLandingPageEnum.LandingPageChamada">
      <app-landingpage-chamada [dadosLandingPage]="dadosLandingPage"></app-landingpage-chamada>
    </div>
    <div *ngSwitchCase="siteOuLandingPageEnum.LandingPageProjeto">
      <app-landingpage-projetos [dadosLandingPage]="dadosLandingPage"></app-landingpage-projetos>
    </div>
    <div *ngSwitchCase="siteOuLandingPageEnum.LandingPageVotacao">
      <app-landingpage-votacao-popular [idVotacao]="idVotacao" [dadosLandingPage]="dadosLandingPage"></app-landingpage-votacao-popular>
    </div>
  </ng-cotainer>
</div>
