import { ModalLoginComponent } from '@modules/login/components/modal-login/modal-login.component';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RouteAction } from '@core/action/action';
import { AuthenticationService } from '@core/auth/authentication.service';
import { AreaInteresse } from '@core/models/chamada';
import { TipoPessoa } from '@core/models/dominio';
import { AcaoFiltrosEnum } from '@core/models/enum/acao-filtro';
import { PapelContaEnum } from '@core/models/enum/papel-conta';
import { NomeStatusChamadaEnum, StatusChamadasEnum } from '@core/models/enum/status-chamadas.enum';
import { ChamadaService } from '@core/services/chamada.service';
import { DominioService } from '@core/services/dominio.service';
import { NotificationService } from '@core/services/notification.service';
import { ModalSelectComponent } from '@modules/chamadas/components/modalSelect/modalSelect';
import { ModalMotivoComponent } from '@modules/chamadas/components/modalMotivo/modalMotivo.component';
import { NgbModal, NgbPaginationConfig } from '@ng-bootstrap/ng-bootstrap';
import { first } from 'rxjs/operators';
import { ChamadaPublicoService } from '@core/services/chamada-publico.service';
import { DOCUMENT } from '@angular/common';
import { ModalInscreverComponent } from '../../components/modal-inscrever/modal-inscrever.component';
import { TipoFotoEnum } from '@core/models/enum/tipo-foto';
import { DomSanitizer } from '@angular/platform-browser';
import { ArquivoService } from '@core/services/arquivo.service';
import { RelacaoDeSeguidosService } from '@core/services/relacao-de-seguidos.service';
import { ModalSeguirComponent } from '../../components/modal-seguir/modal-seguir.component';
import { ModalEscolherFichaInscricaoComponent } from '@modules/chamadas/components/modal-escolher-ficha-inscricao/modal-escolher-ficha-inscricao.component';
import { InscreverSeService } from '@core/services/inscrever-se.service';
import { ModalJustificativaComponent } from '@modules/chamadas-bloqueadas/components/modal-justificativa/modal-justificativa.component';

@Component({
  selector: 'app-filtro-de-chamadas-publicas',
  templateUrl: './filtro-de-chamadas-publicas.component.html',
  styleUrls: ['./filtro-de-chamadas-publicas.component.scss']
})
export class FiltroDeChamadasPublicasComponent implements OnInit {

  namePage = "Todas as Chamadas";

  id!: number;
  acao!: RouteAction;
  criar!: boolean;
  editar!: boolean;
  visualizar!: boolean;

  page = 1;
  pageSize = 5;
  lastPage = 1;
  listaChamadas: any[] = []; // criarModel
  tabelaListaChamadas: any[] = [];
  collectionSize: any;

  usuarioLogado: any;
  papelAtivo: any;
  idContaAtiva: any;
  usuarioLogadoContas: any[] = [];
  idUsuarioLogado: any;
  message!: string;
  isLoading: boolean = false;
  success: boolean = false;
  exibirErroVista: boolean = false;
  objEnvio: any;
  objEnvioFiltroChamadas: any;
  fill: boolean = false;

  statusEdital: any[] = [];
  fonteFinanciamento: any[] = [];
  tipoEdital: any[] = [];
  tipoPessoa: any[] = [];
  tipoFoto: any[] = [];
  fotos: any[] = [];
  tipoPessoas: Array<TipoPessoa> = [];
  publicos: any[] = [];
  ods: any[] = [];
  areasDeInteresse: Array<AreaInteresse> = [];
  estados: any[] = [];
  ufs: any;
  municipios: any[] = [];
  tipoDocumento: any[] = [];
  midias: any[] = [];
  areaDeInteresseEdital: any[] = [];
  acaoFiltroChamadaEnum = AcaoFiltrosEnum;
  statusChamadas = StatusChamadasEnum;
  nomeStatusChamadas = NomeStatusChamadaEnum;
  papelContaEnum = PapelContaEnum;
  areasInteresse: any;
  tipoFotoEnum = TipoFotoEnum;
  imagemChamada!: string|any;
  filtroPaginas: any[]=[];
  btSeguir: boolean = true;
  btDeixarSeguir: boolean = true;
  listaFichasInscricao: any[]=[];

  teste: any;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private notificationService: NotificationService,
    private chamadaService: ChamadaService,
    private dominioService: DominioService,
    private authenticationService: AuthenticationService,
    private modalService: NgbModal,
    private activatedRoute: ActivatedRoute,
    private chamadaPublicoService: ChamadaPublicoService,
    private relacaoDeSeguidosService: RelacaoDeSeguidosService,
    @Inject(DOCUMENT) private document: any,
    private arquivoService: ArquivoService,
    private sanitizer: DomSanitizer,
    private config: NgbPaginationConfig,
    private inscreverSeService: InscreverSeService
  ) {
    config.size = 'sm';
    config.boundaryLinks = true;
  }

  formFiltroChamadas = this.formBuilder.group({
    nomeOuDescricao: [],
    idPatrocinador: [],
    estadoId: [],
    municipioId: [],
    areaInteresse: [],
    fonteFinanciamento: [],
    ods: [],
    tipoEdital: [],
    statusEdital: [],
    publico: [],
    ordenacao: [],
  });

  ngOnInit() {
    window.scrollTo({ top: 0, behavior: 'smooth' });

    this.userlogged();
    this.buscarDadosEtapas();

    this.pesquisar();
  }

  goToUrl(url: any): void {
    this.document.location.href = url;
  }

  inscrever(idChamada: any){
    if(!this.usuarioLogado || this.papelAtivo != this.papelContaEnum.Empreendedor){
      this.openModal(idChamada);
    }
    if(this.papelAtivo == this.papelContaEnum.Empreendedor){

      this.inscreverSeService.buscarFichaInscricaoPorEdital(idChamada).subscribe((data: any) => {
        this.listaFichasInscricao = data;

        if(data.length)
          this.openModalEscolherFicha(idChamada);
        else
          this.router.navigate([`/chamadas/inscrever-se/inserir/${idChamada}`]);
      });
    }
  }

  visualizarLandingPage(idChamada: any){
    window.open(`${window.location.origin}/#/landingpage/chamada/visualizar/${idChamada}`, '_blank')
  }

  openModal(idChamada: any) {
    let modalInscricao = this.modalService.open(ModalInscreverComponent, {
      centered: true,
      beforeDismiss: () => false,
      size: 'md'
    });
    modalInscricao.componentInstance.idChamada = idChamada;
    modalInscricao.componentInstance.papelAtivo = this.papelAtivo;
  }

  openModalEscolherFicha(idChamada: any) {
    let modalEscolherFicha = this.modalService.open(ModalEscolherFichaInscricaoComponent, {
      centered: true,
      beforeDismiss: () => false,
      size: 'lg'
    });
    modalEscolherFicha.componentInstance.idChamada = idChamada;
    modalEscolherFicha.componentInstance.papelAtivo = this.papelAtivo;
    modalEscolherFicha.componentInstance.listaFichasInscricao = this.listaFichasInscricao;
  }

  voltar(): void {
    this.router.navigate(['/']);
  }

  voltarDashboard(): void {
    this.router.navigate(['/dashboard']);
  }

  listarChamadas(): void {
    if (this.formFiltroChamadas.valid) {
      this.page = 1;
      this.pesquisar();
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    this.fill == false
  }

  trocarSize(): void {
    this.page = 1;
    this.pesquisar(undefined);
  }

  pesquisar(lastPage?: number): void {
    if (lastPage && lastPage == this.page)
      return;

    this.lastPage = this.page;
    let objEnvio = { ...this.formFiltroChamadas.getRawValue() };

    if(this.estado.value)
      objEnvio.estadoId = this.estado.value.id

    if(this.municipio.value)
      objEnvio.municipioId = this.municipio.value.id

    this.chamadaPublicoService.listarEditais(objEnvio, this.page, this.pageSize).subscribe((data: any) => {
      this.listaChamadas = this.removeDuplicateList(data.content);
      this.collectionSize = data.totalElements;
    });
  }

  fotosChamada(item: any): any {
    if(item){
      if(item.base64Content && item.base64info){
        let img = item.base64info + item.base64Content;
        if(img){
          return this.sanitizer.bypassSecurityTrustUrl(img);
        }
      }
    }
    return 'https://images.unsplash.com/photo-1485217988980-11786ced9454?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80';
  }

  reset() {
    this.formFiltroChamadas.reset();

    this.fill == false
  }

  irParaLandingPage(idChamada: any, acao: AcaoFiltrosEnum) {
    window.open(`${window.location.origin}/#/landingpage/chamada/${acao}/${idChamada}`, '_blank')
  }

  visualizarChamada(idChamada: any, acao: AcaoFiltrosEnum) {
    this.router.navigate([`/chamadas/${acao}/${idChamada}`]);
  }

  Filtro() {
    if (this.fill == false) {
      this.fill = true
// console.log(this.fill)
    } else if (this.fill == true) {
      this.fill = false
// console.log(this.fill)
    }
    return console.log(this.fill)
  }

  buscarDadosEtapas(): void {
    this.buscarStatusEdital();
    this.buscarTipoEdital();
    this.buscarPublicoAlvo();
    this.buscarOds();
    this.buscarAreaInteresse();
    this.buscarEstado();
    this.buscarFonteFinanciamento();
    this.buscarfiltroPaginas();
  }

  buscarTipoEdital(): void {
    this.dominioService.buscarTipoEdital().subscribe((data: any) => {
      this.tipoEdital = data;
      this.tipoEdital = this.tipoEdital.filter(x => x.ativo == true);
      this.tipoEdital = this.tipoEdital.filter(x => x.id != 3);
    });
  }

  buscarEstado(): void {
    this.dominioService.buscarEstado().subscribe((data: any) => {
      this.estados = data;
    });
  }

  buscarMunicipio(idEstado: number): void {
    if(idEstado){
      this.formFiltroChamadas.controls.municipioId.setValue(null);
      this.municipios = [];
      this.ufs = { "estados": [idEstado] }
      this.dominioService.buscarMunicipioEstados(this.ufs).subscribe((data: any) => {
        this.municipios = data;
      });
    }
    else
      this.formFiltroChamadas.controls.municipioId.setValue(null);
      this.municipios = [];
  }

  buscarAreaInteresse(): void {
    this.dominioService.buscarAreaInteresse().subscribe((data: any) => {
      this.areasDeInteresse = data;
      this.areasDeInteresse = this.areasDeInteresse.filter(x => x.ativo == true);
    });
  }

  buscarOds(): void {
    this.dominioService.buscarOdsFiltros().subscribe((data: any) => {
      this.ods = data;
    });
  }

  buscarPublicoAlvo(): void {
    this.dominioService.buscarPublicoAlvo().subscribe((data: any) => {
      this.publicos = data;
      this.publicos = this.publicos.filter(x => x.ativo == true);
    });
  }

  buscarStatusEdital(): void {
    this.dominioService.buscarStatusEdital().subscribe((data: any) => {
      this.statusEdital = data;
    });
  }

  buscarFonteFinanciamento(): void {
    this.dominioService.buscarFonteFinanciamento().subscribe((data: any) => {
      this.fonteFinanciamento = data;
    });
  }

  buscarfiltroPaginas(): void {
    this.dominioService.filtroPaginas().subscribe((data: any) => {
      this.filtroPaginas = data;
    });
  }

  btSeguirEdital(item: any): void {
    if(this.usuarioLogado)
      this.relacaoDeSeguidosService.seguirEdital(item.id).subscribe((data: any) => {
        this.ocultarSeguir(item);

        let mensagem = "Seguindo a chamada!"
        this.notificationService.showSuccess(mensagem);
      });

    else if(!this.usuarioLogado){
      this.openModalSeguir()
    }

  }

  btDeixarDeSeguirEdital(item: any): void {
    if(this.usuarioLogado)
      this.relacaoDeSeguidosService.deixarDeSeguirEdital(item.id).subscribe((data: any) => {
        this.ocultarDeixarDeSeguir(item);

        let mensagem = "Deixou de seguir a chamada!"
        this.notificationService.showSuccess(mensagem);
      });
      location.reload();
  }

  ocultarSeguir(item: any): void {
    item.selectSeguir = !item.selectSeguir;
    item.selectDeixar = item.selectDeixar;
  }

  ocultarDeixarDeSeguir(item: any): void {
    item.selectDeixar = !item.selectDeixar;
    item.selectSeguir = item.selectSeguir;
  }

  openModalSeguir() {
    let modalSeguir = this.modalService.open(ModalSeguirComponent, {
      centered: true,
      beforeDismiss: () => false,
      size: 'md'
    });
    modalSeguir.componentInstance.titulo = "Seguir Chamada";
    modalSeguir.componentInstance.descricao = "esta chamada";
    //modalSeguir.componentInstance.papelAtivo = this.papelAtivo;
  }

  bloquearEdital(dataForm: any){
    if(dataForm){
      this.chamadaService.bloquearChamadas(dataForm).subscribe((data: any) => {
        this.notificationService.showSuccess('Chamada bloqueada com sucesso!');
        this.pesquisar();
      })
    }
  }

  openModalBloquear(id: any){
    let modalBloquear = this.modalService.open(ModalJustificativaComponent, {
      centered: true,
      beforeDismiss: () => false,
      size: 'md'
    });
    modalBloquear.componentInstance.tituloDescricao = AcaoFiltrosEnum.Bloquear;
    modalBloquear.result.then((data) => {
      let objData = data;
      objData = objData?.justificativa;
      if (data) {
        let objEnvio = {
          idEdital: id,
          justificativa: objData
        }
// console.log('objEnvio', objEnvio)
        this.bloquearEdital(objEnvio);
      }
    })
  }

  removeDuplicateList(array: any) {
    array.forEach((x:any) => {
      if (x.areaInteresse) {
        let newArray: any[]=[];
        x.areaInteresse.forEach((a:any) => {
          if (!newArray.find((i:any) => i == a)) {
            newArray.push(a)
          }
        })
        x.areaInteresse = newArray;
      }
    })
    return array
  }

  showError(mensagem: any) {
    this.notificationService.showError(mensagem);
  }

  showSuccess(mensagem: any) {
    this.notificationService.showSuccess(mensagem);
  }

  get estado(): FormGroup {
    return <FormGroup>this.formFiltroChamadas.controls.estadoId;
  }

  get municipio(): FormGroup {
    return <FormGroup>this.formFiltroChamadas.controls.municipioId;
  }

  userlogged(): void {
    if(this.authenticationService.currentUserValue){
      this.usuarioLogado = this.authenticationService.currentUserValue;
      this.papelAtivo = this.usuarioLogado.papelAtivo;
      this.idContaAtiva = this.usuarioLogado.idContaAtiva;
    }
  }

}
