export enum AcaoFiltrosEnum {
    Default = 'default',
    Sair = 'logout',
    Cadastrar = 'cadastrar',
    Remover = 'remover',
    Inserir = 'inserir',
    Reativar = 'reativar',
    Inativar = 'inativar',
    Editar = 'editar',
    Salvar = 'salvar',
    Rascunho = 'rascunho',
    Responder = 'responder',
    ConvidarEmpreededor = 'convidarEmpreededor',
    VincularParecerista = 'vincularParecerista',
    Visualizar = 'visualizar',
    Bloquear = 'bloquear',
    Desbloquear = 'desbloquear',
    DesbloquearUsuarioProduto = 'desbloquearUsuarioProduto',
    ReenviarLinkAtivacao = 'reenviarLinkAtivacao',
    LandingPage = 'landingPage',
    CadastrarFichaInscricao = 'cadastrarFichaInscricao',
    MudarOrdemExibicao = 'mudarOrdemExibicao',
    Desqualificar = 'desqualificar',
    Pendencia = 'pendencia',
    Parecer = 'parecer',
    Aprovar = 'aprovar',
    Notificacao = 'notificacao',
    AguardarFaseCaptacao = 'aguardarFaseCaptacao'
}