import { Router } from '@angular/router';
import { ModalLoginComponent } from '@modules/login/components/modal-login/modal-login.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, Inject, HostListener, OnDestroy } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { PageScrollService } from 'ngx-page-scroll-core';
import { AuthenticationService } from '@core/auth/authentication.service';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Notificacao, NotificacaoStatusEnum, Notificacoes, QtdNotificacao } from '@core/models/notificacao';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NotificationService } from '@core/services/notification.service';
import { HeaderSharedDataService } from '../../header-shared-data.service';
import { LocalStorageService } from '@core/local-storage/local-storage.service';
import { PapelContaEnum } from '@core/models/enum/papel-conta';
import { ArquivoService } from '@core/services/arquivo.service';
import { DomSanitizer } from '@angular/platform-browser';
import { FotosUsuarioService } from '@core/services/fotos-usuario.service';
import { UsuarioService } from '@core/services/usuario.service'
import { TipoFotoEnum } from '@core/models/enum/tipo-foto';
import { first } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { PerfilEnum } from '@core/models/enum/perfil';
import { Observable } from 'rxjs';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxPermissionsService } from 'ngx-permissions';
import { environment } from '@env/environment';



@Component({
  selector: 'app-header-dashboard',
  templateUrl: './header-dashboard.component.html',
  styleUrls: ['./header-dashboard.component.scss']
})


export class HeaderDashboardComponent implements OnInit, OnDestroy {


  cities = [
    {
      id: 0,
      value: "pt-BR",
      avatar: 'https://static.todamateria.com.br/upload/ba/nd/bandeira-do-brasil-og.jpg',
      lingua: 'PT'
    },
    {
      id: 1,
      value: "en-US",
      avatar: 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/a4/Flag_of_the_United_States.svg/2560px-Flag_of_the_United_States.svg.png',
      lingua: 'EN'
    },
    {
      id: 2,
      value: "es-ES",
      avatar: 'https://upload.wikimedia.org/wikipedia/commons/thumb/9/9a/Flag_of_Spain.svg/2560px-Flag_of_Spain.svg.png',
      lingua: 'ES'
    }
  ];

  selectedCity = this.cities[0];
  windowScrolled!: boolean;
  activeSection = 1;
  notificacoes: Notificacoes[] = [];
  qtdNotificacao!: QtdNotificacao;
  qtdNotificacaoPendente: any;
  notificacaoPendente: any;
  message!: string;
  form = new FormGroup({});
  loadingNotificacoes: boolean = false;
  messageNotification: string = '';
  intervalNotificacoes: any;
  dadosUsuarioLogado: any;
  conta: any;
  nomeEmpresa: string = '';
  nomeUsuario: string = '';
  initials: any;
  nomeUserCompleto: any;
  nomeUsuarioMaior: string = '';
  nomePerfil: string = '';
  nomereduzido: string = '';
  dataAtual!: any;

  dataUser: any;
  usuarioLogado: any;
  idContaAtiva: any;
  idUsuarioLogado: any
  papelAtivo: any;
  usuarioLogadoContas: any[] = [];
  id: any;
  papelSelecionado: any;
  idConta!: number;

  pt = true

  changeText = false;
  changeText2 = false

  classe1 = true
  classe2 = false
  classe3 = false
  classe4 = false
  linguas = 0

  imagemPerfil!: string | any; // logo
  imagemCapa!: string | any;
  imagemPerfilConta!: string | any;
  papelContaEnum = PapelContaEnum;
  perfilEnum = PerfilEnum;

  nomeConta: any;
  formPerfilCidadao!: boolean;
  formPerfilEmpreendedor!: boolean;
  formPerfilPatrocinador!: boolean;
  nomeTrocarPerfil!: string;
  // githubUsers$: Observable<any[]>;
  selectedUsers = ['anjmao'];
  public isMenuCollapsed = true;

  constructor(
    private translateService: TranslateService,
    private notificationService: NotificationService,
    private authenticationService: AuthenticationService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    public router: Router,
    private headerSharedDataService: HeaderSharedDataService,
    private localStorageService: LocalStorageService,
    private arquivoService: ArquivoService,
    private sanitizer: DomSanitizer,
    private usuarioService: UsuarioService,
    private fotosUsuarioService: FotosUsuarioService,
    private permissionService: NgxPermissionsService,

  ) { }

  ngOnDestroy(): void {
    if (this.intervalNotificacoes)
      clearInterval(this.intervalNotificacoes);
  }


  ngOnInit() {
    this.userlogged();
    this.trocarPerfil();
    this.buscarImagemPerfilConta(this.idConta);
    this.buscarNomeEmpresa(this.idConta);
    this.buscarFotoUsuario(this.idContaAtiva);
  }


  voltarDashboard(): void {
    this.router.navigate(['/dashboard']);
  }

  buscarImagemPerfilConta(idConta: any) {
    if (idConta) {
// console.log('aqui')
      this.fotosUsuarioService.buscarFotoPerfil(idConta).subscribe((data: any) => {
        if (data.arquivo?.id != null) {
// console.log('data', data)
          this.imagemPerfilConta = data;
          this.arquivoService.getBase64(data.arquivo?.id).subscribe((res: any) => {

            this.imagemPerfilConta = this.sanitizer.bypassSecurityTrustUrl(`data:image/${this.imagemPerfilConta?.extencao};base64,${this.imagemPerfilConta?.base64Content}`);
          })
        } else {
          this.imagemPerfilConta = undefined;
        }

      })
    }
  };

  buscarFotoUsuario(idContaAtiva: any) {
    this.usuarioService.consultarUsuario(idContaAtiva)
      .pipe(first())
      .subscribe((data: any) => {
        if (data.fotos[0]?.base64Content != null) {
          this.imagemPerfil = data.fotos[0];

          if (this.imagemPerfil?.base64Content && this.imagemPerfil?.extencao) {
            this.imagemPerfil = this.sanitizer.bypassSecurityTrustUrl(`data:image/${this.imagemPerfil?.extencao};base64,${this.imagemPerfil?.base64Content}`);
          }
        }
      })
  }

  buscarNomeEmpresa(idConta: any) {
    if (idConta)
      this.usuarioService.buscarDadosUsuario(idConta).pipe(first())
        .subscribe((data: any) => {
          this.nomeEmpresa = (data.pessoa.nomePessoa)
          if (this.nomeEmpresa.length <= 9) {
            this.nomereduzido = this.nomeEmpresa.slice(0);
          } else if (this.nomeEmpresa.length > 9) {
            this.nomereduzido = this.nomeEmpresa.slice(0, 9);
            this.nomereduzido = this.nomereduzido + '...'
          }
        })
  }


  @HostListener("window:scroll", [])
  onWindowScroll() {
    if (window.pageYOffset > 50) {
      this.windowScrolled = true;
    } else {
      this.windowScrolled = true;
    }
  }

  clickActiveLink(i: number) {
    this.activeSection = i;
    if (i === i) {
      return true;
    } else {
      return false;
    }
  }

  logout() {
    this.authenticationService.logout();
  }

  openNotificacoes(): void {
    this.buscarNotificacoes(this.idContaAtiva);
  }

  buscarQuantidadeNotificacoes(idUsuarioLogado: number) {
    this.notificationService.buscarQuantidade(idUsuarioLogado).subscribe((data) => {
      this.qtdNotificacao = data;
    })
  }

  buscarNotificacoes(idUsuarioLogado: number) {
    this.loadingNotificacoes = true;

    this.notificationService.buscarNotificacoes(idUsuarioLogado).subscribe((data: any) => {
      this.notificacoes = data;
      this.qtdNotificacao = data.length;



      this.qtdNotificacaoPendente = (this.notificacoes.filter(x => x.status == NotificacaoStatusEnum.Pendente)).length;
      this.notificacaoPendente = this.notificacoes.filter(x => x.status == NotificacaoStatusEnum.Pendente);

      this.loadingNotificacoes = false;

    }, (error) => {
      this.loadingNotificacoes = true;
    })
  }

  expandirNotificacao(notificacao: Notificacoes, event: any) {
    event.stopPropagation();
    notificacao.expandido = !notificacao.expandido;
    this.notificacoes.filter(x => x.id != notificacao.id).forEach(x => x.expandido = false);
    // if (notificacao.status != NotificacaoStatusEnum.Lida && notificacao.expandido) {
    //   notificacao.status = NotificacaoStatusEnum.Lida;
    //   this.notificationService.marcarComoLida(notificacao.id).subscribe((data) => {

    //   })
    // }
  }

  // id Notificação
  removerNotificacao(notificacao: Notificacoes) {
    this.notificationService.excluir(notificacao.id).subscribe((data) => {
      this.buscarNotificacoes(this.idUsuarioLogado);
    })
  }

  // id Usuario
  removerTodasNotificacoes(event: any) {
    event.stopPropagation();
    this.notificationService.excluirTodos(this.idUsuarioLogado).subscribe((data) => {
      this.buscarNotificacoes(this.idUsuarioLogado);
    })
  }

  // id Notificação
  marcarNotificacaoLida(notificacao: Notificacoes) {
    this.notificationService.marcarComoLida(notificacao.id).subscribe((data) => {
      this.buscarNotificacoes(this.idUsuarioLogado);
    })
  }

  // id Usuario
  marcarTodasNotificacaoLida(event: any) {
    event.stopPropagation();
    this.notificationService.marcarTodasComoLida(this.idUsuarioLogado).subscribe((data) => {
      this.buscarNotificacoes(this.idUsuarioLogado);
    })
  }


  BTN1() {
    if (this.classe2 == true || this.classe3 == true || this.classe4 == true) {
      this.classe2 = false
      this.classe3 = false
      this.classe4 = false
    }
    if (this.classe2 == false || this.classe3 == false || this.classe4 == false) {
      this.classe1 = true
    }
  }

  BTN2() {
    if (this.classe1 == true || this.classe3 == true || this.classe4 == true) {
      this.classe1 = false
      this.classe3 = false
      this.classe4 = false
    }
    if (this.classe1 == false) {
      this.classe2 = true
    }
  }

  BTN3() {
    if (this.classe1 == true || this.classe2 == true || this.classe4 == true) {
      this.classe1 = false
      this.classe2 = false
      this.classe4 = false
    }
    if (this.classe1 == false) {
      this.classe3 = true
    }
  }

  BTN4() {
    if (this.classe1 == true || this.classe3 == true || this.classe2 == true) {
      this.classe1 = false
      this.classe3 = false
      this.classe2 = false
    } if (this.classe1 == false) {
      this.classe4 = true
    }
  }


  openModalLogin(): void {
    const modalColunas = this.modalService.open(ModalLoginComponent, {
      centered: true,
      beforeDismiss: () => false,
      size: 'md',
      modalDialogClass: 'modal-grafico'
    })

    let instance = <ModalLoginComponent>modalColunas.componentInstance;
  }

  acessarDadosUsuario(): void {
    this.router.navigate([`/usuario/editar/${this.idUsuarioLogado}`]);
  }

  acessarFaq() {
    this.router.navigate([`/faq`]);
  }

  trocarSenha(): void {
    this.router.navigate([`usuario/trocar-senha`]);
  }

  trocarPerfil() {
    let contasAtivas = this.usuarioLogado.contas.map((x: any) => x).map((x: { papelPessoa: { nome: any; }; }) => x.papelPessoa?.nome);

    contasAtivas.forEach((contasAtivas: any) => {
      switch (contasAtivas.toUpperCase()) {
        case PapelContaEnum.Cidadao:
          if (this.papelAtivo == PapelContaEnum.Cidadao)
            this.formPerfilCidadao = false;
          else
            this.nomeTrocarPerfil = NomeTrocarPerfilEnum.Cidadao;
          break;
        case PapelContaEnum.Empreendedor:
          if (this.papelAtivo == PapelContaEnum.Empreendedor)
            this.formPerfilEmpreendedor = false;
          else
            this.nomeTrocarPerfil = NomeTrocarPerfilEnum.Empreendedor;
          break;
        case PapelContaEnum.Patrocinador:

          if (this.papelAtivo == PapelContaEnum.Patrocinador)
            this.formPerfilPatrocinador = false;
          else
            this.nomeTrocarPerfil = NomeTrocarPerfilEnum.Patrocinador;
          break;
      }
    });
  }

  selectPerfil(papelContaEnum: any) {
    this.dataUser = this.authenticationService.currentUserValue;

    switch (papelContaEnum) {
      case NomeTrocarPerfilEnum.Cidadao:
        this.dataUser.papelAtivo = "Cidadão";
        this.setValidarDadosPermissaoUsuario(this.dataUser.papelAtivo);
        break;
      case NomeTrocarPerfilEnum.Empreendedor:
        this.dataUser.papelAtivo = PapelContaEnum.Empreendedor;
        this.setValidarDadosPermissaoUsuario(this.dataUser.papelAtivo);
        break;
      case NomeTrocarPerfilEnum.Patrocinador:
        this.dataUser.papelAtivo = PapelContaEnum.Patrocinador;
        this.setValidarDadosPermissaoUsuario(this.dataUser.papelAtivo);
        break;
    }
  }

  setValidarDadosPermissaoUsuario(papelContaEnum: PapelContaEnum): void {
    this.dataUser.papelAtivo = papelContaEnum;

    this.dataUser.idContaAtiva = ((this.dataUser.contas.filter((x: any) => x.papelPessoa.nome == this.capitalize(this.dataUser.papelAtivo))).map((x: { id: any; }) => x.id)).toString();

    this.dataUser.codigoProdutosContratados = ((this.dataUser.contas.filter((x: any) => x.papelPessoa.nome == this.capitalize(this.dataUser.papelAtivo))).map((x: { codigoProdutosContratados: any; }) => x.codigoProdutosContratados))[0];

    this.dataUser.produtosContratados = ((this.dataUser.contas.filter((x: any) => x.papelPessoa.nome == this.capitalize(this.dataUser.papelAtivo))).map((x: { produtosContratados: any; }) => x.produtosContratados))[0];

    this.setDadosPermissao(this.dataUser);
  }

  setDadosPermissao(dataUser: any): void {
    this.authenticationService.setCurrentUser(dataUser);

    let permissions = [];
    if (dataUser.perfil)
      permissions.push(dataUser.perfil.toString());

    if (dataUser.papelAtivo)
      permissions.push(dataUser.papelAtivo.toString());

    // if (dataUser.codigoProdutosContratados)
    //   dataUser.codigoProdutosContratados.forEach((x: any) => permissions.push(x));

    if (dataUser.produtosContratados)
      dataUser.produtosContratados.forEach((item: any) => permissions.push(item.codigo));

    this.permissionService.loadPermissions(permissions);
    this.router.navigate(['/dashboard'])
      .then(() => {
        window.location.reload();
      });
  }


  userlogged(): void {
    this.usuarioLogado = this.authenticationService.currentUserValue;
    this.papelAtivo = this.usuarioLogado.papelAtivo;
    this.idUsuarioLogado = this.usuarioLogado?.id;

    this.nomeUsuario = this.usuarioLogado?.nome;
    this.nomeUserCompleto = this.nomeUsuario
    this.nomeUsuario.length >= 15 ? this.nomeUsuario = this.nomeUsuario.substring(0, 15).concat('...') : this.nomeUsuario;

    const user = this.nomeUsuario;
    const nameArr = user.split(' ');

    const firstName = nameArr[0].charAt(0);
    const lastName = nameArr[nameArr.length -1].charAt(0);

    const initial = firstName + lastName;
    this.initials = initial

    this.nomePerfil = this.usuarioLogado?.perfil;
    this.usuarioLogadoContas = ((this.usuarioLogado.contas.filter((x: any) => x.papelPessoa.nome == this.usuarioLogado.papelAtivo)));
    this.idContaAtiva = this.usuarioLogado.id;
    this.idConta = parseInt(this.usuarioLogado?.idContaAtiva)

    if (this.idUsuarioLogado) {
      this.buscarQuantidadeNotificacoes(this.idUsuarioLogado);
      this.intervalNotificacoes = setInterval(() => {
        this.buscarQuantidadeNotificacoes(this.idUsuarioLogado);
      }, 30000);

      this.buscarNotificacoes(this.idUsuarioLogado);
    }
  }

  capitalize(text: any) {
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  }

  public selectLanguage(event: any) {
    this.headerSharedDataService.setTraducao(event.value);
    this.translateService.use(event.value)
    this.linguas = (event.id)
  }

  activeMenu() {
    return this.nomePerfil == PerfilEnum.Administrador
  }

}

export enum NomeTrocarPerfilEnum {
  Cidadao = 'Colaborador',
  Empreendedor = 'Empreendedor',
  Patrocinador = 'Investidor'
}
