import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-message-dialog',
  templateUrl: './message-dialog.component.html',
  styleUrls: ['./message-dialog.component.scss']
})
export class MessageDialogComponent implements OnInit {

  title! : string;
  message! : string;

  constructor(
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
  }


  close(value: boolean) {
    this.activeModal.close(value);
  }

  public finish() {
    this.activeModal.close();
  }

}
