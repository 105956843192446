<div class="modal-header">
  <h4 class="modal-title"></h4>
  <button type="button" class="close btn-close" aria-label="Close" (click)="fecharModal()">
  </button>
</div>

<div class="modal-body">
  <section class="titulo col-12 text-center">
    <h1 class="h3 m-0">{{'Realizar_Cadastro' | translate}}</h1>
    <h2 class="h5 m-0 txt-medio-color fw-300">{{'Escolha_o_melhor_perfil_para_cadastro' | translate}}</h2>
  </section>

  <section class="profiles col-12 my-md-5 mt-5 px-md-5 d-none d-sm-block" id="profiles" draggable="true">
    <div class="row gx-4 scrolling-wrapper-flexbox">
      <div class="box col-11 col-md-4 text-center ms-2 ms-md-0" id="1">
        <section>
          <img src="/assets/image/adesao/img-cidadao.png" alt="imagem de adesão de cidadão no Brada">
          <h3 class="h5 mt-3">{{'Colaborador' | translate}}</h3>
          <p class="text-center">{{'Colabore_com_os_projetos_sociais' | translate}}</p>
          <button type="button" class="btn btn-primary-color px-5 py-3 mt-2 border-brada h7 text-center"
            (click)="cadastro('cidadao')">
            {{'Criar_Colaborador' | translate}}
          </button>
        </section>
      </div>


      <div class="box col-11 col-md-4 text-center mx-3 mx-md-0" id="2">
        <section>
          <img src="/assets/image/adesao/img-empreendedor.png" alt="imagem de adesão de empreendedor no Brada">
          <h3 class="h5 mt-3">{{'Empreendedor' | translate}}</h3>
          <p class="text-center">{{'Plataforma_de_gestão_de_incentivos' | translate}}</p>
          <button type="button" class="btn btn-primary-color px-5 py-3 mt-2 border-brada h7 text-center"
            (click)="cadastro('empreendedor')">
            {{'Criar_Perfil_Empreendedor' | translate}}
          </button>
        </section>
      </div>

      <div class="box col-11 col-md-4 text-center me-2 me-md-0" id="3">
        <section>
          <img src="/assets/image/adesao/img-investidor.png" alt="imagem de adesão de investidor no Brada">
          <h3 class="h5 mt-3">{{'Investidor' | translate}}</h3>
          <p class="text-center">{{'Potencialize_seu_impacto_social' | translate}}</p>
          <!-- <p class="text-center">Radar de patrocinadores. Saia do óbvio. Otimize sua captação. Potencialize seu impacto social.</p> -->
          <button type="button" class="btn btn-primary-color px-5 py-3 mt-2 border-brada h7 text-center"
            (click)="cadastro('investidor')">
            {{'Criar_Perfil_Investidor' | translate}}
          </button>
        </section>
      </div>
    </div>
  </section>

  <div id="carouselExampleIndicators" class="carousel slide d-block d-sm-none py-5" data-bs-interval="false">
    <div class="carousel-indicators">
      <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active"
        aria-current="true" aria-label="Slide 1"></button>
      <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1"
        aria-label="Slide 2"></button>
      <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2"
        aria-label="Slide 3"></button>
    </div>
    <div class="carousel-inner">
      <div class="carousel-item active">
        <div class="box col-12 col-md-4 text-center" id="1">
          <section>
            <img src="/assets/image/adesao/img-empreendedor.png" alt="imagem de adesão de empreendedor no Brada">
            <h3 class="h5 mt-3">{{'Empreendedor' | translate}}</h3>
            <p class="text-center">{{'Plataforma_de_gestão_de_incentivos' | translate}}</p>
            <button type="button" class="btn btn-primary-color px-5 py-3 mt-2 border-brada h7 text-center"
              (click)="cadastro('empreendedor')">
              {{'Criar_Perfil_Empreendedor' | translate}}
            </button>
          </section>
        </div>
      </div>
      <div class="carousel-item">
        <div class="box col-12 col-md-4 text-center" id="2">
          <section>
            <img src="/assets/image/adesao/img-investidor.png" alt="imagem de adesão de investidor no Brada">
            <h3 class="h5 mt-3">{{'Investidor' | translate}}</h3>
            <p class="text-center">{{'Potencialize_seu_impacto_social' | translate}}</p>
            <!-- <p class="text-center">Radar de patrocinadores. Saia do óbvio. Otimize sua captação. Potencialize seu impacto social.</p> -->
            <button type="button" class="btn btn-primary-color px-5 py-3 mt-2 border-brada h7 text-center"
              (click)="cadastro('investidor')">
              {{'Criar_Perfil_Investidor' | translate}}
            </button>
          </section>          
        </div>
      </div>
      <div class="carousel-item">
        <div class="box col-12 col-md-4 text-center" id="3">
          <section>
            <img src="/assets/image/adesao/img-cidadao.png" alt="imagem de adesão de cidadão no Brada">
            <h3 class="h5 mt-3">{{'Colaborador' | translate}}</h3>
            <p class="text-center">{{'Colabore_com_os_projetos_sociais' | translate}}</p>
            <button type="button" class="btn btn-primary-color px-5 py-3 mt-2 border-brada h7 text-center"
              (click)="cadastro('cidadao')">
              {{'Criar_Colaborador' | translate}}
            </button>
          </section>
        </div>
      </div>
    </div>
    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators"
      data-bs-slide="prev">
      <span class="carousel-control-prev-icon corBotao" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button class="carousel-control-next " type="button" data-bs-target="#carouselExampleIndicators"
      data-bs-slide="next">
      <span class="carousel-control-next-icon corBotao" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
  </div>
</div>
<div class="modal-footer"></div>
