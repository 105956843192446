import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { LoaderService } from '@shared/components/loader/loader.service';
declare var html2pdf: any;
//import * as html2pdf from 'html2pdf.js';

@Component({
  selector: 'app-modal-export-pdf',
  templateUrl: './modal-export-pdf.component.html',
  styleUrls: ['./modal-export-pdf.component.scss']
})
export class ModalExportPdfComponent implements OnInit {

  empreendedor: any;
  areaInteresse: any[]=[];
  ods: any[]=[];
  midia: any[]=[];
  imagemPerfilMenu: any;
  imagemCapaBanner: any;
  listaProjetos: any[]=[];
  loading: boolean = true;

  constructor(
    public activeModal: NgbActiveModal,
    private sanitizer: DomSanitizer,
    private translateService: TranslateService,
    private loaderService: LoaderService
  ) { }

  ngOnInit() {
    this.exportarPdf();
  }

  fotosChamada(item: any): any {
    if(item){
      if(item.base64Content && item.base64info){
        let img = item.base64info + item.base64Content;
        if(img){
          return this.sanitizer.bypassSecurityTrustUrl(img);
        }        
      }   
    }
    return 'https://images.unsplash.com/photo-1485217988980-11786ced9454?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80';
  }

  fecharModal(){
    this.activeModal.close();
  }

  exportarPdf(){
    this.showLoader();
    const element = document.getElementById('export-pdf');
    const options = {
      filename: `${this.translateService.instant('Empreendedor')} - ${this.empreendedor?.nomePessoa}.pdf`,
      image: { type: 'png', quality: 0.98 },
      html2Canvas: { scale: 2, logging: true, dpi: 300, letterRendering: true },
      jsPDF: { orientation: 'landscape', unit: 'mm', format: 'a4' },
      enableLinks: false,
      pagebreak: { 
        mode: 'avoid-all',
        avoid: [
          '#banner',
          '#sobre',
          '#lista-de-projetos',
          '#interesses',
          '#midias',
          "#contato"
        ]
      }
    };

    html2pdf()
      .from(element)
      .set(options)
      .save()
      // .then(() => {
      //   this.hideLoader();
      // })
      setTimeout(() => {
        this.hideLoader();
      }, 1300);
  }

  private showLoader(): void {
    this.loaderService.show();
  }

  private hideLoader(): void {
    this.loaderService.hide();
  }
}
