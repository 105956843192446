<div class="bg-banner-overlay py-5">
  <div class="container d-flex justify-content-center my-md-5 mt-4 pt-1 py-sm-5">

    <div class="col-10 col-sm-12 d-flex flex-column mt-5 pt-5">

      <h2 class="mt-5">{{'Um_chamado_à' | translate}} <span class="fw-600">{{'transformação!' | translate}}</span>
      </h2>
      <h2 class="mt-3 lh">
        {{'Somos_uma' | translate}} <span class="fw-600">{{'Plataforma_digital' | translate}}</span> 
        {{'que_incentiva_a' | translate}} <br />  {{'implementação_de_projetos_e_negócios_de' | translate}}
        <br />
        <span class="fw-600">{{'impacto_social' | translate}}</span></h2>

      <h4 class="mt-3 lh-2">{{'Uma' | translate}} <span class="fw-600">{{'solução_completa' | translate}}</span> {{'para_quem_quer' | translate}} <span class="fw-600">{{'investir' | translate}}</span> <br>{{'e_para_quem_quer' | translate}}  <span class="fw-600">{{'implementar_projetos' | translate}}
          </span></h4>

      <a (click)="scrollTo('#facaparte')">
        <button type="button" class="btn col-sm-3 btn-primary-color px-5 py-3 border-brada mt-5">{{'Faça_parte' | translate}}</button>
      </a>
    </div>
  </div>
</div>