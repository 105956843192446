<header class="container mt-1" id="topo">

  <nav class="navbar navbar-expand-lg navbar-light">
    <div class="container-fluid mt-3 py-3 shadow bg-white-color border-brada">
      <a class="navbar-brand" (click)="voltarDashboard()">
        <img class="" [src]="imagemPerfilConta?imagemPerfilConta:'/assets/image/marca-brada.png'" height="28"
          alt="Logo do site Brada" loading="lazy" />
      </a>

      <div ngbDropdown class="col notificacao-bell d-flex justify-content-end me-3 d-sm-none">

        <button class="btn d-flex align-items-center justify-content-center" ngbDropdownToggle>
          <i class="bi bi-bell fs-5"></i>
          <span class="notification-num" *ngIf="qtdNotificacaoPendente">{{qtdNotificacaoPendente}}</span>
        </button>

        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">

          <ul class="dropdown-menu-container notifications">
            <li *ngIf="loadingNotificacoes">
              <div class="d-flex align-items-center justify-content-center p-2 w-100">
                <div class="lds-ellipsis me-1" role="status">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>

                <span class="sr-only ms-4">{{'Buscando_notificações' | translate}}</span>
              </div>
            </li>
            <ng-container *ngIf="!loadingNotificacoes">
              <li class="border-bottom px-3 py-3" [class.expandido]="item.expandido"
                *ngFor="let item of notificacaoPendente" (click)="expandirNotificacao(item,$event)">
                <p class="m-0 d-flex fw-500" href="#">{{item.txtAssunto}}</p>
                <p class="mt-1 mb-0" [class.text-break]="item.expandido" [class.expandido]="item.expandido"
                  [class.text-truncate]="!item.expandido" [innerHtml]="item.txtDescricaoNotificacao">
                </p>
                <div class="d-flex justify-content-end" *ngIf="item.expandido">
                  <button class="btn d-flex justify-content-end marcar-lida" (click)="marcarNotificacaoLida(item)">
                    <h6 class="pt-1 me-2 h7">{{'Marcar_como_Lida' | translate}}</h6>
                    <i class="bi bi-archive pointer" placement="top" placement="bottom"
                      ngbTooltip="Marcar como Lida"></i>
                  </button>
                </div>
              </li>
            </ng-container>
          </ul>

          <div class="d-flex justify-content-center px-3 pb-3" *ngIf="!qtdNotificacaoPendente && !loadingNotificacoes">
            <span class="fw-500">{{'Nenhuma_notificação_encontrada' | translate}}</span>
          </div>
          <div class="d-flex justify-content-end py-2 border-top"
            *ngIf="qtdNotificacaoPendente && !loadingNotificacoes">
            <button type="button" class="btn btn-outline marcar-lida" placement="bottom"
              ngbTooltip="Marcar todas como lida" (click)="marcarTodasNotificacaoLida($event)">
              <span class="me-2">{{'Limpar' | translate}}</span>
              <i class="bi bi-archive"></i>
            </button>
          </div>

        </div>
      </div>
      <button class="navbar-toggler " type="button" (click)="isMenuCollapsed = !isMenuCollapsed"
        [ngClass]="{'ms-auto': isMenuCollapsed}">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div [ngbCollapse]="isMenuCollapsed" class="collapse navbar-collapse">
        <div class="navbar-nav mt-4 mt-sm-0 menu-principal" [ngClass]="activeMenu() ? 'ms-auto' : 'ms-5'">
          <div class="navbar-nav mt-2">
            <a class="nav-item nav-link pt-2 pt-sm-0 fw-500 text-start" (click)="voltarDashboard()">{{'Dashboard' |
              translate}}</a>
            <a class="nav-item nav-link pt-2 pt-sm-0 fw-500 text-left"
              [routerLink]="['/chamadas/todas-chamadas']">{{"Chamadas" | translate}}</a>
            <a class="nav-item nav-link pt-2 pt-sm-0 fw-500" [routerLink]="['/filtro-projetos']">{{'Projetos' |
              translate}}</a>
           <ng-template [ngxPermissionsOnly]="[perfilEnum.Administrador]">
              <a class="nav-item nav-link pt-2 pt-sm-0 fw-500"
                [routerLink]="['/filtro-empreendedores']">{{'Empreendedores' | translate}}</a>
              <a class="nav-item nav-link pt-2 pt-sm-0 fw-500"
                [routerLink]="['/filtro-patrocinadores']">{{'Investidores'
                | translate}}</a>
          </ng-template>
            <div class="d-sm-none d-flex justify-content-center" *ngIf="nomePerfil != perfilEnum.Parecerista">
              <a class="nav-item nav-link pt-2 pt-sm-0 fw-500" *ngIf="nomeTrocarPerfil"
                (click)="selectPerfil(nomeTrocarPerfil)">
                {{'Trocar_para' | translate}} <span class="text-capitalize">{{nomeTrocarPerfil}}</span>
              </a>
            </div>
          </div>

          <!-- <div class="border-top pt-2 mt-3 d-block d-sm-none">
              <a class="nav-item nav-link pt-2 pt-sm-0 fw-500" [routerLink]="['/filtro-patrocinadores']">{{'Investidores' | translate}}</a>
              <a class="nav-item nav-link pt-2 pt-sm-0 fw-500" [routerLink]="['/filtro-patrocinadores']">{{'Investidores' | translate}}</a>
              <a class="nav-item nav-link pt-2 pt-sm-0 fw-500" [routerLink]="['/filtro-patrocinadores']">{{'Investidores' | translate}}</a>
              <a class="nav-item nav-link pt-2 pt-sm-0 fw-500" [routerLink]="['/filtro-patrocinadores']">{{'Investidores' | translate}}</a>
            </div> -->
        </div>

        <!-- <div class="col-12 mx-auto ms-sm-0 d-inline-flex align-items-center justify-content-center border-sm-0 py-sm-0 border-top pt-2 mt-3"> -->
        <div class="navbar-nav mt-1 ms-auto">

          <div class="navbar-nav mt-2 mt-sm-0 ms-sm-0 ms-0">
            <ng-select [notFoundText]="'Nenhum_item_encontrado' | translate"(change)="selectLanguage($event)" class="custom" [searchable]="false" [clearable]="false"
              [items]="cities" [(ngModel)]="selectedCity">
              <ng-template ng-label-tmp let-item="item">
                <div class="d-flex align-items-center justify-content-start">
                  <img class="mx-2" height="18" width="29" [src]="item.avatar" />
                  <h5 class="Txt mt-2">{{item.lingua}}</h5>
                </div>
              </ng-template>
              <ng-template ng-option-tmp let-item="item">
                <div style="margin: 4px 0;" *ngIf="item.name !== 'Kaunas'">
                  <div class="d-flex align-items-center justify-content-start">
                    <img class="mx-2" height="15" width="24" [src]="item.avatar" />
                    <h5 class="Txt mt-2">{{item.lingua}}</h5>
                  </div>
                </div>
              </ng-template>
            </ng-select>
          </div>

          <button class="btn d-flex align-items-center justify-content-center" (click)="acessarFaq()">
            <i class="bi bi-chat-quote fs-5" placement="bottom" ngbTooltip="FAQ"></i>
          </button>

          <div class="navbar-nav mt-1 ms-auto d-none d-sm-block">
            <div ngbDropdown class="col notificacao-bell">

              <button class="btn d-flex align-items-center justify-content-center" ngbDropdownToggle>
                <i class="bi bi-bell fs-5" placement="bottom" ngbTooltip="Notificações"></i>
                <span class="notification-num" *ngIf="qtdNotificacaoPendente">{{qtdNotificacaoPendente}}</span>
              </button>

              <div ngbDropdownMenu aria-labelledby="dropdownBasic1">

                <ul class="dropdown-menu-container notifications">
                  <li *ngIf="loadingNotificacoes">
                    <div class="d-flex align-items-center justify-content-center p-2 w-100">
                      <div class="lds-ellipsis me-1" role="status">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>

                      <span class="sr-only ms-4">{{'Buscando_notificações' | translate}}</span>
                    </div>
                  </li>
                  <ng-container *ngIf="!loadingNotificacoes">
                    <li class="border-bottom px-3 py-3" [class.expandido]="item.expandido"
                      *ngFor="let item of notificacaoPendente" (click)="expandirNotificacao(item,$event)">
                      <p class="m-0 d-flex fw-500" href="#">{{item.txtAssunto}}</p>
                      <p class="mt-1 mb-0" [class.text-break]="item.expandido" [class.expandido]="item.expandido"
                        [class.text-truncate]="!item.expandido" [innerHtml]="item.txtDescricaoNotificacao">
                      </p>
                      <div class="d-flex justify-content-end" *ngIf="item.expandido">
                        <button class="btn d-flex justify-content-end marcar-lida"
                          (click)="marcarNotificacaoLida(item)">
                          <h6 class="pt-1 me-2 h7">{{'Marcar_como_Lida' | translate}}</h6>
                          <i class="bi bi-archive pointer" placement="top" placement="bottom"
                            ngbTooltip="Marcar como Lida"></i>
                        </button>
                      </div>
                    </li>
                  </ng-container>
                </ul>

                <div class="d-flex justify-content-center px-3 pb-3"
                  *ngIf="!qtdNotificacaoPendente && !loadingNotificacoes">
                  <span class="fw-500">{{'Nenhuma_notificação_encontrada' | translate}}</span>
                </div>
                <div class="d-flex justify-content-end py-2 border-top"
                  *ngIf="qtdNotificacaoPendente && !loadingNotificacoes">
                  <button type="button" class="btn btn-outline marcar-lida" placement="bottom"
                    ngbTooltip="Marcar todas como lida" (click)="marcarTodasNotificacaoLida($event)">
                    <span class="me-2">{{'Limpar' | translate}}</span>
                    <i class="bi bi-archive"></i>
                  </button>
                </div>

              </div>
            </div>
          </div>
          <div class="navbar-nav mt-1 me-auto d-none d-sm-block">
            <div class="col mt-sm-1 me-sm-3" ngbDropdown>
              <div class="d-flex justify-content-between align-items-center justify-content-center">
                <a class="d-flex align-items-center justify-content-center">
                  <div class="ms-1 me-2 mt-1" ngbDropdownToggle></div>
                  <h6 class=" initials me-3 mt-2 menu-nome h7 d-none d-sm-block" ngbDropdownToggle>{{ initials }}</h6>
                  <img ngbDropdownToggle class="rounded-circle" height="34" width="34"
                    [src]="imagemPerfil?imagemPerfil:'/assets/image/perfil-1.png'" alt="Logo do site Brada"
                    loading="lazy" />
                </a>
              </div>

              <!-- Arrumar -->
              <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="mx-3">
                <div class="col-12 border-bottom d-flex align-items-center justify-content-start ps-5 mb-2 pb-2">
                  <div>
                    <h6 class="card-title my-1 fw-500">{{ nomeUserCompleto }}</h6>
                    <p class="card-text h7 fw-400 m-0">{{ nomePerfil }}</p>
                  </div>

                </div>

                <li>
                  <a class="dropdown-item" (click)="acessarDadosUsuario()">
                    <i class="bi bi-person mx-2"></i>
                    {{'Meus_Dados' | translate}}
                  </a>
                </li>

                <li>
                  <a class="dropdown-item" (click)="trocarSenha()">
                    <i class="bi bi-gear mx-2"></i>
                    {{'Trocar_Senha' | translate}}
                  </a>
                </li>

                <li *ngIf="nomePerfil == perfilEnum.Master">
                  <a class="dropdown-item" *ngIf="nomeTrocarPerfil" (click)="selectPerfil(nomeTrocarPerfil)">
                    <i class="bi bi-arrow-left-right mx-2"></i>
                    {{'Trocar_para' | translate}} <span class="text-capitalize">{{nomeTrocarPerfil}}</span>
                  </a>
                </li>

                <li>
                  <a class="dropdown-item" href="#" (click)="logout()">
                    <i class="bi bi-box-arrow-right mx-2"></i>
                    {{'Sair' | translate}}
                  </a>
                </li>

              </div>
            </div>
          </div>
        </div>

        <div class="col-12 border-top pt-2 mt-3 d-block d-sm-none d-flex justify-content-between">
          <a class="nav-item nav-link pt-2 pt-sm-0 fw-500" (click)="logout()">
            <i class="bi bi-box-arrow-right mx-2"></i>
            {{'Sair' | translate}}
          </a>

        </div>
      </div>
    </div>
  </nav>
</header>
