export const environment = {
  production: false,
  profile: 'homolog',
  api:'https://api-homolog.somosbrada.com.br/api',
  apiDominios:'https://api-homolog.somosbrada.com.br/api',
  auth:'https://api-homolog.somosbrada.com.br/auth',
  apiVindi: 'https://sandbox-app.vindi.com.br/api/v1/public/payment_profiles',
  publicKeyVindi: 'Cawquv0ez-vOZ6W_fbk-u8aJpT75MSgvl3bZYfSEHtI',
  privateKeyVindi: 'R1Q36o1E2qRbJ_iCSUwD8oCnj_Ek1YmcvXTKMfYf6hs',
  urlSiteAtual: 'homolog.somosbrada.com.br/',
  urlAreaLogada: 'homolog.somosbrada.com.br/#/'
};
