import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ChamadaPublicoService } from '@core/services/chamada-publico.service';
import { NotificationService } from '@core/services/notification.service';
import * as dayJS from 'dayjs';

@Component({
  selector: 'app-faca-parte-site',
  templateUrl: './faca-parte-site.component.html',
  styleUrls: ['./faca-parte-site.component.scss']
})
export class FacaParteSiteComponent implements OnInit {

  investidor = false;
  executor = false;
  colaborador = false;
  facaParteForm!: FormGroup;
  perfis: any[]=[
    { id: 1, nome: 'Colaborador' },
    { id: 2, nome: 'Empreendedor' },
    { id: 3, nome: 'Investidor' }
  ];
  check!: any;
  message!:any;
  perfilSelecionado: any;
  public anoDireitos: string = '';

  constructor(
    private router: Router,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit() {
    this.facaParteForm = this.formBuilder.group({
      perfil: ['', Validators.required]
    })

    this.anoDireitos = dayJS().year().toString();
  }

  selecionar(item: any): void {
    item.select = !item.select;
    this.perfilSelecionado = item.nome;
  }

  btnFacaParte(){
    switch (this.perfilSelecionado) {
      case 'Colaborador':
        this.router.navigate(['/adesao/colaborador']);
      break;
      case 'Empreendedor':
        this.router.navigate(['/adesao/planos']);
      break;
      case 'Investidor':
        this.router.navigate(['/adesao/investidor']);
      break;
    }
  }
}
