<div class="bg-overlay bg-body-color h-100 landingPage" id="topo">
  <div class="position-fixed w-100">
    <header id="topo" class="container-flex shadow bg-white-color">
      <nav class="navbar navbar-expand-lg navbar-light mt-0 pt-0">
        <div class="container py-3 align-middle">

          <button class="navbar-toggler " type="button" (click)="isMenuCollapsed = !isMenuCollapsed"
            [ngClass]="{'ms-auto': isMenuCollapsed}">
            <span class="navbar-toggler-icon"></span>
          </button>

          <div [ngbCollapse]="isMenuCollapsed" class="collapse navbar-collapse ">
            <div class="navbar-nav col-12 mt-4 mt-sm-0 ms-auto menu-principal">
              <div class="navbar-nav mt-2 col d-flex justify-content-start">
                <ul class="navbar-nav col-9 d-flex justify-content-around me-auto mb-2 mb-lg-0 align-items-center">
                  <li class="nav-item ps-3 fw-500" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                    <a class="nav-link" pageScroll href="/#topo">{{'Home' | translate}}</a>
                  </li>

                  <li class="nav-item ps-3 fw-500" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                    <a class="nav-link" pageScroll href="/#sobre">{{'Sobre' | translate}}</a>
                  </li>

                  <li class="nav-item ps-3 fw-500" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                    <a class="nav-link" pageScroll href="/#locais-de-atuacao">{{'Local_de_Atuacao' | translate}}</a>
                  </li>

                  <li class="nav-item ps-3 fw-500" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                    <a class="nav-link" pageScroll href="/#midias">{{'Mídias' | translate}}</a>
                  </li>

                  <li class="nav-item ps-3 fw-500" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                    <a class="nav-link" pageScroll href="/#documentos">{{'Documentos' | translate}}</a>
                  </li>
                </ul>
              </div>
              <ng-select [notFoundText]="'Nenhum_item_encontrado' | translate" (change)="selectLanguage($event)" class="custom" [searchable]="false" [clearable]="false"
                [items]="cities" [(ngModel)]="selectedCity">
                <ng-template ng-label-tmp let-item="item">
                  <div class="d-flex align-items-center justify-content-start">
                    <img class="mx-2" height="18" width="29" [src]="item.avatar" />
                    <h5 class="Txt mt-2">{{item.lingua}}</h5>
                  </div>
                </ng-template>
                <ng-template [items]="selectLanguage" ng-option-tmp let-item="item">
                  <div style="margin: 4px 0;" *ngIf="item.name !== 'Kaunas'">
                    <div class="d-flex align-items-center justify-content-start">
                      <img class="mx-2" height="15" width="24" [src]="item.avatar" />
                      <h5 class="Txt mt-2">{{item.lingua}}</h5>
                    </div>
                  </div>
                </ng-template>
              </ng-select>
            </div>

          </div>
        </div>
      </nav>
    </header>

    <div id="inscreva" class="w-100 position-fixed bottom-0" *ngIf="inscrevaScrolled">
      <div class="container-fluid py-3 shadow bg-body-color align-middle">
        <div class="d-flex align-items-end">
          <section id="data-e-status" class="col-12">
            <div class="col-12 transbox">
              <div class="container d-flex align-items-center justify-content-end">
                <div class="col text-center">

                  <div class="row gx-3 d-flex align-items-center">

                    <div class="col d-flex align-items-centerjustify-content-center" *ngIf="!exibirContador">
                      <p class="h6 txt-primary-color my-3">{{'Inscrições_Encerradas' | translate}}</p>
                    </div>

                    <div class="col-3 mt-2"
                      *ngIf="dataLandingPage?.status?.id == nomeStatusChamadas.AguardandoInicioInscricao && exibirContador">
                      <p class="h6">{{'Data_Início_das_Inscrições' | translate}}</p>
                      <div class="d-flex align-items-center justify-content-center mt-2">
                        <i class="bi bi-calendar3 fs-6 txt-primary-color"></i>
                        <p class="h5 mt-1 txt-primary-color ms-3">{{dataLandingPage?.dataInicioInscricao | slice:0:10}}</p>
                      </div>
                    </div>

                    <div class="col-3 mt-2"
                      *ngIf="dataLandingPage?.status?.id != nomeStatusChamadas.AguardandoInicioInscricao && exibirContador">
                      <p class="h6">{{'Data_Limite_para_Inscrição' | translate}}</p>
                      <div class="d-flex align-items-center justify-content-center mt-2">
                        <i class="bi bi-calendar3 fs-6 txt-primary-color"></i>
                        <p class="h5 mt-1 txt-primary-color ms-3">{{dataLimiteInscricao | slice:0:10}}</p>
                      </div>
                    </div>

                    <div class="col d-flex align-items-center"
                      *ngIf="exibirContador && dataLandingPage?.status?.id != nomeStatusChamadas.AguardandoInicioInscricao">
                      <div class="col-8 d-flex align-items-center">
                        <div class="col-10 mx-auto">
                          <div class="row gx-2">
                            <div class="col">
                              <div class="border bg-light border-2 rounded-3 py-2 px-3">
                                <span class="h6">{{daysToDday}}</span>
                                <p class="h7 m-0">{{'Dias' | translate}}</p>
                              </div>
                            </div>

                            <div class="col">
                              <div class="border bg-light border-2 rounded-3 py-2 px-3">
                                <span class="h6">{{hoursToDday}} </span>
                                <p class="h7 m-0">{{'Horas' | translate}}</p>
                              </div>
                            </div>

                            <div class="col">
                              <div class="border bg-light border-2 rounded-3 py-2 px-3">
                                <span class="h6">{{minutesToDday}}</span>
                                <p class="h7 m-0">{{'Minutos' | translate}}</p>
                              </div>
                            </div>

                            <div class="col">
                              <div class="border bg-light border-2 rounded-3 py-2 px-3">
                                <span class="h6">{{secondsToDday}}</span>
                                <p class="h7 m-0">{{'Segundos' | translate}}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col me-3 d-flex align-items-center justify-content-end">
                        <button class="btn btn-terciary-color border-brada py-3 px-5" (click)="inscrever()"
                          *ngIf="dataLandingPage?.status?.id != nomeStatusChamadas.AguardandoInicioInscricao">
                          {{'INSCREVA-SE' | translate}}
                        </button>
                      </div>
                    </div>

                  </div>

                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>

    <a type="button" class="btn arraste-cima btn-primary-color border-brada position-fixed bottom-0 end-0 me-5 mb-4"
      href="/#topo" pageScroll *ngIf="windowScrolled">
      <i class="bi bi-arrow-up-square fs-4"></i>
    </a>
  </div>

  <div class="container-flex">

    <div class="banner-principal bg-image d-flex align-items-end justify-content-center" [ngClass]="{'transbox': !imagemCapaBanner}">
      <img [src]="imagemCapaBanner" alt="..." *ngIf="imagemCapaBanner">
      <div class="col-12 text-banner text-center">

        <div class="container d-flex align-items-end">
          <div class="col-9">
            <div class="col-12 mx-auto mb-4 text-start py-4 px-5 bg-white border-brada title">
              <h1 class="h3">{{dataLandingPage?.titulo}}</h1>
              <h6 class="text-capitalize mt-3">{{dataLandingPage?.tipoEdital?.nome}}
              </h6>
            </div>
          </div>

          <div class="col d-flex align-items-center justify-content-center">
            <div class="navbar-brand shadow d-flex align-items-center justify-content-center bg-white-color p-2"
              href="#">
              <div class="col-12 d-flex align-items-center justify-content-center bg-white-color shadow h-100">
                <img class="mx-4 logo" [src]="imagemPerfilMenu?imagemPerfilMenu:'/assets/image/marca-brada.png'"
                  height="40" alt="Logo do site Brada" loading="lazy" />
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

    <section id="sobre" class="col-12 bg-white py-5">
      <div class="container my-5">
        <div class="col-12 d-flex">

          <div class="col-10 col-sm-5 mx-auto">
            <section id="data-e-status" class="col-12">
              <div class="col-12 py-5">
                <div class="container">
                  <div class="col text-center">

                    <div *ngIf="!exibirContador && !dataLandingPage?.inscricaoContinua">
                      <p class="h3 txt-primary-color my-5">{{'Inscrições_Encerradas' | translate}}</p>
                    </div>

                    <div *ngIf="dataLandingPage?.status?.id == nomeStatusChamadas.AguardandoInicioInscricao && exibirContador">
                      <p class="h3">{{'Data_Início_das_Inscrições' | translate}}</p>
                      <div class="d-flex align-items-center justify-content-center mt-2">
                        <i class="bi bi-calendar3 fs-6 txt-primary-color"></i>
                        <p class="h5 mt-1 txt-primary-color ms-3">{{dataLandingPage?.dataInicioInscricao | slice:0:10}}</p>
                      </div>
                    </div>

                    <div class="col-12" *ngIf="dataLandingPage?.status?.id != nomeStatusChamadas.AguardandoInicioInscricao && exibirContador">
                      <p class="h4 text-start">{{'Data_Limite_para_Inscrição' | translate}}</p>
                      <div class="d-flex align-items-center justify-content-start mt-2">
                        <i class="bi bi-calendar3 fs-6 txt-primary-color"></i>
                        <p class="h5 mt-1 txt-primary-color ms-3">{{dataLimiteInscricao | slice:0:10}}</p>
                      </div>
                    </div>

                    <div class="col-12 d-flex align-items-center mt-3" [ngClass]="{'justify-content-center' : dataLandingPage?.inscricaoContinua}">
                      <button class="btn btn-terciary-color border-brada py-2 px-5" *ngIf="exibirContador || dataLandingPage?.inscricaoContinua" [ngClass]="{'py-3' : dataLandingPage?.inscricaoContinua}" (click)="inscrever()">
                        {{'INSCREVA-SE' | translate}}
                      </button>
                    </div>

                  </div>
                </div>
              </div>
            </section>
          </div>

          <div class="col-10 col-sm-7 mx-auto text-muted pe-4 scroll">
            <div [innerHtml]="dataLandingPage?.descricao"></div>
          </div>

        </div>
      </div>
    </section>

    <section id="detalhes" class="container py-5">
      <section id="title" class="mt-2 mb-4 d-flex justify-content-start pt-3">
        <h5 class="title-line">Detalhes</h5>
      </section>

      <div class="row gx-4 gy-3 row-cols-2 pb-5">

        <div class="col" *ngIf="areaInteresse?.length">
          <div class="col-12 h-100 border border-brada p-5">
            <h6>{{'Areas_de_Interesse' | translate}}</h6>
            <div class="mt-3" *ngFor="let item of areaInteresse">
              <div class="card-body d-flex align-items-center m-0 p-0">
                <img [src]="imagensDominios(item)" width="30" alt="..." *ngIf="imagensDominios(item)" />
                <p class="card-text h6 ms-3 text-muted fw-400">{{item.nomeAreaInteresse}}</p>
              </div>
            </div>
          </div>
        </div>

        <div class="col" *ngIf="publico?.length">
          <div class="col-12 h-100 border border-brada p-5">
            <h6>{{'Público_Alvo' | translate}}:</h6>
            <div *ngFor="let item of publico">
              <div class="card-body d-flex align-items-center m-0 p-0">
                <img [src]="imagensDominios(item)" width="30" alt="..." *ngIf="imagensDominios(item)" />
                <p class="card-text h6 text-muted fw-400 ms-3">{{item.nome}}</p>
              </div>
            </div>
          </div>
        </div>

        <div class="col pt-3" *ngIf="ods?.length">
          <div class="col-12 h-100 border border-brada p-5">
            <h6>{{'ODS' | translate}}:</h6>
            <div *ngFor="let item of ods">
              <div class="card-body d-flex align-items-center mt-3 m-0 p-0">
                <img [src]="imagensDominios(item)" class="border-brada" width="30" alt="..."
                  *ngIf="imagensDominios(item)" />
                <p class="card-text h6 text-muted fw-400 ms-3">{{item.nome}}</p>
              </div>
            </div>
          </div>
        </div>

        <div class="col pt-3" *ngIf="dataLandingPage?.tipoPessoas?.length">
          <div class="col-12 h-100 border border-brada p-5">
            <h6>{{'Tipo_de_Empreendedor' | translate}}:</h6>
            <div *ngFor="let item of dataLandingPage?.tipoPessoas">
              <div class="card-body d-flex align-items-center mt-3 m-0 p-0">
                <p class="card-text h6 text-muted fw-400">{{item.nome}}</p>
              </div>
            </div>
          </div>
        </div>

        <div class="col pt-3" *ngIf="dataLandingPage?.fonteFinanciamento?.length">
          <div class="col-12 h-100 border border-brada p-5">
            <h6>{{'Fonte_de_Financiamento' | translate}}:</h6>
            <div *ngFor="let item of dataLandingPage?.fonteFinanciamento">
              <div class="card-body d-flex align-items-center mt-3 m-0 p-0">
                <p class="card-text h6 text-muted fw-400">{{item.nomeFonteFinanciamento}}</p>
              </div>
            </div>
          </div>
        </div>

        <div class="col pt-3" *ngIf="dataLandingPage?.localAtuacao?.estado?.length || dataLandingPage?.localAtuacao?.municipio?.length">
          <div class="col-12 h-100 border border-brada p-5">
            <h6>{{'Estados_de_Atuação' | translate}}:</h6>
            <div class="col-12">
              <div class="col d-flex mt-3">
                <!-- <div class="col-2 pe-3">
                  <p class="h6 fw-400">{{'Estados' | translate}}:</p>
                </div> -->

                <div class="col scroll">
                  <div *ngFor="let estado of dataLandingPage?.localAtuacao?.estado">
                    <p class="h6 text-muted fw-400 mb-3">{{estado?.nome}}</p>
                  </div>
                </div>
              </div>

              <!-- <div class="col d-flex">
                <div class="col-2">
                  <p class="h6 fw-400">{{'Municípios' | translate}}:</p>
                </div>

                <div class="col ps-3 scroll">
                  <div *ngFor="let municipio of dataLandingPage?.localAtuacao?.municipio">
                    <p class="h6 text-muted fw-400 mb-3">{{municipio?.nome}} - {{municipio?.estado?.codigo}}</p>
                  </div>
                </div>
              </div> -->
            </div>
          </div>
        </div>

        <div class="col pt-3" *ngIf="dataLandingPage?.localAtuacao?.municipio?.length || dataLandingPage?.localAtuacao?.municipio?.length">
          <div class="col-12 h-100 border border-brada p-5">
            <h6>{{'Municípios_de_Atuação' | translate}}:</h6>
            <div class="col-12">
              <div class="col d-flex mt-3">
                <div class="col scroll">
                  <div *ngFor="let municipio of dataLandingPage?.localAtuacao?.municipio">
                    <p class="h6 text-muted fw-400 mb-3">{{municipio?.nome}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </section>

    <section id="midias" class="col-12 bg-white" *ngIf="midia?.length">
      <div class="col-12 transbox pt-4 pb-5">
        <div class="container pb-5">

          <section id="title" class="mt-5 mb-3 d-flex justify-content-start">
            <h5 class="title-line">{{'Mídias' | translate}}</h5>
          </section>

          <div class="row g-4 row-cols-2 row-cols-sm-5 d-flex justify-content-start">
            <div class="col" *ngFor="let item of midia">
              <div
                class="card border-0 shadow-sm d-flex align-items-center justify-content-center text-center py-4 h-100"
                (click)="goToUrl(item.enderecoMidia)">

                <i class="bi bi-play-btn fs-2 txt-primary-color m-0 p-0"
                  *ngIf="item?.tipoMidia?.nomeTipoMidia == 'Vídeos'"></i>

                <i class="bi bi-image fs-2 txt-primary-color m-0 p-0"
                  *ngIf="item?.tipoMidia?.nomeTipoMidia == 'Fotos'"></i>

                <i class="bi bi-link-45deg fs-2 txt-primary-color m-0 p-0"
                  *ngIf="item?.tipoMidia?.nomeTipoMidia == 'Sites'"></i>

                <div class="card-body m-0 p-0">
                  <a class="card-text h7">{{item.enderecoMidia | slice:0:20}}...</a>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>

    <section id="documentos" class="col-12" *ngIf="dataLandingPage?.documentos?.length">
      <div class="col-12 transbox pt-4 pb-5">
        <div class="container pb-5">

          <section id="title" class="mt-5 mb-3 d-flex justify-content-start">
            <h5 class="title-line">{{'Documentos' | translate}}</h5>
          </section>

          <div class="col col-sm-8 d-flex align-items-center">
            <div class="col col-sm-10">
              <div class="row g-3 gy-2 row-cols-2">

                <div *ngFor="let item of dataLandingPage?.documentos">

                  <div class="doc border border-1 p-4 d-flex align-items-center justify-content-between"
                    (click)="downloadEnviado(dataLandingPage?.id, item)">
                    <p class="h6 fw-400">{{item?.tipoDocumento?.nomeTipoDocumento}}</p>
                    <i class="bi bi-cloud-download fs-4 m-0 p-0 txt-secundary-color"></i>
                  </div>

                </div>

              </div>
            </div>
          </div>

        </div>
      </div>
    </section>

  </div>
</div>

<app-footer></app-footer>
