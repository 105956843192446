import { CoreModule } from '@core/core.module';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {HttpClient, HttpClientModule } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '@shared/shared.module';
import { NgxPermissionsModule, NgxPermissionsService, NgxRolesService } from 'ngx-permissions';
import { LocalStorageService } from '@core/local-storage/local-storage.service';
import { UserAuth } from '@core/auth/user';
import { PublicModule } from '@modules/public/public.module';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { registerLocaleData } from '@angular/common';
import localePT from '@angular/common/locales/pt';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ListProjetosAtendemChamadaComponent } from '@modules/list-projetos-atendem-chamada/list-projetos-atendem-chamada.component';
import { ListChamadasAtendemProjetoComponent } from '@modules/list-chamadas-atendem-projeto/list-chamadas-atendem-projeto.component';
import { ListPatrocinadoresComponent } from '@modules/list-patrocinadores/list-patrocinadores.component';
import { FichaInscricaoChamadaComponent } from '@modules/chamadas/pages/ficha-inscricao-chamada/ficha-inscricao-chamada.component';
import { InscreverSeComponent } from '@modules/chamadas/pages/inscrever-se/inscrever-se.component';
import { FiltroDeChamadasPublicasComponent } from '@modules/chamadas/pages/public/pages/filtro-de-chamadas-publicas/filtro-de-chamadas-publicas.component';
import { ListFichaInscricaoChamadaComponent } from '@modules/chamadas/pages/list-ficha-inscricao-chamada/list-ficha-inscricao-chamada.component';
import { ListEmpreendedoresComponent } from '@modules/list-empreendedores/list-empreendedores/list-empreendedores.component'
import { ListProjetosComponent } from '@modules/list-projetos/list-projetos.component'
import { TrocarSenhaComponent } from '@modules/usuario/pages/trocar-senha/trocar-senha.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HeaderDashboardComponent } from '../app/shared/components/header/components/header-dashboard/header-dashboard.component';
import * as $ from 'jquery';
import { MensagemPagamentoComponent } from '@modules/checkout-pagamento/pages/mensagem-pagamento/mensagem-pagamento.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '../assets/jsonTranslate/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    ListProjetosAtendemChamadaComponent,
    ListChamadasAtendemProjetoComponent,
    ListPatrocinadoresComponent,
    ListProjetosComponent,
    FiltroDeChamadasPublicasComponent,
    ListEmpreendedoresComponent,
    MensagemPagamentoComponent,
  ],
  imports: [
    TranslateModule.forRoot({
      defaultLanguage: 'pt-BR',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NgxPermissionsModule.forRoot(),
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    NgbModule,
    PublicModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    CoreModule
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    NgxPermissionsService,
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    {
      provide: APP_INITIALIZER,
      useFactory: loadPermissions,
      deps: [NgxPermissionsService, NgxRolesService, LocalStorageService],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function loadPermissions(permissionService: NgxPermissionsService, roleService: NgxRolesService, localStorageService: LocalStorageService) {
  return () => {
    let userData = <UserAuth>JSON.parse(localStorageService.getItem('currentUser'));

    if (userData) {
      let permissions = [];
      if (userData.perfil)
        permissions.push(userData.perfil.toString());

      if (userData.papelAtivo)
        permissions.push(userData.papelAtivo.toString());

      // if (userData.codigoProdutosContratados)
      //   userData.codigoProdutosContratados.forEach((x: any) => permissions.push(x));

      if (userData.produtosContratados)
        userData.produtosContratados.forEach((item: any) => permissions.push(item.codigo));

      permissionService.loadPermissions(permissions);
      //permissionService.loadPermissions([userData.perfil.toString()]);

      /* if (userData.perfil.perfil && userData.roles)
        roleService.addRole(userData.perfil.perfil, userData.roles); */
    }
  };
}
