<div class="col-12 pt-5 bg-contato-overlay">
  <div id="facaparte" class="container d-flex justify-content-center pt-sm-4 mt-sm-5">
    <div class="col-10 col-sm-12 d-flex flex-column mt-3 mt-md-5">
      <h2>{{'BRADA' | translate}}</h2>
      <h2>{{'é_um_grito_de' | translate}} <span class="fw-600">{{'liberdade.' | translate}}</span> <br> {{'é_a_vontade' | translate}}
        <span class="fw-600">{{'de_ser_a_diferença' | translate}}</span> <br> {{'é_a_chamada_para_a' | translate}}
         <span class="fw-600">{{'transformação.' | translate}}</span></h2>
      <div class="mt-4">
        <h1><span class="fw-600">{{'Faça_parte!' | translate}}</span> {{'Queremos_te' | translate}} <span class="fw-600">{{'conhecer.' | translate}}</span></h1>
      </div>

      <div class="col-12 my-md-4">
        <div class="col-12 my-3 my-md-5">
          <button (click)="btnFacaParte()" type="button"
            class="btn col-12 col-sm-8 btn-primary-color px-2 px-sm-5 py-3 border-brada letter2">
            {{'Formulário_com_todas_informações_necessárias' | translate}}
          </button>
        </div>

        <div class="col-10 col-sm-9 mt-5 my-md-5">
          <div class="row">
            <div class="col my-2 d-flex" *ngFor="let p of perfis">
              <input class="form-check-input mt-1" type="radio" name="p.nome" (change)="selecionar(p)">
              <label class="form-check-label text-capitalize ps-4 h3 fw-400">{{p.nome}}</label>
            </div>
          </div>
        </div>         
      </div>
    </div>
  </div>

  <app-footer></app-footer>
</div>