<div class="container">
  <div class="modal-header">
    <h4 class="modal-title"></h4>
    <button type="button" class="close btn-close" aria-label="Close" (click)="fechar()">

    </button>
  </div>
  <div class="modal-body">
    <div class="col-10 m-auto mb-5">

      <h5 class="text-center" *ngIf="!exibirErroVista">
        {{titulo}}
      </h5>

      <form [formGroup]="formConvite" id="formConvite" (ngSubmit)="submit()" [ngClass]="{'mt-5': !exibirErroVista}">
        <div class="col-md mt-2">
          <div class="form-group" *ngIf="acao == acaoFiltroEnum.ConvidarEmpreededor && !exibirErroVista">

            <div class="selecionarEmpreendedor col-12 mx-auto">
              <label class="mb-1 text-center required">{{'Selecione_os_Empreendedores' | translate}}</label>
              <ng-select [notFoundText]="'Nenhum_item_encontrado' | translate" [multiple]="true" name="idContasEmail" formControlName="idContasEmail" placeholder="Selecione">
                <ng-option *ngFor="let item of listaEmpreedendores" [value]="item.idConta">{{ item.nomePessoa }}</ng-option>
              </ng-select>
            </div>

            <div class="emailSemCadastrado col-12 mx-auto my-4">
              <label class="mb-1">Não encontrou o convidado?</label>
              <ng-select  [notFoundText]="'Nenhum_item_encontrado' | translate"[multiple]="true" [addTag]="true" formControlName="enderecoEmailConvidado" id="enderecoEmailConvidado" placeholder="Digite o email do convidado" [compareWith]="compareFn"></ng-select>
            </div>

          </div>

          <div class="form-group" *ngIf="acao == acaoFiltroEnum.VincularParecerista && !exibirErroVista">
            <ng-select  [notFoundText]="'Nenhum_item_encontrado' | translate" [multiple]="true" name="idContasEmail" formControlName="idContasEmail" placeholder="Selecione" *ngIf="!exibirErroVista">
              <ng-option *ngFor="let item of listaPareceristas" [value]="item.id">{{ item.nomeReduzido }}</ng-option>
            </ng-select>
          </div>
        </div>

        <div class="row mt-1">
          <div class="col-12 mt-4 d-flex align-items-center justify-content-center" *ngIf="!exibirErroVista">
            <button type="submit" class="btn btn-primary-color py-2 px-5 border-brada">
              {{'Adicionar_a_Lista' | translate}}
            </button>
          </div>

          <div *ngIf="exibirErroVista">
            <h6 class="text-center fw-400 mb-4">{{message}}</h6>
            <div class="col-12 mt-4 d-flex align-items-center justify-content-center">
              <button type="button" class="btn btn-primary-color py-2 px-5 border-brada" (click)="fechar()">
                {{'Fechar' | translate}}
              </button>
            </div>
          </div>
        </div>

        <section class="listaEmpreendedores mt-5 pt-5 border-top" *ngIf="acao == acaoFiltroEnum.ConvidarEmpreededor && !exibirErroVista && exibirListaConvidados">
          <h5 class="fw-400 text-center txt-primary-color">{{'Lista_de_Convidados' | translate}}</h5>

          <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col">{{'Nome' | translate}}</th>
                <th scope="col" class="text-center">{{'Ação' | translate}}</th>
              </tr>
            </thead>
            <tbody class="scroll">
              <tr *ngFor="let item of listaConvidados">
                <td>{{item.nome}}</td>
                <td class="text-center">
                  <div class="col-2 mx-auto">
                    <button class="btn btn-primary-color" placement="bottom" ngbTooltip="Remover da Lista" (click)="removerEmpreendedor(item.id)">
                      <i class="bi bi-trash h7 text-center"></i>
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </section>

        <section class="listaPareceristas mt-5 pt-5 border-top" *ngIf="acao == acaoFiltroEnum.VincularParecerista && !exibirErroVista && exibirListaPareceristas">
          <h5 class="fw-400 text-center txt-primary-color">{{'Lista_de_Pareceristas' | translate}}</h5>

          <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col">{{'Nome' | translate}}</th>
                <th scope="col" class="text-center">{{'Ação' | translate}}</th>
              </tr>
            </thead>
            <tbody class="scroll">
              <tr *ngFor="let item of listaPareceristas">
                <td>{{item.nomeReduzido}}</td>
                <td class="text-center">
                  <div class="col-2 mx-auto">
                    <button class="btn btn-primary-color" placement="bottom" ngbTooltip="Remover da Lista" (click)="removerEmpreendedor(item.id)">
                      <i class="bi bi-trash h7 text-center"></i>
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </section>
      </form>
    </div>
  </div>
</div>
