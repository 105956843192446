import { NgModule } from '@angular/core';
import { NgbCarouselModule, NgbDatepickerModule, NgbDropdownModule, NgbHighlight, NgbModalModule, NgbPaginationModule, NgbTooltipModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [],
  imports: [
    NgbModalModule,
    NgbDatepickerModule,
    NgbDropdownModule,
    NgbTooltipModule,
    NgbCarouselModule,
    NgbPaginationModule,
    NgbTypeaheadModule,
    NgbPaginationModule
  ],
  exports: [
    NgbModalModule,
    NgbDatepickerModule,
    NgbDropdownModule,
    NgbTooltipModule,
    NgbCarouselModule,
    NgbPaginationModule,
    NgbTypeaheadModule,
    NgbPaginationModule,
    NgbHighlight
  ]
})
export class NgbBoostrapModule { }
