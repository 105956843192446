import { Component, OnInit } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-kanban',
  templateUrl: './kanban.component.html',
  styleUrls: ['./kanban.component.scss']
})
export class KanbanComponent implements OnInit {

  workflow = [
    {
      id: 1,
      nome: 'Em Progresso',
      itens: [
        {
          id: 1,
          nome: 'lfksdjglkdsflkf'
        },
        {
          id: 2,
          nome: 'lfksdjglkdsflkf'
        },
        {
          id: 3,
          nome: 'lfksdjglkdsflkf'
        },
        {
          id: 4,
          nome: 'lfksdjglkdsflkf'
        },

      ]
    },
    {
      id: 2,
      nome: 'Concluído',
      itens: [

      ]
    },
    {
      id: 3,
      nome: 'Deu Merda',
      itens: [

      ]
    }
  ]

  ngOnInit(): void {

  }

  drop(event: CdkDragDrop<string[]>, etapa: any) {
    let item = event.previousContainer.data[event.previousIndex];
// console.log('Etapa para onde quero ir', etapa);
// console.log('Dados do Item Corrente', item);
// console.log('Nova Ordem', event.currentIndex)

    if (event.previousContainer === event.container) {
      //chama serviço de mudar ordem
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      //chama serviço de mudar de etapa
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
  }

  get idsEtapas() {
    return this.workflow.map(x => x.id.toString());
  }
}
