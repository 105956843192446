import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AcaoFiltrosEnum } from '@core/models/enum/acao-filtro';
import { StatusChamadasEnum } from '@core/models/enum/status-chamadas.enum';
import { ModalAtualEnum } from '@core/models/enum/tipo-chamadas.enum';
import { ChamadaService } from '@core/services/chamada.service';
import { DominioService } from '@core/services/dominio.service';
import { NotificationService } from '@core/services/notification.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalAjudaNavegacaoComponent } from '../modal-ajuda-navegacao/modal-ajuda-navegacao.component';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-modalSelect',
  templateUrl: './modalSelect.html',
  styleUrls: ['./modalSelect.scss']
})
export class ModalSelectComponent implements OnInit {

  exibirErroVista: boolean = false;
  message!:any;
  listaEmpreedendores: any[] = [];
  listaPareceristas: any[] = [];
  listaConvidados: any[] = [];
  exibirListaConvidados: boolean = false;
  exibirListaPareceristas: boolean = false;

  acao: any;
  titulo!: string;
  idContaAtiva!: number;
  idPessoaAtiva!: number;
  idEdital!: number;
  listEmailsExternos: any[]=[];

  acaoFiltroEnum = AcaoFiltrosEnum;
  statusChamadas = StatusChamadasEnum;
  tipoChamada: any;

  formConvite = this.formBuilder.group({
    idContasEmail: [null],
    enderecoEmailConvidado: [null]
  })

  constructor(
    private router: Router,
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private dominioService: DominioService,
    private chamadaService: ChamadaService,
    private notificationService: NotificationService,
    private modalService: NgbModal,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    this.definirTitulo(this.acao);
    this.buscarConviteEditalPrivado(this.idEdital);
  }

  definirTitulo(acao: AcaoFiltrosEnum) {
    switch (acao) {
      case AcaoFiltrosEnum.ConvidarEmpreededor:
        this.titulo = this.translateService.instant('Convidar_Empreendedor');
        this.buscarEmpreendedor();
        break;
      case AcaoFiltrosEnum.VincularParecerista:
        this.titulo = this.translateService.instant('Vincular_Parecerista');
        this.buscarPareceristaPorPatrocinador();
        break;
    }
  }

  fechar():void{
    this.activeModal.close();
    this.exibirErroVista = false;
  }

  submit():void{
    if(this.enderecoEmailConvidado.value)
      this.enderecoEmailConvidado.value.forEach((x: any) => {
        this.listEmailsExternos.push(x.label);
      });

    if(this.formConvite.invalid || this.idContasEmail.value == 'null'){
      this.exibirErroVista = true;
      this.message = this.translateService.instant('Escolha_ao_menos_um')`${this.titulo}`;
      setTimeout(() => {
        this.exibirErroVista = false;
      }, 4000);
    }
    else if(this.formConvite.valid) {
      let objEnvio = {...this.formConvite.value};
      if(this.listEmailsExternos){
        objEnvio.enderecoEmailConvidado = this.listEmailsExternos;
      }

      if(this.acao == AcaoFiltrosEnum.ConvidarEmpreededor){
        let objConvites = {
          idEdital: this.idEdital,
          idContaEmpreendendor: objEnvio.idContasEmail,
          listEmailsEmpreendedoresExterno: objEnvio.enderecoEmailConvidado
        }
        this.chamadaService.convidarEmpreededores(objConvites).subscribe((data: any) => {
          this.message = this.translateService.instant('Convite_Enviado_com_Sucesso');
          this.notificationService.showSuccess(this.message);

          this.tipoChamada = localStorage.getItem('tipoChamadaAtual');

          if(this.tipoChamada)
            this.modalFaseCaptacao();
          else
            this.activeModal.close();
        })
      }
      else{
        delete objEnvio.enderecoEmailConvidado
        this.activeModal.close(objEnvio);
      }
    }
  }

  modalFaseCaptacao(){
    this.router.navigate([`/dashboard`]);
    this.activeModal.close();

    let modalFaseCaptacao = this.modalService.open(ModalAjudaNavegacaoComponent, {
      centered: true,
      beforeDismiss: () => false,
      size: 'md'
    });
    modalFaseCaptacao.componentInstance.modalAtual = ModalAtualEnum.AguardarFaseCaptacao;
  }

  buscarEmpreendedor(): void {
    this.dominioService.buscarEmpreendedor().subscribe((data: any) => {
      this.listaEmpreedendores = data;

      this.listaEmpreedendores.sort((x, y) => {
        let a = x.nomePessoa.toUpperCase(),
            b = y.nomePessoa.toUpperCase();
        return a == b ? 0 : a > b ? 1 : -1;
      });

      this.exibirErroVista = false;
    })
  }

  buscarPareceristaPorPatrocinador(): void {
    this.dominioService.buscarPareceristaPorPatrocinador(this.idPessoaAtiva).subscribe((data: any) => {
// console.log(data)
      this.listaPareceristas = data;
      this.exibirErroVista = false;
    });
  }

  buscarConviteEditalPrivado(idEdital: number){
    if(idEdital){
      this.exibirListaConvidados == true;
      this.chamadaService.buscarConviteEditalPrivado(idEdital).subscribe((data: any) => {
        this.listaConvidados = data;
// console.log(this.listaConvidados, 'lista')
      });
    }
  }

  removerEmpreendedor($event: any) {

  }

  get idContasEmail(): FormGroup {
    return <FormGroup>this.formConvite.controls.idContasEmail;
  }

  get enderecoEmailConvidado(): FormGroup {
    return <FormGroup>this.formConvite.controls.enderecoEmailConvidado;
  }

  compareFn(a: any, b: any) {
    return a.id == b.id;
  }
}
