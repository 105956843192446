<div class="col-12 bg-somos-overlay bg-white-color py-5">

  <div id="quemsomos" class="container d-flex justify-content-center py-sm-5 my-sm-5">

    <div class="col-10 col-sm-12 d-flex flex-column my-3">
      <h5 class="fw-400 fs">{{'O' | translate}} <span class="fw-600">{{'BRADA' | translate}}</span> {{'foi_idealizado_para_ser_uma_plataforma_digital_que_incentiva_ações_e_projetos_de' | translate}} 
        <span class="fw-600">{{'impacto' | translate}} <br> {{'social.' | translate}}</span></h5>
      <h5 class="fw-400 mt-1 fs1">{{'Possibilitar__facilitar_e_conectar_investidores_e_empreendedores_para_que_projetos_ganhem_visibilidade' | translate}} <br>
        {{'e__a_partir_disso__a' | translate}}  <span class="fw-600">{{'transformação_na_sociedade_aconteça' | translate}}</span>
      </h5>
      <h5 class="fw-400 mt-4 fs">{{'Nossa_equipe_é_composta_por_especialistas_em_gestão_de_projetos__finanças__assessoria_e_consultoria' | translate}}</h5>
      <h5 class="fw-400 mt-4 fs">{{'Venha fazer parte dessa' | translate}} <span class="fw-600">{{'transformação!' | translate}}</span></h5>
    </div>

  </div>

</div>