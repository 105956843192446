import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenticationService } from '@core/auth/authentication.service';
import { ApiService } from '@core/services/api.service';
import { environment } from '@env/environment';

const routes = {
  consultar: (id: number) => `/publica/empreendedor/consultar/${id}`,
  listar: `/publica/empreendedor/listar`
}
 
@Injectable({
  providedIn: 'root'
})
export class EmpreendedorPublicoService {

  constructor(
    private apiService: ApiService,
    private authenticationService: AuthenticationService,
    ) { }

  buscarPorId(id: number) {
    return this.apiService.getCustomApi<any>(`${environment.api}/${routes.consultar(id)}`);
  }

  listar(obj: any, page:number,pageSize:number) {
    let params = new HttpParams();
    params = params.append('size',pageSize);
    params = params.append('page',page - 1);
    return this.apiService.post<any>(`${routes.listar}`, obj,undefined,undefined,params);
  }

}