<div class="modal-header">
  <h4 class="modal-title ps-3 pt-3">
    <button class="btn btn-secundary-color" (click)="exportarPdf()">Exportar PDF</button>
  </h4>
  <button type="button" class="close btn-close" aria-label="Close" (click)="fecharModal()"></button>
</div>
<div class="modal-body">
  <div class="col-12 pe-none" id="export-pdf">
    <section id="banner">
      <div class="banner-principal bg-image d-flex align-items-center justify-content-center" *ngIf="!imagemCapaBanner" style="background-image: url('https://images.unsplash.com/photo-1511649475669-e288648b2339?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1632&q=80%27')">      
        
        <div class="container position-absolute mt-5 pt-5">
          <div class="col-6 bg-white-color p-5 rounded-3 shadow">
            <span class="h7 text-muted m-0">{{'Empreendedor' | translate}}</span>
            <h1>{{empreendedor?.nomePessoa}}</h1>
            <h6 class="fw-400">{{empreendedor?.tipoPessoa?.nome}}</h6>
          </div>
        </div>
      </div>
      
      <div class="banner-principal" *ngIf="imagemCapaBanner">
        <img [src]="imagemCapaBanner" alt="...">
        
        <div class="container position-absolute mt-5 pt-5">
          <div class="col-6 bg-white-color p-5 rounded-3 shadow">
            <span class="h7 text-muted m-0">{{'Empreendedor' | translate}}</span>
            <h1>{{empreendedor?.nomePessoa}}</h1>
            <h6 class="fw-400">{{empreendedor?.tipoPessoa?.nome}}</h6>
          </div>
        </div>
      </div>
    </section>

    <section id="sobre" class="col-12 bg-white-color py-5">
      <div class="container my-5 py-5">        
        <div class="row g-3 py-3">

          <div class="col">            
            <h2 class="h1 px-5 txt-primary-color">
              {{'Sobre_o' | translate}}<br />
              <span class="txt-support-color">
                {{'Empreendedor' | translate}}
              </span>
            </h2>
            <div class="col-12 ps-5 mt-4">
              <div class="col-10 ms-0 border-top text-capitalize text-start" *ngIf="empreendedor?.dataNascimentoFundacao">
                <h6 class="mt-4 mb-2 fw-400 h7 text-muted ">{{'Data_Nascimento_Fundacão' | translate}}</h6>
                <h6>{{empreendedor?.dataNascimentoFundacao}}</h6>
              </div>
            </div>
          </div>

          <div class="col align-items-center">
            <div [innerHtml]="empreendedor?.sobreOrganizacao"></div>
          </div>

        </div>
      </div>      
    </section>

    <section id="lista-de-projetos" class="col-12 py-5" *ngIf="listaProjetos.length">
      <div class="container my-3 p-5">
        
        <section class="title-primary col-12 mx-auto text-center pb-3">
          <div class="title-line col-4 px-sm-5 mx-auto">
            <h2 class="h3 text-center mt-3 fw-500">
              {{'Lista_de_Projetos' | translate}}
            </h2>
          </div>      
        </section>

        <div class="col-12 mt-4">
          <div class="row g-5">
            <div class="col-4" *ngFor="let item of listaProjetos">
              <div class="col h-100 d-flex align-items-center bg-white-color rounded-3 shadow-sm click">
                <div class="col-4 p-4">
                  <img class="card-img border-brada-4 w-100" [src]="fotosChamada(item.fotoPerfil)" alt="imagem chamada" loading="lazy" />
                </div>
                <div class="col-8 ps-1 pe-4 py-4">
                  <p class="h7 fw-500 txt-secundary-color m-0">{{'Projeto:' | translate}}</p>
                  <h3 class="my-1 h6">{{item?.titulo}}</h3>
                  <div class="mt-2 h7 text-muted descricao" [innerHtml]="item?.descricao | slice:0:100"></div>

                  <a href="" class="h7 m-0 p-0 fw-500">
                    {{'Visualizar' | translate}}
                  </a>

                </div>
              </div>
            </div>
          </div>
        </div>
        
        <section class="cards mt-5 d-none">
          <div class="row g-3 row-cols-3">
    
            <div *ngFor="let item of listaProjetos" class="col ">
              <div class="card-brada col-10 mx-auto pt-2 pt-sm-3 px-3 px-sm-4 bg-white-color shadow border-5 h-100">
    
                <div class="card position-relative bg-body-color text-white border-brada-4 mt-2">
                  <img class="card-img border-brada-4 w-100" [src]="fotosChamada(item.fotoPerfil)" alt="imagem chamada" loading="lazy" />                    
    
                  <div class="card-img-overlay border-brada-4">
                    <div class="card-header col-12 position-absolute top-0 start-0 d-flex align-items-center border-0 border-brada-4">
                      <div class="col mt-2">
                        <span class="h7 bg-white-color-t2 py-2 px-3 rounded-3 fw-300">{{item?.tipoEdital?.nome}}</span>
                      </div>
      
                      <div class="col-2 mt-2">
                        <div class="p-1 bg-white-color-t2 rounded-3 d-flex align-items-center justify-content-center">
                          <i class="bi bi-bookmark-fill txt-yellow-color fs-6"></i>
                        </div>
                      </div>
                    </div>
      
                    <div class="card-footer col-12 position-absolute bottom-0 start-0 border-0 d-flex align-items-center border-brada-4">
                      <div class="col-5 mb-2">
                        <span class="h7 bg-primary-color-t5 py-2 px-3 rounded-3 fw-300">{{'__Seguir' | translate}}</span>
                      </div>
      
                      <div class="col-7 mt-2 mb-2 me-2 d-flex align-items-center justify-content-end" *ngIf="item?.status?.id == 4">
                        <span class="h7 fw-400 txt-white-color d-flex align-items-center">
                          <i class="bi bi-circle-fill txt-green-color me-2"></i>
                          {{'Inscrições_Abertas' | translate}}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
      
                <div class="card-body px-2 px-sm">
                  <div class="col-12 border-bottom pb-3">
                    <h5 class="card-title my-3">{{item?.titulo}}</h5>
                    <p class="h7 card-subtitle mb-2 text-muted d-flex fw-500">
                      {{'Patrocinador:' | translate}}
                      <span class="ms-1 fw-600 txt-secundary-color">
                        {{item?.nomePatrocinador}}
                      </span>
                    </p>
                  </div>
      
                  <div class="col-12 mt-3 pt-2">
                    <div class="row gx-2">
                      <div class="col-6 card-data d-flex">
                        <div class="col-3 pt-1">
                          <i class="bi bi-calendar-week text-muted fs-5"></i>
                        </div>
                        <div class="col text-left">
                          <p class="h7 text-muted fw-500 m-0">
                            {{'Inscrições_até' | translate}}
                          </p>
                          <p class="fs-6 fw-600">
                            {{item?.dataLimiteInscricao | slice:0:10}}
                          </p>
                        </div>
                      </div>
                      <div class="col-6 d-flex align-items-start justify-content-end">
                        <button type="button" class="btn btn-secundary-color py-2 h7">
                          <i class="bi bi-pencil-square me-1"></i>
                          {{'Saiba_Mais' | translate}}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
      
              </div>
            </div>
    
          </div>
        </section>
      </div>
    </section>

    <section id="interesses" class="col-12" [ngClass]="{'bg-body-color': !listaProjetos.length, 'bg-white-color': listaProjetos.length}" *ngIf="areaInteresse.length || ods.length">
      <div class="container p-5">
        
        <div class="col-10 mx-auto d-flex align-items-center py-5">
          <div class="col">
            <div id="title" class="my-5 text-end">
              <h2 class="border-bottom txt-primary-color pb-2 mb-4">{{'Interesses' | translate}}</h2>
            </div>
          </div>
          
          <div class="col-8 d-flex align-items-center justify-content-end">
            <div class="col-10 ps-5">
              <div class="col-12 pt-5 d-flex flex-wrap" *ngIf="areaInteresse.length">
                <h5 class="col-12 fw-400 flex-fill py-1 pe-1 my-1 me-1 mb-2">
                  {{'Areas_de_Interesse' | translate}}
                </h5>
                <div *ngFor="let area of areaInteresse">
                  <h6 class="bg-claro-color rounded-3 px-3 py-2 my-1 me-2 flex-fill text-muted h7">
                    {{area.nomeAreaInteresse}}
                  </h6>
                </div>
              </div>
              
              <div class="col-12 pt-4 pb-5 d-flex flex-wrap" *ngIf="ods.length">
                <h5 class="col-12 fw-400 flex-fill py-1 pe-1 my-1 me-1 mb-2">
                  {{'ODS' | translate}}
                </h5>

                <div *ngFor="let ods of ods">
                  <h6 class="bg-claro-color rounded-3 px-3 py-2 my-1 me-2 flex-fill text-muted h7">
                    {{ods?.nome}}
                  </h6>
                </div>

              </div>
            </div>                
          </div>
        </div>
    
      </div>      
    </section>

    <section id="midias" class="col-12" [ngClass]="{'bg-body-color': listaProjetos.length, 'bg-white-color': !listaProjetos.length}" *ngIf="midia.length">
      <div class="col-12 transbox p-5">
        <div class="container p-5">
        
          <section id="title" class="d-flex justify-content-center py-5">
            <h3 class="title-line">{{'Mídias' | translate}}</h3>
          </section>
  
          <div class="row g-4 row-cols-5 d-flex justify-content-center pb-5">
            <div class="col" *ngFor="let item of midia">
              <div class="card border-0 shadow-sm d-flex align-items-center justify-content-center text-center py-4 h-100">
                
                <i class="bi bi-play-btn fs-2 txt-primary-color m-0 p-0" *ngIf="item?.tipoMidia?.nomeTipoMidia == 'Vídeos'"></i>

                <i class="bi bi-image fs-2 txt-primary-color m-0 p-0" *ngIf="item?.tipoMidia?.nomeTipoMidia == 'Fotos'"></i>

                <i class="bi bi-link-45deg fs-2 txt-primary-color m-0 p-0" *ngIf="item?.tipoMidia?.nomeTipoMidia == 'Sites'"></i>

                <div class="card-body m-0 p-0">
                  <a class="card-text h7">{{item.enderecoMidia | slice:0:20}}...</a>
                </div>
              </div>
            </div> 
          </div> 
  
        </div>     
      </div>       
    </section>

    <section id="contato" class="col-12 py-5" [ngClass]="{'bg-body-color': midia.length, 'bg-white-color': listaProjetos.length}" *ngIf="empreendedor?.estouDeAcordoDadosPublicos">
      <div class="container my-5">        
        <div class="row g-3 py-3 px-5">

          <div class="col-4">
            <div id="title" class="my-5 text-start mx-4">
              <h2 class="border-bottom txt-primary-color pb-2 mb-4">{{'Contato' | translate}}</h2>
              <p>{{'Informações_de_contato_do_empreendedor' | translate}}</p>
            </div>
          </div>

          <div class="col d-flex justify-content-center align-items-center">
            <div>
              <h6 class="text-muted">{{'Endereço:' | translate}}</h6>
              <h6>
                {{'Endereço:' | translate}}
                {{empreendedor?.logradouro}}, {{empreendedor?.numero}}
                <span *ngIf="empreendedor?.complemento">, {{empreendedor?.complemento}}</span>
                - {{empreendedor?.bairro}}              
                <br />
                {{empreendedor?.cidade?.nome}} - {{empreendedor?.estado?.codigo}} / {{empreendedor?.pais?.nome}}
              </h6>
              
              <h6 class="text-muted mt-4">{{'Telefone_de_Contato:' | translate}}</h6>
              <h6>{{empreendedor?.telefone}}</h6>          
            </div>
          </div>

        </div>
      </div>      
    </section>
  </div>
</div>