import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalEscolhaAdesaoComponent } from '@modules/adesao/components/modal-escolha-adesao/modal-escolha-adesao.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-cadastro',
  templateUrl: './cadastro.component.html',
  styleUrls: ['./cadastro.component.scss']
})
export class CadastroComponent implements OnInit {

  acao: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal
  ) { }

  ngOnInit() {
    const modalCadastro = this.modalService.open(ModalEscolhaAdesaoComponent, {
      centered: true,
      beforeDismiss: () => false,
      size: 'xl'
    })
  }

}
