import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RouteAction } from '@core/action/action';
import { AuthenticationService } from '@core/auth/authentication.service';
import { AreaInteresse } from '@core/models/chamada';
import { TipoPessoa } from '@core/models/dominio';
import { AcaoFiltrosEnum } from '@core/models/enum/acao-filtro';
import { ChamadaService } from '@core/services/chamada.service';
import { DominioService } from '@core/services/dominio.service';
import { NotificationService } from '@core/services/notification.service';
import { NgbModal, NgbPaginationConfig } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';
import { EmpreendedorPublicoService } from '@core/services/empreendedor-publico';
import { RelacaoDeSeguidosService } from '@core/services/relacao-de-seguidos.service';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-list-empreendedores',
  templateUrl: './list-empreendedores.component.html',
  styleUrls: ['./list-empreendedores.component.scss']
})
export class ListEmpreendedoresComponent implements OnInit {

  namePage = "Lista de Empreendedores";

  id!: number;
  acao!: RouteAction;
  criar!: boolean;
  editar!: boolean;
  visualizar!: boolean;

  page = 1;
  pageSize = 5;
  listaPatrocinadores: any[] = []; // criarModel
  tabelaListaPatrocinadores: any[] = [];
  collectionSize: any;

  usuarioLogado: any;
  papelAtivo: any;
  idContaAtiva: any;
  lastPage: number = 0;
  lista: any[] = [];
  usuarioLogadoContas: any[] = [];
  idUsuarioLogado: any;
  message!: string;
  isLoading: boolean = false;
  success: boolean = false;
  exibirErroVista: boolean = false;
  objEnvio: any;
  objEnvioFiltroChamadas: any;
  fill: boolean = false;

  statusEdital: any[] = [];
  fonteFinanciamento: any[] = [];
  tipoEdital: any[] = [];
  tipoPessoa: any[] = [];
  tipoFoto: any[] = [];
  fotos: any[] = [];
  tipoPessoas: Array<TipoPessoa> = [];
  publicos: any[] = [];
  ods: any[] = [];
  areasDeInteresse: Array<AreaInteresse> = [];
  estados: any[] = [];
  ufs: any;
  municipios: any[] = [];
  tipoDocumento: any[] = [];
  midias: any[] = [];
  areaDeInteresseEdital: any[] = [];
  acaoFiltroChamdaEnum = AcaoFiltrosEnum;
  filtroPaginas: any[]=[];

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private notificationService: NotificationService,
    private chamadaService: ChamadaService,
    private dominioService: DominioService,
    private authenticationService: AuthenticationService,
    private modalService: NgbModal,
    private activatedRoute: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private empreendedorPublicoService: EmpreendedorPublicoService,
    private relacaoDeSeguidosService: RelacaoDeSeguidosService,
    private config: NgbPaginationConfig,
    private translateService: TranslateService
  ) {
    config.size = 'sm';
    config.boundaryLinks = true;
  }

  formFiltroEmpreendedores = this.formBuilder.group({
    areaInteresse: [],
    estado: [],
    municipio: [],
    nome: [],
    ods: [],
    tipoPessoa: [],
  });

  ngOnInit() {
    this.userlogged();
    this.buscarDadosEtapas();
    this.pesquisar();
  }

  voltarDashboard(): void {
    this.router.navigate(['/dashboard']);
  }

  reset(){
    this.formFiltroEmpreendedores.reset();

    this.fill == false
  }

  Filtro() {
    if (this.fill == false) {
      this.fill = true
// console.log(this.fill)
    } else if (this.fill == true) {
      this.fill = false
// console.log(this.fill)
    }
    return console.log(this.fill)
  }

  fotosChamada(item: any): any {
    if(item){
      if(item.base64Content && item.base64info){
        let img = item.base64info + item.base64Content;
        if(img){
          return this.sanitizer.bypassSecurityTrustUrl(img);
        }
      }
    }
    return 'https://images.unsplash.com/photo-1485217988980-11786ced9454?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80';
  }

  buscarDadosEtapas(): void {
    this.buscarTipoPessoa();
    this.buscarPublicoAlvo();
    this.buscarOds();
    this.buscarAreaInteresse();
    this.buscarEstado();
    this.buscarFonteFinanciamento();
    this.buscarfiltroPaginas();
  }

  buscarTipoPessoa(): void {
    this.dominioService.buscarTipoPessoa().subscribe((data: any) => {
      this.tipoPessoa = data;
      this.tipoPessoa = this.tipoPessoa.filter(x => x.ativo == true);
    });
  }

  buscarEstado(): void {
    this.dominioService.buscarEstado().subscribe((data: any) => {
      this.estados = data;
    });
  }

  consultaMunicipio(ufs: any) {
    this.ufs = { "estados": [ufs] }

    if(ufs)
      this.buscarMunicipioEstados(this.ufs);
  }

  buscarMunicipioEstados(idEstado: any): void {
    this.dominioService.buscarMunicipioEstados(idEstado).subscribe((data: any) => {
      this.municipios = data;
    });
  }

  buscarAreaInteresse(): void {
    this.dominioService.buscarAreaInteresse().subscribe((data: any) => {
      this.areasDeInteresse = data;
      this.areasDeInteresse = this.areasDeInteresse.filter(x => x.ativo == true);
    });
  }

  buscarOds(): void {
    this.dominioService.buscarOdsFiltros().subscribe((data: any) => {
      this.ods = data;
    });
  }

  buscarPublicoAlvo(): void {
    this.dominioService.buscarPublicoAlvo().subscribe((data: any) => {
      this.publicos = data;
      this.publicos = this.publicos.filter(x => x.ativo == true);
    });
  }

  visualizarLandingPage(idEmpreendedor: any){
    window.open(`${window.location.origin}/#/landingpage/empreendedor/visualizar/${idEmpreendedor}`, '_blank')
  }

  buscarFonteFinanciamento(): void {
    this.dominioService.buscarFonteFinanciamento().subscribe((data: any) => {
      this.fonteFinanciamento = data;
    });
  }

  buscarfiltroPaginas(): void {
    this.dominioService.filtroPaginas().subscribe((data: any) => {
      this.filtroPaginas = data;
    });
  }

  showError(mensagem: any) {
    this.notificationService.showError(mensagem);
  }

  showSuccess(mensagem: any) {
    this.notificationService.showSuccess(mensagem);
  }

  listar(): void {
    if (this.formFiltroEmpreendedores.valid) {
      this.page = 1;
      this.pesquisar();
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    this.fill == false
  }

  trocarSize(): void {
    this.page = 1;
    this.pesquisar(undefined);
  }

  pesquisar(lastPage?: number): void {
// console.log(lastPage)
    if (lastPage && lastPage == this.page)
      return;

    this.lastPage = this.page;
    let objEnvio = { ...this.formFiltroEmpreendedores.value };
    this.empreendedorPublicoService.listar(objEnvio, this.page, this.pageSize).subscribe((data: any) => {
// console.log(data)
      this.lista = data.content;
      this.collectionSize = data.totalElements;
    });
  }

  userlogged(): void {
    if(this.authenticationService.currentUserValue){
      this.usuarioLogado = this.authenticationService.currentUserValue;
      this.papelAtivo = this.usuarioLogado.papelAtivo;
      this.idContaAtiva = this.usuarioLogado.idContaAtiva;
    }
  }

  acessarLandingPage(url: any): void {
    window.location.href=url;
  }

  compareFn(a: any, b: any) {
    return a.id == b.id;
  }

  btSeguirEmpreendedor(item: any): void {
    this.relacaoDeSeguidosService.seguirEmpreendedor(item.id).subscribe((data: any) => {
      this.ocultarSeguir(item);

      let mensagem = this.translateService.instant("Seguindo_o_Empreendedor")
      this.notificationService.showSuccess(mensagem);
    });
  }

  btDeixarDeSeguirEmpreendedor(item: any): void {
    this.relacaoDeSeguidosService.deixarDeSeguirEmpreendedor(item.id).subscribe((data: any) => {
      this.ocultarDeixarDeSeguir(item);

      let mensagem = this.translateService.instant("Deixou_de_seguir_o_Empreendedor")
      this.notificationService.showSuccess(mensagem);
    });
    location.reload();
  }

  ocultarSeguir(item: any): void {
    item.selectSeguir = !item.selectSeguir;
    item.selectDeixar = item.selectDeixar;
  }

  ocultarDeixarDeSeguir(item: any): void {
    item.selectDeixar = !item.selectDeixar;
    item.selectSeguir = item.selectSeguir;
  }

  get estado(): FormGroup {
    return <FormGroup>this.formFiltroEmpreendedores.controls.estado;
  }
}
