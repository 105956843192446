import { Injectable, Injector, ErrorHandler } from '@angular/core';
import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { NotificationService } from '@core/services/notification.service';
import { AuthenticationService } from '@core/auth/authentication.service';
//import { HttpResponseValidation } from './http-error';
//import { MessageService } from '@core/services/message.service';
import { environment } from '@env/environment';
import { HttpStatusEnum } from './http-status';
import { ErrorResponse, ItemErroResponse } from './http-error';

const ignoreErrors = [
    "Token is not valid",
    ""
]

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
    constructor(
        private injector: Injector,
        private notificationService: NotificationService,
        private authenticationService: AuthenticationService,
        //private messageService: MessageService
    ) { }

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            tap(null, (err: any) => {
                if (request.url.indexOf(environment.auth) == -1)
                    if (err instanceof HttpErrorResponse) {
// console.log('Error: ',err)
                        if (err.status === 401) {
                            this.authenticationService.logout(true);
                        }
                        else {
                            if (request.params && request.params.has('ignoreError')) {

                            }
                            else
                                if (err.statusText == 'Unknown Error') {
                                    this.notificationService.showError('Ocorreu um erro inesperado durante esta ação.')
                                }
                                else {
                                    let errorObj = err.error;
// console.log(errorObj)
                                    if (request.responseType == 'arraybuffer') {
                                        if ('TextDecoder' in window) {
                                            var dataView = new DataView(err.error);
                                            var decoder = new TextDecoder('utf8');
                                            errorObj = JSON.parse(decoder.decode(dataView));
                                        } else {
                                           /*  var decodedString = String.fromCharCode.apply(null, new Uint8Array(err.error));
                                            errorObj = JSON.parse(decodedString);
                                            let errorResponse = <ItemErroResponse[]>errorObj;
                                            if (!Array.isArray(errorResponse))
                                                errorResponse = [errorResponse];
                                            let messages = '';
                                            errorResponse.forEach(x => {
                                                messages += `${x.mensagem} <br>`;
                                            })
                                            this.notificationService.warn('Atenção', messages); */
                                        }
                                    }
                                    else if (request.responseType == 'blob') {
                                        var reader = new FileReader();
                                        reader.onload = (ev: any) => {
// console.log(ev)
                                            if (typeof ev.target.result == 'string') {
                                                try {
                                                    let jsonError = JSON.parse(ev.target.result);
                                                    if (!Array.isArray(jsonError))
                                                        jsonError = [jsonError];

                                                    let messages = '';
                                                   jsonError.forEach((x:any) => {
                                                        messages += `${x.mensagem} <br>`;
                                                    })
                                                    this.notificationService.showError(messages);
                                                } catch {
                                                    this.notificationService.showError(ev.target.result);
                                                }
                                            }
                                            else {
                                                let errorResponse = <ErrorResponse>errorObj;
                                                let messages = '';
                                                errorResponse.mensagens.forEach(x => {
                                                    messages += `${x.mensagem} <br>`;
                                                })
                                                this.notificationService.showError(messages);
                                            }
                                        }
                                        reader.readAsText(errorObj);
                                    }
                                    else
                                        if (err.statusText == 'Unknown Error' || err.status == HttpStatusEnum.Accepted) {
                                            this.notificationService.showError('Ocorreu um erro inesperado durante esta ação.')
                                        }
                                        else {
                                            if (err.status == HttpStatusEnum.NotFound) {
                                                this.notificationService.showError(err.error);
                                            }
                                            else {
                                                let errorResponse = <ErrorResponse>errorObj;
                                                let messages = '';
                                                errorResponse.mensagens.forEach(x => {
                                                    messages += `${x.mensagem} <br>`;
                                                })
                                                this.notificationService.showError(messages);
                                            }
                                        }
                                }
                        }
                    }
            })
        )
    }
}
