import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-politica-privacidade',
  templateUrl: './politica-privacidade.component.html',
  styleUrls: ['./politica-privacidade.component.scss']
})
export class PoliticaPrivacidadeComponent implements OnInit {

  showLoading = false;
  src!: SafeResourceUrl;
  urlPoliticasPrivacidade = "https://somosbrada.com.br/politica-de-privacidade-termos-de-uso/";

  constructor(
    private activeModal:NgbActiveModal,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.loadSite();
  }

  loadSite() {
    this.showLoading = true;
    this.src = this.sanitizer.bypassSecurityTrustResourceUrl(this.urlPoliticasPrivacidade);
  }

  onLoad() {
    this.showLoading = false;
  }

  aceitoTermos():void {
    this.activeModal.close(true);
  }

  fechar():void{
    this.activeModal.close();
  }
}
