export class Notificacao {

    constructor(
      assunto: string,
      dataCadastramento: Date,
      descricaoNotificacao: string,
      id: number,
      nomeStatusNotificacao: string,
      statusNotificacaoId: number,
      usuarioId: string,
      expandido:boolean
      ) {
      this.assunto =  assunto;
      this.dataCadastramento = dataCadastramento;
      this.descricaoNotificacao = descricaoNotificacao;
      this.id = id;
      this.nomeStatusNotificacao = nomeStatusNotificacao;
      this.statusNotificacaoId = statusNotificacaoId ;
      this.usuarioId = usuarioId;
      this.expandido = expandido;
    }
  
    assunto: string;
    dataCadastramento: Date;
    descricaoNotificacao: string;
    id: number;
    nomeStatusNotificacao: string;
    statusNotificacaoId: number;
    usuarioId: string;
    expandido:boolean;
  }
  
export interface Notificacoes {
  id: number;
  txtAssunto: string;
  txtDescricaoNotificacao: string;
  dataCadastro: string;
  dataStatus: string;
  status: string;
  expandido:boolean;
}

export class QtdNotificacao {
  
}
  
export enum NotificacaoStatusEnum{
  Pendente = 'PENDENTE',
  Lida = 'LIDA',
}