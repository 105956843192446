<div class="loader d-flex align-items-center justify-content-center" *ngIf="show">
  <div class="loader-container d-flex align-items-center">
    <div class="loader-content">

      <div class="row">
        <div class="col-12">
          <div class="lds-ripple"><div></div><div></div></div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <span class="mx-3">
            {{text}}
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
