<nav class="navbar navbar-expand-lg navbar-light pt-0" id="topo">
  <div class="container-fluid py-3 shadow bg-white-color align-middle ">

    <div class="container">
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <a class="navbar-brand mt-2 mt-lg-0" (click)="goToUrl()">
          <img class="mx-5" [src]="imagemPerfilMenu?imagemPerfilMenu:'/assets/image/marca-brada.png'" height="40" alt="Logo do site Brada" loading="lazy" />
        </a>

        <ul class="navbar-nav ms-3 me-auto mb-2 mb-lg-0">
          <li class="nav-item ps-3 fw-500" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
            <a class="nav-link" pageScroll href="/#topo">{{'Home' | translate}}</a>
          </li>

          <li class="nav-item ps-3 fw-500" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
            <a class="nav-link" pageScroll href="/#sobre">{{'Sobre' | translate}}</a>
          </li>

          <li class="nav-item ps-3 fw-500" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
            <a class="nav-link" pageScroll href="/#locais-de-atuacao">{{'Local_de_Atuacao' | translate}}</a>
          </li>

          <li class="nav-item ps-3 fw-500" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
            <a class="nav-link" pageScroll href="/#midias">{{'Mídias' | translate}}</a>
          </li>

          <li class="nav-item ps-3 fw-500" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
            <a class="nav-link" pageScroll href="/#documentos">{{'Documentos' | translate}}</a>
          </li>

          <ng-select [notFoundText]="'Nenhum_item_encontrado' | translate"(change)="selectLanguage($event)" class="custom" [searchable]="false" [items]="cities" [(ngModel)]="selectedCity">
            <ng-template ng-label-tmp let-item="item">
              <div class="d-flex align-items-center justify-content-start">
                <img class="mx-2" height="18" width="29" [src]="item.avatar" />
                <h5 class="Txt mt-2">{{item.lingua}}</h5>
              </div>
            </ng-template>
            <ng-template [items]="selectLanguage" ng-option-tmp let-item="item">
              <div style="margin: 4px 0;" *ngIf="item.name !== 'Kaunas'">
                <div class="d-flex align-items-center justify-content-start">
                  <img class="mx-2" height="15" width="24" [src]="item.avatar" />
                  <h5 class="Txt mt-2">{{item.lingua}}</h5>
                </div>
              </div>
            </ng-template>
          </ng-select>

        </ul>

        <div class="d-flex align-items-end me-5">
          <button type="button" class="btn btnl-primary-color rounded-pill px-5">{{'Login' | translate}}</button>
          <button type="button" class="btn btn-terciary-color rounded-pill px-5 ms-3">{{'Inscrevase' | translate}}</button>
        </div>
      </div>
    </div>
  </div>
</nav>

<a type="button" class="btn arraste-cima btn-primary-color border-brada position-fixed bottom-0 end-0 me-5 mb-5" href="/#topo" pageScroll *ngIf="windowScrolled">
  <i class="bi bi-arrow-up-square fs-4"></i>
</a>
