<div class="example-container" *ngFor="let etapa of workflow">
    <h2>{{etapa.nome}}</h2>
    <div cdkDropList id="{{etapa.id}}" [cdkDropListData]="etapa.itens" [cdkDropListConnectedTo]="idsEtapas"
        class="example-list" (cdkDropListDropped)="drop($event,etapa)">
        <div class="col cards" *ngFor="let item of etapa.itens" cdkDrag>
            <div class="card card-blue">
                <img src="https://images.unsplash.com/photo-1485217988980-11786ced9454?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
                    class="c card-img border-4" alt="...">
                <div
                    class="card-body card-img-overlay text-center d-flex justify-content-center align-items-center flex-column">

                    <i class="bi bi-journal-text fs-4 icon-base txt-secudary-color"></i>

                    <h6 class="card-title m-1">Projetos</h6>
                    <h6 class="card-text h7 fw-400 m-0">Cadastre seus projetos gratuitamente</h6>
                    <i class="bi bi-arrow-right-circle fs-3 icon-hover"></i>
                </div>
            </div>
        </div>
    </div>
</div>