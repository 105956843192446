import { Inject, Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { map, take } from 'rxjs/operators';
import { AuthenticationService } from '@core/auth/authentication.service';
import { Observable } from 'rxjs';
import { NgxPermissionsService } from 'ngx-permissions';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalLoginComponent } from '@modules/login/components/modal-login/modal-login.component';
import { ModalAcessoRestritoComponent } from '@modules/public/components/modal-acesso-restrito/modal-acesso-restrito.component';
import { LocalStorageService } from '@core/local-storage/local-storage.service';
import { DOCUMENT } from '@angular/common';
import { environment } from '@env/environment';
//import * as dayJS from 'dayjs';

@Injectable()
export class AuthGuardService implements CanActivate {

    constructor(
      private router: Router,
      private authenticationService: AuthenticationService,
      private modalService: NgbModal,
      private localStorageService: LocalStorageService,
      private permissionsService: NgxPermissionsService, @Inject(DOCUMENT) private document: any) { }

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
        return this.authenticationService.isLoggedIn
            .pipe(
                take(1),
                map((isLoggedIn: boolean) => {

                    if (!isLoggedIn) {
                      //this.document.location.href = `https://${environment.urlSiteAtual}`;
// console.log("URL acessado", window.location.href);
                      this.localStorageService.setItem('url_before', window.location.href);
                      this.openModalAcessoRestrito();
                      return false;
                    }

                    /*   if (this.authenticationService.currentUserValue && dayJS(this.authenticationService.currentUserValue.expires_in).toDate() < dayJS().toDate()) {
                           this.authenticationService.logout();
                           return false;
                       } */

                    /* let permissions = [];

                    if (this.authenticationService.currentUserValue) {
                        if (this.authenticationService.currentUserValue.perfil)
                            permissions.push(this.authenticationService.currentUserValue.perfil);

                        if (this.authenticationService.currentUserValue.papelAtivo)
                            permissions.push(this.authenticationService.currentUserValue.papelAtivo);
                    }

                    this.permissionsService.loadPermissions(permissions);
                    let can = true;
                    if (route.data && route.data.roles && this.authenticationService.currentUserValue && this.authenticationService.currentUserValue.perfil) {
                        can = route.data.roles.some((x:any) => x == this.authenticationService.currentUserValue.perfil);
                        if (!can) {
                            this.router.navigate(['acessoNaoPermitido']);
                        }
                    } */
                    return true;
                })
            );
    }

    openModalAcessoRestrito(): void {
      const modalColunas = this.modalService.open(ModalAcessoRestritoComponent, {
        centered: true,
        beforeDismiss: () => false,
        size: 'md',
        modalDialogClass: 'modal-grafico'
      })

      let instance = <ModalLoginComponent>modalColunas.componentInstance;
    }
}
