import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RouteAction } from '@core/action/action';
import { LandingPageChamadaComponent } from './pages/landingpage-chamada/landingpage-chamada.component';
import { LandingpagePatrocinadorComponent } from './pages/landingpage-patrocinador/landingpage-patrocinador.component';
import { LandingpageEmpreendedorComponent } from './pages/landingpage-empreendedor/landingpage-empreendedor.component';
import { LandingpageProjetosComponent } from './pages/landingpage-projetos/landingpage-projetos.component'
import { LandingpageVotacaoPopularComponent } from './pages/landingpage-votacao-popular/landingpage-votacao-popular.component';
import { LandingpageChamadaV2Component } from './pages/landingpage-chamada-v2/landingpage-chamada-v2.component';

const routes: Routes = [
  {
    path: 'chamada/:acao/:id',
    component: LandingPageChamadaComponent,
    data: {
      breadcrumb: RouteAction.Visualizar,
      acao: RouteAction.Visualizar,
      header:{
        dashboard:false
      }
    }
  },
  {
    path: 'patrocinador/:acao/:id',
    component: LandingpagePatrocinadorComponent,
    data: {
      breadcrumb: RouteAction.Visualizar,
      acao: RouteAction.Visualizar,
      header:{
        dashboard:false
      }
    }
  },
  {
    path: 'empreendedor/:acao/:id',
    component: LandingpageEmpreendedorComponent,
    data: {
      breadcrumb: RouteAction.Visualizar,
      acao: RouteAction.Visualizar,
      header:{
        dashboard:false
      }
    }
  },
  {
    path: 'projeto/:acao/:id',
    component: LandingpageProjetosComponent,
    data: {
      breadcrumb: RouteAction.Visualizar,
      acao: RouteAction.Visualizar,
      header:{
        dashboard:false
      }
    }
  },
  {
    path: 'votacao-popular/:idChamada/:idEtapaAvaliacao',
    component: LandingpageVotacaoPopularComponent,
    data: {
      header:{
        dashboard:false
      }
    }
  },
  {
    path: 'votacao-popular/:idChamada/:idEtapaAvaliacao/:idProtocolo',
    component: LandingpageVotacaoPopularComponent,
    data: {
      header:{
        dashboard:false
      }
    }
  },
  {
    path: 'v2/:acao/:id',
    component: LandingpageChamadaV2Component,
    data: {
      header:{
        dashboard:false
      }
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LandingPageRoutingModule { }
