import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenticationService } from '@core/auth/authentication.service';
import { Papel, TipoPessoa } from '@core/models/dominio';
import { ApiService } from '@core/services/api.service';
import { environment } from '@env/environment';
import { of } from 'rxjs';

const routes = {
  papel: `/adesao/papel`,
  planos: `/adesao/planos`,
  tipoPessoa: `/adesao/tipoPessoa`,
  perfil: `/perfis`,
  statusEdital: `publica/statusEdital`,
  statusEditalMeusEditais: `publica/statusEdital/meus-editais`,
  statusEditalMeusProjetos: `/statusProjeto/meus-projetos`,
  statusProjeto: `/statusProjeto`,
  tipoEdital: `publica/tipoEdital`,
  tipoFoto: `tipoFoto`,
  publicoAlvo: `publica/publicoAlvo/todos`,
  ods: `publica/ods/todos`,
  odsFiltros: `/publica/ods/filtros/todos`,
  fonteFinanciamento: `publica/fontefinanciamento/todos`,
  areaInteresse: `publica/areainteresse/todos`,
  estado: `/publica/estado/todos?`,
  pais: `publica/pais/todos`,
  estadosMunicipio: `publica/municipio/estado`,
  municipio: `publica/municipio/todos`,
  tipoMidia: `tipoMidia?`,
  tipoDocumento: `tipoDocumento`,
  buscarEmpreendedor: `edital/consultarempreendendor`,
  buscarPareceristaPorPatrocinador: (id: number) => `edital/consultarpareceristas/${id}`,
  pessoas: `pessoas/listar`,
  regimeTributario:`/regime-tributario`,
  tipoPergunta: `/tipos-pergunta/todos`,
  tipoNotificacao: `/publica/tipo-notificacao`,
  consultarDominio: (id: number) => `/dominio/consultar/${id}`,
  desativarDominio: `/dominio/desativar`,
  listarDominio: `/dominio/listar`,
  listarDominioDescricao: (nomeDominio: any) => `/dominio/listar/${nomeDominio}`,
  novoDominio: `/dominio/novo`,
  reativarDominio: `/dominio/reativar`,
  consultarItemDominio: (id: number) => `/item-dominio/consultar/${id}`,
  salvarDominio: `/item-dominio/save`,
  buscarEscolaridade: `/escolaridade/todos`,
  buscarComoColaborar: `/como-colaborar/todos`,
  statusProduto: `/publica/status-produto`,
  statusPreço: `/status-preco`,
  indicacoesPlanos: `/papel-pessoa/indicacoes`,
  statusVenda: '/status-venda/todos',
  formasPagamento: `/forma-pagamento/todos`,
  periodicidade: `/periodicidades`,
  indicacoesProdutos: `/indicacoes`,
  statusFichaProjeto: `/statusFichaProjeto`
}
@Injectable({
  providedIn: 'root'
})
export class DominioService {

  constructor(
    private apiService: ApiService,
    private authenticationService: AuthenticationService,
  ) { }

  buscarPapeis() {
    return this.apiService.get<Papel>(routes.papel);
  }

  buscarPlanos() {
    return this.apiService.get<any>(routes.planos);
  }

  buscarTipoPessoa() {
    return this.apiService.get<TipoPessoa>(routes.tipoPessoa);
  }

  buscarPessoa(obj: any) {
    return this.apiService.postCustomApi(`${environment.api}/${routes.pessoas}`, obj);
  }

  consultarTipoPerfil(){
      return this.apiService.get<any>(routes.perfil);
  }

  consultarFlag() {
    return of([
      { id: 1, valor: 'true', nome: 'Sim' },
      { id: 2, valor: 'false', nome: 'Não' }
    ]);
  }

  consultarStatus() {
    return of([
      { id: 1, valor: true, nome: 'Ativo' },
      { id: 2, valor: false, nome: 'Inativo' }
    ]);
  }

  consultarIdioma(){
    return of([
      {id:1, nome: 'Português' },
      {id:2, nome: 'Inglês' },
      {id:3, nome: 'Espanhol' }
    ])
  }

  consultarFormasPagamennto() {
    return of([
      { id: 1, valor: false, nome: 'Pago' },
      { id: 2, valor: true, nome: 'Gratuito' }
    ]);
  }

  filtroPaginas() {
    return of([
      { id: 1, valor: '5', nome: '05 itens por páginas' },
      { id: 2, valor: '10', nome: '10 itens por páginas' },
      { id: 3, valor: '25', nome: '25 itens por páginas' },
      { id: 4, valor: '50', nome: '50 itens por páginas' }
    ]);
  }

  filtroPaginasPar() {
    return of([
      { id: 1, valor: '6', nome: '06 itens por páginas' },
      { id: 2, valor: '12', nome: '12 itens por páginas' },
      { id: 3, valor: '26', nome: '26 itens por páginas' },
      { id: 4, valor: '52', nome: '52 itens por páginas' }
    ]);
  }


  // Api Dominios
  buscarStatusEdital() {
    return this.apiService.getCustomApi<any>(`${environment.api}/${routes.statusEdital}`);
  }
  buscarStatusEditalMeusEditais() {
    return this.apiService.getCustomApi<any>(`${environment.api}/${routes.statusEditalMeusEditais}`);
  }

  buscarStatusProjeto() {
    return this.apiService.get<any>(routes.statusProjeto);
  }

  buscarStatusMeusProjetos() {
    return this.apiService.get<any>(routes.statusEditalMeusProjetos);
  }

  buscarTipoEdital() {
    return this.apiService.getCustomApi<any>(`${environment.api}/${routes.tipoEdital}`);
  }

  buscarTipoNotificacao() {
    return this.apiService.get<any>(routes.tipoNotificacao);
  }

  buscarTipoFoto() {
    return this.apiService.getCustomApi<any>(`${environment.api}/${routes.tipoFoto}`);
  }

  buscarPublicoAlvo() {
    return this.apiService.getCustomApi<any>(`${environment.api}/${routes.publicoAlvo}`);
  }

  buscarOds() {
    return this.apiService.getCustomApi<any>(`${environment.api}/${routes.ods}`);
  }

  buscarOdsFiltros() {
    return this.apiService.get<any>(routes.odsFiltros);
  }

  buscarFonteFinanciamento() {
    return this.apiService.getCustomApi<any>(`${environment.api}/${routes.fonteFinanciamento}`);
  }

  buscarAreaInteresse() {
    return this.apiService.getCustomApi<any[]>(`${environment.api}/${routes.areaInteresse}`);
  }

  buscarPais() {
    return this.apiService.getCustomApi<any>(`${environment.api}/${routes.pais}`);
  }

  buscarEstado() {
    return this.apiService.get<any>(routes.estado);
  }

  buscarTodosMunicipios() {
    return this.apiService.getCustomApi<any>(`${environment.api}/${routes.municipio}`);
  }

  buscarMunicipioEstados(obj: any) {
    return this.apiService.postCustomApi<any>(`${environment.api}/${routes.estadosMunicipio}`, obj);
  }

  buscarTipoMidia() {
    return this.apiService.getCustomApi<any>(`${environment.api}/${routes.tipoMidia}`);
  }

  buscarTipoDocumento() {
    return this.apiService.getCustomApi<any>(`${environment.api}/${routes.tipoDocumento}`);
  }

  buscarEmpreendedor() {
    return this.apiService.getCustomApi<any>(`${environment.api}/${routes.buscarEmpreendedor}`);
  }

  buscarPareceristaPorPatrocinador(id: number) {
    return this.apiService.getCustomApi<any>(`${environment.api}/${routes.buscarPareceristaPorPatrocinador(id)}`);
  }

  buscarRegimesTributarios() {
    return this.apiService.get<any>(`${routes.regimeTributario}`);
  }

  buscarTipoPergunta() {
    return this.apiService.get<any>(`${routes.tipoPergunta}`);
  }


  //
  consultarDominio(id: any) {
    return this.apiService.get<any>(routes.consultarDominio(id));
  }

  listarDominioDescricao(nomeDominio: any, page:number, pageSize:number) {
    let params = new HttpParams();
    params = params.append('size',pageSize);
    params = params.append('page',page - 1);
    return this.apiService.get<any>(routes.listarDominioDescricao(nomeDominio), undefined, params);
  }

  desativarDominio(obj: any) {
    return this.apiService.post<any>(routes.desativarDominio, obj);
  }

  listarDominio(page:number,pageSize:number) {
    let params = new HttpParams();
    params = params.append('size',pageSize);
    params = params.append('page',page - 1);
    return this.apiService.get<any>(routes.listarDominio, undefined, params);
  }

  novoDominio(obj: any) {
    return this.apiService.post<any>(routes.novoDominio, obj);
  }

  reativarDominio(obj: any) {
    return this.apiService.post<any>(routes.reativarDominio, obj);
  }

  consultarItemDominio(id: any) {
    return this.apiService.get<any>(routes.consultarItemDominio(id));
  }

  salvarDominio(obj: any) {
    return this.apiService.post<any>(routes.salvarDominio, obj);
  }

  buscarEscolaridade() {
    return this.apiService.get<any>(routes.buscarEscolaridade);
  }

  buscarComoColaborar() {
    return this.apiService.get<any>(routes.buscarComoColaborar);
  }

  buscarStatusProduto() {
    return this.apiService.get<any>(routes.statusProduto);
  }

  buscarStatusPreço() {
    return this.apiService.get<any>(routes.statusPreço);
  }

  buscarIndicacoesPlanos() {
    return this.apiService.get<any>(routes.indicacoesPlanos);
  }

  statusVenda() {
    return this.apiService.get<any>(routes.statusVenda);
  }

  formasPagamento() {
    return this.apiService.get<any>(routes.formasPagamento);
  }

  buscarPeriodicidade() {
    return this.apiService.get<any[]>(routes.periodicidade);
  }

  buscarIndicacoesProdutos() {
    return this.apiService.get<any>(routes.indicacoesProdutos);
  }

  buscarStatusFichaProjeto() {
    return this.apiService.get<any>(routes.statusFichaProjeto);
  }
}
