import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService } from '@core/auth/authentication.service';
import { DominioService } from '@core/services/dominio.service';
import { NotificationService } from '@core/services/notification.service';
import { ModalEscolhaAdesaoComponent } from '@modules/adesao/components/modal-escolha-adesao/modal-escolha-adesao.component';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HeaderSharedDataService } from '@shared/components/header/header-shared-data.service';
import { PapelContaEnum } from '@core/models/enum/papel-conta';
import { LocalStorageService } from '@core/local-storage/local-storage.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { UserAuth } from '@core/auth/user';
import { PerfilEnum } from '@core/models/enum/perfil';
import { DOCUMENT } from '@angular/common';
import { environment } from '@env/environment';

@Component({
  selector: 'app-modal-login',
  templateUrl: './modal-login.component.html',
  styleUrls: ['./modal-login.component.scss']
})
export class ModalLoginComponent implements OnInit {

  constructor(
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private router: Router,
    private authenticationService: AuthenticationService,
    private dominioService: DominioService,
    private headerSharedDataService: HeaderSharedDataService,
    private notificationService: NotificationService,
    private permissionService: NgxPermissionsService,
    private localStorageService: LocalStorageService,
    @Inject(DOCUMENT) private document: any
  ) {
  }

  formAcessarConta!: FormGroup;
  message!: string;
  isLoading: boolean = false;
  success: boolean = false;
  exibirErroVista: boolean = false;

  inscreverSe: boolean = false;
  redireciona: boolean = false;
  idChamada: any;
  dataUser: any;
  objEnvio: any;
  papeis: any[] = [];
  active = 1;
  formPerfil: boolean = false;
  papelContaEnum = PapelContaEnum;
  perfilEnum = PerfilEnum;
  papelSelecionado: any;
  usuarioLogado: any;
  formPerfilPatrocinador: boolean = false;
  formPerfilCidadao: boolean = false;
  formPerfilEmpreendedor: boolean = false;
  ambosPapeis: boolean = false;
  urlBefore: any;

  teste: any;

  ngOnInit(): void {
    this.formAcessarConta = this.formBuilder.group({
      //username: ['', Validators.compose([Validators.required, Validators.email])],
      //password: ['', Validators.required],
      username: ['', Validators.compose([Validators.required])],
      password: ['', Validators.required],
      //papel: ['Patrocinador', Validators.required]
    })

    this.urlBefore = this.localStorageService.getItem('url_before');
    this.localStorageService.removeItem('url_before');

    if (this.localStorageService.getItem('idChamada')) {
      this.idChamada = this.localStorageService.getItem('idChamada');
      this.localStorageService.removeItem('idChamada');
    }

    if (this.localStorageService.getItem('inscreverSe')) {
      this.inscreverSe = this.localStorageService.getItem('inscreverSe');
      this.localStorageService.removeItem('inscreverSe');
    }

    this.buscarPapel();
  }

  submit_original(): void {
    console.log("aqui");

    if (this.formAcessarConta.valid) {
      this.exibirErroVista = false;

      let objEnvio = {...this.formAcessarConta.getRawValue()};
      objEnvio.username = objEnvio.username.trim();

      this.authenticationService.login(objEnvio).subscribe((data) => {
        this.dataUser = data;
        console.log(this.dataUser);


        let contasAtivas = this.dataUser.contas.map((x: any) => x).map((x: { papelPessoa: { nome: any; }; }) => x.papelPessoa?.nome);

        if(this.dataUser.perfil == PerfilEnum.Administrador){
          this.setDadosPermissao(this.dataUser);
        }
        else if(this.dataUser.perfil == PerfilEnum.Parecerista){
          this.setDadosPermissao(this.dataUser);
        }
        else {
          contasAtivas.forEach((contasAtivas: any) => {

            if (contasAtivas == 'Cidadão')
              contasAtivas = 'CIDADAO'

            switch (contasAtivas.toUpperCase()) {
              case PapelContaEnum.Cidadao:
                this.formPerfilCidadao = true;
                break;
              case PapelContaEnum.Empreendedor:
                this.formPerfilEmpreendedor = true;
                break;
              case PapelContaEnum.Patrocinador:
                this.formPerfilPatrocinador = true;
                break;
            }
          });

          if (this.formPerfilCidadao && !this.formPerfilEmpreendedor && !this.formPerfilPatrocinador)
            this.selectPerfil(PapelContaEnum.Cidadao);

          else if (this.formPerfilEmpreendedor && !this.formPerfilCidadao && !this.formPerfilPatrocinador)
            this.selectPerfil(PapelContaEnum.Empreendedor);

          else if (this.formPerfilPatrocinador && !this.formPerfilCidadao && !this.formPerfilEmpreendedor)
            this.selectPerfil(PapelContaEnum.Patrocinador);

          else
            this.active = 2;
        }

      }, (response: HttpErrorResponse) => {
        this.exibirErroVista = true;
        switch (response.status) {
          case HttpStatusCode.BadRequest:
            this.message = response.error?.mensagens[0]?.mensagem;
            break;
          default:
            this.message = response.error?.mensagens[0]?.mensagem;
            break;
        }
      });
    }
  }

  submit(): void {
    console.log("aqui");

    if (this.formAcessarConta.valid) {
      this.exibirErroVista = false;

      let objEnvio = {...this.formAcessarConta.getRawValue()};
      objEnvio.username = objEnvio.username.trim();

      this.authenticationService.login(objEnvio).subscribe((data) => {
        this.dataUser = data;
        console.log(this.dataUser);

        if(this.dataUser.perfil == PerfilEnum.Administrador || this.dataUser.perfil == PerfilEnum.Parecerista){
          this.setDadosPermissao(this.dataUser);
        } else {
          switch (this.dataUser.papel) {
            case PapelContaEnum.Cidadao:
              this.selectPerfil(PapelContaEnum.Cidadao);
              break;
            case PapelContaEnum.Empreendedor:
              this.selectPerfil(PapelContaEnum.Empreendedor);
              break;
            case PapelContaEnum.Patrocinador:
              this.selectPerfil(PapelContaEnum.Patrocinador);
              break;
            default:
              this.ambosPapeis = true;
              this.active = 2;
          }
        }

      }, (response: HttpErrorResponse) => {
        this.exibirErroVista = true;
        switch (response.status) {
          case HttpStatusCode.BadRequest:
            this.message = response.error?.mensagens[0]?.mensagem;
            break;
          default:
            this.message = response.error?.mensagens[0]?.mensagem;
            break;
        }
      });
    }
  }

  selectPerfil(papelContaEnum: PapelContaEnum) {
    switch (papelContaEnum) {
        case PapelContaEnum.Cidadao:
        this.dataUser.papelAtivo = "Cidadão";
        this.setValidarDadosPermissaoUsuario(this.dataUser.papelAtivo);
        break;
        case PapelContaEnum.Empreendedor:
        this.dataUser.papelAtivo = PapelContaEnum.Empreendedor;
        if(this.inscreverSe == true){
          this.redireciona = true;
        }
        this.setValidarDadosPermissaoUsuario(this.dataUser.papelAtivo);
        break;
        case PapelContaEnum.Patrocinador:
        this.dataUser.papelAtivo = PapelContaEnum.Patrocinador;
        this.setValidarDadosPermissaoUsuario(this.dataUser.papelAtivo);
        break;
    }
  }

  setValidarDadosPermissaoUsuario(papelContaEnum: PapelContaEnum): void {
    this.dataUser.papelAtivo = papelContaEnum;

    this.dataUser.idContaAtiva = ((this.dataUser.contas.filter((x: any) => x.papelPessoa.nome == this.capitalize(this.dataUser.papelAtivo))).map((x: { id: any; }) => x.id)).toString();

    this.dataUser.codigoProdutosContratados = ((this.dataUser.contas.filter((x: any) => x.papelPessoa.nome == this.capitalize(this.dataUser.papelAtivo))).map((x: { codigoProdutosContratados: any; }) => x.codigoProdutosContratados))[0];

    this.dataUser.produtosContratados = ((this.dataUser.contas.filter((x: any) => x.papelPessoa.nome == this.capitalize(this.dataUser.papelAtivo))).map((x: { produtosContratados: any; }) => x.produtosContratados))[0];

    this.setDadosPermissao(this.dataUser);
  }

  setDadosPermissao(dataUser: any): void {
    this.authenticationService.setCurrentUser(dataUser);

    let permissions = [];
    if (dataUser.perfil)
      permissions.push(dataUser.perfil.toString());

    if (dataUser.papelAtivo)
      permissions.push(dataUser.papelAtivo.toString());

    // if (dataUser.codigoProdutosContratados)
    //   dataUser.codigoProdutosContratados.forEach((x: any) => permissions.push(x));

    if (dataUser.produtosContratados)
    dataUser.produtosContratados.forEach((item: any) => permissions.push(item.codigo));

    this.permissionService.loadPermissions(permissions);

    if(this.redireciona == true){
      this.router.navigate([`/chamadas/inscrever-se/inserir/${this.idChamada}`]);
    }
    else if(this.urlBefore){
      window.open(this.urlBefore, '_self');
      this.urlBefore = null;
    }
    else{
      this.router.navigate(['/dashboard']);
    }

    this.fechar();
  }

  capitalize (text: any) {
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  }

  showError(mensagem: any) {
    this.notificationService.showError(mensagem);
  }

  fechar(): void {
    this.activeModal.close();
  }

  goToUrl(): void {
    window.open(`http://${environment.urlSiteAtual}`, '_self')
  }

  fecharModal(): void {
    this.goToUrl();
    this.fechar();
  }

  recuperarSenha(): void {
    this.fechar();
    this.router.navigate(['/login/recuperar-senha']);
  }

  recuperarUsername(): void {
    this.fechar();
    this.router.navigate(['/login/recuperar-username']);
  }

  openModalCadastrar(): void {
    this.fechar();

    const modalColunas = this.modalService.open(ModalEscolhaAdesaoComponent, {
      centered: true,
      beforeDismiss: () => false,
      size: 'xl',
      modalDialogClass: 'modal-grafico'
    })

    let instance = <ModalEscolhaAdesaoComponent>modalColunas.componentInstance;
  }

  buscarPapel(): void {
    this.dominioService.buscarPapeis().subscribe((data: any) => {
      this.papeis = data;
    });
  }

  get papelPessoa(): string {
    return this.formAcessarConta.controls.papelPessoa?.value;
  }
}
