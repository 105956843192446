import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenticationService } from '@core/auth/authentication.service';
import { environment } from '@env/environment';
import { of } from 'rxjs';
import { ApiService } from './api.service';

const routes = {
  listarEditais: `/relatorios/projetos/listar-editais`,
  listarEditaisPublicos: `/relatorios/projetos/listar-editais-publicos`,
  consultarProjetosPorEditail: (id: number) => `/relatorios/projetos/edital/${id}`
}

@Injectable({
  providedIn: 'root'
})
export class ListProjetosAtendemChamadaService {

  constructor(
    private apiService: ApiService,
    private authenticationService: AuthenticationService,
  ) { }

  listarEditais() {
    return this.apiService.get<any>(routes.listarEditais);
  }

  listarEditaisPublicos() {
    return this.apiService.get<any>(routes.listarEditaisPublicos);
  }
  
  consultarProjetosPorEditail(id: number, page:number,pageSize:number) {
    let params = new HttpParams();
    params = params.append('size',pageSize);
    params = params.append('page',page - 1);
    return this.apiService.get<any>(`${routes.consultarProjetosPorEditail(id)}`, undefined,params);
  }

}
