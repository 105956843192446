<div class="bg-body-color pb-5">
  <div class="container">
    <!-- <section class="titleEbreadcrumb mt-5">
      <div class="col-12 d-sm-flex align-items-center">
        <button class="btn btn-secundary-color d-flex align-items-center me-4 px-4 py-1 rounded-pill h7"
          (click)="voltarDashboard()">
          <i class="bi bi-arrow-left-short fs-6 me-1"></i>
          {{'Voltar' | translate}}
        </button>
        <nav class="col pt-3" aria-label="breadcrumb">
          <ol class="breadcrumb h7 fw-500">
            <li class="breadcrumb-item txt-primary-color text-decoration-underline">
              <a (click)="voltarDashboard()">{{'Dashboard' | translate}}</a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              {{ namePage }}
            </li>
          </ol>
        </nav>
      </div>
    </section> -->

    <div class="col-12 d-sm-flex align-items-center py-2">
      <a [href]="urlHome" class="btn btn-secundary-color d-flex align-items-center me-4 px-4 py-1 rounded-pill h7">
        <i class="bi bi-arrow-left-short fs-6 me-1"></i>
        {{'Home' | translate}}
      </a>
    </div>

    <section class="title py-3">
      <div class="col col-sm-2 px-5 mx-auto">
        <h1 class="h3 txt-support-color text-center title-line text-uppercase">
          {{ namePage }}
        </h1>
      </div>
    </section>
  </div>

  <div class="container mt-3">
    <form [formGroup]="form" class="col-12">
      <div class="form-group my-3">
        <label class="mb-1">Pesquisar</label>
        <input type="text" class="form-control tm-input" formControlName="textoPergunta" placeholder="Buscar perguntas" autocomplete="off" (change)="pesquisar()" />
      </div>
    </form>

    <div class="row gx-3 gy-2 mt-4 px-2" *ngFor="let item of lista">
      <div class="col-12 p-4 border border-brada">
        <h6>{{ item.textoPerguntaPT }}</h6>
        <h6 class="fw-400">{{ item.textoRespostaPT }}</h6>
        <div class="d-flex justify-content-center mt-5 pt-5" *ngIf="item.urlMidiaPT">
          <iframe [src]= "item.urlMidiaPT" width="560" height="315" frameborder="0" allowfullscreen></iframe>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="footer-pages bg-white-color">
</div>
