import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ChamadaPublicoService } from '@core/services/chamada-publico.service';

@Component({
  selector: 'app-oportunidades-site',
  templateUrl: './oportunidades-site.component.html',
  styleUrls: ['./oportunidades-site.component.scss']
})
export class OportunidadesSiteComponent implements OnInit {

  investidor = false;
  executor = false;
  colaborador = false;

  page = 1;
  pageSize = 3;
  lastPage = 1;
  lista: any[]=[];

  constructor(
    private router: Router,
    private chamadaPublicoService: ChamadaPublicoService,
    private formBuilder: FormBuilder,
    private sanitizer: DomSanitizer,
  ) { }

  formFiltroChamadas = this.formBuilder.group({
    nomeOuDescricao: [],
    idPatrocinador: [],
    estadoId: [],
    municipioId: [],
    areaInteresse: [],
    fonteFinanciamento: [],
    ods: [],
    tipoEdital: [],
    statusEdital: [],
    publico: [],
    ordenacao: [],
  });

  ngOnInit() {
    this.chamadasEmDestaque();
  }

  adesaoInvestidor(): void {
    this.router.navigate(['/adesao/investidor']);
  }

  maisEditais(): void {
    this.router.navigate(['/todas-chamadas']);
  }

  chamadasEmDestaque(){
    this.chamadaPublicoService.listarEmDestaque().subscribe((data: any) => {
      if(data)
        this.lista = data;
    });
  }

  fotosChamada(item: any): any {
    if(item){
      if(item.base64Content && item.base64info){
        let img = item.base64info + item.base64Content;
        if(img){
          return this.sanitizer.bypassSecurityTrustUrl(img);
        }
      }
    }
    return 'https://images.unsplash.com/photo-1485217988980-11786ced9454?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80';
  }

  visualizarLandingPage(idChamada: any){
    this.router.navigate([`/landingpage/chamada/visualizar/${idChamada}`]);
  }

}
